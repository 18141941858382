import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateService } from '@ngx-translate/core';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';
@Component({
  selector: 'iu-create-from-shipment-window-ui',
  templateUrl: './create-from-shipment-window-ui.component.html',
  styleUrls: ['./create-from-shipment-window-ui.component.scss'],
})
export class CreateFromShipmentWindowUiComponent extends SpecificWindowUiComponent implements OnInit {
  orderGrid: any;
  blanketOrderGrid: any;
  invoiceGrid: any;
  orderSelections: any;
  blanketOrderSelections: any;
  invoiceSelections: any;

  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    protected translateService: TranslateService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    progressService: ProcessInProgressService,
    private messageManager: MessageManagerService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
    this.isModal = false;
  }
  ngOnInit() {
    super.ngOnInit();
    this.dataStore.data['C_Order_ID'] = null;
    this.dataStore.data['C_Invoice_ID'] = null;
    this.dataStore.data['C_BlanketOrder_ID'] = null;
  }

  notifyFromDataChange(item: any) {
    /**
     * @start_custo_code
     */
    const usedInOtherReceiptsField = this.dataContainers.find((elem) => elem.data.columnName === 'XX_UsedInOtherReceipts');
    usedInOtherReceiptsField.updateStore('N');
    /**
     * @end_custo_code
     */
    if (item.data.columnName === 'C_Order_ID') {
      this.dataStore.data['C_Invoice_ID'] = null;
      this.dataStore.data['C_BlanketOrder_ID'] = null;
      /**
       * @start_custo_code
       */
      const v_C_Order_ID = this.getFormStoreData('C_Order_ID');
      if(v_C_Order_ID && v_C_Order_ID["id"]){
        this.subscriptions.push(
          this.uiCreator
            .getDBSelect(
              "SELECT iol.M_InOutLine_ID FROM M_InOutLine iol, M_InOut io WHERE iol.M_InOut_ID=io.M_InOut_ID AND iol.M_InOut_ID != ? AND io.DocStatus = 'DR' AND iol.C_OrderLine_ID IN (SELECT C_OrderLine_ID FROM C_OrderLine WHERE C_Order_ID = ?)",
              [this.dataStore.data.M_InOut_ID, v_C_Order_ID.id],
              []
            )
            .subscribe((response) => {
              if (response && response[0] && response[0]["M_INOUTLINE_ID"]) {
                if (usedInOtherReceiptsField !== undefined) {
                  usedInOtherReceiptsField.updateStore('Y');
                }
              }
            })
        );
      }
      /**
       * @end_custo_code
       */
      this.clearSelections();
    } else if (item.data.columnName === 'C_BlanketOrder_ID') {
      this.dataStore.data['C_Invoice_ID'] = null;
      this.dataStore.data['C_Order_ID'] = null;
      this.clearSelections();
    } else if (item.data.columnName === 'C_Invoice_ID') {
      this.dataStore.data['C_Order_ID'] = null;
      this.dataStore.data['C_BlanketOrder_ID'] = null;
      this.clearSelections();
    }
    super.notifyFromDataChange(item);
  }

  clearSelections() {
    this.dataStore.data['selections'].forEach((grid) => {
      grid['selection'] = [];
    });
    this.gridViews.forEach((grid) => {
      grid.GridTabInfinityScrollUiComponent.agGrid.api.deselectAll();
    });
  }

  /**
   * @start_custo_code
   */
  checkBeforeProcessing() {
    if (super.checkBeforeProcessing()) {
      let rowSelected = false;
      this.gridViews.forEach((grid) => {
        if (grid.GridTabInfinityScrollUiComponent.agGrid.api.getSelectedRows().length != 0) {
          rowSelected = true;
        }
      });
      if (!rowSelected) {
        this.messageManager.newMessage(
          new IupicsMessage(this.translateService.instant('create-from-shipment.rowSelected'), 'error')
        );
        return false;
      } else {
        return true;
      }
    }
  }
  /**
   * @end_custo_code
   */
}
