<div [ngClass]="{ 'p-steps p-component': true, 'p-readonly': readonly }" [ngStyle]="style" [class]="styleClass">
  <ul role="tablist" data-cy="workflow-stepper">
    <li
      *ngFor="let item of model; let i = index"
      class="p-steps-item"
      #menuitem
      [ngStyle]="item.style"
      [class]="item.styleClass"
      role="tab"
      [attr.aria-selected]="i === activeIndex"
      [attr.aria-expanded]="i === activeIndex"
      [ngClass]="{
        'p-highlight p-steps-current': isActive(item, i),
        'p-disabled': item.disabled || (readonly && !isActive(item, i))
      }"
    >
      <a
        *ngIf="!item.routerLink"
        [href]="item.url || '#'"
        class="p-menuitem-link"
        (click)="itemClick($event, item, i)"
        [attr.target]="item.target"
        [attr.id]="item.id"
        (contextmenu)="showCMChecker(item, i) ? onContextMenu($event) : null"
      >
        <span class="p-steps-number">{{ getDisplayIndex(i) }}</span> <span class="p-steps-title">{{ item.label }}</span>
      </a>
      <a
        *ngIf="item.routerLink"
        [routerLink]="item.routerLink"
        [queryParams]="item.queryParams"
        [routerLinkActive]="'p-highlight'"
        [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
        class="p-menuitem-link"
        (click)="itemClick($event, item, i)"
        [attr.target]="item.target"
        [attr.id]="item.id"
      >
        <span class="p-steps-number">{{ i + 1 }}</span>
        <span class="p-steps-title" *ngIf="item.escape !== false; else htmlRouteLabel">{{ item.label }}</span>
        <ng-template #htmlRouteLabel><span class="p-steps-title" [innerHTML]="item.label"></span></ng-template>
      </a>
    </li>
  </ul>
</div>
