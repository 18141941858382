import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import { DataStore } from '@compiere-ws/models/compiere-data-json';
import { PoService } from '@compiere-ws/services/po/po.service';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryUtils } from '@iupics-manager/managers/ui-creator/window-factory/window-factory-utils';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { IupicsEvent } from '@iupics-manager/models/iupics-event';
import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';

@Component({
  selector: 'iu-accordion-ui',
  templateUrl: './accordion-ui.component.html',
  styleUrls: ['./accordion-ui.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AccordionUiComponent extends AbstractDataContainer implements OnInit, AfterViewInit {
  @Input()
  selected: boolean;

  isMandatory: boolean;

  @HostBinding('class.p-col-nopad') hasNoPad = false;

  constructor(
    public elementRef: ElementRef,
    public store: DataStoreService,
    protected connectorService: SecurityManagerService,
    public cmService: ContextMenuService,
    public uiCreatorService: UICreatorService,
    private resolver: ComponentFactoryResolver,
    renderer: Renderer2,
    protected po: PoService
  ) {
    super(elementRef, connectorService, cmService, store, uiCreatorService, renderer, po);
  }

  ngOnInit() {
    super.ngOnInit();
    this.cssGrid = 'p-col-12';
    if (this.data) {
      if (!this.data.isCollapsedDefault) {
        this.selected = true;
      } else {
        this.selected = this.data.isSelected;
      }
    }
    this.isAccordion = true;
  }

  onChildUpdate(event: IupicsEvent) {}
  onSiblingUpdate(event: IupicsEvent) {}
  onRemoveComponent(event: IupicsEvent) {}
  ngAfterViewInit() {
    super.ngAfterViewInit();
    setTimeout(() => {
      this.children.forEach((child) => {
        WindowFactoryUtils.addContainerComponent(this, child, this.resolver, child.container ? child.isCssOnComponent : false);
      });
    }, 0);
  }

  setMandatory() {
    // check if mandatory children are OK
    let found = false;
    for (let i = 0; i < this.DOMChildrenComponent.length && !found; i++) {
      for (let j = 0; j < this.DOMChildrenComponent[i].DOMChildrenComponent.length && !found; j++) {
        const field = this.DOMChildrenComponent[i].DOMChildrenComponent[j];
        // si autocomplete
        if (
          !found &&
          (<any>field).isAutocompleteField === true &&
          field.data.isMandatory === true &&
          (<any>field).displayCss !== 'none' &&
          ((<any>field).fieldValue === undefined ||
            (<any>field).fieldValue === null ||
            !((<any>field).fieldValue instanceof Object))
        ) {
          found = true;
        }
        // si c'est autre qu'autocomplete
        if (
          !found &&
          field.data.isMandatory === true &&
          (<any>field).displayCss !== 'none' &&
          ((<any>field).fieldValue === undefined || (<any>field).fieldValue === '')
        ) {
          found = true;
        }
      }
    }
    this.isMandatory = found;
  }

  updateDisplay(dataStored: DataStore) {
    super.updateDisplay(dataStored);
    if (this.displayCss !== 'none') {
      this.displayCss = 'none';
      if (this.DOMChildrenComponent && this.DOMChildrenComponent.length > 0) {
        // pour toutes les row
        for (let i = 0; i < this.DOMChildrenComponent.length && this.displayCss === 'none'; i++) {
          const rowUI = this.DOMChildrenComponent[i];
          if (rowUI.DOMChildrenComponent && rowUI.DOMChildrenComponent.length > 0) {
            // pour tous les input de la row
            for (let j = 0; j < rowUI.DOMChildrenComponent.length && this.displayCss === 'none'; j++) {
              const input = rowUI.DOMChildrenComponent[j] as AbstractDataContainer;
              input.isFieldDisplay(dataStored).subscribe((displayed) => {
                if (displayed) {
                  this.displayCss = 'inline';
                }
              });
            }
          }
        }
      }
    }
    this.hasNoPad = this.displayCss === 'none';
  }
}
