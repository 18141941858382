import { DataStoreRequest } from '@compiere-ws/models/compiere-data-json';

export interface KanbanGroup {
  datas: DataKanban[];
  isMoreData: boolean;
  dataStoreRequest: DataStoreRequest;
  columnName?: string;
  title?: string;
  groupValue?: any;
}

export interface DataKanban {
  data: any;
  image: string;
  rows: {
    dataAlignLeft: { type: DataKanbanType; value: string }[];
    dataAlignright: { type: DataKanbanType; value: string }[];
  }[];
}
export enum DataKanbanType {
  TEXT = 'text',
  IMG = 'image'
}
