export enum IupicsMenuType {
  WINDOW = 'Window',
  PROCESS = 'Process',
  REPORT = 'Report',
  FORM = 'Form',
  WORKFLOW = 'Workflow',
  SUMMARY = 'Summary',
  INFO_WINDOW = 'Info Window'
}

export interface MenuCategoryUI {
  /**
   * -1 = Tous les menus\
   * 0  = Une vraie catégorie\
   * 1  = (Sans catégorie)
   */
  id: number;
  name: string;
  isSelected?: boolean;
  icon: string;
  isDisplay: boolean;
}

export interface MenuFavoritesCategoryUI {
  id: number;
  name: string;
  seqNo?: number;
}

export interface MenuItemUI {
  menu_id: number;
  angularClass?: string;
  description?: string;
  menu_category?: MenuCategoryUI;
  menu_type: IupicsMenuType;
  action_id: number;
  iconClass: string;
  color: string;
  name: string;
  tags: string[];
  isSummary: boolean;
  parentId: number;
  type?: string; // REMOVE IN FUTUR
  id?: string;
  seqNo?: number;
}
