import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  CompiereDataGridGroupModel,
  CompiereDataGridRequestJSON,
  CompiereDataGridType,
  DataStoreRequest
} from '@compiere-ws/models/compiere-data-json';
import { KanbanGroup } from '@iupics-components/models/kanban-interface';
import { BladeUiComponent } from '@iupics-components/standard/layouts/blade-ui/blade-ui.component';
import { AppConfig } from '@iupics-config/app.config';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { IupicsColumnKanban } from '@iupics-manager/models/iupics-data';
import { IupicsEvent } from '@iupics-manager/models/iupics-event';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { KanbanUtils } from '../utils/kanban.utils';

@Component({
  selector: 'iu-kanban-ui',
  templateUrl: './kanban-ui.component.html',
  styleUrls: ['./kanban-ui.component.scss']
})
export class KanbanUiComponent extends AbstractDynamicComponent implements OnInit, AfterViewInit {
  @Input()
  tableName: string;
  @Input()
  datas: any[];
  @Input()
  filter: CompiereDataGridRequestJSON;
  @Input()
  columns_display_AD: IupicsColumnKanban[];
  @Input()
  kanbanImageColumn: string;
  @Input()
  initRequest: CompiereDataGridRequestJSON;
  @Output()
  clickEmitter = new EventEmitter<any>();
  @Output()
  setFilterEmitter = new EventEmitter<CompiereDataGridRequestJSON>();
  draggedElement: any;
  kanbanGroups: KanbanGroup[] = [];
  prevKanbanGroups: KanbanGroup[] = [];
  datasByGroup: any[][];
  prevRowGroupCols: CompiereDataGridGroupModel[] = [];
  initialStoreRequest: DataStoreRequest;

  private isGetDatagridInProgress = false;

  constructor(private dataStore: DataStoreService, private config: AppConfig, private translateService: TranslateService) {
    super();
  }
  ngOnInit() {}
  ngAfterViewInit() {
    this.getData();
  }
  getData() {
    if (this.isGetDatagridInProgress) {
      return;
    }
    if (this.container) {
      this.datas = [];
      this.queryDataStore();
    } else if (this.datas) {
      if (this.filter && this.filter.rowGroupCols.length > 0 && this.filter.rowGroupCols[0].field) {
        this.kanbanGroups = this.convertDataToDataKanban(this.datas, false, this.filter.rowGroupCols[0]);
      } else {
        this.kanbanGroups = this.convertDataToDataKanban(this.datas, false);
      }
      if (this.prevKanbanGroups.length > 0) {
        this.kanbanGroups = [
          ...this.prevKanbanGroups.filter(
            (prevGroup) => !this.kanbanGroups.find((group) => prevGroup.groupValue.id === group.groupValue.id)
          ),
          ...this.kanbanGroups
        ];
      }
      this.prevKanbanGroups = [...this.kanbanGroups];
      this.prevKanbanGroups = this.prevKanbanGroups.map((group) => {
        group = { ...group, datas: [], isMoreData: false };
        return group;
      });
    }
  }
  convertDataToDataKanban(datas: any[], isMoreData: boolean, columnGroup?: {}): KanbanGroup[] {
    let columnGroupName: string;
    if (columnGroup) {
      columnGroupName = columnGroup['field'].replace(/"/g, '');
    }
    const groupsObj = {};
    datas.forEach((data) => {
      let key = 'default';
      if (data[columnGroupName] !== undefined && data[columnGroupName] !== null) {
        key = data[columnGroupName]
          ? data[columnGroupName].hasOwnProperty('id')
            ? data[columnGroupName]['id']
            : data[columnGroupName]
          : 'null';
        if (!groupsObj[key]) {
          const dataStoreRequest = Object.assign({}, this.initialStoreRequest);
          dataStoreRequest.compiereRequest = Object.assign({}, this.initialStoreRequest.compiereRequest);
          dataStoreRequest.compiereRequest.groupKeys = [data[columnGroupName]];
          groupsObj[key] = <KanbanGroup>{
            title: data[columnGroupName]
              ? data[columnGroupName].hasOwnProperty('id')
                ? data[columnGroupName]['displayValue']
                : data[columnGroupName]
              : data[columnGroupName],
            columnName: columnGroup,
            groupValue: data[columnGroupName],
            datas: [],
            isMoreData: isMoreData,
            dataStoreRequest: dataStoreRequest
          };
        }
      } else if (!groupsObj[key] && !columnGroup) {
        const dataStoreRequest = Object.assign({}, this.initialStoreRequest);
        groupsObj[key] = <KanbanGroup>{
          title: this.translateService.instant('kanban.all'),
          datas: [],
          isMoreData: isMoreData,
          dataStoreRequest: dataStoreRequest
        };
      }

      if (!columnGroup) {
        const dataKanban = KanbanUtils.transformDataForKanbanView(data, this.columns_display_AD, this.kanbanImageColumn);
        if (data[columnGroupName] !== undefined && groupsObj[key]) {
          groupsObj[key].datas.push(dataKanban);
        } else {
          groupsObj[key].datas.push(dataKanban);
        }
      }
    });
    const kanbanGroups = [];
    Object.keys(groupsObj).forEach((key) => {
      kanbanGroups.push(groupsObj[key]);
    });
    return kanbanGroups;
  }

  applyFilter(filter: CompiereDataGridRequestJSON) {
    this.filter = filter;
    this.queryDataStore();
  }

  queryDataStore() {
    this.initialStoreRequest = {
      windowId: (<BladeUiComponent>this.container).infoComponent.windowId,
      compiereRequest: {
        windowType: CompiereDataGridType.WINDOW,
        entityId: this.tabId,
        startRow: 0,
        endRow: this.config.getConstant('GridTabInfinityScrollUiComponent#cacheBlockSize')
      }
    };
    if (this.initRequest) {
      this.filter = {};
      if (this.initRequest.filterModel) {
        this.filter.filterModel = { ...this.initRequest.filterModel };
      }
      if (this.initRequest.sortModel) {
        this.filter.sortModel = [...this.initRequest.sortModel];
      }
      if (this.initRequest.rowGroupCols) {
        this.filter.rowGroupCols = [...this.initRequest.rowGroupCols];
      }
    }
    if (this.filter) {
      this.initialStoreRequest.compiereRequest.filterModel = this.filter.filterModel;
      this.initialStoreRequest.compiereRequest.rowGroupCols = this.filter.rowGroupCols;
      this.initialStoreRequest.compiereRequest.sortModel = this.filter.sortModel;
    }
    // appliquer des querys venant de l'url
    if (this.initRequest) {
      this.initRequest = undefined;
      this.setFilterEmitter.emit(this.initialStoreRequest.compiereRequest);
    } else {
      this.isGetDatagridInProgress = true;
      this.subscriptions.push(
        this.dataStore
          .getDataGrid(this.initialStoreRequest, true)
          .pipe(tap((_) => (this.isGetDatagridInProgress = false)))
          .subscribe((response) => {
            if (response.data) {
              this.datas = response.data;
              if (this.filter && this.filter.rowGroupCols.length > 0 && this.filter.rowGroupCols[0]) {
                this.kanbanGroups = this.convertDataToDataKanban(this.datas, response.lastRow <= -1, this.filter.rowGroupCols[0]);
              } else {
                this.kanbanGroups = this.convertDataToDataKanban(this.datas, response.lastRow <= -1);
              }
              if (this.filter.rowGroupCols[0] && this.prevRowGroupCols[0]) {
                if (this.kanbanGroups.length > 0 && this.filter.rowGroupCols[0].id === this.prevRowGroupCols[0].id) {
                  this.kanbanGroups = [
                    ...this.prevKanbanGroups.filter(
                      (prevGroup) => !this.kanbanGroups.find((group) => prevGroup.groupValue.id === group.groupValue.id)
                    ),
                    ...this.kanbanGroups
                  ];
                }
              }
              this.prevRowGroupCols = this.filter.rowGroupCols;
              this.prevKanbanGroups = [...this.kanbanGroups];
              this.prevKanbanGroups = this.prevKanbanGroups.map((group) => {
                group = { ...group, datas: [], isMoreData: false };
                return group;
              });
            }
          })
      );
    }
  }

  onChildUpdate(event): void {}

  onSiblingUpdate(event: IupicsEvent) {}

  onRemoveComponent(event: IupicsEvent) {}
}
