import { AfterViewInit, Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { ProcessUiComponent } from '@iupics-components/specific/window/process-ui/process-ui.component';
import { WindowFactoryUtils } from '@iupics-manager/managers/ui-creator/window-factory/window-factory-utils';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsEvent } from '@iupics-manager/models/iupics-event';

@Component({
  selector: 'iu-grid-ui',
  templateUrl: './grid-ui.component.html',
  styleUrls: ['./grid-ui.component.css']
})
export class GridUiComponent extends AbstractDynamicComponent implements OnInit, AfterViewInit {
  constructor(private resolver: ComponentFactoryResolver) {
    super();
  }

  ngOnInit() {
    if (!this.cssClass || Global.isMobileWidth()) {
      this.cssClass = 'p-col-12 p-col-nopad';
    }
  }
  ngAfterViewInit() {
    // timeout pour afficher les élément un par un
    if (
      this.DOMParentComponent &&
      this.DOMParentComponent['componentRef'] &&
      this.DOMParentComponent['componentRef'].componentType.name === 'ProcessUiComponent'
    ) {
      const currentProcess = this.DOMParentComponent;
      this.parentProcess = <ProcessUiComponent>currentProcess;
    }
    let i = 0;
    this.children.forEach((child) => {
      setTimeout(() => {
        WindowFactoryUtils.addContainerComponent(this, child, this.resolver, child.container ? child.isCssOnComponent : false);
      }, (i += 1));
    });
  }
  onChildUpdate(event): void {}

  onSiblingUpdate(event: IupicsEvent) {}

  onRemoveComponent(event: IupicsEvent) {}
}
