<div class="iu-specific-window" [ngClass]="{ 'iu-specific-window-container': !isModal }">
  <p-scrollPanel [style]="{ width: '100%' }">
    <ng-template iuDynamicContainer></ng-template>
    <div class="iu-specific-btn-container">
      <ng-template #vcrButtons></ng-template>
      <ng-template #vcrButtons></ng-template>
      <iu-button-ui
        *ngIf="isModal"
        class="iu-btn btn-cancel p-col-5 p-md-3 p-lg-1"
        (click)="$event.preventDefault(); $event.stopPropagation(); closeModalEmitter.emit()"
        label="{{ 'process.cancel' | translate }}"
        icon="fa fa-ban"
      >
      </iu-button-ui>
    </div>
  </p-scrollPanel>
</div>
<ng-container *ngIf="displaySearch">
  <iu-modal-ui
    [title]="this.searchLinkedComponent.autoComplete.label"
    [hasCloseBtn]="true"
    (closeModalEmitter)="displaySearch = false"
  >
    <div class="autocomplete-universal-filter">
      <iu-universal-filter-ui
        [tabId]="-1"
        [columns]="searchLinkedComponent.autoComplete.data.searchColumns"
        [gridTab]="gridTab"
      ></iu-universal-filter-ui>
    </div>
    <iu-grid-tab-infinity-scroll-ui
      #gridTab
      (searchEmitter)="this.searchLinkedComponent.setSearchSelectItem($event); displaySearch = false"
      [data]="this.searchLinkedComponent.autoComplete.data"
      [isSearch]="true"
      [dataStored]="this.searchLinkedComponent.autoComplete.dataStored"
    ></iu-grid-tab-infinity-scroll-ui>
  </iu-modal-ui>
</ng-container>
