import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LanguageSelectionService } from '@web-desktop/components/menu-top/controllers/language-selection/language-selection.service';
const enum ErrorMessage {
  ACCESDENIED = "Attention!!! Vous n'avez pas les droits nécessaires pour accéder à cette page.",
  HIDDEN = 'hidden'
}
@Component({
  selector: 'iu-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  @Input()
  message;
  constructor(private route: ActivatedRoute, private languageSelectionService: LanguageSelectionService) {}
  ngOnInit() {
    this.languageSelectionService.initLanguages();
    this.route.queryParams.subscribe(data => {
      this.message = data['message'];
    });
    this.route.url.subscribe(url => {
      if (url[0].path !== 'accesUnauthorized') {
        this.message = 'pagenotfound.you_are_lost';
      }
    });
  }
}
