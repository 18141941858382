<div
  *ngIf="render"
  [ngClass]="'ui-overlaypanel ui-widget ui-widget-content ui-corner-all ui-shadow'"
  [ngStyle]="style"
  [class]="styleClass"
  (click)="onContainerClick()"
  [@animation]="{
    value: overlayVisible ? 'open' : 'close',
    params: {
      showTransitionParams: showTransitionOptions,
      hideTransitionParams: hideTransitionOptions,
      voidTranslateY: voidTranslateY,
      closeTranslateY: closeTranslateY,
      openTranslateY: openTranslateY
    }
  }"
  (@animation.start)="onAnimationStart($event)"
  (@animation.done)="onAnimationEnd($event)"
>
  <div class="ui-overlaypanel-content">
    <ng-content></ng-content>
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </div>
  <a
    tabindex="0"
    *ngIf="showCloseIcon"
    class="ui-overlaypanel-close ui-state-default"
    (click)="onCloseClick($event)"
    (keydown.enter)="hide()"
    [attr.aria-label]="ariaCloseLabel"
  >
    <span class="ui-overlaypanel-close-icon pi pi-times"></span>
  </a>
</div>
