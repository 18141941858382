import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { CompiereDataGridType, DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { CompiereDataService } from '@compiere-ws/services/compiere-data/compiere-data.service';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ViewType } from '@iupics-components/models/view-type.enum';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccesRessourceGuard implements CanActivateChild {
  component = { id: null, recordId: null, type: null, viewData: null };
  constructor(
    private router: Router,
    private uiCreatorService: UICreatorService,
    private processService: CompiereProcessService,
    private dataService: CompiereDataService,
    private store: DataStoreService
  ) {}
  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkRes(next);
  }
  /**
   * @param route it contains url params
   * @param state it contains the url
   * @description

check whether the current user has the authorization to access to the specified resource*/
  private checkRes(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const data = route.paramMap;
    if (data.get('windowId')) {
      this.component.id = +data.get('windowId');
      this.component.recordId = data.get('recordId');
      return this.checkAccesToWindow(this.component.id, route, this.component.recordId);
    } else if (data.get('processId')) {
      this.component.id = +data.get('processId');
      return this.checkAccesToProcess(this.component.id);
    } else if (data.get('formId')) {
      this.component.id = +data.get('formId');
      return this.checkAccesToForm(this.component.id);
    } else if (data.get('reportId')) {
      this.component.id = +data.get('reportId');
      return this.checkAccesToReport(this.component.id);
    } else {
      return true;
    }
  }
  /**
   * @param id window id
   * @description check whether the current user has access to a window which has the specified id
   */
  private checkAccesToWindow(id: number, route: ActivatedRouteSnapshot, recordId?: string): Observable<boolean> {
    return new Observable<boolean>(obs =>
      this.uiCreatorService.getWindow(id).subscribe(
        success => {
          if (
            route.queryParams['viewType'] &&
            route.queryParams['viewType'] === ViewType.TREE &&
            !success[0].gridView.data.hasTree
          ) {
            this.router.navigate(['/accesUnauthorized'], { queryParams: { message: 'pagenotfound.no_viewType' } });
          }
          // Check si on ouvre un record existant ou un nouveau record
          if (recordId && recordId !== 'newRecord') {
            const recordIdSplit = recordId.split(',');
            // check si le record est bien constitué sous forme clé-valeur
            if (recordIdSplit.length > 1) {
              const request: DataStoreRequest = {
                windowId: id,
                record_id: recordId,
                parent_constraint: undefined,
                compiereRequest: {
                  windowType: CompiereDataGridType.WINDOW,
                  entityId: success[0].tabId,
                  startRow: 0,
                  endRow: 1
                }
              };
              this.store.getWindowSingleData(request).subscribe(response => {
                if (!response || response.data.length === 0) {
                  this.router.navigate(['/accesUnauthorized'], { queryParams: { message: 'pagenotfound.no_ressource' } });
                  obs.next(false);
                } else {
                  obs.next(true);
                }
              });
            } else {
              this.router.navigate(['/accesUnauthorized'], { queryParams: { message: 'pagenotfound.no_ressource' } });
              obs.next(false);
            }
          } else {
            obs.next(true);
          }
          // check si le type de vue est autorisé pour cette fenetre
        },
        error => {
          this.router.navigate(['/accesUnauthorized'], { queryParams: { message: 'pagenotfound.no_ressource' } });
          obs.next(false);
        }
      )
    );
  }

  /**
   * @param id form id
   * @description check whether the current user has access to a form which has the specified id
   */
  private checkAccesToForm(id: number): Observable<boolean> {
    return new Observable(obs =>
      this.uiCreatorService.getSpecificWindow(id).subscribe(
        success => {
          if (success) {
            obs.next(true);
          } else {
            this.router.navigate(['/accesUnauthorized'], { queryParams: { message: 'pagenotfound.no_ressource' } });
            obs.next(false);
          }
        },
        error => {
          this.router.navigate(['/accesUnauthorized'], { queryParams: { message: 'pagenotfound.no_ressource' } });
          obs.next(false);
        }
      )
    );
  }
  /**
   * @param id process id
   * @description check whether the current user has access to a process which has the specified id
   */
  private checkAccesToProcess(id: number): Observable<boolean> {
    return new Observable(obs =>
      this.processService.getProcess(id).subscribe(
        success => {
          if (success.process.ClassName || success.process.ProcedureName) {
            obs.next(true);
          } else {
            this.router.navigate(['/accesUnauthorized'], { queryParams: { message: 'pagenotfound.no_ressource' } });
            obs.next(false);
          }
        },
        error => {
          this.router.navigate(['/accesUnauthorized'], { queryParams: { message: 'pagenotfound.no_ressource' } });
          obs.next(false);
        }
      )
    );
  }
  /**
   * @param id report id
   * @description check whether the current user has access to a report which has the specified id
   */
  private checkAccesToReport(id: number): Observable<boolean> {
    return new Observable(obs =>
      this.processService.getProcess(id).subscribe(
        success => {
          if (success.params.length > 0) {
            obs.next(true);
          } else {
            this.router.navigate(['/accesUnauthorized'], { queryParams: { message: 'pagenotfound.no_ressource' } });
            obs.next(false);
          }
        },
        error => {
          this.router.navigate(['/accesUnauthorized'], { queryParams: { message: 'pagenotfound.no_ressource' } });
          obs.next(false);
        }
      )
    );
  }
}
