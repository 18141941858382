<iu-prime-steps
  *ngIf="isDisplay"
  [model]="items"
  [isIndexNumber]="isIndexNumber"
  [activeIndex]="activeIndex"
  styleClass="steps-custom"
  [readonly]="isReadOnly"
  [attr.data-cy-value]="isReadOnly"
  (activeIndexChange)="changeItem($event)"
  [preventPrevious]="preventPrevious"
></iu-prime-steps>
