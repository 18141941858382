import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { PoService } from '@compiere-ws/services/po/po.service';
import { EditTabUiComponent } from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractDataContainer, AbstractDataContainerCallout } from '@iupics-manager/models/abstract-datacontainer';
import { IupicsEvent } from '@iupics-manager/models/iupics-event';
import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';

@Component({
  selector: 'iu-input-textarea-ui',
  templateUrl: './input-textarea-ui.component.html',
  styleUrls: ['./input-textarea-ui.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class InputTextareaUiComponent extends AbstractDataContainer implements OnInit, AfterViewInit {
  constructor(
    public elementRef: ElementRef,
    public store: DataStoreService,
    protected connectorService: SecurityManagerService,
    public uiCreatorService: UICreatorService,
    public cmService: ContextMenuService,
    renderer: Renderer2,
    protected po: PoService
  ) {
    super(elementRef, connectorService, cmService, store, uiCreatorService, renderer, po);
  }
  cssClassMandatory: string;
  @Input()
  columnName: string;
  @ViewChild('input', { static: true })
  inputRef: ElementRef;
  dataContainers: AbstractDataContainerCallout;

  @Input()
  resize: 'vertical' | 'horizontal' | 'auto' | 'none' = 'vertical';
  @Input()
  shouldSyncHeightWithLine = false;
  @Input()
  maxRows = 5;

  get rows() {
    const split = (this.fieldValue as string)?.split(/\r*\n/).length;
    if (split > this.maxRows) {
      return this.maxRows;
    }
    return split || 1;
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.cssClass !== undefined) {
      this.cssGrid = this.cssClass;
    }
    this.cssClass = ' ' + this.cssGrid;
    this.setFieldMandatory();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    let parentComp = this.DOMParentComponent;
    while (parentComp && !(parentComp instanceof EditTabUiComponent)) {
      parentComp = parentComp.DOMParentComponent;
    }
    if (parentComp instanceof EditTabUiComponent) {
      this.dataContainers = new AbstractDataContainerCallout(parentComp.dataContainers);
    }
    if (this.data && this.data.callouts && this.data.callouts.callouts) {
      this.data.callouts.callouts.forEach((callout) => {
        this.inputRef.nativeElement.addEventListener(callout.calloutevent, () => {
          const calloutFn = new Function('Field', 'Fields', 'DBSelect', callout.script);
          calloutFn(this, this.dataContainers, this.uiCreatorService);
        });
      });
    }
  }

  onChildUpdate(event: IupicsEvent) {}
  onSiblingUpdate(event: IupicsEvent) {}
  onRemoveComponent(event: IupicsEvent) {}
}
