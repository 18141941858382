import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { GridViewUiComponent } from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { EditViewUiComponent } from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateService } from '@ngx-translate/core';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-non-product-catalog',
  templateUrl: './non-product-catalog.component.html',
  styleUrls: ['./non-product-catalog.component.scss']
})
export class NonProductCatalogComponent extends SpecificWindowUiComponent implements OnInit {

  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    protected translateService: TranslateService,
    private messageManager: MessageManagerService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    progressService: ProcessInProgressService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
    this.isModal = false;
  }
  ngOnInit() {
    super.ngOnInit();
    //this.showSpecificWindow();
  }

  notifyFromDataChange(item: any) {
    if (item.data.columnName === 'XX_Thickness' || item.data.columnName === 'XX_Length') {
      const thickness = this.dataStore.data['XX_Thickness'];
      const length = this.dataStore.data['XX_Length'];

      this.subscriptions.push(
        this.uiCreator
          .getDBSelect(
            'SELECT (SELECT CHARGEPERTN FROM XX_CutCharge WHERE XX_Thickness = ? AND ROWNUM = 1) ChargePerTN,' +
            ' (SELECT XX_LENGTH FROM XX_NoCutCharge WHERE XX_Length = ? AND ROWNUM = 1) XX_Length' +
            ' FROM DUAL',
            [thickness, length],
            []
          )
          .subscribe(response => {
            if (response !== undefined && response[0]) {
              const chargePerTNContainer = this.dataContainers.find(elem => elem.data.columnName === 'ChargePerTN');
              if (item.data.columnName === 'XX_Thickness' && !response[0].XX_LENGTH && response[0].CHARGEPERTN) {
                chargePerTNContainer.updateStore(response[0].CHARGEPERTN);
              } else if (item.data.columnName === 'XX_Length' && response[0].XX_LENGTH && response[0].CHARGEPERTN) {
                chargePerTNContainer.updateStore(0);
              }
            }
          })
      );

    }

    super.notifyFromDataChange(item);
  }

  checkBeforeProcessing() {
    super.checkBeforeProcessing();
    console.log('Hello .');
    console.log(this);
    const p_XX_Length = this.dataStore.data.XX_Length;
    const p_XX_Width = this.dataStore.data.XX_Width;
    const p_XX_Thickness = this.dataStore.data.XX_Thickness;

    if (!p_XX_Length || p_XX_Length === '0' || !p_XX_Width || p_XX_Width === '0' || !p_XX_Thickness || p_XX_Thickness === '0') {
      this.messageManager.newMessage(
        new IupicsMessage(
          this.translateService.instant('blmErrors.diemnsionError'),
          'error'
        )
      );
      console.log('Failed');
      return false;
    } else {
      console.log('Success');
      return true;
    }
  }

  onEndProcess() {
    this.refreshAllBlades();

    super.closeForm();
  }

  refreshAllBlades() {
    let editViews: EditViewUiComponent[];
    if (this.parentComponent && this.parentComponent.DOMParentComponent &&
      this.parentComponent.DOMParentComponent.DOMChildrenComponent) {
      if (this.parentComponent.DOMParentComponent.DOMChildrenComponent[0]) {
        const windowId = (<GridViewUiComponent>this.parentComponent.DOMParentComponent.DOMChildrenComponent[0]).data.AD_window_ID;
        editViews = <EditViewUiComponent[]>this.parentComponent.DOMParentComponent.DOMChildrenComponent.slice()
          .filter(
            child =>
              child instanceof EditViewUiComponent && child.currentDataStoreKey && windowId == child.currentDataStoreKey.windowId
          );
      }
    }
    editViews.forEach(editView => {
      editView.refreshData();
      editView.isProcessLaunch = false;
    });
  }

}
