import { AfterViewInit, Component, ComponentFactoryResolver, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataStore, DataStoreKey } from '@compiere-ws/models/compiere-data-json';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { EditViewTab } from '@iupics-components/models/edit-view-tab';
import { EditTabUiComponent } from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { GridUiComponent } from '@iupics-components/standard/layouts/grid-ui/grid-ui.component';
import { MenuBarDetailUiComponent } from '@iupics-components/standard/menu/menu-bar-detail-ui/menu-bar-detail-ui.component';
import { AppConfig } from '@iupics-config/app.config';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { WindowFactoryUtils } from '@iupics-manager/managers/ui-creator/window-factory/window-factory-utils';
import { AbstractDynamicView } from '@iupics-manager/models/abstract-dynamic-view';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsEvent, IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';
import { BladeUiComponent } from '../blade-ui/blade-ui.component';
import { EditViewUiComponent } from '../edit-view-ui/edit-view-ui.component';
@Component({
  selector: 'iu-additional-info-ui',
  templateUrl: './additional-info-ui.component.html',
  styleUrls: ['./additional-info-ui.component.scss']
})
export class AdditionalInfoUiComponent extends AbstractDynamicView implements OnInit, AfterViewInit, OnDestroy {
  submitted: boolean;
  editTabs: EditTabUiComponent[] = [];
  noData = false;
  scrollPanelid: string;
  widthExpanded: string;
  mustRefresh = false;
  hasConflicts = false;
  wantToMergeConflicts = false;
  localData: DataStore;
  remoteData: DataStore;
  oldDataStoreKey: DataStoreKey;
  displayJoinFilesPanel = false;
  isZoomEditView = false;

  @ViewChild(MenuBarDetailUiComponent, { static: true })
  menuBarDetailComponent: MenuBarDetailUiComponent;

  @ViewChild(GridUiComponent, { static: true })
  editTabcomponent: GridUiComponent;
  @ViewChild('article', { static: true })
  articleElement: ElementRef;

  height: number;

  currentDataStoreKey: DataStoreKey;

  currentTabActive: EditViewTab;
  constructor(
    private store: DataStoreService,
    private socketService: SocketService,
    private config: AppConfig,
    private resolver: ComponentFactoryResolver,
    private connectorService: SecurityManagerService
  ) {
    super();
    this.height = this.config.getConstant('AdditionalInfoUiComponent#height');
  }

  ngOnInit() {
    // this.vcr = this.DOMParentComponent.vcr;
    this.scrollPanelid = 'scrollPanelEditView' + this.tabId;
  }
  getCurrentDataStoreKey() {
    return this.currentDataStoreKey;
  }

  ngAfterViewInit() {
    this.children.forEach(child =>
      WindowFactoryUtils.addContainerComponent(this, child, this.resolver, child.container ? child.isCssOnComponent : false)
    );
    (<EditViewUiComponent>this.DOMParentComponent).retrieveData(
      (<EditViewUiComponent>this.DOMParentComponent).currentDataStoreKey
    );
    setTimeout(() => {
      (<BladeUiComponent>this.container).updateScrollingPosition(
        (<EditViewUiComponent>this.DOMParentComponent).editViewElement.nativeElement
      );
      const event: IupicsEvent = (<EditViewUiComponent>this.DOMParentComponent).lastEvent;
      if (event) {
        event.item = { ...event.item, DOMParentComponent: null };
        event.item.DOMParentComponent = this;
        (<EditViewUiComponent>this.DOMParentComponent).onSiblingUpdate(event);
      }
    });
  }
  addTabToEditView(editTabUi: EditTabUiComponent) {
    editTabUi.data.isAdditionalInfo = true;
    this.editTabs.push(editTabUi);
  }

  onRemoveComponent(event: IupicsEvent) {
    if (event.type === IupicsTypeEvent.removeChildEvent) {
      event.type = IupicsTypeEvent.removeBreadcrumbItem;
      this.DOMParentComponent.onChildUpdate(event);
      this.notifierLinkedComponent.next({
        type: IupicsTypeEvent.expandEvent,
        item: undefined
      });
    }
  }

  onChildUpdate(event): void {
    if (event.type === IupicsTypeEvent.showAdditionalInfo) {
      this.currentDataStoreKey = event.item.dataStoreKey;
    }
  }

  onSiblingUpdate(event: IupicsEvent) {}
  isTabDisplay(displayLogic: string, dataMap: Map<string, any>): boolean {
    if (displayLogic) {
      return LogicEvaluator.evaluateLogic(dataMap, displayLogic);
    }
    return true;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (Global.mergeLevel !== 0) {
      this.socketService.closeDataChannel(this.currentDataStoreKey.windowId + '-' + this.currentDataStoreKey.recordId);
    }
  }
}
