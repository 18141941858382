import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { DataStore } from '@compiere-ws/models/compiere-data-json';
import { PoService } from '@compiere-ws/services/po/po.service';
import { PrimeCalendarComponent } from '@iupics-components/overrided/prime-calendar/prime-calendar.component';
import { EditTabUiComponent } from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractDataContainer, AbstractDataContainerCallout } from '@iupics-manager/models/abstract-datacontainer';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';
import { TranslateService } from '@ngx-translate/core';
import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'iu-calendar-ui',
  templateUrl: './calendar-ui.component.html',
  styleUrls: ['./calendar-ui.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CalendarUiComponent extends AbstractDataContainer implements OnInit, AfterViewInit {
  @ViewChild('calendar', { static: true })
  calendar: PrimeCalendarComponent;

  @ViewChild('input', { static: true })
  inputRef: ElementRef;
  dataContainers: AbstractDataContainerCallout;
  @Input('fieldValue')
  set fieldValue(value: any) {
    this.calendar.value = value;
    this.calendar.setFieldValue();
  }

  get fieldValue() {
    return this.calendar.value;
  }

  @ViewChild('opConflict', { static: true })
  opConflict: OverlayPanel;

  @Output()
  checkGridRendererEmitter = new EventEmitter<any>();

  @Input()
  columnName: string;

  @Input()
  isGridRenderer: boolean;

  calendarValue: Date;
  rangeDate: string;

  top;
  left;
  width;

  constructor(
    public elementRef: ElementRef,
    public store: DataStoreService,
    protected connectorService: SecurityManagerService,
    public cmService: ContextMenuService,
    public uiCreatorService: UICreatorService,
    renderer: Renderer2,
    private translateService: TranslateService,
    protected po: PoService
  ) {
    super(elementRef, connectorService, cmService, store, uiCreatorService, renderer, po);
    this.isDateField = true;
  }

  ngOnInit() {
    this.rangeDate = new Date().getFullYear() - 100 + ':' + (new Date().getFullYear() + 100);
    super.ngOnInit();
    this.cssGrid = this.cssClass;
    this.setFieldMandatory();
    this.calendar.setMandatoryCss(this.mandatoryCss);
    if (this.label && this.label.indexOf('_From') >= 0) {
      this.label = this.label.replace('_From', this.translateService.instant('ranged-value.calendar.from'));
    } else if (this.label && this.label.indexOf('_To') >= 0) {
      this.label = this.label.replace('_To', this.translateService.instant('ranged-value.calendar.to'));
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    let parentComp = this.DOMParentComponent;
    while (parentComp && !(parentComp instanceof EditTabUiComponent)) {
      parentComp = parentComp.DOMParentComponent;
    }
    if (parentComp instanceof EditTabUiComponent) {
      this.dataContainers = new AbstractDataContainerCallout(parentComp.dataContainers);
    }
    if (this.data && this.data.callouts && this.data.callouts.callouts) {
      this.data.callouts.callouts.forEach((callout) => {
        this.calendar.inputRef.nativeElement.addEventListener(callout.calloutevent, () => {
          const calloutFn = new Function('Field', 'Fields', 'DBSelect', callout.script);
          calloutFn(this, this.dataContainers, this.uiCreatorService);
        });
      });
    }
    if (this.fieldValue) {
      this.calendar.setFieldValue();
    }
  }

  setFieldMandatory() {
    if (this.fieldValue) {
      this.mandatoryCss = ' iu-field ';
    } else {
      if (this.data && this.data.mandatoryLogic) {
        this.data.isMandatory = LogicEvaluator.evaluateLogic(
          this.getCurrentContext(this.dataStored, false),
          this.data.mandatoryLogic
        );
      }
      if (this.data && this.data.isMandatory) {
        this.mandatoryCss = ' iu-field-mandatory ';
      } else {
        this.mandatoryCss = ' iu-field ';
      }
    }
    this.calendar.setMandatoryCss(this.mandatoryCss);
  }

  fieldChange(event) {
    this.dataChange(event);
  }

  calloutEventChange() {
    if (this.data && this.data.callouts && this.data.callouts.callouts) {
      this.data.callouts.callouts.forEach((callout) => {
        if (callout.calloutevent === 'change') {
          const calloutFn = new Function('Field', 'Fields', 'DBSelect', callout.script);
          calloutFn(this, this.dataContainers, this.uiCreatorService);
        }
      });
    }
  }

  setNewData(dataStored: DataStore, isInit = false) {
    super.setNewData(dataStored, isInit);
    this.calendar.setMandatoryCss(this.mandatoryCss);
  }

  showConflictPanel(ev) {
    ev.target.getBoundingClientRect = function () {
      return { top: this.offsetTop, left: this.offsetLeft };
    };
    this.opConflict.toggle(ev);
  }

  checkGridRenderer() {
    const rect = this.elementRef.nativeElement.firstChild.getBoundingClientRect();
    this.top = rect.y + 32;
    this.left = rect.x;
    this.width = rect.width;
    const event = {
      panelStyleClass: this.calendar.panelStyleClass,
      panelStyle: this.calendar.panelStyle,
      inline: this.calendar.inline,
      overlayCalendarVisible: true,
      months: this.calendar.months,
      numberOfMonths: this.calendar.numberOfMonths,
      monthNavigator: this.calendar.monthNavigator,
      view: this.calendar.view,
      locale: this.calendar.locale,
      yearNavigator: this.calendar.yearNavigator,
      yearOptions: this.calendar.yearOptions,
      currentYear: this.calendar.currentYear,
      weekDays: this.calendar.weekDays,
      showOtherMonths: this.calendar.showOtherMonths,
      dateTemplate: this.calendar.dateTemplate,
      monthPickerValues: this.calendar.monthPickerValues,
      linkCalendarRenderer: this,
      top: this.top,
      left: this.left,
      width: this.width,
      currentMonth: this.calendar.currentMonth
    };
    this.checkGridRendererEmitter.emit(event);
  }
}
