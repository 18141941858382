<div
  [ngStyle]="style"
  [ngClass]="['ui-breadcrumb ui-widget ui-widget-header ui-helper-clearfix ui-corner-all', styleClass ? styleClass : '']"
>
  <ul>
    <ng-template ngFor let-item let-end="last" [ngForOf]="model">
      <li role="menuitem">
        <span
          *ngIf="!item.routerLink"
          (contextmenu)="onRightClick($event, item)"
          (press)="onRightClick($event, item)"
          (click)="itemClick($event, item)"
          [ngClass]="[item.disabled ? 'ui-breadcrumb-inactive' : 'ui-breadcrumb-active']"
          [attr.id]="item.id"
        >
          {{ item.label }}
        </span>
      </li>
      <li class="ui-breadcrumb-chevron  icon-ico-next " *ngIf="!end"></li>
    </ng-template>
  </ul>
</div>
