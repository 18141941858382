import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ElasticService } from '@elastics-ws/services/elastic.service';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { TranslateService } from '@ngx-translate/core';
import { WebDesktopService } from '@web-desktop/controllers/web-desktop.service';

@Component({
  selector: 'iu-u-search-ui',
  templateUrl: './u-search-ui.component.html',
  styleUrls: ['./u-search-ui.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class USearchUiComponent implements OnInit, OnDestroy {
  text: string;
  results: any[];
  values: any[];
  inputValue: any;
  subscriptions: any[] = [];

  constructor(
    private elasticService: ElasticService,
    private webDesktopService: WebDesktopService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  search(event) {
    const term = event.query;
    this.results = this.values.filter(value => value.toLowerCase().includes(term.toLowerCase()));
  }

  onkeyup(event) {
    this.webDesktopService.triggerEvents(
      {
        textSearch: this.inputValue,
        searchResults: this.results,
        menu_type: 'UniversalSearch',
        appName: 'Universal search',
        name: this.translateService.instant('usearch.searchLabel'),
        iconClass: 'fa fa fa-search',
        windowId: ''
      },
      IupicsTypeEvent.UNIVERSAL_SEARCH
    );
  }
}
