import { CompiereDataGridFilterType, CompiereDataGridType } from '@compiere-ws/models/compiere-data-json';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import * as IBAN from 'iban';
export class QRInfo {
  IBAN: string;
  vendorName: string;
  address1: string;
  address2: string;
  postalCode: string;
  city: string;
  currency: string;
  amountToPay: number;
  bvr: string;
  vendorRef: string;
  countryCode: string;
  countryName: string;
  errors = [];
  logicalErrors = 0;
  logicalWarnings = 0;
  constructor(qrInfo: string[]) {
    if (qrInfo[3] && qrInfo[3].trim() !== '') {
      this.IBAN = qrInfo[3].trim();
      if (!isIBANValid(this.IBAN)) {
        this.errors.push('ibanInvalid');
      }
    } else {
      this.errors.push('iban');
    }
    if (qrInfo[5] && qrInfo[5].trim() !== '') {
      this.vendorName = qrInfo[5].trim();
    } else {
      this.errors.push('vendorName');
    }
    if (qrInfo[6] && qrInfo[6].trim() !== '') {
      this.address1 = qrInfo[6].trim();
    } else {
      this.errors.push('address1');
    }
    let increment = 0;
    // address2
    if (qrInfo[7] && qrInfo[7].match(/^\d/)) {
      this.address2 = null;
    } else {
      this.address2 = qrInfo[7] ? qrInfo[7].trim() : null;
      increment = 1;
    }
    // postal and city
    if (qrInfo[7 + increment] && qrInfo[7 + increment] !== '') {
      const postalCitySplitted = qrInfo[7 + increment].split(' ');
      this.postalCode = postalCitySplitted[0] ? postalCitySplitted[0].trim() : null;
      if (this.postalCode == null) {
        this.errors.push('postalCode');
      }
      this.city = postalCitySplitted[1] ? postalCitySplitted[1].trim() : null;
      if (this.city == null) {
        this.errors.push('city');
      }
    }
    // country
    if (qrInfo[10 + increment] && qrInfo[10 + increment].trim() !== '') {
      this.countryCode = qrInfo[10 + increment].trim();
    } else {
      this.errors.push('countryCode');
    }
    // currency
    if (qrInfo[19 + increment] && qrInfo[19 + increment].trim() !== '') {
      this.currency = qrInfo[19 + increment].trim();
    }
    // amount to pay
    if (qrInfo[18 + increment] && qrInfo[18 + increment].trim() !== '') {
      const value = qrInfo[18 + increment].trim();
      const floatValue = value && value.replace ? parseFloat(value.replace(',', '.')) : 0;
      if (Number.isNaN(floatValue) || floatValue === null) {
        this.amountToPay = 0;
      } else {
        this.amountToPay = floatValue;
      }
    }
    // bvr
    if (qrInfo[28 + increment] && qrInfo[28 + increment].trim() !== '') {
      this.bvr = qrInfo[28 + increment].trim();
    }
    // vendorRef
    if (qrInfo[31 + increment] && qrInfo[31 + increment].trim() !== '') {
      this.vendorRef = qrInfo[31 + increment] ? qrInfo[31 + increment].trim() : '';
    }
  }
  getCompleteAddress() {
    //  completeAddress
    return `${this.address1 ? this.address1 : ''}${this.address2 ? ', ' + this.address2 : ''}, ${
      this.postalCode ? this.postalCode : ''
    } ${this.city ? this.city : ''}${this.countryName ? ', ' + this.countryName : ''}`;
  }
}
//#region DATAGRID REQUEST
export function getPartnerRequest(qrInfo: QRInfo, ad_language: string) {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'C_BPartner',
      ad_language,
      filterModel: {
        Name: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.CONTAINS],
          values: [qrInfo.vendorName]
        }
      }
    }
  };
}
export function getBankRequest(qrInfo: QRInfo, ad_language: string) {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'C_BP_BankAccount',
      ad_language,
      filterModel: {
        IBAN: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.CONTAINS],
          values: [qrInfo.IBAN]
        }
      }
    }
  };
}
export function getCurrencyRequest(qrInfo: QRInfo, ad_language: string) {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'C_Currency',
      ad_language,
      filterModel: {
        ISO_Code: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [qrInfo.currency]
        }
      }
    }
  };
}
export function getCountryDataRequest(qrInfo: QRInfo, ad_language: string) {
  const countrySelector =
    "(SELECT country.C_COUNTRY_ID,countryTrl.NAME FROM C_COUNTRY_trl countryTrl INNER JOIN C_COUNTRY country ON countryTrl.C_Country_ID=country.C_Country_ID WHERE LOWER(countryTrl.AD_LANGUAGE) = LOWER('" +
    ad_language +
    "') AND LOWER(country.countrycode) = LOWER('" +
    qrInfo.countryCode +
    "')) as CountryRequest";
  return {
    windowId: null,
    parent_constraint: '',
    compiereRequest: {
      startRow: 0,
      endRow: 1,
      tableName: countrySelector,
      filterModel: {}
    }
  };
}
export function getTabDataRequest() {
  return {
    windowId: null,
    parent_constraint: '',
    compiereRequest: {
      startRow: 0,
      endRow: -1,
      tableName: `( select tv.tableName,tv.ad_tab_id,tv.ad_table_id,tv.ad_window_id,tv.taggedcolumns,tv.docfilters,w.value FROM ad_tab_v tv INNER JOIN ad_window w on tv.ad_window_id=w.ad_window_id WHERE (tv.tablename='C_Invoice' AND w.value='Invoice (Vendor)_183') OR (tv.tablename='C_InvoiceLine' AND w.value='Invoice (Vendor)_183') OR (tv.tablename='C_InvoiceTax' AND w.value='Invoice (Vendor)_183')) as TabRequest`,
      filterModel: {}
    }
  };
}
export function getInvoiceTaxRequest(c_invoice_id: number, ad_language: string) {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'C_InvoiceTax',
      ad_language,
      filterModel: {
        C_Invoice_ID: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [c_invoice_id]
        }
      }
    }
  };
}
export function getInvoiceRequest(c_invoice_id: number, ad_language: string) {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'C_Invoice',
      ad_language,
      filterModel: {
        C_Invoice_ID: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [c_invoice_id]
        }
      }
    }
  };
}
export function getInvoiceLineRequest(c_invoice_id: number, ad_language: string) {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'C_InvoiceLine',
      ad_language,
      filterModel: {
        C_Invoice_ID: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [c_invoice_id]
        }
      }
    }
  };
}
export function getLogMessageRequest(ad_language: string) {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'AD_MESSAGE',
      ad_language,
      filterModel: {
        value: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.CONTAINS],
          values: ['SZ_VendorInvoice']
        }
      }
    }
  };
}
export function getOrderRequest(qrInfo: QRInfo, data: any, ad_language: string, marginError: number) {
  // set partner id
  const C_BPartner_ID = data['C_BPartner_ID']
    ? data['C_BPartner_ID'].id
      ? data['C_BPartner_ID'].id
      : data['C_BPartner_ID']
    : -1;
  // set C_BPartner_ID
  const IsReturnTrx = data['IsReturnTrx'] ? (data['IsReturnTrx'].id ? data['IsReturnTrx'].id : data['IsReturnTrx']) : -1;
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'C_Order',
      ad_language,
      filterModel: {
        GrandTotal: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.BETWEEN, OperatorFilterType.BETWEEN],
          values: [qrInfo.amountToPay - marginError, qrInfo.amountToPay + marginError]
        }
      },
      validation: `C_Order.c_bpartner_id = ${C_BPartner_ID} AND C_Order.issotrx = 'N'  AND C_Order.isreturntrx = '${IsReturnTrx}' AND C_Order.c_order_id IN ( SELECT ol.c_order_id FROM c_orderline ol INNER JOIN c_order oo ON oo.c_order_id = ol.c_order_id LEFT OUTER JOIN m_matchpo m ON ( ol.c_orderline_id=m.c_orderline_id) where m.m_inoutline_id IS NOT NULL AND ( ol.isdropship='N' OR '${IsReturnTrx}'= 'N') AND oo.c_bpartner_id = ${C_BPartner_ID} GROUP BY ol.c_order_id, ol.c_orderline_id, ol.qtyordered HAVING ol.qtyordered > SUM(m.qty) UNION SELECT ol.C_Order_ID FROM C_OrderLine ol INNER JOIN C_Order oo ON oo.C_Order_ID = ol.C_Order_ID LEFT OUTER JOIN M_MatchPO m ON (ol.C_OrderLine_ID=m.C_OrderLine_ID) where m.m_inoutline_id IS NULL AND ( ol.isdropship='N' OR '${IsReturnTrx}'= 'N') AND oo.c_bpartner_id = ${C_BPartner_ID} GROUP BY ol.C_Order_ID, ol.C_OrderLine_ID, ol.QtyOrdered) AND C_Order.docstatus IN ( 'CL', 'CO' ) AND not exists (select 1 from C_BlanketOrder bo where bo.C_BlanketOrder_ID = C_Order.C_BlanketOrder_ID AND bo.IsConsigned ='Y' )`
    }
  };
}
export function getChargeDescRequest(c_charge_id: number) {
  return {
    windowId: null,
    parent_constraint: null,
    compiereRequest: {
      startRow: 0,
      tableName: 'C_Charge',
      filterModel: {
        C_Charge_ID: {
          filterType: CompiereDataGridFilterType.SET,
          values: [c_charge_id],
          operators: [OperatorFilterType.EQUALS]
        }
      }
    }
  };
}
export function getProductDescRequest(m_product_id: number) {
  return {
    windowId: null,
    parent_constraint: null,
    compiereRequest: {
      startRow: 0,
      tableName: 'M_Product',
      filterModel: {
        M_Product_ID: {
          filterType: CompiereDataGridFilterType.SET,
          values: [m_product_id],
          operators: [OperatorFilterType.EQUALS]
        }
      }
    }
  };
}

export function getUserRoleRequest(ad_user_id: number, ad_client_id: number, ad_language: string) {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'AD_User_Roles',
      ad_language,
      filterModel: {
        AD_Client_ID: {
          filterType: CompiereDataGridFilterType.SET,
          values: [ad_client_id],
          operators: [OperatorFilterType.EQUALS]
        },
        AD_User_ID: {
          filterType: CompiereDataGridFilterType.SET,
          values: [ad_user_id],
          operators: [OperatorFilterType.EQUALS]
        }
      }
    }
  };
}
export function getPartnerLocationRequest(qrInfo: QRInfo) {
  let whereClause = 'WHERE ';
  if (qrInfo.address1 && qrInfo.address1.length > 0) {
    whereClause += "cl.address1 LIKE '%" + qrInfo.address1 + "%' ";
  }
  if (qrInfo.address2 && qrInfo.address2.length > 0) {
    whereClause += "AND cl.address2 LIKE '%" + qrInfo.address1 + "%' ";
  }
  if (qrInfo.postalCode && qrInfo.postalCode.length > 0) {
    whereClause += "AND cl.postal LIKE '%" + qrInfo.postalCode + "%' ";
  }
  if (qrInfo.city && qrInfo.city.length > 0) {
    whereClause +=
      "AND ( EXISTS(SELECT C_CITY_ID FROM C_CITY city WHERE LOWER(city.name) = LOWER('" +
      qrInfo.city +
      "') AND cl.C_CITY_ID=city.C_CITY_ID ) OR LOWER(cl.city)=LOWER('" +
      qrInfo.city +
      "') )";
  }
  if (qrInfo.countryCode && qrInfo.countryCode.length > 0) {
    whereClause +=
      "AND EXISTS(SELECT 1 FROM C_COUNTRY country WHERE LOWER(country.countrycode) = LOWER('" + qrInfo.countryCode + "') ) ";
  }
  return {
    windowId: null,
    parent_constraint: '',
    compiereRequest: {
      startRow: 0,
      endRow: 1,
      tableName:
        '( SELECT cbl.C_BPartner_Location_ID FROM C_BPartner_Location cbl INNER JOIN C_Location cl on cl.C_Location_ID = cbl.C_Location_ID ' +
        whereClause +
        ' ) as PartnerLocationRequest',
      filterModel: {}
    }
  };
}
//#endregion DATAGRID REQUEST

export function getNullValueInData(data: any, columnNames: string[]) {
  let nullValue = null;
  columnNames.forEach((columnName) => {
    const value = data[columnName];
    if (
      value == null ||
      value == undefined ||
      value == '' ||
      (value.trim && value.trim() == '') ||
      (columnName === 'AD_Org_ID' && value.id <= 0)
    ) {
      nullValue = columnName;
      return;
    }
  });
  return nullValue;
}
export function isIBANValid(value: string): boolean {
  return IBAN.isValid(value);
}
export function getLineCustomDesignItems() {
  return [
    {
      vcr: 'vcrsCharge',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_Charge_ID',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsProduct',
      type: CustomDesignItemType.FIELD,
      columnName: 'M_Product_ID',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsQty',
      type: CustomDesignItemType.FIELD,
      columnName: 'QtyEntered',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsPriceEntered',
      type: CustomDesignItemType.FIELD,
      columnName: 'PriceEntered',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsPriceList',
      type: CustomDesignItemType.FIELD,
      columnName: 'PriceList',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsTax',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_Tax_ID',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsTaxAmt',
      type: CustomDesignItemType.FIELD,
      columnName: 'TaxAmt',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsLineNetAmt',
      type: CustomDesignItemType.FIELD,
      columnName: 'LineNetAmt',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    // hidden fields for callout
    {
      vcr: 'vcrsOthers',
      type: CustomDesignItemType.FIELD,
      columnName: 'IsSOTrx',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrsOthers',
      type: CustomDesignItemType.FIELD,
      columnName: 'PriceActual',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsOthers',
      type: CustomDesignItemType.FIELD,
      columnName: 'Description',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsOthers',
      type: CustomDesignItemType.FIELD,
      columnName: 'QtyInvoiced',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsOthers',
      type: CustomDesignItemType.FIELD,
      columnName: 'PriceLimit',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsOthers',
      type: CustomDesignItemType.FIELD,
      columnName: 'EnforcePriceLimit',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsOthers',
      type: CustomDesignItemType.FIELD,
      columnName: 'TVAEncaissement',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsOthers',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_UOM_ID',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsOthers',
      type: CustomDesignItemType.FIELD,
      columnName: 'LineTotalAmt',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsOthers',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_InvoiceLine_ID',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsOthers',
      type: CustomDesignItemType.FIELD,
      columnName: 'IsDropShip',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    }
  ];
}
export function getHeaderCustomDesignItems() {
  return [
    {
      vcr: 'vcrCreateFrom',
      type: CustomDesignItemType.FIELD,
      columnName: 'CreateFrom',
      cssClass: 'createFrom',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrBPartner',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_BPartner_ID',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrBPBankAccount',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_BP_BankAccount_ID',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrValidator',
      type: CustomDesignItemType.FIELD,
      columnName: 'XX_Validator_ID',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrBVR',
      type: CustomDesignItemType.FIELD,
      columnName: 'XX_BVR',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrBPartnerLocation',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_BPartner_Location_ID',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrOrder',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_Order_ID',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrDescription',
      type: CustomDesignItemType.FIELD,
      columnName: 'Description',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrDateInvoiced',
      type: CustomDesignItemType.FIELD,
      columnName: 'DateInvoiced',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrGrandTotal',
      type: CustomDesignItemType.FIELD,
      columnName: 'GrandTotal',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'AD_Client_ID',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'IsSOTrx',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_Invoice_ID',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'AD_Org_ID',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'DocumentNo',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'DateAcct',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'DateOrdered',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_DocTypeTarget_ID',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_DocType_ID',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'DocAction',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'AD_User_ID',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'SalesRep_ID',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'M_PriceList_ID',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'IsReturnTrx',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'DocStatus',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_Currency_ID',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'PaymentRule',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_PaymentTerm_ID',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrProcessInvoice',
      type: CustomDesignItemType.FIELD,
      columnName: 'Processing',
      cssClass: 'p-col-12'
    }
  ];
}
