<p-toolbar styleClass="wd-task-bar" (contextmenu)="onContextMenu($event)" (press)="onContextMenu($event)">
  <div class="p-toolbar-group-left p-col-12" #toolbarLeft (wheel)="onWheel($event, toolbarLeft)">
    <p-button
      life="3"
      pTooltip="{{ item.name }}"
      tooltipPosition="bottom"
      showDelay="250"
      [ngClass]="['wd-task-bar-item', item.active ? 'wd-task-bar-item-active' : '']"
      type="button"
      *ngFor="let item of items; index as i"
      label="{{ item.name | textLimit: 13 }}"
      [icon]="item.iconClass"
      #taskBarItem
      (click)="displayWindow($event, i)"
      (contextmenu)="showPinOptions($event, i)"
      (press)="showPinOptions($event, i)"
    ></p-button>
  </div>
</p-toolbar>
