import { Pipe, PipeTransform } from '@angular/core';
import { ColumnFilterAutocomplete } from '@iupics-components/models/autocomplete-interfaces';
import { cloneDeep } from 'lodash';

@Pipe({
  name: 'universalFilterColFilter'
})
export class UniversalFilterColFilterPipe implements PipeTransform {
  transform(value: { items: ColumnFilterAutocomplete[] }, notWantedColumnName: String[]): any {
    if (notWantedColumnName) {
      const newValue: { items: ColumnFilterAutocomplete[] } = { items: cloneDeep(value.items) };
      newValue.items = newValue.items.filter((item) => !notWantedColumnName.find((columnName) => columnName === item.id));
      return newValue;
    }
    return value;
  }
}
