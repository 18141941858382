import { EventEmitter, Injectable } from '@angular/core';
import { IupicsCookieService, LocalStorageIupics } from '@iupics-manager/managers/security-manager/cookies/iupics-cookie.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { Global } from '@iupics-manager/models/global-var';
import { RoleUI, UserAccount } from '@login-page/models/user-account.';
import { LanguageSelectionService } from '@web-desktop/components/menu-top/controllers/language-selection/language-selection.service';
import { Subscription } from 'rxjs';

@Injectable()
export class HomePageService {
  errorMessage = '';

  private subLogin: Subscription;

  constructor(
    private connectorService: SecurityManagerService,
    private cookieService: IupicsCookieService,
    private languageSelectionService: LanguageSelectionService
  ) {}

  generateCryptoKey() {
    if (!this.cookieService.checkLS(LocalStorageIupics.crypto_key)) {
      this.cookieService.generateRandomKeyCookie();
    }
  }

  generateDeviceId() {
    if (!this.cookieService.checkLS(LocalStorageIupics.device_id)) {
      this.cookieService.setAndEncryptLocalStorage(LocalStorageIupics.device_id, Global.device);
    }
  }

  initLanguages() {
    this.languageSelectionService.initLanguages();
  }

  getAccountsSaved(): UserAccount[] {
    return this.cookieService.getUsersFromLocalStorage();
  }

  verifyFullAuthentification(login: string, password: string, validLoginEmitter: EventEmitter<any>, rememberMe: boolean) {
    this.verifyAuthentification(login, password, validLoginEmitter, rememberMe);
  }

  verifySemiAuthentification(account: UserAccount, password: string, validLoginEmitter: EventEmitter<any>) {
    this.verifyAuthentification(account, password, validLoginEmitter);
  }

  private verifyAuthentification(
    login: string | UserAccount,
    password: string,
    validLoginEmitter: EventEmitter<any>,
    rememberMe: boolean = true
  ) {
    if (this.subLogin) {
      this.subLogin.unsubscribe();
    }
    if ((<UserAccount>login).login) {
      this.subLogin = this.connectorService.authenticateWithUserAccount(<UserAccount>login, password).subscribe(
        (userAccount) => {
          this.connectorService.getCtx().subscribe(
            (iupicsUserAccount) => {
              this.authentificationValid(userAccount, rememberMe);
              validLoginEmitter.emit();
            },
            (err) => {
              if (err.status === 0 || err.status > 399) {
                this.errorMessage = 'AuthDown';
              }
              if (Global.activeXHRRequest > 0) {
                Global.activeXHRRequest--;
              }
            }
          );
        },
        (err) => {
          if (err.status === 0 || err.status > 400) {
            this.errorMessage = 'AuthDown';
          } else if (err.status === 400) {
            this.errorMessage = 'Error';
          }
          if (Global.activeXHRRequest > 0) {
            Global.activeXHRRequest--;
          }
        }
      );
    } else {
      this.subLogin = this.connectorService.authenticate(<string>login, password).subscribe(
        (userAccount) => {
          this.connectorService.getCtx().subscribe(
            (iupicsUserAccount) => {
              this.authentificationValid(iupicsUserAccount, rememberMe);
              validLoginEmitter.emit();
            },
            (err) => {
              if (err.status === 0 || err.status > 399) {
                this.errorMessage = 'AuthDown';
              }
              if (Global.activeXHRRequest > 0) {
                Global.activeXHRRequest--;
              }
            }
          );
        },
        (err) => {
          if (err.status === 0 || err.status > 400) {
            this.errorMessage = 'AuthDown';
          } else if (err.status === 400) {
            this.errorMessage = 'Error';
          }
          if (Global.activeXHRRequest > 0) {
            Global.activeXHRRequest--;
          }
        }
      );
    }
  }

  private authentificationValid(account: UserAccount, rememberMe: boolean) {
    // Clean error message
    this.errorMessage = undefined;

    // Save User in cookie (only if remember me)
    if (rememberMe) {
      this.cookieService.addUserToLocalStorage(account);
    }

    // Save remember me option in cookie and last user connected
    this.cookieService.setAndEncryptLocalStorage(LocalStorageIupics.is_remember_user, rememberMe ? 'Y' : 'N');

    Global.isLoginPageShow = true;
  }

  getRoleForUser(): RoleUI {
    return this.connectorService.getIupicsUserAccount().current_role;
  }
}
