import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { InfoDialogType } from '@iupics-components/specific/window/info-dialog/info-dialog.component';
import { KeyCode } from '@iupics-config/keycode.enum';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { Global } from '@iupics-manager/models/global-var';
import { TranslateService } from '@ngx-translate/core';
import { DashboardManagerService } from '@web-desktop/components/workspace/controllers/dashboard-manager/dashboard-manager.service';
import { DashboardMenuItem, DashboardRemoveMenuItem } from '@web-desktop/models/dashboard-menu-item';
import { MenuFavoritesCategoryUI, MenuItemUI } from '@web-desktop/models/menu-item-ui';
import { Observable, of } from 'rxjs';
import { DragDropGroup } from '../drag-drop-group';

// tslint:disable: deprecation

@Component({
  selector: 'iu-favorite-group-ui',
  templateUrl: './favorite-group-ui.component.html',
  styleUrls: ['./favorite-group-ui.component.scss']
})
export class FavoriteGroupUiComponent extends DragDropGroup implements OnInit, OnDestroy {
  @Output()
  favoriteClicked = new EventEmitter<any>();

  isMobile = Global.isMobile();

  menuCategories$: Observable<{ category: string; items: MenuItemUI[] }[]>;
  indexGroupDrag: number;
  indexGroupOver: number;
  showMenuFavorites = false;

  // favorites
  currentItemList$: Observable<any[]>;
  currentGroupID = 0;
  groups: MenuFavoritesCategoryUI[] = [];
  itemsList = {};

  searchFavoriteValue: string;
  selectedCategory: string;
  arrowFocusedCategory: string;
  arrowFocusedMenu: MenuItemUI;

  //#region draggable v2
  protected _currentItemList: MenuItemUI[] = [];
  //#endregion
  @ViewChild('searchInput', { read: ElementRef }) searchInputEl: ElementRef;
  constructor(
    private uiCreatorService: UICreatorService,
    private dashboardManager: DashboardManagerService,
    private translator: TranslateService,
    protected renderer: Renderer2
  ) {
    super(renderer);
  }

  ngOnInit() {
    this.createEmptyDiv('p-col-12 p-md-6 p-lg-3');
    // récupère la liste des favoris et les tries par catégories
    this.getFavorites();
  }

  ngOnDestroy() {
    ['mouseupListener', 'mousemoveListener', 'windowFocusListener'].forEach((key) => {
      if (this[key] !== undefined) {
        this[key]();
      }
    });
  }

  public openTab(favorite: MenuItemUI) {
    this.favoriteClicked.emit(favorite);
  }

  public openMenuFavorite(event: Event) {
    event.preventDefault();
    if (this.searchInputEl && this.searchInputEl.nativeElement) {
      setTimeout(() => {
        this.searchInputEl.nativeElement.focus();
      }, 100);
    }
    // récupère la liste des menus et les tries par catégories
    this.menuCategories$ = this.dashboardManager.getDashboardIupicsMenu();
    this.searchFavoriteValue = '';
    this.selectedCategory = undefined;
    this.arrowFocusedCategory = undefined;
    this.arrowFocusedMenu = undefined;
  }

  // ouvre et cache la liste des menus (dans l'ajout)
  public openShortCut(event: Event, category: string) {
    event.stopPropagation();
    this.selectCategory(category);
  }

  public closeShortCut(event: Event) {
    event.stopPropagation();
    this.selectCategory(undefined);
  }

  private selectCategory(category: string) {
    this.selectedCategory = category;
    this.arrowFocusedCategory = undefined;
    this.arrowFocusedMenu = undefined;
  }

  /**
   * afficher la liste d'une autre catégorie
   * @param elem
   */
  public changeMenuItemList(id: number) {
    if (id !== this.currentGroupID) {
      this.showMenuFavorites = false;
      this.currentGroupID = id;
      this._currentItemList = [];
      if (this.itemsList[this.currentGroupID]) {
        this._currentItemList = this.itemsList[this.currentGroupID];
      }
      this.currentItemList$ = of(this._currentItemList);
    }
  }

  /**
   * ajoute une catégorie
   * @param elem
   * @param favorite
   */
  public addGroupItem(favorite?: MenuItemUI) {
    const name = this.getDefaultName();
    const arrayToSave = [];
    const dashboardGroupItem: DashboardMenuItem = {
      cssColor: null,
      name: name,
      isSummary: true,
      isEditable: true,
      tags: [],
      parentId: 0,
      seqNo: this.groups.length > 0 ? this.groups[this.groups.length - 1].seqNo + 10 : 10
    };
    arrayToSave.push(dashboardGroupItem);
    this.uiCreatorService.setIupicsFavorites(arrayToSave).subscribe((res) => {
      if (Object.keys(this.itemsList).length === 0) {
        this.currentGroupID = res[0].menuId;
      }

      this.itemsList[res[0].menuId] = [];
      this._currentItemList = this.itemsList[this.currentGroupID];
      this.groups.push({ id: res[0].menuId, name: name, seqNo: res[0].seqNo });
      if (favorite) {
        this.addFavorite(favorite);
      }
      this.currentItemList$ = of(this._currentItemList);
    });
  }

  private getDefaultName(): string {
    const base = this.translator.instant('dashboard.newTab.favorites');
    if (this.groups.length > 0) {
      return (base + ' ' + (this.groups.length + 1)).trim();
    }
    return base.trim();
  }

  /**
   * renome une catégorie
   * @param elem
   */
  public renameGroupItem(elem: { name: string; id: number; seqNo: number }) {
    const arrayToSave = [];
    const dashboardGroupItem: DashboardMenuItem = {
      isSummary: true,
      menuId: elem.id,
      name: elem.name,
      isEditable: true,
      tags: [],
      seqNo: elem.seqNo
    };

    arrayToSave.push(dashboardGroupItem);
    this.uiCreatorService.setIupicsFavorites(arrayToSave).subscribe(() => {
      this.groups[this.groups.findIndex((group) => group.id === elem.id)].name = elem.name;
    });
  }

  /**
   * supprime une catégorie
   * @param elem
   */
  public removeGroupItem(elem: { id: number }) {
    const dashboardGroupItem: DashboardRemoveMenuItem = {
      isSummary: true,
      menuId: elem.id,
      parentId: 0
    };
    this.uiCreatorService.deleteIupicsFavorites(dashboardGroupItem).subscribe(() => {
      this.groups.splice(
        this.groups.findIndex((group) => group.id === elem.id),
        1
      );
      delete this.itemsList[elem.id];
      if (this.groups.length > 0) {
        this.changeMenuItemList(this.groups[0].id);
      } else {
        this._currentItemList = [];
        this.currentItemList$ = of(this._currentItemList);
      }
    });
  }

  public addFavorite(favorite: MenuItemUI) {
    if (this.groups.length === 0) {
      this.addGroupItem(favorite);
    } else {
      let exist = false;
      let i = 0;
      while (this._currentItemList.length > i && !exist) {
        if (this._currentItemList[i++].menu_id === favorite.menu_id) {
          exist = true;
        }
      }
      if (!exist) {
        const dashboardMenuItem: DashboardMenuItem = {
          menuId: favorite.menu_id,
          parentId: this.currentGroupID,
          isSummary: favorite.isSummary,
          isEditable: true,
          tags: [],
          cssColor: '',
          name: favorite.name,
          seqNo: this._currentItemList.length > 0 ? this._currentItemList[this._currentItemList.length - 1].seqNo + 10 : 10
        };
        const arrayToSave = [];
        arrayToSave.push(dashboardMenuItem);
        favorite.seqNo = dashboardMenuItem.seqNo;
        favorite.parentId = this.currentGroupID;
        favorite.color = '';
        this.uiCreatorService.setIupicsFavorites(arrayToSave).subscribe(() => {
          this._currentItemList.push(favorite);
          this.currentItemList$ = of(this._currentItemList);
        });
      }
    }
  }

  public onDelete(favorite: MenuItemUI) {
    let confirm: any, cancel: any;
    Global.infoDialog.message = {
      summary: this.translator.instant('infodialog.dialogs.delete.title'),
      detail: this.translator.instant('infodialog.dialogs.delete.message')
    };
    Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
    Global.infoDialog.showInfoDialog();
    confirm = Global.infoDialog.confirm.subscribe((e: any) => {
      this.deleteFavorite(favorite);
      if (confirm !== undefined) {
        confirm.unsubscribe();
      }
      if (cancel !== undefined) {
        cancel.unsubscribe();
      }
    });
    cancel = Global.infoDialog.cancel.subscribe((e: any) => {
      if (confirm !== undefined) {
        confirm.unsubscribe();
      }
      if (cancel !== undefined) {
        cancel.unsubscribe();
      }
    });
  }

  public deleteFavorite(favorite: MenuItemUI) {
    const dashboardMenuItem: DashboardMenuItem = {
      menuId: favorite.menu_id,
      parentId: favorite.parentId,
      isSummary: favorite.isSummary,
      isEditable: true,
      tags: [],
      cssColor: favorite.color,
      name: favorite.name
    };
    this.uiCreatorService.deleteIupicsFavorites(dashboardMenuItem).subscribe((res) => {
      this._currentItemList.splice(
        this._currentItemList.findIndex((item) => item.menu_id === favorite.menu_id),
        1
      );
      this.currentItemList$ = of(this._currentItemList);
    });
  }

  public updateFavorite(MenuItem: MenuItemUI) {
    const dashboardMenuItem: DashboardMenuItem = {
      menuId: MenuItem.menu_id,
      isSummary: MenuItem.isSummary,
      cssColor: MenuItem.color,
      isEditable: true,
      tags: [],
      name: MenuItem.name,
      parentId: this.currentGroupID,
      seqNo: MenuItem.seqNo
    };
    const arrayToSave = [];
    arrayToSave.push(dashboardMenuItem);

    this.uiCreatorService.setIupicsFavorites(arrayToSave).subscribe();
  }

  public switchMenuGroup({ from, to }) {
    const menuToReplace = this.groups.splice(from, 1)[0];
    this.groups.splice(to, 0, menuToReplace);
    let seqNo = 10;
    const arrayToSave = [];
    this.groups.forEach((groupFavoris) => {
      const dashboardGroupItem: DashboardMenuItem = {
        cssColor: null,
        menuId: groupFavoris.id,
        name: groupFavoris.name,
        isEditable: true,
        tags: [],
        isSummary: true,
        parentId: 0,
        seqNo: seqNo
      };
      seqNo += 10;
      arrayToSave.push(dashboardGroupItem);
    });
    this.uiCreatorService.setIupicsFavorites(arrayToSave).subscribe();
  }

  handleInputKeydown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case KeyCode.LEFT_ARROW:
        event.preventDefault();
        break;
      case KeyCode.UP_ARROW:
        event.preventDefault();
        break;
      case KeyCode.RIGHT_ARROW:
        event.preventDefault();
        break;
      case KeyCode.DOWN_ARROW:
        event.preventDefault();
        break;
      default:
        break;
    }
  }

  handleInputKeyup(event: KeyboardEvent, groups: { category: string; items: MenuItemUI[] }[]) {
    switch (event.keyCode) {
      case KeyCode.LEFT_ARROW:
        this.getNextCategoryOrMenu(-1, groups);
        this.scrollToCategoryOrMenuPosition(this.arrowFocusedCategory, this.arrowFocusedMenu);
        break;
      case KeyCode.UP_ARROW:
        this.getNextCategoryOrMenu(-1, groups);
        this.scrollToCategoryOrMenuPosition(this.arrowFocusedCategory, this.arrowFocusedMenu);
        break;
      case KeyCode.RIGHT_ARROW:
        this.getNextCategoryOrMenu(1, groups);
        this.scrollToCategoryOrMenuPosition(this.arrowFocusedCategory, this.arrowFocusedMenu);
        break;
      case KeyCode.DOWN_ARROW:
        this.getNextCategoryOrMenu(1, groups);
        this.scrollToCategoryOrMenuPosition(this.arrowFocusedCategory, this.arrowFocusedMenu);
        break;
      case KeyCode.ENTER:
        this.selectItem();
        break;
      default:
        this.selectedCategory = undefined;
        this.arrowFocusedMenu = undefined;
        this.arrowFocusedCategory = undefined;
        break;
    }
  }

  private selectItem() {
    if (
      this.selectedCategory === undefined &&
      (this.searchFavoriteValue === undefined ||
        this.searchFavoriteValue === '' ||
        this.searchFavoriteValue === '!' ||
        this.searchFavoriteValue === '#' ||
        this.searchFavoriteValue === '!#')
    ) {
      if (this.arrowFocusedCategory !== undefined) {
        this.selectCategory(this.arrowFocusedCategory);
      }
    } else {
      if (this.arrowFocusedMenu !== undefined) {
        this.addFavorite(this.arrowFocusedMenu);
      }
    }
  }

  private getNextCategoryOrMenu(offset: number, groups: { category: string; items: MenuItemUI[] }[]) {
    const response = this.dashboardManager.getNextCategoryOrMenu(
      offset,
      groups,
      this.selectedCategory,
      this.searchFavoriteValue,
      this.arrowFocusedCategory,
      this.arrowFocusedMenu
    );
    if (response !== undefined && typeof response === 'string') {
      this.arrowFocusedCategory = response;
    } else if (response !== undefined) {
      this.arrowFocusedMenu = <MenuItemUI>response;
    }
  }

  private scrollToCategoryOrMenuPosition(selectedCategory: string, selectedMenu: MenuItemUI) {
    if (selectedCategory !== undefined) {
      this.dashboardManager.scrollToItem('iu_favorite_category_' + selectedCategory, 'iu_favorite_container');
    } else if (selectedMenu !== undefined) {
      this.dashboardManager.scrollToItem('iu_favorite_menu_' + selectedMenu.menu_id, 'iu_favorite_container');
    }
  }

  refreshFavorites() {
    this.getFavorites(true);
  }

  private getFavorites(forceRefresh = false) {
    const sub = this.uiCreatorService.getIupicsMenuFavorites(forceRefresh).subscribe((menuFavorites) => {
      this.groups = this.uiCreatorService.getIupicsMenuFavoritesCategories();
      this.groups.forEach((element) => {
        this.itemsList[element.id] = [];
      });
      if (this.groups[0] && this.currentGroupID === 0) {
        this.currentGroupID = this.groups[0].id;
      }
      menuFavorites.forEach((favorite) => {
        this.itemsList[favorite.parentId].push(favorite);
      });
      if (this.itemsList[this.currentGroupID]) {
        this._currentItemList = this.itemsList[this.currentGroupID];
      } else {
        this._currentItemList = [];
      }
      this.currentItemList$ = of(this._currentItemList);
      sub.unsubscribe();
    });
  }

  protected saveItemPos() {
    if (!this.isListSorted()) {
      let seqNo = 10;
      const arrayToSave = [];
      this._currentItemList.forEach((menuFavoris) => {
        menuFavoris.seqNo = seqNo;
        const dashboardMenuItem: DashboardMenuItem = {
          menuId: menuFavoris.menu_id,
          parentId: menuFavoris.parentId,
          isSummary: menuFavoris.isSummary,
          isEditable: true,
          tags: [],
          cssColor: menuFavoris.color,
          name: menuFavoris.name,
          seqNo: menuFavoris.seqNo
        };
        arrayToSave.push(dashboardMenuItem);
        seqNo += 10;
      });
      const sub = this.uiCreatorService.setIupicsFavorites(arrayToSave).subscribe(() => {
        this.currentItemList$ = of(this._currentItemList);
        sub.unsubscribe();
      });
    }
  }

  private isListSorted(): boolean {
    for (let i = 0; i < this._currentItemList.length - 1; i++) {
      if (this._currentItemList[i].seqNo > this._currentItemList[i + 1].seqNo) {
        return false;
      }
    }
    return true;
  }
}
