<div
  [style.display]="displayCss"
  class="input-file-ui"
  style="padding: 0.5em"
  [style.margin-top.px]="data && data.marginTop > 0 ? data.marginTop : null"
  #input
  (contextmenu)="onContextMenu($event)"
  (press)="onContextMenu($event)"
  [style.backgroundColor]="backgroundColorCss"
  tooltipZIndex="2100"
  [pTooltip]="tooltip"
  life="3"
>
  <label *ngIf="this.isLabelDisplay" style="color: #898989">{{ label }}</label>
  <iu-prime-fileupload
    (deleteEmitter)="deleteFile($event.file)"
    (downloadEmitter)="downloadFile($event.file)"
    (fileSelectEmitter)="onFileSelect($event)"
    (uploadFiles)="uploadFiles($event)"
    (uploadHandler)="myUploader($event)"
    [auto]="auto"
    [canDelete]="canDelete"
    [canDownload]="canDownload"
    [canPreview]="canPreview"
    [canUpload]="canUpload"
    [customUpload]="true"
    [displayButtons]="displayButtons"
    [displayFileUploaded]="displayFileUploaded || isReadOnly"
    [isLoaderActive]="isLoaderActive"
    [isReadOnly]="isReadOnly"
    [multiple]="multiple"
    [styleClass]="styleClass"
    [uploadBtn_cancel]="uploadBtn_cancel"
    [uploadBtn_confirm]="uploadBtn_confirm"
    [uploadedFileList_noFiles]="uploadedFileList_noFiles"
    [uploadedFileList_title]="uploadedFileList_title"
    [uploadedFiles]="uploadedFiles"
    [linkedFiles]="linkedFiles"
    [uploadingFileList_drag]="uploadingFileList_drag"
    [uploadingFileList_title]="uploadingFileList_title"
    [uploadingFiles]="uploadingFiles"
    [displayedTags]="displayedTags"
    [attr.data-cy-columnName]="data ? data.columnName : columnName"
    [overridedCSS]="overridedCSS"
    [fromDocServer]="fromDocServer"
  >
  </iu-prime-fileupload>
</div>
