import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Global } from '@iupics-manager/models/global-var';
import { ThemeService } from '@web-desktop/controllers/theme.service';
import { MenuItemUI } from '@web-desktop/models/menu-item-ui';
@Component({
  selector: 'favorites-ui',
  templateUrl: './favorites-ui.component.html',
  styleUrls: ['./favorites-ui.component.scss']
})
export class FavoritesUiComponent implements OnInit {
  @Input()
  favorite: MenuItemUI;

  @Output()
  deleteFavorite = new EventEmitter<MenuItemUI>();

  @Output()
  updateFavorite = new EventEmitter<MenuItemUI>();

  @Output()
  open = new EventEmitter<any>();

  new_favorite_name = '';
  showColor = false;
  showMoreColor = false;
  colorpicked = '#ff0000';
  defaultColor: string[];

  showMenu = false;

  isMobile = Global.isMobile();

  constructor(private themeService: ThemeService, public el: ElementRef) {
    // this.onResize();
    this.defaultColor = this.themeService.getThemeProperty('colorsPalette');
  }

  ngOnInit() {
    this.new_favorite_name = this.favorite.name;
    this.colorpicked = this.favorite.color || this.colorpicked;
  }

  onDelete() {
    this.deleteFavorite.emit(this.favorite);
  }

  onChangeColor(event: Event) {
    event.stopPropagation();
    this.favorite.color = this.colorpicked;
    this.updateFavorite.emit(this.favorite);
    this.showColor = false;
    this.showMoreColor = false;
  }

  openTab(event: MouseEvent) {
    this.open.emit(this.favorite);
    event.stopPropagation();
  }

  changeDefaultColor(color: string) {
    this.colorpicked = color;
    this.onChangeColor(document.createEvent('Event'));
    this.showMenu = false;
  }

  onColorPicker($event: { originalEvent: MouseEvent; value: string }) {
    $event.originalEvent.stopPropagation();
    this.colorpicked = $event.value;
  }

  cancelColorChange(event: Event) {
    event.stopPropagation();
    this.showColor = false;
    this.showMoreColor = false;
    this.colorpicked = this.favorite.color;
  }
}
