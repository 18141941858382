<div
  class="content"
  draggable="false"
  (drop)="dropInView({ originalEvent: $event })"
  (dragover)="allowDropInView({ originalEvent: $event })"
>
  <div *ngIf="activeTab === null; else other_content"><p>'no tab selected'</p></div>
  <ng-template #other_content>
    <as-split #mainSplit gutterSize="8" direction="vertical" (dragEnd)="handleGutterDragEnd()">
      <as-split-area>
        <as-split gutterSize="8" direction="horizontal">
          <as-split-area
            *ngIf="isSplitAreaTop1Visible"
            id="splitAreaTop1"
            [ngClass]="[
              isSplitAreaTop1Visible && isSplitAreaTop2Visible ? 'viewSplittedTop' : '',
              isSplitAreaTop1Visible && isSplitAreaTop2Visible ? 'viewLeft' : ''
            ]"
            side="top"
            #splitAreaTop1
            (drop)="drop({ originalEvent: $event })"
            (dragstart)="dragStart({ originalEvent: $event, vcr: vcrSplitViewContentTop1 })"
            (dragend)="dragEnd({ originalEvent: $event })"
            (dragover)="swapView({ originalEvent: $event, vcr: vcrSplitViewContentTop1 })"
          >
            <div *ngIf="isSplitAreaTop2Visible" class="split-handle">
              <svg
                (mousedown)="setDraggable(splitAreaTop1)"
                class="split-handle-icon"
                width="24px"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"
                ></path>
                <path d="M0 0h24v24H0z" fill="none"></path>
              </svg>
            </div>
            <ng-template #splitViewContentTop1 class="splitAreaContent"></ng-template>
          </as-split-area>

          <as-split-area
            *ngIf="isSplitAreaTop2Visible"
            [ngClass]="[
              isSplitAreaTop1Visible && isSplitAreaTop2Visible ? 'viewSplittedTop' : '',
              isSplitAreaTop2Visible ? 'viewRight' : ''
            ]"
            side="top"
            id="splitAreaTop2"
            #splitAreaTop2
            (drop)="drop({ originalEvent: $event })"
            (dragstart)="dragStart({ originalEvent: $event, vcr: vcrSplitViewContentTop2 })"
            (dragend)="dragEnd({ originalEvent: $event })"
            (dragover)="swapView({ originalEvent: $event, vcr: vcrSplitViewContentTop2 })"
          >
            <div class="split-handle">
              <svg
                (mousedown)="setDraggable(splitAreaTop2)"
                class="split-handle-icon"
                width="24px"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"
                ></path>
                <path d="M0 0h24v24H0z" fill="none"></path>
              </svg>
            </div>
            <ng-template #splitViewContentTop2 class="splitAreaContent"></ng-template>
          </as-split-area>
        </as-split>
      </as-split-area>

      <as-split-area *ngIf="activeTab != null && tabs.length > 2">
        <as-split gutterSize="8" direction="horizontal">
          <as-split-area
            *ngIf="isSplitAreaBot1Visible"
            [ngClass]="[
              isSplitAreaBot1Visible ? 'viewSplittedBottom' : '',
              isSplitAreaBot1Visible && isSplitAreaBot2Visible ? 'viewLeft' : ''
            ]"
            id="splitAreaBot1"
            side="bot"
            #splitAreaBot1
            (drop)="drop({ originalEvent: $event })"
            (dragstart)="dragStart({ originalEvent: $event, vcr: vcrSplitViewContentBot1 })"
            (dragend)="dragEnd({ originalEvent: $event })"
            (dragover)="swapView({ originalEvent: $event, vcr: vcrSplitViewContentBot1 })"
          >
            <div class="split-handle">
              <svg
                (mousedown)="setDraggable(splitAreaBot1)"
                class="split-handle-icon"
                width="24px"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"
                ></path>
                <path d="M0 0h24v24H0z" fill="none"></path>
              </svg>
            </div>
            <ng-template #splitViewContentBot1 class="splitAreaContent"></ng-template>
          </as-split-area>

          <as-split-area
            *ngIf="isSplitAreaBot2Visible"
            [ngClass]="[
              isSplitAreaBot1Visible && isSplitAreaBot2Visible ? 'viewSplittedBottom' : '',
              isSplitAreaBot2Visible ? 'viewRight' : ''
            ]"
            side="bot"
            id="splitAreaBot2"
            #splitAreaBot2
            (drop)="drop({ originalEvent: $event })"
            (dragstart)="dragStart({ originalEvent: $event, vcr: vcrSplitViewContentBot2 })"
            (dragend)="dragEnd({ originalEvent: $event })"
            (dragover)="swapView({ originalEvent: $event, vcr: vcrSplitViewContentBot2 })"
          >
            <div class="split-handle">
              <svg
                (mousedown)="setDraggable(splitAreaBot2)"
                class="split-handle-icon"
                width="24px"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"
                ></path>
                <path d="M0 0h24v24H0z" fill="none"></path>
              </svg>
            </div>

            <ng-template #splitViewContentBot2 class="splitAreaContent"></ng-template>
          </as-split-area>
        </as-split>
      </as-split-area>
    </as-split>
  </ng-template>
</div>
