import { Injectable } from '@angular/core';
import { CompiereProcess } from '@compiere-ws/models/compiere-process-json';
import { AppConfig } from '@iupics-config/app.config';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class CompiereProcessService {
  private url: string;
  private executionUrl: string;

  constructor(private http: ApiService, private config: AppConfig) {}

  getProcess(processId: any): Observable<CompiereProcess> {
    if (this.url === undefined) {
      this.url = this.config.getBackendResource('process');
    }
    return this.http.get<CompiereProcess>(`${this.url}/${processId}`);
  }

  executeProcess(params: any): Observable<any> {
    if (this.executionUrl === undefined) {
      this.executionUrl = this.config.getProcessRunnerResource('executeProcess');
    }
    return this.http.post<any>(`${this.executionUrl}`, params);
  }
}
