import { Injectable } from '@angular/core';
import { CompiereMenu } from '@compiere-ws/models/compiere-menu-json';
import { WidgetFavoriteCompiereJson } from '@compiere-ws/models/widget-center-json';
import { AppConfig } from '@iupics-config/app.config';
import { DashboardMenuItem } from '@web-desktop/models/dashboard-menu-item';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable()
export class CompiereMenuFavoritesService {
  private urlMenu: string;
  private urlMenuEdit: string;
  private urlMenuDelete: string;

  private urlWidget: string;
  private urlWidgeEdit: string;
  private urlWidgeDelete: string;

  constructor(private http: ApiService, private config: AppConfig) {}

  getMenuFavorites(): Observable<CompiereMenu[]> {
    if (this.urlMenu === undefined) {
      this.urlMenu = this.config.getBackendResource('menuFavorites');
    }
    return this.http.get<CompiereMenu[]>(this.urlMenu);
  }

  getWidgetFavorites(): Observable<WidgetFavoriteCompiereJson[]> {
    if (this.urlWidget === undefined) {
      this.urlWidget = this.config.getBackendResource('widgetFavorites');
    }
    return this.http.get<WidgetFavoriteCompiereJson[]>(this.urlWidget);
  }

  setWidgetFavorites(compiereMenu: DashboardMenuItem[]): Observable<WidgetFavoriteCompiereJson[]> {
    if (this.urlWidgeEdit === undefined) {
      this.urlWidgeEdit = this.config.getBackendResource('widgetFavorites');
    }
    const isSummary = compiereMenu[0].isSummary;
    return this.http.post<WidgetFavoriteCompiereJson[]>(this.urlWidgeEdit + '?isCategory=' + isSummary, compiereMenu);
  }

  deleteWidgetFavorites(compiereMenu): Observable<CompiereMenu> {
    if (this.urlWidgeDelete === undefined) {
      this.urlWidgeDelete = this.config.getBackendResource('widgetFavorites');
    }
    return this.http.delete<CompiereMenu>(
      this.urlWidgeDelete +
        '?isCategory=' +
        compiereMenu.isSummary +
        '&menuID=' +
        compiereMenu.menuId +
        '&parentId=' +
        compiereMenu.parentId
    );
  }

  setMenuFavorites(compiereMenu: DashboardMenuItem[]): Observable<CompiereMenu> {
    if (this.urlMenuEdit === undefined) {
      this.urlMenuEdit = this.config.getBackendResource('menuFavorites');
    }
    const isSummary = compiereMenu[0].isSummary;
    return this.http.post<CompiereMenu>(this.urlMenuEdit + '?isCategory=' + isSummary, compiereMenu);
  }

  deleteMenuFavorites(compiereMenu): Observable<CompiereMenu> {
    if (this.urlMenuDelete === undefined) {
      this.urlMenuDelete = this.config.getBackendResource('menuFavorites');
    }
    return this.http.delete<CompiereMenu>(
      this.urlMenuDelete +
        '?isCategory=' +
        compiereMenu.isSummary +
        '&menuID=' +
        compiereMenu.menuId +
        '&parentId=' +
        compiereMenu.parentId
    );
  }
}
