<div
  #statusBarCount
  class="status-bar-panel-lines-container"
  *ngIf="isPanelDisplayed"
  [style.transform]="'translateY(-' + (this.selectedLines.length > 6 ? 37 + 25 * 7 : 37 + 25 * this.selectedLines.length) + 'px)'"
>
  <div class="status-bar-panel-close-btn" (click)="closeSelectedLinesPanel($event)">
    <i class="fas fa-lg fa-times-circle"></i>
  </div>
  <div class="status-bar-panel-selected-lines">
    <ng-container *ngFor="let line of selectedLines">
      <div style="margin: 5px; height: 20px; text-overflow: ellipsis;">
        <i class="fas fa-sm fa-times" style="cursor: pointer; color: var(--colorPrimary);" (click)="removeLine($event, line)"></i>
        <div style="display: inline; margin-left: 5px;">{{ line.Name }}</div>
      </div>
    </ng-container>
  </div>
</div>

<div class="ag-name-value">
  <span
    [hidden]="params.api.getSelectedRows().length <= 0"
    class="ag-name-value-value"
    (click)="isPanelDisplayed ? closeSelectedLinesPanel($event) : openSelectedLinesPanel($event)"
    >{{ params.api.getSelectedRows().length + ' ' }}{{ 'gridpanel.row_count' | translate }}</span
  >
</div>
