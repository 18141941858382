<div
  class="iu-specific-window iu-specific-window-container cruise-specific-window"
  [attr.data-cy-specific-window-name]="name"
  [style.fontSize.px]="12"
>
  <p-progressBar
    *ngIf="!isModal && isLoading"
    [mode]="'indeterminate'"
    [value]="0"
    [showValue]="false"
    [styleClass]="'loading-progressbar'"
  ></p-progressBar>
  <label class="title"
    ><b>{{ title }}</b></label
  >
  <div class="fields"><ng-template iuDynamicContainer></ng-template></div>
  <div *ngIf="ship" class="ship">
    <img
      #shipIMG
      class="iupics-ship-img"
      [style.opacity]="0"
      [src]="ship?.IMAGEURL | docServerUrl | async"
      [alt]="ship?.NAME"
      width="1000"
      height="1000"
    />
    <div #container></div>
  </div>

  <div
    #sideBar
    class="cruise-situation-sidebar"
    [ngStyle]="{ 'padding.px': isSidebarOpen ? 15 : 0, 'width.px': isSidebarOpen ? 400 : 0 }"
  >
    <i *ngIf="isSidebarOpen" class="iu-specific-btn icon-ico-next" (click)="isSidebarOpen = false; displayShip = undefined"></i>
    <div *ngIf="isSidebarOpen && displayShip" class="display-ship">
      <div class="iu-specific-ship-info">
        <div class="iu-specific-ship-info-children">Cabine n°{{ displayShip.cabinNo }}</div>
        <div class="iu-specific-ship-info-children">{{ displayShip.nbBed }} Lit(s)</div>
        <div class="iu-specific-ship-info-children">Type: {{ displayShip.type }}</div>
        <div class="iu-specific-ship-info-children">Status: {{ displayShip.status === 'taken' ? 'Réservée' : 'Libre' }}</div>
      </div>
      <hr *ngIf="displayShip.status !== 'taken'" />
      <div *ngIf="displayShip.status !== 'taken'">
        <iu-input-text-ui [isStandalone]="true" [label]="'Nom'" [(fieldValue)]="booking_name"></iu-input-text-ui><br />
        <iu-input-text-ui [isStandalone]="true" [label]="'Prénom'" [(fieldValue)]="booking_firstname"></iu-input-text-ui><br />
        <iu-calendar-ui
          #dateOfBirthEl
          [isStandalone]="true"
          [label]="'Date de naissance'"
          [fieldValue]="booking_dateOfBirth"
          (fieldValueModified)="booking_dateOfBirth = dateOfBirthEl.fieldValue"
        ></iu-calendar-ui>
        <br />
        <div class="iu-specific-btn-container" [ngClass]="[shouldAddPadding ? 'shouldAddPadding' : '']" style="margin: 5px">
          <button pButton class="cruise-btn" (click)="bookCabin($event, displayShip.cabinOnShip)">Réserver</button>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="displaySearch">
  <iu-modal-ui
    data-cy="search-panel"
    [attr.data-cy-columnName]="this.searchLinkedComponent.autoComplete.data.columnName"
    [title]="this.searchLinkedComponent.autoComplete.label"
    [hasCloseBtn]="true"
    (closeModalEmitter)="closeModalSearch()"
  >
    <div class="autocomplete-universal-filter">
      <iu-universal-filter-ui
        [tabId]="-1"
        [columns]="searchLinkedComponent.autoComplete.data.searchColumns"
        [gridTab]="gridTab"
      ></iu-universal-filter-ui>
    </div>
    <iu-grid-tab-infinity-scroll-ui
      #gridTab
      (searchEmitter)="this.searchLinkedComponent.setSearchSelectItem($event); displaySearch = false"
      [data]="this.searchLinkedComponent.autoComplete.data"
      [container]="this"
      [isSearch]="true"
      [dataStored]="this.searchLinkedComponent.autoComplete.dataStored"
    ></iu-grid-tab-infinity-scroll-ui>
  </iu-modal-ui>
</ng-container>
<div [ngClass]="[!displayFormUI ? 'hidden' : '', 'specificModal']">
  <iu-modal-ui
    [title]="specificWindowTitle"
    [isModalDisplay]="displayFormUI"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false })"
  >
    <ng-template #vcrSpecific></ng-template>
  </iu-modal-ui>
</div>
