<div [ngClass]="['ui-terminal ui-widget ui-widget-content ui-corner-all', styleClass]" [ngStyle]="style" (click)="focus(in)">
  <div *ngIf="welcomeMessage">{{ welcomeMessage }}</div>
  <div class="ui-terminal-content">
    <div *ngFor="let command of commands">
      <span>{{ prompt }}</span> <span class="ui-terminal-command">{{ command.text }}</span>
      <div>{{ command.response }}</div>
    </div>
  </div>
  <div>
    <span class="ui-terminal-content-prompt">{{ prompt }}</span>
    <input
      #in
      type="text"
      readonly
      [(ngModel)]="command"
      class="ui-terminal-input"
      autocomplete="no"
      (keydown)="isInputBlocked ? blockInput($event) : handleCommand($event)"
      autofocus
    />
  </div>
</div>
