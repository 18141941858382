<div [ngClass]="['valuePreferencePanel p-col-12']">
  <div class="valuePreference-fields-container">
    <iu-input-text-ui
      #attribute
      data-cy="valuePreferencePanel-attribute"
      label="{{ 'valuePreference.attributeLabel' | translate }}"
      [fieldValue]="this.attributeDisplayValue"
      [isReadOnly]="true"
      [isStandalone]="true"
      [data]="{ marginTop: 10 }"
      [cssClass]="'p-col-12 p-md-12 p-lg-12'"
    ></iu-input-text-ui>
    <iu-input-text-ui
      #value
      data-cy="valuePreferencePanel-value"
      label="{{ 'valuePreference.value' | translate }}"
      [(fieldValue)]="this.displayValue"
      [isReadOnly]="true"
      [isStandalone]="true"
      [data]="{ marginTop: 10 }"
      [cssClass]="'p-col-12 p-md-12 p-lg-12'"
    ></iu-input-text-ui>
    <p data-cy="valuePreferencePanel-desc" class="help alignLeft">{{ description }}</p>
  </div>
  <div class="valuePreference-checkbox-container">
    <iu-input-switch-ui
      #adClientRef
      data-cy="valuePreferencePanel-switch-client"
      label="{{ 'valuePreference.adClient' | translate }}"
      [(fieldValue)]="this.adClient"
      [isReadOnly]="true"
      [isStandalone]="true"
      cssGrid="p-col-12 p-md-6 p-lg-6"
      style="text-align: left"
      (fieldValueModified)="updateToggleValue('adClient', $event)"
    ></iu-input-switch-ui>
    <iu-input-switch-ui
      #adOrgRef
      data-cy="valuePreferencePanel-switch-org"
      label="{{ 'valuePreference.adOrg' | translate }}"
      [(fieldValue)]="this.adOrg"
      [isReadOnly]="this.adOrgReadOnly"
      [isStandalone]="true"
      cssGrid="p-col-12 p-md-6 p-lg-6"
      style="text-align: left"
      (fieldValueModified)="updateToggleValue('adOrg', $event)"
    ></iu-input-switch-ui>
    <iu-input-switch-ui
      #adUserRef
      data-cy="valuePreferencePanel-switch-user"
      label="{{ 'valuePreference.adUser' | translate }}"
      [(fieldValue)]="this.adUser"
      [isReadOnly]="this.adUserReadOnly"
      [isStandalone]="true"
      cssGrid="p-col-12 p-md-6 p-lg-6"
      style="text-align: left"
      (fieldValueModified)="updateToggleValue('adUser', $event)"
    >
    </iu-input-switch-ui>
    <iu-input-switch-ui
      #adWindowRef
      data-cy="valuePreferencePanel-switch-window"
      label="{{ 'valuePreference.adWindow' | translate }}"
      [fieldValue]="this.adWindow"
      [isStandalone]="true"
      cssGrid="p-col-12 p-md-6 p-lg-6"
      style="text-align: left"
      (fieldValueModified)="updateToggleValue('adWindow', $event)"
    >
    </iu-input-switch-ui>
  </div>
  <div class="valuePreference-btn-container">
    <button
      *ngIf="isDeletable"
      class="p-button p-button-secondary"
      data-cy="valuePreferencePanel-btn-delete"
      (click)="delete(); $event.stopPropagation()"
    >
      {{ 'valuePreference.delete' | translate }}
    </button>
    <button
      class="p-button p-button-secondary"
      data-cy="valuePreferencePanel-btn-cancel"
      (click)="cancel(); $event.stopPropagation()"
    >
      {{ 'valuePreference.cancel' | translate }}
    </button>
    <button class="p-button primary" data-cy="valuePreferencePanel-btn-save" (click)="save(); $event.stopPropagation()">
      {{ 'valuePreference.save' | translate }}
    </button>
  </div>
</div>
