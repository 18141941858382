import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CompiereLanguage } from '@compiere-ws/models/compiere-language-json';

@Component({
  selector: 'iu-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {
  @ViewChild('dropDownContent', { static: true })
  dropDownContent: ElementRef;
  @Input()
  languages: CompiereLanguage[];
  defaultLanguage: CompiereLanguage;
  isDisplayContent = false;

  /*
    TODO: check si on utilise encore #checkToClean
   */

  @Output()
  changeLanguageEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {
    this.defaultLanguage = this.languages[0];
    this.languages = this.languages.slice(1);
  }

  displayDropdown(event) {
    this.isDisplayContent = true;
    event.stopPropagation();
  }

  hideDropdown() {
    this.isDisplayContent = false;
  }

  changeLanguage(event, language: CompiereLanguage) {
    this.hideDropdown();
    for (let i = 0; i < this.languages.length; i++) {
      if (this.languages[i].iso_code === language.iso_code) {
        this.languages[i] = this.defaultLanguage;
        break;
      }
    }
    this.defaultLanguage = language;
    if (event) {
      this.changeLanguageEmitter.emit(this.defaultLanguage);
      event.stopPropagation();
    }
  }

  @HostListener('document:click', ['$event'])
  clickonLoginPage() {
    if (this.isDisplayContent) {
      this.hideDropdown();
    }
  }
}
