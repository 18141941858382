<p-card
  [ngClass]="[item?.closed === false && !isStandalone ? 'untreated-notification' : '']"
  (click)="handleNotificationItSelf($event)"
>
  <p-header class="p-headerNotif">
    <h2 class="iu-notification-header" [ngClass]="[item.isError ? 'notifTitleError' : 'notifTitle']">
      <span class="notif-title" (click)="zoomSource()" data-cy="notification-title-zoom">{{ item.title }}</span>
      <p *ngIf="description.length > 0" class="help alignRight" pTooltip="{{ description }}"></p>
      <!-- <span *ngIf="priority.length > 0" class="{{ 'priorityIcon ' + priorityClass }}">{{ priority }}</span> -->
    </h2>
    <button
      pButton
      *ngIf="!isStandalone"
      icon="icon-ico-close"
      class="close-notification-btn"
      (click)="deleteNotification($event)"
      [disabled]="areButtonsDisabled"
    ></button>
  </p-header>
  <span *ngIf="item.zoomInfo && item.zoomInfo.zoomUrl?.length > 0" class="iu-notification-text"
    ><p
      class="zoomUrl"
      [innerHTML]="item.zoomInfo.zoomUrl"
      (click)="goToUrl()"
      pTooltip="{{ 'notification.openLink' | translate }}"
      data-cy="notification-title-url"
    ></p
  ></span>
  <span *ngIf="item.summary" class="iu-notification-text">
    <p
      *ngIf="item.zoomInfo && item.zoomInfo.zoomType"
      [innerHTML]="item.summary"
      class="zoomEntity"
      pTooltip="{{ 'notification.zoomOnRecords' | translate }}"
      (click)="zoom()"
      data-cy="notification-title-zoomInfo"
    ></p>
    <p *ngIf="!item.zoomInfo || !item.zoomInfo.zoomType" [innerHTML]="item.summary"></p>
  </span>
  <span *ngIf="item.fileLinks" class="iu-notification-icons"
    ><img
      *ngFor="let icon of icons"
      class="iu-notification-icon"
      [src]="icon.icon | docServerUrl | async"
      alt="icone"
      pTooltip="{{ icon.fileName }}"
      tooltipZIndex="8000"
      width="32"
      height="32"
      (click)="dlFile($event, icon.fileLink, icon)"
    />
    <i
      *ngIf="item.fileLinks?.length > 1"
      class="fa fa-download iu-notification-icon iu-notification-download-icon"
      (click)="dlFile($event, item.fileLinks)"
      pTooltip="{{ 'notification.downloadAll' | translate }}"
      tooltipZIndex="8000"
    ></i>
  </span>
  <span *ngIf="readAbleDate" class="iu-notification-date">
    <p [innerHTML]="readAbleDate" style="font-style: italic"></p>
    <i
      *ngIf="priority.length > 0"
      class="{{ ' fa-bell priorityIcon ' + priorityClass }}"
      [ngClass]="[item.closed ? 'far' : 'fas']"
    ></i
  ></span>
</p-card>
