<button
  pButton
  tooltipPosition="top"
  showDelay="250"
  life="3"
  pTooltip="{{ 'menuBarDetails.save' | translate }}"
  type="button"
  icon="icon-ico-close"
  class="p-menu-bar-button"
  type="button"
  icon="icon-ico-save3x"
  (click)="save()"
></button>
<p-pickList
  [source]="exclusionDataList"
  [target]="inclusionDataList"
  dragdrop="true"
  [sourceHeader]="'select-order.source-header' | translate"
  [targetHeader]="'select-order.target-header' | translate"
  [showSourceControls]="true"
>
  <ng-template let-exclusionDataList pTemplate="item">
    <div class="ui-helper-clearfix">{{ exclusionDataList.Name }}</div>
  </ng-template>
  <ng-template let-inclusionDataList pTemplate="item">
    <div class="ui-helper-clearfix">{{ inclusionDataList.Name }}</div>
  </ng-template>
</p-pickList>
