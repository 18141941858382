<span
  class="inputSwitchlabel"
  [style.display]="displayCss"
  [style.margin-top.px]="data && data.marginTop > 0 ? data.marginTop : null"
  [pTooltip]="tooltip"
  life="3"
  tooltipPosition="top"
  showDelay="500"
  tooltipZIndex="2100"
>
  <p-checkbox
    (contextmenu)="onContextMenu($event)"
    (press)="onContextMenu($event)"
    [(ngModel)]="checked"
    [binary]="true"
    [disabled]="isReadOnly"
    [ngClass]="[hasConflict ? 'iu-field-conflict' : '']"
    [style.backgroundColor]="backgroundColorCss"
    data-cy="input-switch"
    [attr.data-cy-columnName]="data ? data.columnName : columnName"
    [attr.data-cy-value]="checked"
  ></p-checkbox>
  <span
    *ngIf="this.isLabelDisplay"
    style="height: 100%"
    (contextmenu)="onContextMenu($event)"
    (press)="onContextMenu($event)"
    [ngClass]="[hasConflict ? 'iu-field-conflict-icon' : '']"
    #spanRef
    ><p style="width: fit-content" (click)="!isReadOnly && labelClick($event)">{{ label }}</p>
    <i
      style="float: none"
      *ngIf="hasConflict && dataStored"
      #spanInput
      class="ui-inputgroup-addon rtconflict fas fa-exclamation-triangle iconClass iu-field-conflict-icon"
      (click)="showConflictPanel($event)"
    ></i
  ></span>
  <iu-value-preference-panel
    *ngIf="showValuePrefPanel"
    [fieldValue]="fieldValue"
    [dataStored]="this.dataStored"
    [sourceComponent]="this"
    [data]="this.data"
    (closeEmitter)="showValuePrefPanel = !showValuePrefPanel"
  ></iu-value-preference-panel>
</span>

<p-overlayPanel #opConflict>
  <a class="data-conflict" (click)="chooseData($event, (dataStored?.data)[data.columnName], true); opConflict.hide($event)">{{
    (dataStored?.data)[data.columnName]
  }}</a>
  | <a class="data-conflict" (click)="chooseData($event, conflictedData, false); opConflict.hide($event)">{{ conflictedData }}</a>
</p-overlayPanel>
