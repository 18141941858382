import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DynamicContainerDirective } from '@iupics-util/directives/dynamic-container.directive';
import { TextLimitPipe } from '@iupics-util/pipes/text-limit/text-limit.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AddActiveOnClickDirective } from './directives/add-active-on-click.directive';
import { AutofocusDirective } from './directives/autofocus.directive';
import { ToggleButtonDirective } from './directives/toggle-button.directive';
import { AccesPortalGuard } from './guards/acces-portal.guard';
import { AccesRessourceGuard } from './guards/acces-ressource.guard';
import { DocServerFormatValuePipe } from './pipes/doc-server-format-value/doc-server-format-value.pipe';
import { DocServerUrlPipe } from './pipes/doc-server-url/doc-server-url.pipe';
import { FakeArrayPipe } from './pipes/fake-array/fake-array.pipe';
import { ObjectArrayFilterPipe } from './pipes/object-array-filter/object-array-filter.pipe';
import { StringArrayFilterPipe } from './pipes/string-array-filter/string-array-filter.pipe';
import { UrlToFilePipe } from './pipes/url-to-file/url-to-file.pipe';

@NgModule({
  imports: [CommonModule, TranslateModule, RouterModule],
  declarations: [
    DynamicContainerDirective,
    TextLimitPipe,
    AddActiveOnClickDirective,
    UrlToFilePipe,
    PageNotFoundComponent,
    ToggleButtonDirective,
    ObjectArrayFilterPipe,
    StringArrayFilterPipe,
    FakeArrayPipe,
    AutofocusDirective,
    DocServerUrlPipe,
    DocServerFormatValuePipe
  ],
  exports: [
    DynamicContainerDirective,
    TextLimitPipe,
    AddActiveOnClickDirective,
    ToggleButtonDirective,
    UrlToFilePipe,
    PageNotFoundComponent,
    ObjectArrayFilterPipe,
    StringArrayFilterPipe,
    FakeArrayPipe,
    AutofocusDirective,
    DocServerUrlPipe,
    DocServerFormatValuePipe
  ],
  providers: [AccesRessourceGuard, AccesPortalGuard]
})
export class IupicsUtilModule {}
