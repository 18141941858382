import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, NgZone, Renderer2 } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'iu-prime-overlay',
  animations: [
    trigger('animation', [
      state(
        'void',
        style({
          transform: 'translateY({{voidTranslateY}})',
          opacity: 0
        }),
        { params: { voidTranslateY: '0px' } }
      ),
      state(
        'close',
        style({
          transform: 'translateY({{closeTranslateY}})',
          opacity: 0
        }),
        { params: { closeTranslateY: '0px' } }
      ),
      state(
        'open',
        style({
          transform: 'translateY({{openTranslateY}})',
          opacity: 1
        }),
        { params: { openTranslateY: '0px' } }
      ),
      transition('void => open', animate('{{showTransitionParams}}')),
      transition('open => close', animate('{{hideTransitionParams}}'))
    ])
  ],
  templateUrl: './prime-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class PrimeOverlayComponent extends OverlayPanel {
  @Input() voidTranslateY = '5%';
  @Input() closeTranslateY = '5%';
  @Input() openTranslateY = '0px';

  constructor(public el: ElementRef, public renderer: Renderer2, cd: ChangeDetectorRef, zone: NgZone) {
    super(el, renderer, cd, zone);
  }
}
