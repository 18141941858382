<div class="chartView">
  <div class="chartViewRender">
    <div class="filters">
      <div class="switchView">
        <button pButton type="button" icon="fas fa-chart-pie" (click)="swicthToPieChart()" class="p-menu-bar-button"></button>
        <button
          pButton
          type="button"
          icon="fas fa-dot-circle"
          (click)="swicthToDoughnutChart()"
          class="p-menu-bar-button"
        ></button>
        <button pButton type="button" icon="fas fa-bullseye" (click)="swicthToPolarChart()" class="p-menu-bar-button"></button>
        <button pButton type="button" icon="fas fa-chart-line" (click)="swicthToLineChart()" class="p-menu-bar-button"></button>
        <button pButton type="button" icon="far fa-chart-bar" (click)="swicthToBarChart()" class="p-menu-bar-button"></button>
        <button
          pButton
          type="button"
          icon="far fa-chart-bar fa-rotate-90"
          (click)="swicthToHorizontalBarChart()"
          class="p-menu-bar-button"
        ></button>
        <button pButton type="button" icon="far fa-snowflake" (click)="swicthToRadarChart()" class="p-menu-bar-button"></button>
      </div>
      <div class="operation">
        <iu-autocomplete-ui
          #operationField
          [label]="'chartview.aggregate' | translate"
          [(fieldValue)]="operationFieldValue"
          (fieldValueModified)="changeOperationToCompute($event)"
          [isStandalone]="true"
          [data]="{
            items: [
              { id: 'sum', displayValue: 'gridTools.sum' | translate },
              { id: 'count', displayValue: 'gridTools.count' | translate }
            ]
          }"
        ></iu-autocomplete-ui>
      </div>
      <div class="column">
        <iu-autocomplete-ui
          [(fieldValue)]="defaultColumn"
          [label]="'chartview.dataAggregate' | translate"
          (fieldValueModified)="changeColumnToCompute($event)"
          [isStandalone]="true"
          [data]="{
            items: columns
          }"
        ></iu-autocomplete-ui>
      </div>
    </div>

    <p-chart #chart [type]="chartType" [data]="dataChart" [options]="options" [height]="'70vh'"></p-chart>
  </div>
</div>
