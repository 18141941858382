<ng-container *ngIf="isPreviewDisplayed && url">
  <iu-modal-ui
    *ngIf="modal"
    (closeModalEmitter)="isPreviewDisplayed = false; showError = false"
    [appendTo]="'body'"
    [angularStyle]="modalStyle"
  >
    <p *ngIf="hasPreviewError">
      {{ 'previewDoc.noPreview' | translate }}
    </p>
    <ng-container *ngIf="blob as src">
      <ng-container *ngIf="isImagePreview">
        <img data-cy="modal-preview-data" style="max-width: fit-content; height: fit-content" [src]="src" [alt]="fileName" />
      </ng-container>
      <ng-container *ngIf="src && isDocPreview && mediaType === 'application/pdf'; else docError">
        <object data-cy="preview-data" [data]="src" type="application/pdf" height="100%" width="100%">
          <p>
            {{ 'previewDoc.noPreview' | translate }}
            <a target="_blank" [href]="src" [download]="fileName"> {{ 'previewDoc.downloadIt' | translate }}</a>
          </p>
        </object>
      </ng-container>
      <ng-template *ngIf="showError">
        {{ 'previewDoc.noExtension' | translate }}
      </ng-template>
      <ng-template #docError>
        <p *ngIf="isDocPreview">
          {{ 'previewDoc.noPreview' | translate }}
          <a target="_blank" [href]="src" [download]="fileName"> {{ 'previewDoc.downloadIt' | translate }}</a>
        </p>
      </ng-template>
    </ng-container>
  </iu-modal-ui>
  <ng-container *ngIf="!modal">
    <p *ngIf="hasPreviewError">
      {{ 'previewDoc.noPreview' | translate }}
    </p>
    <ng-container *ngIf="blob as src">
      <ng-container *ngIf="isImagePreview">
        <img
          data-cy="preview-data"
          style="max-width: fit-content; height: fit-content"
          [ngStyle]="imgStyle"
          [src]="src"
          [alt]="fileName"
        />
      </ng-container>
      <ng-container *ngIf="src && isDocPreview && mediaType === 'application/pdf'; else docError">
        <object data-cy="preview-data" [data]="src" type="application/pdf" height="100%" width="100%">
          <p>
            {{ 'previewDoc.noPreview' | translate }}
            <a target="_blank" [href]="src" [download]="fileName"> {{ 'previewDoc.downloadIt' | translate }}</a>
          </p>
        </object>
      </ng-container>
      <ng-template *ngIf="showError">
        {{ 'previewDoc.noExtension' | translate }}
      </ng-template>
      <ng-template #docError>
        <p *ngIf="isDocPreview">
          {{ 'previewDoc.noPreview' | translate }}
          <a target="_blank" [href]="src" [download]="fileName"> {{ 'previewDoc.downloadIt' | translate }}</a>
        </p>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>
