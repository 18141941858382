export class StringTokenizer {
  private currentPosition: number;
  private newPosition: number;
  private maxPosition: number;
  private str: string;
  private delimiters: string;
  private retDelims: boolean;
  private delimsChanged: boolean;
  private maxDelimCodePoint: number;
  private hasSurrogates = false;
  private delimiterCodePoints: number[];

  constructor(str: string, delim: string, returnDelims: boolean) {
    this.currentPosition = 0;
    this.newPosition = -1;
    this.delimsChanged = false;
    this.str = str;
    this.maxPosition = str.length;
    this.delimiters = delim;
    this.retDelims = returnDelims;
    this.setMaxDelimCodePoint();
  }

  setMaxDelimCodePoint() {
    if (this.delimiters == null) {
      this.maxDelimCodePoint = 0;
      return;
    }

    let m = 0;
    let c;
    let count = 0;
    for (let i = 0; i < this.delimiters.length; i += String.fromCharCode(c).length) {
      c = this.delimiters.charCodeAt(i);
      if (c >= '\uD800' && c <= '\uDFFF') {
        c = this.delimiters.codePointAt(i);
        this.hasSurrogates = true;
      }
      if (m < c) {
        m = c;
      }
      count++;
    }
    this.maxDelimCodePoint = m;

    if (this.hasSurrogates) {
      this.delimiterCodePoints = [];
      for (let i = 0, j = 0; i < count; i++, j += String.fromCharCode(c).length) {
        c = this.delimiters.codePointAt(j);
        this.delimiterCodePoints.push(c);
      }
    }
  }

  skipDelimiters(startPos: number): number {
    if (this.delimiters == null) {
      return undefined;
    }

    let position = startPos;
    while (!this.retDelims && position < this.maxPosition) {
      if (!this.hasSurrogates) {
        const c = this.str.charCodeAt(position);
        if (c > this.maxDelimCodePoint || this.delimiters.indexOf(String.fromCharCode(c)) < 0) {
          break;
        }
        position++;
      } else {
        const c = this.str.codePointAt(position);
        if (c > this.maxDelimCodePoint || !this.isDelimiter(c)) {
          break;
        }
        position += String.fromCharCode(c).length;
      }
    }
    return position;
  }

  scanToken(startPos: number): number {
    let position = startPos;
    while (position < this.maxPosition) {
      if (!this.hasSurrogates) {
        const c = this.str.charCodeAt(position);
        if (c <= this.maxDelimCodePoint && this.delimiters.indexOf(String.fromCharCode(c)) >= 0) {
          break;
        }
        position++;
      } else {
        const c = this.str.codePointAt(position);
        if (c <= this.maxDelimCodePoint && this.isDelimiter(c)) {
          break;
        }
        position += String.fromCharCode(c).length;
      }
    }
    if (this.retDelims && startPos === position) {
      if (!this.hasSurrogates) {
        const c = this.str.charCodeAt(position);
        if (c <= this.maxDelimCodePoint && this.delimiters.indexOf(String.fromCharCode(c)) >= 0) {
          position++;
        }
      } else {
        const c = this.str.codePointAt(position);
        if (c <= this.maxDelimCodePoint && this.isDelimiter(c)) {
          position += String.fromCharCode(c).length;
        }
      }
    }
    return position;
  }

  isDelimiter(codePoint: number): boolean {
    for (let i = 0; i < this.delimiterCodePoints.length; i++) {
      if (this.delimiterCodePoints[i] === codePoint) {
        return true;
      }
    }
    return false;
  }

  hasMoreTokens(): boolean {
    this.newPosition = this.skipDelimiters(this.currentPosition);
    return this.newPosition < this.maxPosition;
  }

  nextToken(): string {
    this.currentPosition =
      this.newPosition >= 0 && !this.delimsChanged ? this.newPosition : this.skipDelimiters(this.currentPosition);
    this.delimsChanged = false;
    this.newPosition = -1;
    if (this.currentPosition >= this.maxPosition) {
      return undefined;
    }
    const start = this.currentPosition;
    this.currentPosition = this.scanToken(this.currentPosition);
    return this.str.substring(start, this.currentPosition);
  }

  nextTokenDelim(delim: string): string {
    this.delimiters = delim;

    this.delimsChanged = true;

    this.setMaxDelimCodePoint();
    return this.nextToken();
  }

  hasMoreElements(): boolean {
    return this.hasMoreTokens();
  }

  nextElement(): any {
    return this.nextToken();
  }

  countTokens(): number {
    let count = 0;
    let currpos = this.currentPosition;
    while (currpos < this.maxPosition) {
      currpos = this.skipDelimiters(currpos);
      if (currpos >= this.maxPosition) {
        break;
      }
      currpos = this.scanToken(currpos);
      count++;
    }
    return count;
  }
}

export class LogicEvaluator {
  public static evaluateLogic(source: Map<string, string>, logic: string): boolean {
    if (logic === null || logic === undefined) {
      return true;
    }
    logic = this.replaceVariables(logic, source, source, false, true);
    const st = new StringTokenizer(logic.trim(), '&|', true);
    const it = st.countTokens();
    if (it / 2 - (it + 1) / 2 === 0) {
      return false;
    }
    if (st.hasMoreTokens()) {
      let retValue: boolean = this.evaluateLogicTuple(source, st.nextToken());
      while (st.hasMoreTokens()) {
        const logOp: string = st.nextToken().trim();
        const temp: boolean = this.evaluateLogicTuple(source, st.nextToken());
        if (logOp === '&') {
          retValue = retValue && temp;
        } else if (logOp === '|') {
          retValue = retValue || temp;
        } else {
          return false;
        }
      } // hasMoreTokens
      return retValue;
    } else {
      return null;
    }
  } //  evaluateLogic

  private static evaluateLogicTuple(source: Map<string, string>, logic: string): boolean {
    const st = new StringTokenizer(logic.trim(), '!=^><', true);
    if (st.countTokens() !== 3) {
      return false;
    }

    // 	First Part
    let first: string = st.nextToken().trim();
    let firstEval: any = first.trim();
    if (first.indexOf('@') !== -1) {
      first = first.replace(/@/g, ' ').trim();
      firstEval = source[first];
      if (firstEval == null) {
        firstEval = '';
      } else if (firstEval.id) {
        firstEval = firstEval.id;
      } else if (typeof firstEval === 'boolean') {
        firstEval = firstEval ? 'Y' : 'N';
      }
    }
    if (firstEval.replace) {
      firstEval = firstEval.replace(/'/g, ' ').replace(/"/g, ' ').trim();
    }
    const operand: string = st.nextToken();

    let second: string = st.nextToken();
    let secondEval: any = second.trim();
    if (second.indexOf('@') !== -1) {
      second = second.replace(/@/g, ' ').trim();
      secondEval = source[second + ''];
      if (secondEval == null) {
        secondEval = '';
      } else if (secondEval.id) {
        secondEval = secondEval.id;
      } else if (typeof secondEval === 'boolean') {
        secondEval = secondEval ? 'Y' : 'N';
      }
    }
    if (secondEval.replace) {
      secondEval = secondEval.replace(/'/g, ' ').replace(/"/g, ' ').trim();
    }

    if (first.indexOf('_ID') !== -1 && firstEval.length === 0) {
      firstEval = '0';
    }
    if (second.indexOf('_ID') !== -1 && secondEval.length === 0) {
      secondEval = '0';
    }

    const result: boolean = this.secondEvaluateLogicTuple(firstEval, operand, secondEval);

    return result;
  }

  public static secondEvaluateLogicTuple(value1: string, operand: string, value2: string): boolean {
    if (value1 === undefined || value1 === null || !operand || value2 === undefined || value2 === null) {
      return false;
    }
    const value1Parsed = parseInt(value1, 10) ? parseInt(value1, 10) : value1 !== 'NULL' ? value1 : '';
    const value2Parsed = parseInt(value2, 10) ? parseInt(value2, 10) : value2 !== 'NULL' ? value2 : ''; //https://helpdesk.audaxis.com/issues/show/118028
    if (operand === '=') {
      return value1Parsed === value2Parsed;
    } else if (operand === '<') {
      return value1Parsed < value2Parsed;
    } else if (operand === '>') {
      return value1Parsed > value2Parsed;
    } else {
      return value1Parsed !== value2Parsed;
    }
  }

  public static parseLogic(source: Map<string, string>, logic: string, windowCtx: {}): string {
    if (!logic) {
      return undefined;
    }
    const st = new StringTokenizer(logic.trim(), '&|', true);
    const it = st.countTokens();
    if (it / 2 - (it + 1) / 2 === 0) {
      return undefined;
    }

    let retValue: string = this.parseLogicTuple(source, st.nextToken());
    while (st.hasMoreTokens()) {
      const logOp: string = st.nextToken().trim();
      const temp: string = this.parseLogicTuple(source, st.nextToken());
      if (logOp === '&') {
        retValue = retValue && temp;
      } else if (logOp === '|') {
        retValue = retValue || temp;
      } else {
        return undefined;
      }
    } // hasMoreTokens
    return retValue;
  } //  evaluateLogic

  private static parseLogicTuple(source: Map<string, string>, logic: string): string {
    const st = new StringTokenizer(logic.trim(), '!=^><', true);
    if (st.countTokens() !== 3) {
      return undefined;
    }
    // 	First Part
    let first: string = st.nextToken().trim();
    let firstEval: string = first.trim();

    if (first.indexOf('@') !== -1) {
      first = first.replace(/@/g, ' ').trim();
      firstEval = source[first];
      if (firstEval == null) {
        firstEval = source['#' + first];
        if (firstEval == null) {
          firstEval = '';
        }
      }
    }
    if (firstEval.replace) {
      firstEval = firstEval.replace(/'/g, ' ').replace(/"/g, ' ').trim();
    }

    const operand: string = st.nextToken();

    let second: string = st.nextToken();
    let secondEval: string = second.trim();
    if (second.indexOf('@') !== -1) {
      second = second.replace(/@/g, ' ').trim();
      secondEval = source[second + ''];
      if (!secondEval) {
        secondEval = '';
      }
    }
    if (secondEval.replace && secondEval.indexOf("'") !== 0) {
      secondEval = secondEval.replace(/'/g, ' ').replace(/"/g, ' ').trim();
    }
    if (first.indexOf('_ID') !== -1 && firstEval.length === 0) {
      firstEval = '-1';
    }
    if (second.indexOf('_ID') !== -1 && secondEval.length === 0) {
      secondEval = '-1';
    }
    if (<any>secondEval instanceof Object) {
      secondEval = secondEval['id'];
    }

    const result: string = this.secondParseLogicTuple(firstEval + '', operand + '', secondEval + '');

    return result;
  }

  public static secondParseLogicTuple(value1: string, operand: string, value2: string): string {
    if (!value1 || !operand || !value2) {
      return undefined;
    }

    let value1bd: number = null;
    let value2bd: number = null;

    if (!value1.startsWith("'")) {
      value1bd = parseInt(value1, 0);
    }
    if (!value2.startsWith("'")) {
      value2bd = parseInt(value2, 0);
    }

    value1bd = null;
    value2bd = null;

    if (value1bd != null && value2bd != null) {
      return value1bd + operand + value2bd;
    } else {
      return value1 + operand + value2;
    }
  }

  public static replaceVariables(
    raw: string,
    ctx: {},
    windowCtx: {},
    isForEmail = false,
    fromEvaluateLogic = false,
    strict = true
  ): any {
    let result = raw;
    const variables: string[] = [];
    this.parseDepends(variables, raw);
    for (let i = 0; i < variables.length; i++) {
      const tmp = variables[i];
      if (tmp.indexOf('\n') !== -1) {
        break;
      }
      let param = null;
      if (windowCtx) {
        param = windowCtx[tmp];
      }
      if (param === undefined || param === null) {
        if (tmp.indexOf('$') === 0) {
          param = ctx[tmp];
        }
        if (tmp.indexOf('#') === 0) {
          param = ctx[tmp];
          if (param === undefined || param === null) {
            param = ctx['#' + tmp];
          }
        }
        if (tmp.indexOf('##') === 0) {
          param = ctx[tmp];
        }
        if ((param === undefined || param === null) && tmp.indexOf('#') === 0) {
          param = ctx[this.replaceAll(tmp, '#', 'P|')];
        }
        if ((param === undefined || param === null) && tmp.indexOf('P') === 0) {
          param = ctx[this.replaceAll(tmp, 'P|', '1|')];
        }
      }
      if (
        !strict &&
        tmp !== 'AD_User_ID' &&
        tmp !== 'AD_Org_ID' &&
        (param === undefined || param === null) &&
        tmp.indexOf('$') !== 0 &&
        tmp.indexOf('#') !== 0 &&
        tmp.indexOf('P') !== 0 &&
        tmp.indexOf('##') !== 0
      ) {
        const possibilities = ['$', '#', '##'];
        if (ctx) {
          possibilities.forEach((possibility) => {
            if (ctx[possibility + tmp] !== undefined) {
              param = ctx[possibility + tmp];
              return;
            }
          });
        }
      }
      if (
        (param === undefined || param === null) &&
        (tmp.indexOf('$') === 0 || tmp.indexOf('#') === 0 || tmp.indexOf('P') === 0)
      ) {
        if (windowCtx) {
          param = windowCtx[tmp.substring(1)];
        }
      }
      if (param !== undefined && param !== null && param instanceof Object) {
        // formater instance de date
        if (isForEmail) {
          result = result.replace('@' + tmp + '@', '<strong>' + param.displayValue + '</strong>');
        } else {
          result = this.replaceAll(result, '@' + tmp + '@', param.id);
          const matchFounds = result.match(new RegExp('@' + tmp + '\\|[^@]*@', 'g'));
          if (matchFounds) {
            matchFounds.forEach((matchFound) => {
              result = this.replaceAll(result, matchFound, param.id); // Fix 131405
            });
          }
        }
      } else {
        if (param || param === 0) {
          if (isForEmail) {
            result = result.replace('@' + tmp + '@', '<strong>' + param + '</strong>');
          } else {
            result = this.replaceAll(result, '@' + tmp + '@', param);
            //131405
            const matchFounds = result.match(new RegExp('@' + tmp + '\\|[^@]*@', 'g'));
            if (matchFounds) {
              matchFounds.forEach((matchFound) => {
                result = this.replaceAll(result, matchFound, param); // Fix #117850 131405
              });
            }
          }
        } else {
          if (isForEmail) {
            result = result.replace('@' + tmp + '@', '<strong>' + null + '</strong>');
          } else {
            if (tmp.endsWith('_ID')) {
              result = this.replaceAll(result, '@' + tmp + '@', '0');
            }
            result = this.replaceAll(result, '@' + tmp + '@', fromEvaluateLogic ? "''" : 'NULL'); // Fix #117850
            //131405
            const matchFounds = result.match(new RegExp('@' + tmp + '\\|[^@]*@', 'g'));
            if (matchFounds) {
              matchFounds.forEach((matchFound) => {
                const pipeIndex = matchFound.lastIndexOf('|');
                result = this.replaceAll(result, matchFound, matchFound.slice(pipeIndex + 1, -1)); // Fix #117850 131405
              });
            }
          }
        }
      }
    }
    if (result) {
      result = this.replaceAll(result, "'NULL'", 'NULL');
      result = this.replaceAll(result, '=NULL', ' IS NULL ');
      result = this.replaceAll(result, '= NULL', ' IS NULL ');
      if (
        // check si c'est null ou non
        result === 'NULL' &&
        raw.trim().slice(0, 5).toLowerCase() !== '@sql='
      ) {
        result = null;
      }
    }

    return result;
  }
  public static escapeRegExp(str) {
    return str.replace(/[$|]/g, '\\$&');
  }
  public static replaceAll(raw: string, replaced: string, replacement: string) {
    return raw.replace(new RegExp(this.escapeRegExp(replaced), 'g'), replacement);
  }
  public static getContextField(raw: string, ctx?: {}, windowCtx?: {}): any {
    const contextField = {};
    const variables: string[] = [];
    this.parseDepends(variables, raw.replace('@SQL', '').replace('@sql', ''));
    for (let i = 0; i < variables.length; i++) {
      const tmp = variables[i];
      if (tmp.indexOf('\n') !== -1) {
        break;
      }
      let param = null;
      if (windowCtx) {
        param = windowCtx[tmp];
      }
      if (ctx && !param && tmp.indexOf('#') === 0) {
        param = ctx[tmp];
      }

      contextField[variables[i]] = param;
    }
    return contextField;
  }

  public static parseDepends(list: string[], parseString: string) {
    if (!parseString || parseString.length === 0) {
      return;
    }
    let s = parseString;
    while (s.indexOf('@') !== -1) {
      let pos = s.indexOf('@');
      s = s.substring(pos + 1);
      pos = s.indexOf('@');
      if (pos === -1) {
        continue;
      } //  error number of @@ not correct
      let variable = s.substring(0, pos);
      s = s.substring(pos + 1);
      // now if the variable contains |, which means it is in the form of @var|alternative@, remove | the the part after
      if (variable.indexOf('|') !== -1) {
        variable = variable.substring(0, variable.indexOf('|'));
      }
      let find = false;
      list.forEach((x) => {
        if (x === variable) {
          find = true;
        }
      });
      if (!find) {
        list.push(variable);
      }
    }
  } // parseDepends
}
