import { Injectable } from '@angular/core';
import { CompiereLanguage } from '@compiere-ws/models/compiere-language-json';
import { AppConfig } from '@iupics-config/app.config';
import { UserAccount } from '@login-page/models/user-account.';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import * as shajs from 'sha.js';

export enum CookieIupics {
  access_token_expires = 'iu-dieoph',
  access_token_previous_user = 'iu-dimutre',
  access_token_expires_previous_user = 'iu-oiteral'
}

export enum LocalStorageIupics {
  access_token = 'iu-gauward',
  crypto_key = 'iu-lafka',
  last_connected_user = 'iu-ubgrim',
  all_users_connected = 'iu-tomrah',
  is_remember_user = 'iu-viokazar',
  default_language = 'iu-kawada',
  device_id = 'iu-Raruk',
  urlConfig = 'iu-bukadunn'
}

@Injectable()
export class IupicsCookieService {
  readonly cookieSeparator = '|=';

  constructor(private appConfig: AppConfig, private cookieService: CookieService) {}

  generateRandomKeyCookie(): string {
    const randKey: string = shajs('sha256')
      .update(
        Math.random()
          .toString(36)
          .substr(2, 9) + moment().valueOf()
      )
      .digest('hex');
    localStorage.setItem(LocalStorageIupics.crypto_key, randKey);
    return randKey;
  }

  setAndEncryptCookie(cookieName: string, value: string, expires?: Date) {
    const key = localStorage.getItem(LocalStorageIupics.crypto_key);
    const encodedCookie = CryptoJS.AES.encrypt(value, key);
    if (!expires) {
      expires = new Date();
      expires.setTime(expires.getTime() + this.appConfig.getConstant('cookieLifeTimeSecond') * 1000);
    }
    this.cookieService.set(cookieName, encodedCookie, expires, '/');
  }

  getDecryptedCookie(cookieName: string): string {
    const key = localStorage.getItem(LocalStorageIupics.crypto_key);
    const cookie = this.cookieService.get(cookieName);
    let decryptedCookie = '';
    try {
      decryptedCookie = CryptoJS.AES.decrypt(cookie, key).toString(CryptoJS.enc.Utf8);
    } catch (e) {
      this.clearLsCookies();
      location.reload(true);
    }
    return decryptedCookie;
  }

  setAndEncryptLocalStorage(keyName: string, value: string) {
    const key = localStorage.getItem(LocalStorageIupics.crypto_key);
    const encodedValue = CryptoJS.AES.encrypt(value, key);
    localStorage.setItem(keyName, encodedValue);
  }

  getDecryptedLocalStorage(keyName: string): string {
    const key = localStorage.getItem(LocalStorageIupics.crypto_key);
    const encodedValue = localStorage.getItem(keyName);
    let decryptedValue = '';
    try {
      decryptedValue = CryptoJS.AES.decrypt(encodedValue, key).toString(CryptoJS.enc.Utf8);
    } catch (e) {
      this.clearLsCookies();
      location.reload(true);
    }
    return decryptedValue;
  }

  check(cookieName: string): boolean {
    return this.cookieService.check(cookieName);
  }

  checkLS(keyName: string): boolean {
    return localStorage.getItem(keyName) !== null;
  }

  delete(cookieName: string) {
    this.cookieService.delete(cookieName);
  }
  clearLsCookies() {
    this.cookieService.deleteAll('/');
    localStorage.clear();
  }

  deleteLS(keyName: string) {
    localStorage.removeItem(keyName);
  }

  addUserToLocalStorage(user: UserAccount) {
    let usersCookie;
    if (this.checkLS(LocalStorageIupics.all_users_connected)) {
      const users = this.getUsersFromLocalStorage();
      let exists = false;
      if (users) {
        users.forEach(userObj => {
          if (userObj.id === user.id) {
            exists = true;
          }
        });
      }
      if (!exists) {
        usersCookie = this.getDecryptedLocalStorage(LocalStorageIupics.all_users_connected);
        usersCookie = usersCookie + this.cookieSeparator + JSON.stringify(user);
        this.setAndEncryptLocalStorage(LocalStorageIupics.all_users_connected, usersCookie);
      }
    } else {
      usersCookie = JSON.stringify(user);
      this.setAndEncryptLocalStorage(LocalStorageIupics.all_users_connected, usersCookie);
    }
  }

  getUsersFromLocalStorage(): UserAccount[] {
    if (this.checkLS(LocalStorageIupics.all_users_connected)) {
      let lastAccountConnected: string;
      if (this.checkLS(LocalStorageIupics.last_connected_user)) {
        lastAccountConnected = this.getDecryptedLocalStorage(LocalStorageIupics.last_connected_user);
      }
      const users: UserAccount[] = [];
      const usersStr = this.getDecryptedLocalStorage(LocalStorageIupics.all_users_connected).split(this.cookieSeparator);
      for (let i = 0; i < usersStr.length; i++) {
        if (usersStr[i] === lastAccountConnected) {
          users.unshift(JSON.parse(usersStr[i]));
        } else {
          users.push(JSON.parse(usersStr[i]));
        }
      }

      if (this.checkLS(LocalStorageIupics.default_language)) {
        const default_language = JSON.parse(
          this.getDecryptedLocalStorage(LocalStorageIupics.default_language)
        ) as CompiereLanguage;
        users.forEach(user => (user.default_language = default_language));
      }

      return users;
    }
    return undefined;
  }
}
