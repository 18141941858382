import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '@iupics-util/tools/util';

@Pipe({
  name: 'objectArrayFilter'
})
export class ObjectArrayFilterPipe implements PipeTransform {
  /**
   * Filtre un tableau d'objet par rapport à un filtre et un chemin donnée dans un tableau de string
   * - 'filtre' = filtre sur le nom du menu
   * - '!filtre' = négation du filtre sur le nom du menu
   * @param array
   * @param args
   */
  transform(array: any[], args?: string[]): any {
    if (args && args.length > 1) {
      const filter = args.shift();
      if (filter && filter !== '' && filter !== '!') {
        return array.filter(item => this.filterByType(this.getValueByPath(item, args), filter, filter.startsWith('!')));
      }
    }
    return array;
  }

  /**
   * Permet de récupérer la valeur d'un attribut grâce au chemin spécifié dans un tableau de string
   * @param parent
   * @param paths
   */
  private getValueByPath(parent: any, paths: string[]): any {
    let response = Object.assign({}, parent);
    paths.forEach(path => (response = response[path]));
    return response;
  }

  /**
   * Filtre les valeurs selon le type de la valeur récupérée
   * @param value
   * @param filter
   * @param inverted
   */
  private filterByType(value: any, filter: any, inverted: boolean = false): boolean {
    filter = inverted ? filter.substr(1, filter.length) : filter;
    switch (typeof value) {
      case 'string':
        const regex = '.*'.concat(
          Utils.cleanUpSpecialChars(filter, true)
            .split(' ')
            .join('.*'),
          '.*'
        );
        return (Utils.cleanUpSpecialChars(value).match(regex) === null) === inverted;
      case 'number':
        return (value !== filter) === inverted;
      default:
        return inverted;
    }
  }
}
