import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppConfig } from '@iupics-config/app.config';
import { Global } from '@iupics-manager/models/global-var';

@Component({
  selector: 'iu-smart-button-ui',
  templateUrl: './smart-button-ui.component.html',
  styleUrls: ['./smart-button-ui.component.scss']
})
export class SmartButtonUiComponent implements OnInit {
  // buttonWidth = 160;
  buttonWidth: number;
  @Input()
  label: string;
  @Input()
  key: number;
  @Input()
  id: number;
  @Input()
  iconClass: string;
  @Output()
  smartButtonClick = new EventEmitter();

  constructor(public elementRef: ElementRef, private config: AppConfig) {
    this.buttonWidth = Global.isMobileWidth()
      ? this.config.getConstant('SmartButtonUiComponent#buttonWidth_mobile')
      : this.config.getConstant('SmartButtonUiComponent#buttonWidth_not-mobile');
  }

  ngOnInit() {}

  onSmartButtonClick() {
    this.smartButtonClick.emit({ key: this.key, id: this.id });
  }
}
