<ul
  class="shortcutsTitle p-grid"
  iuKeybinding
  [iuKeybinding-active]="true"
  [iuKeybinding-code]="keybinding"
  [iuKeybinding-behavior-function]="switchCategory"
  [iuKeybinding-behavior-context]="this"
>
  <li
    #divRef
    [ngClass]="[
      'tab p-grid',
      (currentActiveIDX === -1 && i === 0) || currentActiveIDX === i ? 'active' : '',
      'iupics-hover-item'
    ]"
    *ngFor="let group of groups; let i = index"
    draggable="true"
    (dragstart)="dragstart($event, divRef, i)"
    (dragover)="dragOver($event, divRef)"
    (dragend)="dragend(divRef)"
  >
    <iu-dashboard-group-tab-ui
      [group]="group"
      (renameEvent)="onRename(i, $event)"
      (selectItemEvent)="onSelectitem($event, i)"
      (deleteEvent)="onDelete(i)"
    ></iu-dashboard-group-tab-ui>
  </li>
  <li
    *ngIf="!isMobile"
    class="last add p-grid"
    (click)="$event.preventDefault(); onAdd()"
    tooltipPosition="right"
    showDelay="250"
    life="3"
    pTooltip="{{ 'dashboard.addGroup' | translate }}"
    data-cy="dashboard-group-add"
  ></li>
</ul>
