import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DataStoreKey } from '@compiere-ws/models/compiere-data-json';
import { GridTabInfinityScrollUiComponent } from '@iupics-components/standard/grid/grid-tab-infinity-scroll-ui/grid-tab-infinity-scroll-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { IupicsEvent } from '@iupics-manager/models/iupics-event';
import { IupicsWidget } from '@web-desktop/models/iupics-widget';

@Component({
  selector: 'iu-grid-widget',
  templateUrl: './grid-widget.component.html',
  styleUrls: ['./grid-widget.component.scss']
})
export class GridWidgetComponent extends AbstractDynamicComponent implements OnInit {
  @ViewChild(GridTabInfinityScrollUiComponent, { static: true })
  gridTabInfinityScroll: GridTabInfinityScrollUiComponent;
  @Input()
  widget: IupicsWidget;
  data: any;
  gridTabFilter: any;
  tabId;
  isTabTopLevel;
  gridPaginator;
  container;
  DOMParentComponent;
  windowId;
  editView;
  @Output()
  openTabEmitter: EventEmitter<any> = new EventEmitter();
  constructor(private uiCreatorService: UICreatorService, private dataStore: DataStoreService) {
    super();
  }
  onRowSelectedOnGridTab(dataStoreKey: DataStoreKey, isOneColumn) {
    // hardcoded
    this.openTabEmitter.emit({
      action_id: this.windowId,
      menu_type: 'Window',
      name: this.editView.data.label,
      iconClass: 'fa fa-windows',
      openedRecordId: dataStoreKey.recordId
    });
    // hardcoded
  }
  ngOnInit() {
    //      this.uiCreatorService.getTab(event.item.tabId).subscribe(tabs => { tabId reçoit tabs[0]
    // hardcoded
    this.windowId = 1000235;
    // hardcoded
    const observ = this.uiCreatorService.getWindow(this.windowId);
    this.subscriptions.push(
      observ.subscribe(tabs => {
        this.data = tabs[0].gridView.data;
        this.tabId = tabs[0].tabId;
        this.gridPaginator = tabs[0].gridView.gridPaginator;
        this.gridTabFilter = tabs[0].gridView.gridTabFilter;
        this.isTabTopLevel = tabs[0].tabLevel === 0 ? true : false;
        this.container = this;
        this.DOMParentComponent = this;
        this.editView = tabs[0].editView;
        // this.subscriptions.push(this.dataStore.getDataList(this.windowId, tabs[0].tabId, null, null, 50, 0).subscribe());
      })
    );
  }

  onChildUpdate(event): void {}

  onSiblingUpdate(event: IupicsEvent) {}

  onRemoveComponent(event: IupicsEvent) {}
}
