import { AfterViewInit, Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ChartType } from '@iupics-components/models/view-type.enum';
import { Global } from '@iupics-manager/models/global-var';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '@web-desktop/controllers/theme.service';
import { IupicsWidget } from '@web-desktop/models/iupics-widget';
import { UIChart } from 'primeng/chart';

@Component({
  selector: 'iu-chart-widget',
  templateUrl: './chart-widget.component.html',
  styleUrls: ['./chart-widget.component.scss']
})
export class ChartWidgetComponent implements OnInit, AfterViewInit {
  @Input()
  widget: IupicsWidget;
  @ViewChild('chart', { read: UIChart })
  chart: UIChart;
  options: any;
  plugins: any;
  multiDataset = false;
  noData = false;
  tooltipLabels: any;
  lastLegendDisplayState: boolean;

  constructor(private translateService: TranslateService, private themeService: ThemeService) {}

  ngOnInit() {
    if (this.widget.data.datasets.length === 0 || this.widget.data.labels.length === 0) {
      this.noData = true;
    }

    this.lastLegendDisplayState = false;
    this.tooltipLabels = {
      pieChart: this.translateService.instant('chartWidgetUi.pieChart'),
      doughnutChart: this.translateService.instant('chartWidgetUi.doughnutChart'),
      polarChart: this.translateService.instant('chartWidgetUi.polarChart'),
      lineChart: this.translateService.instant('chartWidgetUi.lineChart'),
      barChart: this.translateService.instant('chartWidgetUi.barChart'),
      horizontalBarChart: this.translateService.instant('chartWidgetUi.horizontalBarChart'),
      radarChart: this.translateService.instant('chartWidgetUi.radarChart'),
      showLegend: this.translateService.instant('chartWidgetUi.showLegend')
    };

    if (this.widget.viewType === 'chart') {
      this.multiDataset =
        this.widget.chartType === ChartType.BAR ||
        this.widget.chartType === ChartType.HORIZONTAL_BAR ||
        this.widget.chartType === ChartType.LINE ||
        this.widget.chartType === ChartType.RADAR;

      const palette: any[] = this.themeService.getThemeProperty('colorsPalette');

      if (this.widget.data.datasets.length > 1) {
        this.widget.data.datasets.forEach((dataset: any, i: number) => {
          const color = i < palette.length ? palette[i] : this.getRandomColor();
          dataset.borderColor = color;
          dataset.pointBorderColor = '#fff';
          dataset.pointBackgroundColor = color;
          dataset.backgroundColor = color;
          dataset.hoverBackgroundColor = color;
        });
      } else if (this.widget.data.datasets.length > 0) {
        this.widget.data.labels.forEach((label: string, i: number) => {
          const color = i < palette.length ? palette[i] : this.getRandomColor();
          this.widget.data.datasets[0].backgroundColor.push(color);
          this.widget.data.datasets[0].hoverBackgroundColor.push(color);
          this.widget.data.datasets[0].borderColor = '#fff';
          this.widget.data.datasets[0].pointBorderColor = '#fff';
          this.widget.data.datasets[0].pointBackgroundColor = color;
        });
      }

      this.options = {
        title: {
          display: true,
          fontSize: 12,
          text: this.widget.description,
          dockInsidePlotArea: false
        },
        tooltips: {
          callbacks: {
            title: function (item, data) {
              const label = data.datasets[item[0].datasetIndex].label;
              return label;
            },
            label: function (item, data) {
              const dataSetValue = data.datasets[item.datasetIndex].data[item.index];
              return dataSetValue;
            }
          }
        },
        legend: {
          display: false
        }
      };
    }
  }

  ngAfterViewInit() {
    // check si on est sur mobile ou si le widget est trop petit pour afficher la légende
    if (!this.noData) {
      this.adaptOptionsWithScreen();
    }
  }

  private getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  swicthToPieChart() {
    this.widget.chartType = ChartType.PIE;
    setTimeout(() => this.chart.reinit(), 0);
  }

  swicthToLineChart() {
    this.widget.chartType = ChartType.LINE;
    this.widget.data.datasets.forEach((dataset) => (dataset.fill = false));
    setTimeout(() => this.chart.reinit(), 0);
  }

  swicthToPolarChart() {
    this.widget.chartType = ChartType.POLARAREA;
    setTimeout(() => this.chart.reinit(), 0);
  }

  swicthToBarChart() {
    this.widget.chartType = ChartType.BAR;
    this.widget.data.datasets.forEach((dataset) => (dataset.fill = true));
    setTimeout(() => this.chart.reinit(), 0);
  }

  swicthToDoughnutChart() {
    this.widget.chartType = ChartType.DOUGHNUT;
    setTimeout(() => this.chart.reinit(), 0);
  }

  swicthToRadarChart() {
    this.widget.chartType = ChartType.RADAR;
    this.widget.data.datasets.forEach((dataset) => (dataset.fill = true));
    setTimeout(() => this.chart.reinit(), 0);
  }
  // Permet d'afficher ou cacher la légende
  changeLegendView() {
    this.options.legend.display = !this.options.legend.display;
    this.lastLegendDisplayState = !this.lastLegendDisplayState;
    this.chart.reinit();
  }
  /**
   * Met le boolean a true ou false selon la largeur de l'écran
   * gère la présence de la légende quand le widget est trop petit ou sur mobile
   * @param {Event}event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    event.stopPropagation();
    if (this.chart) {
      this.adaptOptionsWithScreen();
    }
  }

  adaptOptionsWithScreen() {
    if (this.chart.el.nativeElement.children[0].clientWidth <= 400 || Global.getDeviceWidth() <= 640) {
      this.options.title.fontSize = 11;
      this.options.title.dockInsidePlotArea = true;
      this.options.legend.position = 'right';
    } else {
      this.options.title.fontSize = 12;
      this.options.title.dockInsidePlotArea = false;
      this.options.legend.position = 'top';
    }
    if (this.lastLegendDisplayState !== this.options.legend.display) {
      this.lastLegendDisplayState = !this.lastLegendDisplayState;
      this.chart.reinit();
    }
  }
}
