<div class="iu-searchpanel">
  <p-progressBar
    *ngIf="isLoading"
    [mode]="'indeterminate'"
    [value]="0"
    [showValue]="false"
    [styleClass]="'loading-progressbar'"
  ></p-progressBar>
  <div class="p-toolbar p-separator">
    <div class="usearch-first-container">
      <h1>
        {{ 'usearch.searchLabel' | translate }}
        <i class="fas fa-info-circle" title=" {{ 'usearch.informationLabel' | translate }}"></i>
      </h1>
      <div class="iu-searchinput">
        <iu-input-text-ui
          [placeHolder]="'usearch.welcome' | translate"
          [fieldValue]="textValue"
          [isStandalone]="true"
          [isContextMenuDisabled]="true"
          styleClass="usearchContainer-Input"
          inputStyleClass="usearchContainer-Input"
          (keyup)="search($event)"
          class="search-field"
          #inputSearch
          data-cy="usearch-input"
        >
        </iu-input-text-ui>
        <i class="icon-ico-search-bolder" (click)="search($event)" data-cy="usearch-search-button"></i>
      </div>
      <iu-input-switch-ui
        #onlyDocField
        label="{{ 'usearch.onlyDoc' | translate }}"
        [fieldValue]="this.onlyDocFieldValue"
        cssGrid="p-col-12"
        style="text-align: left"
        data-cy="usearch-doc-only-switch"
      ></iu-input-switch-ui>
    </div>

    <div class="iu-bestresults">
      <p-fieldset legend="{{ 'usearch.bestSuggestions' | translate }}">
        <div *ngIf="totResult > 0; else emptyResults">
          <ul>
            <div *ngFor="let item of topResults">
              <div class="ui-row">
                <li>
                  <a (click)="openTarget(item)"> {{ item.cat.displayValue }} > {{ item.source.displayValue }} </a>
                </li>
              </div>
            </div>
          </ul>
        </div>
        <ng-template #emptyResults>
          <p>{{ 'usearch.loading' | translate }}</p>
        </ng-template>
      </p-fieldset>
    </div>
    <div class="apiz-p-grid p-dir-rev" style="min-height: 50vh">
      <div class="categories p-col-12 p-md-5 p-lg-5">
        <p class="title">{{ 'usearch.Categories' | translate }}</p>
        <!-- <p-menu [model]="categories" (click)="onClick($event)" (visibleChange)="onClick($event)" #usearchMenu></p-menu> -->
        <ul class="categories-list">
          <ng-container *ngFor="let category of cats">
            <li
              [ngClass]="[selectedCategory === category.label ? 'active' : '', 'iu-menuitem-link']"
              (click)="selectedCategory = category.label; onClick($event)"
            >
              {{ category.label }}
            </li>
          </ng-container>
        </ul>
      </div>
      <div class="iu-results p-col-12 p-md-7 p-lg-7">
        <div class="p-col-12 resultListContainer" (mouseleave)="cancelPreviewFile()">
          <p-scrollPanel>
            <div *ngIf="totResult > 0">
              <div *ngIf="('usearch.allResults' | translate) === selectedCategory">
                <div
                  *ngFor="let itemCategory of catsResult; let idxcategory = index"
                  (mouseleave)="itemCategory.cat.id !== -999 ? '' : cancelPreviewFile()"
                  data-cy="usearch-resultList-container"
                  [attr.data-cy-catName]="itemCategory.cat.displayValue"
                >
                  <h4>{{ itemCategory.cat.displayValue }} ( {{ itemCategory.sources.length }} )</h4>
                  <div class="categoryResultList">
                    <div *ngFor="let itemResults of itemCategory.sources; let idxResults = index">
                      <div *ngIf="$any(idxResults) < 10">
                        <div class="ui-row">
                          <p>
                            <a
                              (click)="
                                itemCategory.cat.id !== -999
                                  ? openTarget({ cat: itemCategory.cat, source: itemResults })
                                  : downloadFile(itemResults)
                              "
                              (mouseover)="itemCategory.cat.id === -999 ? previewFile($event, itemResults) : ''"
                              data-cy="usearch-resultList-data"
                              [attr.data-cy-index]="idxResults"
                              [attr.data-cy-filename]="itemResults?.name"
                            >
                              {{ itemResults.displayValue }}
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="itemCategory.sources.length > 10">
                      <p>
                        <a style="cursor: pointer" (click)="seeMoreDetails($event, itemCategory.cat.displayValue)">
                          {{ 'usearch.seeMore' | translate }}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ng-template #emptyResults>
              <p>{{ 'usearch.loading' | translate }}</p>
            </ng-template>
            <div *ngIf="('usearch.allResults' | translate) !== selectedCategory">
              <div *ngFor="let item of resultsByCategory; let idx = index">
                <div class="ui-row">
                  <p>
                    <a
                      (click)="item.cat.id !== -999 ? openTarget({ cat: item.cat, source: item }) : downloadFile(item)"
                      (mouseover)="item.cat.id === -999 ? previewFile($event, item) : ''"
                    >
                      {{ item.displayValue }}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </p-scrollPanel>
        </div>
        <div #overlayArea class="iu-results-separator"></div>
      </div>
    </div>
  </div>
  <iu-preview-doc #previewDoc [z_index]="7200"></iu-preview-doc>
  <p-overlayPanel #opPreviewDoc>
    <ng-template pTemplate>
      <iu-preview-doc
        #previewDocModal
        [z_index]="7200"
        [fileName]="fileName"
        [urlFile]="urlFile"
        [modal]="false"
        [imgStyle]="{ 'max-width': '100%', height: '100%' }"
        (onPreviewError)="cancelPreviewFile()"
      ></iu-preview-doc>
    </ng-template>
  </p-overlayPanel>
</div>
