import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserAccount } from '@login-page/models/user-account.';

@Component({
  selector: 'iu-account-label',
  templateUrl: './account-label.component.html',
  styleUrls: ['./account-label.component.scss']
})
export class AccountLabelComponent implements OnInit {
  @Input()
  account: UserAccount;
  @Input()
  displayCarret: boolean;

  @Output()
  clickCarret: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {
    if (this.account) {
      if (!this.account.avatarLetters && this.account.id > -1) {
        this.account.avatarLetters = this.getTwoLetters();
      }
      if (!this.account.avatarImg) {
        if (!this.account.avatarColor) {
          if (this.account.isAnotherAccount) {
            this.account.avatarColor = '#ededed';
          } else {
            this.account.avatarColor = this.randomColor();
          }
        }
      } else {
        // image
      }
    }
  }

  getTwoLetters(): string {
    const spacesSplit = this.account.fullname.split(' ');
    if (spacesSplit.length > 1) {
      return spacesSplit[0].charAt(0) + spacesSplit[1].charAt(0);
    }
    const dashSplit = this.account.fullname.split('-');
    if (dashSplit.length > 1) {
      return dashSplit[0].charAt(0) + dashSplit[1].charAt(0);
    } else {
      return this.account.fullname.charAt(0) + this.account.fullname.charAt(1);
    }
  }

  randomColor(): string {
    return (
      'rgb(' +
      Math.floor(Math.random() * 256) +
      ',' +
      Math.floor(Math.random() * 256) +
      ',' +
      Math.floor(Math.random() * 256) +
      ')'
    );
  }
}
