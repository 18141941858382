export class Task {
  id: number | string;
  start_date?: string | Date;
  end_date?: string | Date;
  duration?: number;
  progress?: number;
  parent?: number | string;
  $open?: boolean;
  calendar_id?: string;
  static_duration: number; // only for programmation purpose
  $has_child?: boolean;
}
export class GanttTask extends Task {
  text: string;
  description: string;
}

export class GanttData {
  data: Task[];
  links: Link[];
}

export class Link {
  id: number | string;
  source: string;
  target: string;
  type: LinkType;
}

export enum LinkType {
  END_TO_START = '0',
  START_TO_START = '1',
  END_TO_END = '2',
  START_TO_END = '3'
}

export enum GanttZoomLevel {
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year'
}

export class GanttGridColumn {
  label?: string;
  name: string;
  width?: number | '*';
  template?: (task: Task) => string;
  align?: 'left' | 'center' | 'right';
  show?: boolean;
  // hide?: boolean; // PRO Only
  max_width?: number;
  min_width?: number;
  // resize?: boolean; // PRO Only
  tree?: boolean; // show the plus button to expand and collapse
}

export interface GanttDragConfig {
  drag_lightbox?: boolean;
  drag_links?: boolean;
  drag_move?: boolean;
  drag_multiple?: boolean;
  drag_progress?: boolean;
  drag_resize?: boolean;
  drag_timeline?: boolean;
}

export enum GanttDragMode {
  RESIZE = 'resize',
  PROGRESS = 'progress',
  MOVE = 'move',
  IGNORE = 'ignore'
}

export interface GanttCalendar {
  id: string;
  worktime: {
    hours: any[];
    days: any[];
  };
}

export interface GanttWorktimeConfig {
  day?: number;
  date?: Date;
  hours: boolean | string[] | number[];
}
