import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { KeybindStateManagerService } from './managers/keybind-state-manager/keybind-state-manager.service';
import { MessageManagerService } from './managers/message/message-manager.service';
import { NotificationManagerService } from './managers/notification-manager/notification-manager.service';
import { RecentItemsManagerService } from './managers/recent-items-manager/recent-items-manager.service';

@NgModule({
  imports: [CommonModule],
  exports: [],
  declarations: [],
  providers: [NotificationManagerService, MessageManagerService, RecentItemsManagerService, KeybindStateManagerService]
})
export class IupicsManagerModule {}
