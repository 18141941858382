import { Pipe, PipeTransform } from '@angular/core';
import { ColumnFilterAutocomplete } from '@iupics-components/models/autocomplete-interfaces';

@Pipe({
  name: 'columnGroupFilter'
})
export class ColumnGroupFilterPipe implements PipeTransform {
  transform(
    groupCols: { items: ColumnFilterAutocomplete[] },
    from?: 'window' | 'form' | 'info'
  ): { items: ColumnFilterAutocomplete[] } {
    const output = {
      items: groupCols.items.filter(item =>
        from === 'window' ? this.filterWindowGroupCol(item) : this.filterOtherGroupCol(item)
      )
    };
    return output;
  }

  private filterWindowGroupCol(item: ColumnFilterAutocomplete): boolean {
    return (
      !item.columnInfo.fieldEntity.field.hasOwnProperty('ColumnSQL') ||
      item.columnInfo.fieldEntity.field.ColumnSQL === undefined ||
      item.columnInfo.fieldEntity.field.ColumnSQL === null
    );
  }

  private filterOtherGroupCol(item: ColumnFilterAutocomplete): boolean {
    return item.columnInfo.fieldEntity.field.hasOwnProperty('ColumnSQL') &&
      item.columnInfo.fieldEntity.field.ColumnSQL &&
      (item.columnInfo.fieldEntity.field.ColumnSQL as string).match(/\bselect\b/gim) !== null
      ? false
      : true;
  }
}
