import { Injectable } from '@angular/core';
import { CompiereMenu } from '@compiere-ws/models/compiere-menu-json';
import { WidgetCompiereJson } from '@compiere-ws/models/widget-center-json';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { ObjectArrayFilterPipe } from '@iupics-util/pipes/object-array-filter/object-array-filter.pipe';
import { TranslateService } from '@ngx-translate/core';
import { IupicsMenuType, MenuItemUI } from '@web-desktop/models/menu-item-ui';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FavoriteFilterPipe } from '../../components/dashboard/pipes/favorite-filter/favorite-filter.pipe';

@Injectable()
export class DashboardManagerService {
  constructor(private uiCreator: UICreatorService, private translator: TranslateService) {}

  public getDashboardIupicsMenu(): Observable<{ category: string; items: MenuItemUI[] }[]> {
    return this.uiCreator.getIupicsMenus().pipe(
      map(menus => {
        const menuCategories = [];
        const menu_other = this.translator.instant('dashboard.menu.others');
        menuCategories.push({
          category: menu_other,
          items: []
        });
        menus.forEach(menu => {
          if (menu.menu_category === undefined) {
            menuCategories.find(group => group.category === menu_other).items.push(menu);
          } else if (menuCategories.find(group => group.category === menu.menu_category.name) === undefined) {
            menuCategories.push({
              category: menu.menu_category.name,
              items: [menu]
            });
          } else {
            menuCategories.find(group => group.category === menu.menu_category.name).items.push(menu);
          }
        });
        return menuCategories;
      })
    );
  }

  public getDashboardIupicsWidgetMenu(widget$: Observable<WidgetCompiereJson[]>): Observable<MenuItemUI[]> {
    return widget$.pipe(
      map(widgets => {
        if (widgets) {
          const widgetMenu: MenuItemUI[] = [];
          widgets.forEach(widget => {
            const menuItem: MenuItemUI = {
              angularClass: widget.angularClass,
              description: widget.description,
              menu_id: widget.ad_Menu_ID,
              menu_type: IupicsMenuType.PROCESS,
              action_id: widget.ad_DashboardFormat_ID,
              iconClass: null,
              color: null,
              name: widget.name,
              tags: null,
              isSummary: false,
              parentId: 0
            };
            widgetMenu.push(menuItem);
          });
          return widgetMenu;
        } else {
          return [];
        }
      })
    );
  }

  /**
   * Formatte la reponse du retour addWidget
   * @param response
   */
  public formatResponse(response: CompiereMenu): MenuItemUI {
    return {
      action_id: null,
      color: response[0].cssColor,
      iconClass: response[0].icon,
      isSummary: response[0].isSummary,
      menu_category: undefined,
      menu_id: response[0].menuId,
      menu_type: null,
      name: response[0].name,
      parentId: response[0].parentId,
      tags: response[0].tags === null ? [] : response[0].tags,
      type: null,
      seqNo: response[0].seqNo
    } as MenuItemUI;
  }

  public getNextCategoryOrMenu(
    offset: number,
    groups: { category: string; items: MenuItemUI[] }[],
    selectedCategory: string,
    searchFavoriteValue: string,
    arrowFocusedCategory: string,
    arrowFocusedMenu: MenuItemUI
  ): string | MenuItemUI {
    if (
      selectedCategory === undefined &&
      (searchFavoriteValue === undefined ||
        searchFavoriteValue === '' ||
        searchFavoriteValue === '!' ||
        searchFavoriteValue === '#' ||
        searchFavoriteValue === '!#')
    ) {
      // * si pas de category sélectionnée et pas de filtre
      const possibilities = groups.map(group => group.category);
      if (arrowFocusedCategory === undefined) {
        return possibilities[0];
      } else {
        const index = possibilities.findIndex(cat => cat === arrowFocusedCategory);
        return this.getNextItem(possibilities, offset, index);
      }
    } else if (
      selectedCategory !== undefined &&
      (searchFavoriteValue === undefined ||
        searchFavoriteValue === '' ||
        searchFavoriteValue === '!' ||
        searchFavoriteValue === '#' ||
        searchFavoriteValue === '!#')
    ) {
      // * si category sélectionnée et pas de filtre
      const currentGroupIndex = groups.findIndex(group => group.category === selectedCategory);
      const possibilities = groups[currentGroupIndex].items;
      if (arrowFocusedMenu === undefined) {
        return possibilities[0];
      } else {
        const index = possibilities.findIndex(menu => menu.menu_id === arrowFocusedMenu.menu_id);
        return this.getNextItem(possibilities, offset, index);
      }
    } else {
      // * si filtre
      const filterPipe = new FavoriteFilterPipe(this.translator);
      const possibilities = filterPipe.transform(groups, searchFavoriteValue);
      if (arrowFocusedMenu === undefined) {
        return possibilities[0];
      } else {
        const index = possibilities.findIndex((menu: MenuItemUI) => menu.menu_id === arrowFocusedMenu.menu_id);
        return this.getNextItem(possibilities, offset, index);
      }
    }
  }

  private getNextItem(possibilities: any[], offset: number, index: number): any {
    return offset + index <= 0
      ? possibilities[0]
      : offset + index >= possibilities.length
      ? possibilities[possibilities.length - 1]
      : possibilities[index + offset];
  }

  /**
   * Scroll sur le menu sélectionné quand il n'est pas totalement affiché à l'écran
   * @param {string}selectedItemDomID
   * @param {string}containerDomID
   */
  public scrollToItem(selectedItemDomID: string, containerDomID: string) {
    const selectedItem_DOM: HTMLElement = document.getElementById(selectedItemDomID);
    const selectedItem_DOMRect: ClientRect | DOMRect = selectedItem_DOM.getBoundingClientRect();
    const container_DOM: HTMLElement = document.getElementById(containerDomID);
    const container_DOMRect: ClientRect | DOMRect = container_DOM.getBoundingClientRect();

    const selectedItem_topPosition: number = selectedItem_DOMRect.top;
    const container_topPosition: number = container_DOMRect.top;
    const selectedItem_bottomPosition: number = selectedItem_DOMRect.bottom;
    const container_bottomPosition: number = container_DOMRect.height;

    // * si le dessus du menu sélectionné se trouve au dessus du container
    if (selectedItem_topPosition < container_topPosition) {
      selectedItem_DOM.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center'
      });
    }

    // * si le bas du menu sélectionné se trouve en dessous du container
    if (selectedItem_bottomPosition > container_bottomPosition) {
      container_DOM.scrollTo({
        top: selectedItem_bottomPosition - container_DOMRect.height + container_DOM.scrollTop,
        behavior: 'smooth'
      });
    }
  }

  public getNextWidget(
    offset: number,
    groups: MenuItemUI[],
    searchWidgetValue: string,
    arrowFocusedWidget: MenuItemUI
  ): MenuItemUI {
    if (
      searchWidgetValue === undefined ||
      searchWidgetValue === '' ||
      searchWidgetValue === '!' ||
      searchWidgetValue === '#' ||
      searchWidgetValue === '!#'
    ) {
      // * quand pas de filtre
      if (arrowFocusedWidget === undefined) {
        return groups[0];
      } else {
        const index = groups.findIndex(menu => menu.menu_id === arrowFocusedWidget.menu_id);
        return this.getNextItem(groups, offset, index);
      }
    } else {
      // * quand filtre
      const filterPipe = new ObjectArrayFilterPipe();
      const possibilities = filterPipe.transform(groups, [searchWidgetValue, 'name']);
      if (arrowFocusedWidget === undefined) {
        return possibilities[0];
      } else {
        const index = possibilities.findIndex(menu => menu.menu_id === arrowFocusedWidget.menu_id);
        return this.getNextItem(possibilities, offset, index);
      }
    }
  }
}
