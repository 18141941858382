import { Injectable } from '@angular/core';
import { CompiereDataGridRequestJSON } from '@compiere-ws/models/compiere-data-json';
import { UniversalFilterWS } from '@iupics-components/models/universal-filter';
import { AppConfig } from '@iupics-config/app.config';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ApiService } from '../api/api.service';

@Injectable()
export class UniversalFilterService {
  private url: string;

  constructor(private http: ApiService, private config: AppConfig) {
    this.url = this.config.getBackendResource('universalFilter');
  }

  getFilters(tabID?: number): Observable<CompiereDataGridRequestJSON[]> {
    return this.http.get<UniversalFilterWS[]>(`${this.url}?tab_id=${tabID}`).pipe(
      switchMap(uFilterWS => {
        if (uFilterWS !== undefined && uFilterWS !== null && Object.keys(uFilterWS).length > 0) {
          const labels = Object.keys(uFilterWS);
          const filters: CompiereDataGridRequestJSON[] = [];
          labels.forEach(label => {
            const uFilter = uFilterWS[label];
            uFilter['label'] = label;
            filters.push(uFilter);
          });
          return of(filters);
        }
        return of([]);
      })
    );
  }

  saveFilter(uFilter: CompiereDataGridRequestJSON): Observable<any> {
    const filterWS = new UniversalFilterWS(uFilter);
    return this.http.post<any>(`${this.url}?tab_id=${uFilter.entityId}&name=${uFilter.label}`, filterWS);
  }

  deleteFilter(uFilter: CompiereDataGridRequestJSON): Observable<CompiereDataGridRequestJSON> {
    return this.http.delete<any>(`${this.url}?tab_id=${uFilter.entityId}&name=${uFilter.label}`);
  }
}
