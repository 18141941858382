<div class="language-selector">
  <div class="dropdown-selector">
    <div class="language">
      <div class="language-circle" (click)="displayDropdown($event)">
        <span class="language-circle-content">{{ defaultLanguage.iso_code | uppercase | slice : 3}}</span>
      </div>
    </div>
    <div #dropDownContent class="language-selector-content" [ngStyle]="{'display' : (isDisplayContent ? 'block' : 'none')}">
      <div *ngFor="let language of languages" class="language-circle" (click)="changeLanguage($event,language)">
        <span class="language-circle-content">{{ language.iso_code | uppercase | slice : 3}}</span>
      </div>
    </div>
  </div>