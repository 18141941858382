import {
  CompiereDataGridFilterType,
  CompiereDataGridSortModelType,
  CompiereDataGridType,
  DataStoreRequest
} from '@compiere-ws/models/compiere-data-json';
import { IAutocomplete } from '@iupics-components/models/autocomplete-interfaces';
import { GanttData, Link, LinkType, Task } from '@iupics-components/models/gantt.model';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import * as moment from 'moment';

export const CALENDAR_ID = 'planning-window-calendar-01';

export function getOFRequest(ad_language: string, pageNumber = 1, paginationStep = 50): DataStoreRequest {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'Z_Production',
      startRow: (pageNumber - 1) * paginationStep,
      endRow: pageNumber * paginationStep,
      ad_language,
      filterModel: {
        DocStatus: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: ['IP']
        }
      },
      sortModel: [{ colId: 'StartDate', sort: CompiereDataGridSortModelType.ASC }]
    }
  };
}

export function getOperationRequest(ad_language: string, ids: number[]): DataStoreRequest {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'Z_Production_Operation',
      startRow: 0,
      endRow: -1,
      ad_language,
      filterModel: {
        Z_Production_ID: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [ids]
        }
      },
      sortModel: [{ colId: 'SeqNo', sort: CompiereDataGridSortModelType.ASC }]
    }
  };
}

export function convertToGanttData(
  productions: { data: any[]; id_key: string },
  operations: { data: any[]; id_key: string }
): GanttData {
  const data: ProductionGanttTask[] = [];
  const links: Link[] = [];
  for (let i = 0; i < productions.data.length; i++) {
    const p = productions.data[i];
    if (p.StartDate === null || p.EndDate === null) {
      continue;
    }
    const p_start_date = formatDate(p.StartDate);
    const p_end_date = formatDate(p.EndDate);
    data.push({
      id: p[productions.id_key],
      reference: p.DocumentNo,
      description: p.Description,
      product: p.M_Product_ID,
      qty_to_produce: p.QtyToProduce,
      start_date: p_start_date,
      end_date: p_end_date,
      duration: p.ProductionTime === 0 ? moment(p_end_date).diff(moment(p_start_date), 'days') : p.ProductionTime,
      static_duration: p.ProductionTime === 0 ? moment(p_end_date).diff(moment(p_start_date), 'days') : p.ProductionTime,
      type: 'OF',
      $has_child: true,
      is_sub_contracting: p.ZIsSubContracting === 'Y',
      calendar_id: CALENDAR_ID
    });

    const _operations = operations.data.filter((op) => op[productions.id_key].id === p[productions.id_key]);
    for (let j = 0; j < _operations.length; j++) {
      const o = _operations[j];
      const o_start_date = o.StartDate ? formatDate(o.StartDate) : formatDate(p.StartDate);
      const o_end_date = o.EndDate ? formatDate(o.EndDate) : formatDate(p.EndDate);
      data.push({
        id: parseFloat(p[productions.id_key] + '.' + o[operations.id_key]),
        reference: o.Value,
        description: o.Name || o.M_ProductOperation_ID?.displayValue,
        product: { id: -1, displayValue: '' },
        qty_to_produce: o.QtyToProduce,
        start_date: o_start_date,
        end_date: o_end_date,
        duration: o.ProductionTime === 0 ? moment(o_end_date).diff(moment(o_start_date), 'days') : o.ProductionTime,
        type: 'OP',
        parent: p[productions.id_key],
        $open: false,
        static_duration: o.ProductionTime === 0 ? moment(o_end_date).diff(moment(o_start_date), 'days') : o.ProductionTime,
        is_sub_contracting: o.IsSubContracting === 'Y',
        calendar_id: CALENDAR_ID
      });
      if (j > 0) {
        links.push({
          id: data[data.length - 1].id,
          source: data[data.length - 2].id + '',
          target: data[data.length - 1].id + '',
          type: LinkType.END_TO_START
        });
      }
    }
  }
  return { data, links };
}

export const compiereMomentFormat = 'YYYY-MM-DD HH:mm:ss';
export const ganttMomentFormat = 'YYYY-MM-DD HH:mm:ss';
// export const ganttDateFormat = '%Y-%m-%d %H:%i:%s';

export function formatDate(date: string, fromFormat = compiereMomentFormat): Date {
  return moment(date, fromFormat).toDate();
}

export class ProductionGanttTask extends Task {
  reference: string; // DocumentNo(OF)/Value(OP)
  description: string; // Description(OF)/Name(OP)
  product: IAutocomplete; // M_Product_ID(OF)/''(OP)
  qty_to_produce: number; // QtyToProduce(OF/OP)
  start_date: string | Date; // StartDate (OF/OP)
  end_date: string | Date; // EndDate (OF/OP)
  duration: number; // ProductionTime (OF/OP)
  is_sub_contracting: boolean; // ZIsSubContracting(OF)/IsSubContracting(OP)
  type: 'OF' | 'OP';
}
