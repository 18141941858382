<!-- #region widgets -->
<div class="apiz-p-grid" style="position: relative">
  <div
    [ngClass]="[
      isMobileWidth() ? 'menu-mobile' : '',
      isMobileWidth() && showMenuWidget ? 'menu-mobile-show' : '',
      'p-col-11 p-md-11 p-lg-11 menu-closed'
    ]"
  >
    <!-- faire switcher menu-closed en menu-opened via le clic sur le button ci-dessous -->
    <iu-dashboard-group-ui
      data-cy="widgets-groups"
      (changeMenuItemList)="changeMenuItemList($event)"
      [groups]="groups"
      (addGroupItem)="addGroupItem($event)"
      (renameGroupItem)="renameGroupItem($event)"
      (removeGroupItem)="removeGroupItem($event)"
      (dragEndEmitter)="switchMenuGroup($event)"
      keybinding="dashboard.widget-group"
    ></iu-dashboard-group-ui>
  </div>
  <h2 class="p-col-10 mobileOnly">
    <div class="dashboard-section-header">
      <button
        data-cy="mobile_menu_widget_toggle"
        class="mobile_menu_bar_toggle"
        iuToggleButton
        [iuToggleButtonSwitch]="true"
        [(iuToggleButtonVar)]="showMenuWidget"
      ></button>
      <div class="menu_name">{{ groups | dashboardGroupName: currentGroupID }}</div>
    </div>
  </h2>
  <div class="p-col-1 p-md-1 p-lg-1 ui-sd-2 shortManage">
    <ul
      id="widgetManager-container"
      class="shortcutsManager"
      addActive
      [addActive-idToCheck]="'widgetManager-add'"
      [addActive-otherToClose]="['shortcutsManager-container']"
      iuKeybinding
      [iuKeybinding-active]="true"
      iuKeybinding-code="dashboard.widget-menu"
    >
      <li>
        <a
          id="widgetManager-add"
          href="#"
          tooltipPosition="left"
          showDelay="250"
          life="3"
          pTooltip="{{ 'dashboard.addWidget' | translate }}"
          class="add"
          (click)="openWidgetMenu($event)"
          ><span></span
        ></a>
        <ul>
          <ng-container *ngIf="widgetMenu$ | async as widgetMenu">
            <li class="title">Widgets disponibles</li>
            <li class="searchSC">
              <input
                #searchInput
                class="searchInput p-inputtext"
                (keydown)="handleInputKeydown($event)"
                (keyup)="handleInputKeyup($event, widgetMenu)"
                [(ngModel)]="searchWidgetValue"
                type="text"
                autofocusDirective
              />
              <i class="iconeSearch icon-ico-search-bolder" aria-hidden="true" #ddBtn type="button"></i>
            </li>
            <span id="iu_widget_container" class="listNiv-1">
              <ng-container *ngFor="let widget of widgetMenu | objectArrayFilter: [searchWidgetValue, 'name']">
                <li>
                  <a
                    id="iu_widget_menu_{{ widget.menu_id }}"
                    href="#"
                    [ngClass]="[widget.menu_id === arrowFocusedWidget?.menu_id ? 'item-arrow-focused' : '', 'ico-add']"
                    (click)="$event.preventDefault(); addWidget(widget)"
                    >{{ widget.name }}</a
                  >
                </li>
              </ng-container>
            </span>
          </ng-container>
        </ul>
      </li>
    </ul>
  </div>
</div>

<div class="apiz-p-grid boardList" data-cy="widgets">
  <ng-container *ngIf="currentItemList$ | async as currentItemList; else widgetsLoading">
    <ng-container *ngFor="let widget of currentItemList; let i = index">
      <div
        #dragElem
        class="drag-item {{ widgetCssClass }}"
        (dragstart)="handleDragStart($event, dragElem, widget)"
        (mouseover)="isDragMode && dragElem !== activeDiv ? handleMouseOver($event, dragElem) : null"
      >
        <widget-ui
          [index]="i"
          [widget]="widget.widget"
          (cssClassEdit)="widgetCssClass = $event !== undefined ? $event : widgetCssClass"
          (changeWidgetColor)="changeWidgetColor($event, widget.menuItem)"
          (deleteWidget)="onDelete($event, widget.menuItem)"
          (openTabEmitter)="openTab($event)"
        ></widget-ui>
      </div>
    </ng-container>
  </ng-container>
</div>
<!-- #endregion -->

<ng-template #widgetsLoading> <!-- On peut mettre le loader ici ou des ghost elements --> </ng-template>
