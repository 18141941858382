import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { RecentItem } from '@compiere-ws/models/compiere-recent-item';
import { RecentItemsManagerService } from '@iupics-manager/managers/recent-items-manager/recent-items-manager.service';
import { IupicsWidget } from '@web-desktop/models/iupics-widget';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'iu-recent-item-widget-ui',
  templateUrl: './recent-item-widget-ui.component.html',
  styleUrls: ['./recent-item-widget-ui.component.scss']
})
export class RecentItemWidgetUiComponent implements OnInit, AfterViewInit, OnDestroy {
  recentItems$: Observable<RecentItem[]>;
  refresherSub: Subscription;

  @Input() widget: IupicsWidget;

  @Output() openTabEmitter = new EventEmitter<any>();

  fakeRI: RecentItem[] = [
    {
      mode: '',
      level: 0,
      name: 'Loading...',
      description: 'Loading...',
      pathDir: 'string',
      parentId: 0,
      nodeId: 0,
      componentId: 0,
      entityID: 0,
      entityKey: 'string',
      nodeType: 0,
      summary: false,
      hasChildren: false,
      isFavorite: false,
      cssColor: 'string',
      icon: 'string',
      seqNo: 0,
      isEditable: false,
      query: 'string',
      fieldValuePairs: 'any',
      isAdhoc: false,
      isExternal: false,
      isDashboard: false
    }
  ];

  constructor(private riManager: RecentItemsManagerService) {}

  ngOnInit() {
    this.recentItems$ = this.riManager.getRecentItems();
  }

  ngAfterViewInit() {
    this.refresherSub = this.widget.refresher.subscribe(() => (this.recentItems$ = this.riManager.getRecentItems()));
  }

  openTab(event: Event, item: RecentItem) {
    event.stopPropagation();
    this.openTabEmitter.emit({
      action_id: item.entityID,
      menu_type: 'Window',
      name: item.name.split(':')[0],
      iconClass: 'fa fa-windows',
      openedRecordId: item.entityKey
    });
  }

  ngOnDestroy() {
    this.refresherSub.unsubscribe();
  }
}
