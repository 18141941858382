import { Injectable } from '@angular/core';
import { AppConfig } from '@iupics-config/app.config';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class GridPreferencesService {
  private gridPreferences = new Map<String, GridPreference>();
  private urlUserGridPreference: string;
  constructor(
    private http: ApiService,
    private config: AppConfig,
    private messageManager: MessageManagerService,
    private translator: TranslateService
  ) {}

  public saveGridPreference(gridPreference: GridPreference): Observable<GridPreference> {
    this.urlUserGridPreference = this.config.getBackendResource('userGridPreference');
    const key = this.generateKeyWithGridPreference(gridPreference);
    if (gridPreference && gridPreference.userGridPreferenceID > 0) {
      return this.http.put<GridPreference>(`${this.urlUserGridPreference}`, gridPreference).pipe(
        map(
          (response) => {
            if (response && response.userGridPreferenceID > 0) {
              this.gridPreferences.set(key, response);
              return this.gridPreferences.get(key);
            } else {
              this.messageManager.newMessage(
                new IupicsMessage(
                  this.translator.instant('generic.error'),
                  this.translator.instant('gridpanel.preferences.save_error'),
                  'error'
                )
              );
              return gridPreference;
            }
          },
          catchError(() => {
            this.messageManager.newMessage(
              new IupicsMessage(
                this.translator.instant('generic.error'),
                this.translator.instant('gridpanel.preferences.save_error'),
                'error'
              )
            );
            return of(gridPreference);
          })
        )
      );
    } else {
      return this.http.post<GridPreference>(`${this.urlUserGridPreference}`, gridPreference).pipe(
        map(
          (response) => {
            if (response && response.userGridPreferenceID > 0) {
              this.gridPreferences.set(key, response);
              return this.gridPreferences.get(key);
            } else {
              this.messageManager.newMessage(
                new IupicsMessage(
                  this.translator.instant('generic.error'),
                  this.translator.instant('gridpanel.preferences.save_error'),
                  'error'
                )
              );
              return gridPreference;
            }
          },
          catchError(() => {
            this.messageManager.newMessage(
              new IupicsMessage(
                this.translator.instant('generic.error'),
                this.translator.instant('gridpanel.preferences.save_error'),
                'error'
              )
            );
            return of(gridPreference);
          })
        )
      );
    }
  }

  public deleteGridPreference(gridPreference: GridPreference): Observable<boolean> {
    this.urlUserGridPreference = this.config.getBackendResource('userGridPreference');
    const key = this.generateKeyWithGridPreference(gridPreference);
    return this.http.delete<boolean>(`${this.urlUserGridPreference}/${gridPreference.userGridPreferenceID}`).pipe(
      map(
        (removed) => {
          if (removed) {
            this.gridPreferences.delete(key);
          } else {
            this.messageManager.newMessage(
              new IupicsMessage(
                this.translator.instant('generic.error'),
                this.translator.instant('gridpanel.preferences.delete_error'),
                'error'
              )
            );
          }
          return removed;
        },
        catchError(() => {
          this.messageManager.newMessage(
            new IupicsMessage(
              this.translator.instant('generic.error'),
              this.translator.instant('gridpanel.preferences.delete_error'),
              'error'
            )
          );
          return of(false);
        })
      )
    );
  }

  public getGridPreference(preference: GridPreference) {
    this.urlUserGridPreference = this.config.getBackendResource('userGridPreference');
    const key = this.generateKeyWithGridPreference(preference);
    if (this.gridPreferences.has(key)) {
      return of(this.gridPreferences.get(key));
    } else {
      return this.http
        .get<GridPreference>(
          `${this.urlUserGridPreference}?AD_Table_ID=${preference.tableID}&AD_FormDetail_ID=${preference.formDetailID}&AD_Tab_ID=${preference.tabID}&type=${preference.type}`
        )
        .pipe(
          map(
            (gridPreference) => {
              if (gridPreference && gridPreference.userGridPreferenceID > 0) {
                this.gridPreferences.set(key, gridPreference);
                return this.gridPreferences.get(key);
              } else {
                this.gridPreferences.set(key, preference);
                return preference;
              }
            },
            catchError(() => {
              this.messageManager.newMessage(
                new IupicsMessage(
                  this.translator.instant('generic.error'),
                  this.translator.instant('gridpanel.preferences.get_error')
                )
              );
              return of(preference);
            })
          )
        );
    }
  }
  generateKeyWithGridPreference(gridPreference: GridPreference) {
    let key = gridPreference.tableID > 0 ? gridPreference.tableID + '' : 'X';
    key += gridPreference.formDetailID > 0 ? gridPreference.formDetailID + '' : 'X';
    key += gridPreference.tabID > 0 ? gridPreference.tabID + '' : 'X';
    key += gridPreference.type;
    return key;
  }
}
export interface GridPreference {
  userGridPreferenceID: number;
  tableID: number;
  tabID: number;
  formDetailID: number;
  type: GridPreferenceType;
  json: string;
}
export enum GridPreferenceType {
  REDUCED = 'R',
  EXPANDED = 'E'
}
export enum GridPreferencesAction {
  SAVE = 'save',
  DELETE = 'delete',
  RESET = 'reset'
}
