<div class="kanban-card-container p-grid">
  <div *ngIf="datasKanban.image !== null" class="img-container p-col-4 p-md-4 p-lg-4">
    <ng-container *ngIf="datasKanban.image | docServerUrl | async as src; else errorImage">
      <img class="iu-image" [src]="src" (error)="datasKanban.image = ''" />
    </ng-container>
    <ng-template #errorImage>
      <img
        class="iu-image"
        [src]="tableName === 'C_BPartner' ? 'assets/noImage-c_bpartner.png' : 'assets/imageError.webp'"
        (error)="datasKanban.image = ''"
      />
    </ng-template>
  </div>
  <div [ngClass]="[datasKanban.image === null ? 'p-col-12' : 'p-col-8 p-md-8 p-lg-8', 'other-container']">
    <ng-container *ngFor="let dataKanban of datasKanban.rows">
      <div *ngIf="dataKanban.dataAlignLeft.length > 0 || dataKanban.dataAlignright.length > 0" class="board-card-line p-grid">
        <h4
          *ngIf="dataKanban.dataAlignLeft.length > 0"
          [ngClass]="[dataKanban.dataAlignright.length > 0 ? 'p-col-6' : '', 'board-card-line-left']"
        >
          <ng-container *ngFor="let dataAlignLeft of dataKanban.dataAlignLeft; let first = first">
            <div #element *ngIf="dataAlignLeft.type === 'text'" [ngClass]="[first ? 'first' : '', 'board-card-line-data']">
              <span
                *ngIf="isMobile"
                tooltipPosition="top"
                showDelay="250"
                life="3"
                tooltipZIndex="8000"
                pTooltip="{{ dataAlignLeft.value }}"
                >{{ dataAlignLeft.value | textLimit: 80 }}</span
              >
              <span *ngIf="!isMobile">{{ dataAlignLeft.value }}</span>
            </div>
            <ng-container *ngIf="dataAlignLeft.value | docServerUrl | async as srcLeft; else errorImageLeft">
              <img *ngIf="dataAlignLeft.type === 'image'" class="iu-image" [src]="srcLeft" (error)="dataAlignLeft.value = ''" />
            </ng-container>
            <ng-template #errorImageLeft>
              <img class="iu-image" [src]="'assets/imageError.webp'" (error)="dataAlignLeft.value = ''" />
            </ng-template>
          </ng-container>
        </h4>
        <div
          *ngIf="dataKanban.dataAlignright.length > 0"
          [ngClass]="[dataKanban.dataAlignLeft.length > 0 ? 'p-col-6' : '', 'board-card-line-right']"
        >
          <ng-container *ngFor="let dataAlignright of dataKanban.dataAlignright; let first = first">
            <div #element *ngIf="dataAlignright.type === 'text'" [ngClass]="[first ? 'first' : '', 'board-card-line-data']">
              <span
                *ngIf="isMobile"
                tooltipZIndex="8000"
                tooltipPosition="top"
                showDelay="250"
                life="3"
                pTooltip="{{ dataAlignright.value }}"
                >{{ dataAlignright.value | textLimit: 80 }}</span
              >
              <span *ngIf="!isMobile">{{ dataAlignright.value }}</span>
            </div>
            <ng-container *ngIf="dataAlignright.value | docServerUrl | async as srcRight; else errorImageRight">
              <img
                *ngIf="dataAlignright.type === 'image'"
                class="iu-image"
                [src]="srcRight"
                (error)="dataAlignright.value = ''"
              />
            </ng-container>
            <ng-template #errorImageRight>
              <img
                *ngIf="dataAlignright.type === 'image'"
                class="iu-image"
                [src]="'assets/imageError.webp'"
                (error)="dataAlignright.value = ''"
              />
            </ng-template>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>
