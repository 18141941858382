import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from '@iupics-util/components/page-not-found/page-not-found.component';
import { AccesPortalGuard } from '@iupics-util/guards/acces-portal.guard';
import { AccesRessourceGuard } from '@iupics-util/guards/acces-ressource.guard';
import { HomePageComponent } from '@login-page/components/home-page/home-page.component';
import { DesktopComponent } from '@web-desktop/components/workspace/components/desktop-ui/desktop-ui.component';
const appRoutes: Routes = [
  { path: 'dashboard', component: DesktopComponent, canActivate: [AccesPortalGuard] },
  {
    path: 'workspace',
    canActivate: [AccesPortalGuard],
    children: [
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
      {
        path: 'window',
        canActivateChild: [AccesRessourceGuard],
        children: [
          { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
          { path: ':windowId', component: DesktopComponent },
          { path: ':windowId/:recordId', component: DesktopComponent }
        ]
      },
      {
        path: 'splitviews',
        canActivateChild: [AccesRessourceGuard],
        children: [
          { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
          { path: ':viewdata', component: DesktopComponent }
        ]
      },
      {
        path: 'process',
        canActivateChild: [AccesRessourceGuard],
        children: [
          { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
          { path: ':processId', component: DesktopComponent }
        ]
      },
      {
        path: 'form',
        canActivateChild: [AccesRessourceGuard],
        children: [
          { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
          { path: ':formId', component: DesktopComponent }
        ]
      },
      {
        path: 'report',
        canActivateChild: [AccesRessourceGuard],
        children: [
          { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
          { path: ':reportId', component: DesktopComponent }
        ]
      }
    ]
  },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'login', component: HomePageComponent },
  { path: 'accesUnauthorized', component: PageNotFoundComponent },
  { path: '**', component: PageNotFoundComponent }
];
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
