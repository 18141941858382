<!-- hardcoded -->
<h4 style="text-align: center;padding: 0;margin:0;">Invoice (Customer)</h4>
<!-- hardcoded -->
<iu-grid-tab-infinity-scroll-ui
  *ngIf="data"
  class="gridWidgetContent"
  [tabId]="tabId"
  [isTabTopLevel]="isTabTopLevel"
  [data]="data"
  [gridTabFilter]="gridTabFilter"
  [container]="container"
  [DOMParentComponent]="DOMParentComponent"
  [gridPaginator]="gridPaginator"
></iu-grid-tab-infinity-scroll-ui>
