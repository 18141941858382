import { EventEmitter, Injectable } from '@angular/core';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';

@Injectable()
export class WebDesktopService {
  emitter: EventEmitter<any> = new EventEmitter();

  constructor() {}

  triggerEvents(item: any, ...types: IupicsTypeEvent[]): any {
    for (const type of types) {
      this.emitter.emit({ type: type, item: item });
    }
  }
}
