import { EventEmitter } from '@angular/core';
import { ChartType, ViewType } from '@iupics-components/models/view-type.enum';

export class IupicsWidget {
  constructor(
    title: string,
    description: string,
    viewType: ViewType,
    chartType: ChartType,
    data: any,
    color: string,
    width: number,
    angularClass?: string
  ) {
    this._title = title;
    this._description = description;
    this._viewType = viewType;
    this._chartType = chartType;
    this._data = data;
    this._color = color;
    this._width = width;
    this._angularClass = angularClass;
    this._refresher = new EventEmitter();
  }
  private _title: string;
  private _description: string;
  private _viewType: ViewType;
  private _chartType: ChartType;
  private _data: any;
  private _color: string;
  private _width: number;
  private _height: number;
  private _angularClass: string;
  private _refresher: EventEmitter<any>;

  /**
   * Getter title
   * @returns {string}
   */
  public get title(): string {
    return this._title;
  }

  /**
   * Setter title
   * @param {string} value
   */
  public set title(value: string) {
    this._title = value;
  }

  /**
   * Getter description
   * @returns {string}
   */
  public get description(): string {
    return this._description;
  }

  /**
   * Setter description
   * @param {string} value
   */
  public set description(value: string) {
    this._description = value;
  }

  /**
   * Getter viewType
   * @returns {ViewType}
   */
  public get viewType(): ViewType {
    return this._viewType;
  }

  /**
   * Setter viewType
   * @param {ViewType} value
   */
  public set viewType(value: ViewType) {
    this._viewType = value;
  }

  /**
   * Getter chartType
   * @returns {ChartType}
   */
  public get chartType(): ChartType {
    return this._chartType;
  }

  /**
   * Setter chartType
   * @param {ChartType} value
   */
  public set chartType(value: ChartType) {
    this._chartType = value;
  }

  /**
   * Getter data
   * @returns {any}
   */
  public get data(): any {
    return this._data;
  }

  /**
   * Setter data
   * @param {any} value
   */
  public set data(value: any) {
    this._data = value;
  }
  /**
   * Getter color
   * @returns {string}
   */
  public get color(): string {
    return this._color;
  }

  /**
   * Setter color
   * @param {string} value
   */
  public set color(value: string) {
    this._color = value;
  }

  /**
   * Getter width
   * @returns {number}
   */
  public get width(): number {
    return this._width;
  }

  /**
   * Setter width
   * @param {number} value
   */
  public set width(value: number) {
    if (value <= 3) {
      this._width = value;
    }
  }

  /**
   * Getter height
   * @returns {number}
   */
  public get height(): number {
    return this._height;
  }

  /**
   * Setter height
   * @param {number} value
   */
  public set height(value: number) {
    this._height = value;
  }

  /**
   * Getter angularClass
   * @returns {string}
   */
  public get angularClass(): string {
    return this._angularClass;
  }

  /**
   * Setter angularClass
   * @param {string} value
   */
  public set angularClass(value: string) {
    this._angularClass = value;
  }

  /**
   * Getter refresher
   * @returns {EventEmitter<any>}
   */
  public get refresher(): EventEmitter<any> {
    return this._refresher;
  }
}
