<div class="account-selector">
  <div class="dropdown-selector">
    <iu-account-label
      [account]="defaultAccount"
      displayCarret="true"
      class="default-account"
      (clickCarret)="displayDropdown($event)"
    ></iu-account-label>
    <div #dropDownContent class="account-selector-content" [ngStyle]="{ display: isDisplayContent ? 'block' : 'none' }">
      <div *ngFor="let account of accounts" class="account" (click)="changeAccount($event, account)">
        <iu-account-label [account]="account"></iu-account-label>
      </div>
      <div class="another-account" (click)="anotherAccount()">
        <iu-account-label [account]="anotherAccountObj"></iu-account-label>
      </div>
    </div>
  </div>
</div>
