import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { IupicsCookieService, LocalStorageIupics } from '@iupics-manager/managers/security-manager/cookies/iupics-cookie.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccesPortalGuard implements CanActivate {
  constructor(private cookieService: IupicsCookieService, private router: Router) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (
      this.cookieService.checkLS(LocalStorageIupics.access_token) &&
      this.cookieService.checkLS(LocalStorageIupics.crypto_key)
    ) {
      return true;
    } else {
      this.router.navigate(['login'], { queryParams: { redirect: state.url } });
      return false;
    }
  }
}
