<!-- #region body -->
<p-progressBar
  *ngIf="isLoading"
  [mode]="'indeterminate'"
  [value]="0"
  [showValue]="false"
  [styleClass]="'loading-progressbar'"
></p-progressBar>
<div class="iu-export-data-body">
  <ng-container *ngIf="exportDataOptions$ | async as edo; else loading">
    <!-- #region footer -->
    <div class="iu-export-data-footer">
      <div class="p-col-12 p-md-6">
        <iu-autocomplete-ui
          #printFormatsAutocomplete
          [cssClass]="''"
          tooltip="{{ 'exportData.printFormats' | translate }}"
          label="{{ 'exportData.printFormats' | translate }}"
          limitSuggestions="3"
          (autoCompleteEmitter)="selectPrintFormat($event)"
          [data]="edo.printFormats"
        ></iu-autocomplete-ui>
      </div>
      <div class="p-col-12 p-md-6">
        <iu-autocomplete-ui
          #fileTypesAutocomplete
          [cssClass]="''"
          tooltip="{{ 'exportData.fileTypes' | translate }}"
          label="{{ 'exportData.fileTypes' | translate }}"
          limitSuggestions="3"
          (autoCompleteEmitter)="selectFileType($event)"
          [data]="edo.fileTypes"
        ></iu-autocomplete-ui>
      </div>
    </div>
    <div class="iu-export-data-footer-buttons">
      <a class="iu-btn btn-execute" (click)="exportData($event, edo.recordIds)">{{ 'exportData.exportData' | translate }}</a>
      <a class="iu-btn btn-cancel" (click)="cancelExportDataEmitter.emit()">{{ 'exportData.cancel' | translate }}</a>
    </div>
  </ng-container>
</div>
<!-- #endregion -->

<ng-template #loading>
  <div class="iu-export-data-footer">
    <div class="p-col-12 p-md-6">
      <iu-autocomplete-ui
        #printFormatsAutocomplete
        tooltip="{{ 'exportData.printFormats' | translate }}"
        label="{{ 'exportData.printFormats' | translate }}"
        [data]="{ items: [] }"
      ></iu-autocomplete-ui>
    </div>
    <div class="p-col-12 p-md-6">
      <iu-autocomplete-ui
        #fileTypesAutocomplete
        tooltip="{{ 'exportData.fileTypes' | translate }}"
        label="{{ 'exportData.fileTypes' | translate }}"
        [data]="{ items: [] }"
      ></iu-autocomplete-ui>
    </div>
  </div>
  <div class="iu-export-data-footer-buttons">
    <a class="iu-btn btn-execute">{{ 'exportData.exportData' | translate }}</a>
    <a class="iu-btn btn-cancel">{{ 'exportData.cancel' | translate }}</a>
  </div>
</ng-template>
