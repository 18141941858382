import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IupicsComponentsModule } from '@iupics-components/iupics-components.module';
import { AccountLabelComponent } from '@login-page/components/account-label/account-label.component';
import { AccountSelectorComponent } from '@login-page/components/account-selector/account-selector.component';
import { HomePageComponent } from '@login-page/components/home-page/home-page.component';
import { LanguageSelectorComponent } from '@login-page/components/language-selector/language-selector.component';
import { HomePageService } from '@login-page/controllers/home-page.service';
import { DesktopModule } from '@web-desktop/web-desktop.module';

@NgModule({
  imports: [CommonModule, IupicsComponentsModule, DesktopModule],
  exports: [HomePageComponent],
  declarations: [HomePageComponent, AccountLabelComponent, AccountSelectorComponent, LanguageSelectorComponent],
  providers: [HomePageService]
})
export class LoginPageModule {}
