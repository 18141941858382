import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { PoService } from '@compiere-ws/services/po/po.service';
import { EditTabUiComponent } from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { AppConfig } from '@iupics-config/app.config';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractDataContainer, AbstractDataContainerCallout } from '@iupics-manager/models/abstract-datacontainer';
import { NumberType } from '@iupics-manager/models/iupics-data';
import { OverridedCSS } from '@iupics-manager/models/overrided-css';
import { Utils } from '@iupics-util/tools/util';
import { TranslateService } from '@ngx-translate/core';
import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'iu-input-number-ui',
  templateUrl: './input-number-ui.component.html',
  styleUrls: ['./input-number-ui.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InputNumberUiComponent extends AbstractDataContainer implements OnInit, AfterViewInit {
  @ViewChild('inputChips', { static: false, read: ElementRef }) inputChipsRef: ElementRef;
  @ViewChild('input', { static: false, read: ElementRef }) inputRef: ElementRef;
  @ViewChild('currencySpan', { static: true }) currencySpanRef: ElementRef;
  @ViewChild('opConflict', { static: true }) opConflict: OverlayPanel;

  @Input()
  columnName: string;
  @Input() fieldStep: string;
  @Input() hasBtn = true;
  @Input() multiple = false;

  @Output() enterKey = new EventEmitter<KeyboardEvent>();
  @Output() inputBlur = new EventEmitter<FocusEvent>();
  regexNB: RegExp = /^[0-9]*(\,{1}|\.{1})?[0-9]*$/;
  btn1Disabled = false;
  btn2Disabled = false;

  dataContainers: AbstractDataContainerCallout;
  min: number;
  max: number;
  isCurrencyDisplay = false;
  lang;

  @Input() overridedCSS: OverridedCSS;
  @Input() placeHolder: string;
  @Input() currency: string;

  constructor(
    public elementRef: ElementRef,
    public store: DataStoreService,
    protected connectorService: SecurityManagerService,
    public cmService: ContextMenuService,
    public uiCreatorService: UICreatorService,
    private config: AppConfig,
    renderer: Renderer2,
    protected po: PoService,
    private translateService: TranslateService
  ) {
    super(elementRef, connectorService, cmService, store, uiCreatorService, renderer, po);
  }

  ngOnInit() {
    if (this.data && this.data.numberType) {
      this.fieldStep = this.data.numberType;
    }
    if (this.fieldStep === NumberType.MONTANT) {
      this.isCurrencyDisplay = true;
      this.isMoneyField = true;
    }
    super.ngOnInit();
    if (this.cssClass !== undefined) {
      this.cssGrid = this.cssClass;
    }
    this.cssClass = ' ' + this.cssGrid;
    this.setFieldMandatory();

    if (this.label?.endsWith('_From')) {
      this.label = this.translateService.instant('ranged-value.number.from') + this.label.replace('_From', '');
    } else if (this.label?.endsWith('_To')) {
      this.label = this.translateService.instant('ranged-value.number.to') + this.label.replace('_To', '');
    }

    this.min = this.data && this.data.min !== null && this.data.min !== undefined ? this.data.min : '';
    this.max = this.data && this.data.max !== null && this.data.max !== undefined ? this.data.max : '';
    this.lang = this.connectorService.getIupicsDefaultLanguage().iso_code.replace('_', '-');
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    let parentComp = this.DOMParentComponent;
    while (parentComp && !(parentComp instanceof EditTabUiComponent)) {
      parentComp = parentComp.DOMParentComponent;
    }
    if (parentComp instanceof EditTabUiComponent) {
      this.dataContainers = new AbstractDataContainerCallout(parentComp.dataContainers);
    }
    if (this.data && this.data.callouts && this.data.callouts.callouts) {
      if (this.inputRef) {
        this.data.callouts.callouts.forEach((callout) => {
          this.inputRef.nativeElement.addEventListener(callout.calloutevent, () => {
            const calloutFn = new Function('Field', 'Fields', 'DBSelect', 'Utils', callout.script);
            calloutFn(this, this.dataContainers, this.uiCreatorService, Utils);
          });
        });
      }
    }
  }

  readOnlyNumberComponent() {
    this.btn1Disabled = true;
    this.btn2Disabled = true;
  }
  _parseFloat(value: any): number {
    const floatValue = value && value.replace ? parseFloat(value.replace(',', '.')) : value;
    if (floatValue === null || floatValue === undefined || isNaN(parseFloat(floatValue))) {
      return null;
    } else {
      if (!this.isStandalone && this.data.numberType === NumberType.INTEGER) {
        return parseFloat(floatValue.toFixed());
      }
      return floatValue;
    }
  }
  showConflictPanel(ev) {
    ev.target.getBoundingClientRect = function () {
      return { top: this.offsetTop, left: this.offsetLeft };
    };
    this.opConflict.toggle(ev);
  }
  decrease() {
    if (this.inputRef) {
      this.inputRef.nativeElement.stepUp();
      this.dataChange(parseFloat(this.inputRef.nativeElement.value));
    }
  }
  increase() {
    if (this.inputRef) {
      this.inputRef.nativeElement.stepDown();
      this.dataChange(parseFloat(this.inputRef.nativeElement.value));
    }
  }

  focus() {
    if (this.inputRef) {
      this.inputRef.nativeElement.focus();
    }
  }
  dataChange(value) {
    super.dataChange(value);
  }
  OnWheel(e) {
    this.inputRef.nativeElement.blur();
  }
}
