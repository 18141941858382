<iu-prime-fieldset [toggleable]="false" [collapsed]="false" [legend]="'gridpanel.preferences.title' | translate">
  <div>
    <button
      pButton
      tooltipPosition="top"
      showDelay="250"
      life="3"
      pTooltip="{{ 'gridpanel.preferences.save' | translate }}"
      type="button"
      icon="icon-ico-save3x"
      (mouseup)="$event.preventDefault(); $event.stopPropagation(); save()"
      data-cy="button-savePref"
    ></button>
    <button
      pButton
      tooltipPosition="top"
      showDelay="250"
      life="3"
      pTooltip="{{ 'gridpanel.preferences.reset' | translate }}"
      type="button"
      icon="icon-ico-refresh3x"
      (mouseup)="$event.preventDefault(); $event.stopPropagation(); reset()"
    ></button>
    <button
      pButton
      tooltipPosition="top"
      showDelay="250"
      life="3"
      pTooltip="{{ 'gridpanel.preferences.delete' | translate }}"
      type="button"
      icon="icon-ico-delete"
      (mouseup)="$event.preventDefault(); $event.stopPropagation(); delete()"
    ></button>
  </div>
  <div
    class="ag-column-select-list"
    ref="primaryColsListPanel"
    cdkDropList
    [cdkDropListData]="columnState"
    (cdkDropListDropped)="drop($event)"
  >
    <div
      *ngFor="let item of columnState"
      cdkDrag
      class="ag-column-select-column ag-column-select-indent-0"
      [attr.data-cy-prefName]="item.label"
    >
      <!--AG-CHECKBOX-->
      <div
        role="presentation"
        ref="cbSelect"
        class="ag-column-select-checkbox ag-labeled ag-label-align-right ag-checkbox ag-input-field"
      >
        <label ref="eLabel" class="ag-input-field-label ag-label ag-hidden ag-checkbox-label" for="ag-input-id-83"></label>
        <div
          ref="eWrapper"
          class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper"
          ngClass="{{ item.hide ? '' : 'ag-checked' }}"
          role="presentation"
        >
          <input
            ref="eInput"
            class="ag-input-field-input ag-checkbox-input"
            type="checkbox"
            id="ag-input-id-83"
            (click)="hide(item)"
          />
        </div>
      </div>
      <span class="ag-icon ag-icon-grip ag-drag-handle ag-column-select-column-drag-handle" unselectable="on"></span>
      <span class="ag-column-select-column-label" ref="eLabel">{{ item.label }}</span>
    </div>
  </div>
</iu-prime-fieldset>
