import { Injectable } from '@angular/core';
import { AppConfig } from '@iupics-config/app.config';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class ValuePreferencesService {
  private urlValuePreference: string;
  constructor(
    private http: ApiService,
    private config: AppConfig,
    private messageManager: MessageManagerService,
    private translator: TranslateService
  ) {}

  public saveGridPreference(valuePreference: ValuePreference, showMessage = true): Observable<boolean> {
    this.urlValuePreference = this.config.getBackendResource('valuePreference');
    const valuePreferenceRequest: ValuePreferenceRequest = {
      valuePreference: valuePreference,
      actionType: ValuePreferenceActionType.SAVE
    };
    return this.http.post<ValuePreferenceResponse>(`${this.urlValuePreference}`, valuePreferenceRequest).pipe(
      map(
        (response) => {
          if (showMessage) {
            if (response && response.success) {
              this.messageManager.newMessage(
                new IupicsMessage(this.translator.instant('generic.success'), response.message, 'success')
              );
            } else {
              this.messageManager.newMessage(
                new IupicsMessage(this.translator.instant('generic.error'), response.message, 'error')
              );
            }
          }
          return response ? response.success : false;
        },
        catchError(() => {
          if (showMessage) {
            this.messageManager.newMessage(
              new IupicsMessage(
                this.translator.instant('generic.error'),
                this.translator.instant('valuePreference.save_error'),
                'error'
              )
            );
          }
          return of(false);
        })
      )
    );
  }

  public deleteValuePreference(valuePreference: ValuePreference): Observable<boolean> {
    this.urlValuePreference = this.config.getBackendResource('valuePreference');
    const valuePreferenceRequest: ValuePreferenceRequest = {
      valuePreference: valuePreference,
      actionType: ValuePreferenceActionType.DELETE
    };
    return this.http.post<ValuePreferenceResponse>(`${this.urlValuePreference}`, valuePreferenceRequest).pipe(
      map(
        (response) => {
          if (response && response.success) {
            this.messageManager.newMessage(
              new IupicsMessage(this.translator.instant('generic.success'), response.message, 'success')
            );
          } else {
            this.messageManager.newMessage(
              new IupicsMessage(this.translator.instant('generic.error'), response.message, 'error')
            );
          }
          return response ? response.success : false;
        },
        catchError(() => {
          this.messageManager.newMessage(
            new IupicsMessage(
              this.translator.instant('generic.error'),
              this.translator.instant('valuePreference.delete_error'),
              'error'
            )
          );
          return of(false);
        })
      )
    );
  }
}
export interface ValuePreference {
  ad_Client_ID: number;
  ad_Org_ID: number;
  ad_User_ID: number;
  ad_Window_ID: number;
  attribute: string;
  value: string;
}
export interface ValuePreferenceRequest {
  valuePreference: ValuePreference;
  actionType: ValuePreferenceActionType;
}
export interface ValuePreferenceResponse {
  success: boolean;
  message: string;
}
export enum ValuePreferenceActionType {
  SAVE = 'SAVE',
  DELETE = 'DELETE'
}
