import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';

export interface IupicsContextMenuComponent {
  componentType: IupicsComponentType;
  cmService: ContextMenuService;

  handleEvent(event): void;
  onContextMenu(event): void;
}

export enum IupicsComponentType {
  SHORTCUT,
  WIDGET,
  MENU_UI,
  PIN_DIALOG_UI,
  BREADCRUMB,
  WORKSPACE,
  DESKTOP,
  FIELDCALLOUT,
  FIELDZOOM,
  ADDRESSFIELD,
  FIELD,
  STEPPER,
  FIELDFORM
}
