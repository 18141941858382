// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  basehref: '/',
  domain: 'https://lpf.dev.apizsolutions.com/',
  authServerUrl: 'auth-server/session/token',
  authServerUrlValid: 'auth-server/session',
  configFilePath: '/iupics-config/default/develop',
  clientId: 'ws_api_001',
  clientSecret: 'ws_api_secret',
  production: true,
  version: 'Iupics LPF/BLM {version}',
  CUST_version: 'in development mode',
  STD_version: '2.10.0',
  webSiteUrl: 'https://www.apizsolutions.com/',
  default_language: 'fr_FR',
  available_languages: 'ar_TN,ca_ES,en_GB,en_US,en,es_ES,fr_FR,fr,nl_NL',
};
