import { ComponentRef, Injectable } from '@angular/core';
import { TabGroupUiComponent } from '@web-desktop/components/menu-top/components/tab-group-ui/tab-group-ui.component';
import { TabUiComponent } from '@web-desktop/components/menu-top/components/tab-ui/tab-ui.component';
import { Tab } from '@web-desktop/models/Tab';
import { has, isNil } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ActiveTabManagerService {
  private activeTab: Tab;
  private listeners: {
    [key: string]: { listener: (event: ActiveTabEvent) => void; listenerOptions: ActiveTabListenerOptions };
  } = {};

  private readonly defaultOptions: ActiveTabListenerOptions = { inSplitView: true };

  constructor() {}

  setActiveTab(tab?: Tab): void {
    if (isNil(this.activeTab) || isNil(tab) || tab.id !== this.activeTab.id) {
      // on prévient qu'on n'est plus actif si un tab est défini
      this.emit(false);
      this.activeTab = tab;
      this.emit(true);
    }
  }

  listen(tab: Tab, listener: (event: ActiveTabEvent) => void, listenerOptions = this.defaultOptions): () => void {
    this.listeners[tab.id] = { listener, listenerOptions };
    return () => {
      delete this.listeners[tab.id];
    };
  }

  isActive(tab: Tab): boolean {
    return this.activeTab && this.activeTab.id === tab.id;
  }

  private emit(active: boolean) {
    if (this.activeTab && has(this.listeners, this.activeTab.id)) {
      this.listeners[this.activeTab.id].listener({
        type: active ? ActiveTabEventType.TAB_ACTIVE : ActiveTabEventType.TAB_INACTIVE,
        listenerOptions: this.listeners[this.activeTab.id].listenerOptions
      });
    }

    // on prévient ceux dans le tabGroup si le listener est configuré comme tel
    if (this.activeTab instanceof TabGroupUiComponent) {
      this.activeTab.components.forEach((tabRef: ComponentRef<TabUiComponent>) => {
        if (has(this.listeners, tabRef.instance.id) && this.listeners[tabRef.instance.id].listenerOptions.inSplitView) {
          this.listeners[tabRef.instance.id].listener({
            type: active ? ActiveTabEventType.TAB_GROUP_ACTIVE : ActiveTabEventType.TAB_GROUP_INACTIVE,
            listenerOptions: this.listeners[tabRef.instance.id].listenerOptions
          });
        }
      });
    }
  }
}

export interface ActiveTabEvent {
  type: ActiveTabEventType;
  listenerOptions: ActiveTabListenerOptions;
}

export interface ActiveTabListenerOptions {
  inSplitView: boolean;
}

export enum ActiveTabEventType {
  TAB_ACTIVE = 'tab_active',
  TAB_INACTIVE = 'tab_inactive',
  TAB_GROUP_ACTIVE = 'tab_group_active',
  TAB_GROUP_INACTIVE = 'tab_group_inactive'
}
