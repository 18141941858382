import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {
  CompiereDataFieldType,
  CompiereDataGridFilterModel,
  CompiereDataGridFilterType,
  CompiereDataGridRequestJSON,
  CompiereDataGridSortModelType,
  DataStore,
  DataStoreStatus
} from '@compiere-ws/models/compiere-data-json';
import { UniversalFilterService } from '@compiere-ws/services/universal-filter/universal-filter.service';
import { ColumnFilterAutocomplete, OperatorFilterAutocomplete } from '@iupics-components/models/autocomplete-interfaces';
import { FilterLink, FilterOperator, filterOperators, OperatorFilterType } from '@iupics-components/models/universal-filter';
import { ViewType } from '@iupics-components/models/view-type.enum';
import { OperatorFilterPipe } from '@iupics-components/pipes/operator-filter/operator-filter.pipe';
import { AutocompleteUiComponent } from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';
import { GridTabInfinityScrollUiComponent } from '@iupics-components/standard/grid/grid-tab-infinity-scroll-ui/grid-tab-infinity-scroll-ui.component';
import { GridViewUiComponent } from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { BladeUiComponent } from '@iupics-components/standard/layouts/blade-ui/blade-ui.component';
import { EditTabUiComponent } from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { AppConfig } from '@iupics-config/app.config';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { UICreatorUtils } from '@iupics-manager/managers/ui-creator/utils/ui-creator.utils';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsData, NumberType } from '@iupics-manager/models/iupics-data';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateService } from '@ngx-translate/core';
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';
import { clone, cloneDeep, has, isNil } from 'lodash';
import { Observable, of, zip } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { DataToDisplay, FilterChip, UniversalFilterUtils } from './universal-filter-utils';
@Component({
  selector: 'iu-universal-filter-ui',
  templateUrl: './universal-filter-ui.component.html',
  styleUrls: ['./universal-filter-ui.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UniversalFilterUiComponent implements OnInit, OnDestroy {
  @ViewChild('filtersContainer', { read: ElementRef }) filtersContainer: ElementRef;
  @ViewChild('sortsContainer', { read: ElementRef }) sortsContainer: ElementRef;
  @ViewChild('groupsContainer', { read: ElementRef }) groupsContainer: ElementRef;
  @ViewChildren('vcrFilter', { read: ViewContainerRef }) vcrFilters: QueryList<ViewContainerRef>;

  @Input() tabId: number;
  @Input() gridView: GridViewUiComponent;
  @Input() gridTab: GridTabInfinityScrollUiComponent;
  @Input() columns: any[];

  @Input() canFilter = true;
  @Input() canSort = true;
  @Input() canGroup = true;
  @Input() isMobile = false;

  //#region standalone
  @Input() isStandalone = false;
  @Input() windowType: IupicsMenuType;
  @Output() filterChange = new EventEmitter<{ filterToApply: CompiereDataGridRequestJSON; isNotFromUF: boolean }>();
  @Input() formId: number;
  @Input() setFilterEmitter: EventEmitter<CompiereDataGridRequestJSON>;
  //#endregion

  // observables
  private _columnNames: { items: ColumnFilterAutocomplete[] };
  private columnNames$: Observable<{ items: ColumnFilterAutocomplete[] }>;
  private _operators: { items: OperatorFilterAutocomplete[] };
  private operators$: Observable<{ items: OperatorFilterAutocomplete[] }>;
  private data$: Observable<CompiereDataGridRequestJSON[]>;
  source$: Observable<{
    columnFilters: { items: ColumnFilterAutocomplete[] };
    operatorFilters: { items: OperatorFilterAutocomplete[] };
  }>;

  // data
  dataToDisplay: DataToDisplay[] = [];

  // display attributes
  isDisplayFavoriteFilter = false;
  isDisplayGroupFilter = false;
  displayFavoriteFilterListener: Function;
  displayGroupFilterListener: Function;

  // selected favorite filter
  selected: DataToDisplay;
  selectedLabel: string;

  // default elements for filter
  defaultColumn: ColumnFilterAutocomplete;
  defaultOperator: OperatorFilterAutocomplete;

  // filter chips to be displayed
  filterChips: FilterChip[] = [];

  // override the buildind of a chips
  buildChipsOnSave = this._buildChipsOnSave.bind(this);
  conditionToAdd = this._checkToAdd.bind(this);
  isFromWindow = false;
  private subscriptions: any[] = [];

  private areDataLoaded = false;

  private selectionColumns: ColumnFilterAutocomplete[] = [];
  private localDatastore: DataStore;
  private initialStateDatastore: DataStore;
  private copylocalDatastoreData: DataStore;
  private copySelected: DataToDisplay;
  private fieldToUpdates = [];
  private parentContext: { [key: string]: any };

  private componentRefs: ComponentRef<any>[] = [];

  private ctxArea: any = {};

  usedFilterColumnNames = [];
  constructor(
    private renderer: Renderer2,
    private uiCreator: UICreatorService,
    private messageManager: MessageManagerService,
    private universalFilterService: UniversalFilterService,
    private config: AppConfig,
    private translator: TranslateService,
    private resolver: ComponentFactoryResolver,
    private connectorService: SecurityManagerService,
    private store: DataStoreService
  ) {
    UniversalFilterUtils.setConfig(this.config);
    UniversalFilterUtils.setConnectorService(this.connectorService);
    UniversalFilterUtils.setTranslateService(this.translator);
  }

  ngOnInit() {
    this.initLocalDatastore();
    this.getColumnFilters();
    this.getOperatorFilters();
    this.getSource();
    this.initData();
    if (this.gridView) {
      this.subscriptions.push(
        this.gridView.updateFilterEmitter.subscribe((newFilter: CompiereDataGridRequestJSON) => {
          let defaultColumnName = null;
          if (
            this.gridView.viewType === ViewType.CALENDAR &&
            this.gridView.calendarViewUiComponent &&
            this.gridView.calendarViewUiComponent.defaultColumn
          ) {
            defaultColumnName = this.gridView.calendarViewUiComponent.defaultColumn
              ? this.gridView.calendarViewUiComponent.defaultColumn.name
              : null;
          }
          this.handleUpdateFilter(newFilter, defaultColumnName);
        })
      );

      this.isFromWindow = this.gridView.windowType === IupicsMenuType.WINDOW;
      // START 141766
      if(this.gridView.container && this.gridView.container.parentComponent && this.gridView.container.parentComponent.DOMChildrenComponent[0]) {

        if(this.gridView.container.parentComponent.DOMChildrenComponent[0]._localDatastore) {
        this.gridView.localDatastore = this.gridView.container.parentComponent.DOMChildrenComponent[0]._localDatastore;
        }
      } else {
        this.gridView.localDatastore = this.localDatastore;
      }
      // END 141766
    }
    if (this.gridTab) {
      this.subscriptions.push(
        this.gridTab.setFilterEmitter.subscribe((newFilter: CompiereDataGridRequestJSON) => this.handleUpdateFilter(newFilter))
      );
    }
    if (this.isFromWindow) {
      this.uiCreator.getActualTab(this.tabId).subscribe((tab) => {(this.ctxArea = cloneDeep(tab.editView.data.ctxArea || {}));
    });
    }
    if (this.isStandalone) {
      this.setFilterEmitter.subscribe((newFilter: CompiereDataGridRequestJSON) => this.handleUpdateFilter(newFilter));
    }
  }

  private initLocalDatastore() {
    const container = this.gridTab ? this.gridTab.container : this.gridView ? this.gridView.container : null;
    if (container?.windowType || this.isStandalone) {
      const windowType = this.isStandalone ? this.windowType : container.windowType;
      switch (windowType) {
        case IupicsMenuType.FORM:
          const formId = this.isStandalone ? this.formId : container.formId;
          this.localDatastore = this.store.newSpecificWindowData(formId);
          break;
        case IupicsMenuType.WINDOW:
          // put random windowid when its a searchpanel
          const windowId = container.infoComponent ? container.infoComponent.windowId : null;
          if (windowId) {
            this.localDatastore = this.store.newWindowData(windowId, this.tabId > 0 ? this.tabId : null);
          } else {
            // build own datastructure to fit this searchpanel columns
            if (this.columns) {
              const dataTransformed: DataStore = new DataStore();
              const newStructure = {};
              this.columns.forEach((col) => {
                if (col.field) {
                  newStructure[col.field.ColumnName] = null;
                }
              });
              const generatedTabId = uuid();
              const generatedWindowId = uuid();
              this.store.addWindowDataStructure(generatedTabId, newStructure);
              const dataStorekey = this.store.generateDataStoreKey(generatedWindowId, generatedTabId, uuid(), null);
              Object.assign(dataTransformed.data, newStructure);
              dataTransformed.key = dataStorekey;
              dataTransformed.status = DataStoreStatus.NEWRECORD;
              this.localDatastore = dataTransformed;
            }
          }
          break;
        case IupicsMenuType.PROCESS:
          this.localDatastore = this.store.newProcessData(container.windowId);
          break;
        default:
          this.localDatastore = new DataStore();
          break;
      }
    } else {
      this.localDatastore = new DataStore();
    }
    // 131192 Avoid to set defaultValue for thoses columns
    this.localDatastore.data['DocStatus'] = null;
    this.localDatastore.data['DocAction'] = null;
    this.initialStateDatastore = cloneDeep(this.localDatastore);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((a) => a.unsubscribe());
  }

  /**
   * Change l'affichage d'une modal selon son nom et ajoute un listener de fermeture selon son id dans l'html
   * @param event
   * @param name
   * @param id
   * @param display
   */
  updatePanelFilter(event: Event, name: string, id: string, display: boolean = !this[`isDisplay${name}`]) {
    event.stopPropagation();
    this[`isDisplay${name}`] = display;
    if (this[`isDisplay${name}`] === true) {
      // keep a copy of initial chips and store
      if (!this.localDatastore) {
        this.initLocalDatastore();
      }
      if (this.localDatastore && this.copylocalDatastoreData) {
        this.localDatastore.data = cloneDeep(this.copylocalDatastoreData);
      }
      this.copylocalDatastoreData = cloneDeep(this.localDatastore.data);
      this.copySelected = cloneDeep(this.selected);
      this.fieldToUpdates = [];
      if (this[`display${name}Listener`] === undefined) {
        this[`display${name}Listener`] = this.renderer.listen(document.documentElement, 'mousedown', (e) => {
          const path = e.path ? e.path : e.composedPath();
          if (
            path.findIndex((target: any) => target.id === id) < 0 &&
            path.findIndex((target: any) => target.className === 'iu-modal-background') < 0 &&
            path.findIndex((target: any) => target.className && target.className.includes('p-autocomplete-panel')) < 0 &&
            path.findIndex((target: any) => target.className && target.className.includes('p-datepicker')) < 0
          ) {
            // reset chips and store if closed without applying
            if (this.selected && this.copySelected) {
              this.selected = cloneDeep(this.copySelected);
            }
            if (this.gridView && this.gridView.fromForm && this.gridView.container && this.gridView.container.dataStore) {
              this.fieldToUpdates.forEach((colName) => {
                this.gridView.container.setDatacontainerValue(colName, this.copylocalDatastoreData[colName]);
              });
            }

            this.updateChips();
            this[`isDisplay${name}`] = false;
            this[`display${name}Listener`]();
            this[`display${name}Listener`] = undefined;
          }
        });
      }
      if (name === 'GroupFilter') {
        this.handleViewChange();
        if (this.selectionColumns.length > 1) {
          this.selectionColumns.forEach((sc) => {
            const index =
              this.selected.filters.length > 0
                ? this.selected.filters.findIndex((f) =>
                    f.column.id !== -1 ? f.column.columnInfo.fieldEntity.field.ColumnName === sc.id : false
                  )
                : -1;
            if (index === -1) {
              this.selected.filters = this.selected.filters.filter((f) => f.column.id !== -1);
              this.addFilter(document.createEvent('Event'));
              this.setColumnFilter(sc, this.selected.filters.length - 1, false);
            }
          });
        }
        setTimeout(() => {
          this.componentRefs = [];
          this.checkVCR();
        }, 50);
      }
    } else {
      if (this[`display${name}Listener`] !== undefined) {
        this[`display${name}Listener`]();
        this[`display${name}Listener`] = undefined;
      }
    }
  }

  loadData(event: Event) {
    event.stopPropagation();
    if (!this.areDataLoaded) {
      this.getData();
      this.getDataToDisplay();
      this.areDataLoaded = true;
    }
  }

  setColumnFilter(value: ColumnFilterAutocomplete, index: number, fromHTML = true) {
    if (value) {
      this.selected.filters[index].column = value;
      if (!this.localDatastore) {
        this.initLocalDatastore();
      }

      const filter =
        has(this.localDatastore.data, value.id) && !isNil(this.localDatastore.data[value.id])
          ? this.localDatastore.data[value.id]
          : undefined;
      const filterTo =
        has(this.localDatastore.data, value.id + '_To') && !isNil(this.localDatastore.data[value.id + '_To'])
          ? this.localDatastore.data[value.id + '_To']
          : undefined;

      this.selected.filters[index].filter = filter;
      this.selected.filters[index].filterTo = filterTo;

      if (fromHTML) {
        this.setVCR(index);
      }

      const operatorPipe: OperatorFilterPipe = new OperatorFilterPipe();
      const operatorFilters = operatorPipe.transform(this._operators, this.selected.filters[index].column.columnInfo.filterType);
      this.setOperatorFilter(operatorFilters.items[0], index);
    }
  }

  setOperatorFilter(value: OperatorFilterAutocomplete, index: number) {
    if (value) {
      this.selected.filters[index].operator = value;
      if (!value.operator.isRange) {
        this.selected.filters[index].filterTo = undefined;
      }

      if (value.operator.filterType === CompiereDataGridFilterType.NUMBER) {
        const compoRef = this.componentRefs[index];
        const isMultiple = value.operator
          ? value.operator.type === OperatorFilterType.EQUALS || value.operator.type === OperatorFilterType.NOT_EQUALS
          : true;
        if (compoRef && compoRef.instance && compoRef.instance.multiple !== isMultiple) {
          const fieldValue = compoRef.instance.fieldValue;
          compoRef.instance.multiple = isMultiple;
          if (fieldValue) {
            compoRef.instance.fieldValue = isMultiple ? [fieldValue] : fieldValue[0];
            this.selected.filters[index].filter = isMultiple
              ? [this.selected.filters[index].filter]
              : this.selected.filters[index].filter[0];
          }
        }
      }

      this.updateChips();
    }
  }

  setFilter(value: any, index: number) {
    // init store because grid is completly rendered after universalfilter
    if (!this.localDatastore) {
      this.initLocalDatastore();
    }
    if (this.localDatastore) {
      const dataModified = {};
      dataModified[this.selected.filters[index].column.id] = value;
      if (this.gridView && this.gridView.fromForm && this.gridView.container && this.gridView.container.dataStore) {
        this.gridView.container.setDatacontainerValue(
          this.selected.filters[index].column.id,
          dataModified[this.selected.filters[index].column.id]
        );
      }
      if (value === undefined || value === null || (!(value instanceof Date) && value.length === 0)) {
        value = null;
      }
      this.selected.filters[index].filter = value;
      this.updateChips();
      const compoRef = this.componentRefs[index];
      if (compoRef && compoRef.instance && compoRef.instance.fieldValue) {
        compoRef.instance.fieldValue = value;
      }
      this.store.syncDataChanges(this.localDatastore, dataModified, true);
    }
  }

  setFilterTo(value: any, index: number) {
    // init store because grid is completly rendered after universalfilter
    if (!this.localDatastore) {
      this.initLocalDatastore();
    }
    if (this.localDatastore) {
      const dataModified = {};
      dataModified[this.selected.filters[index].column.id + '_To'] = value;

      if (this.gridView && this.gridView.fromForm && this.gridView.container && this.gridView.container.dataStore) {
        this.gridView.container.setDatacontainerValue(
          this.selected.filters[index].column.id + '_To',
          dataModified[this.selected.filters[index].column.id + '_To']
        );
      }
      if (value === undefined || value === null || (!(value instanceof Date) && value.length === 0)) {
        value = null;
      }
      this.selected.filters[index].filterTo = value;
      this.updateChips();
      this.store.syncDataChanges(this.localDatastore, dataModified, true);
    }
  }

  setGroup(value: ColumnFilterAutocomplete, index: number) {
    if (value) {
      this.selected.groups[index] = value;
      this.updateChips();
    }
  }

  setSorting(value: ColumnFilterAutocomplete, index: number) {
    if (value) {
      this.selected.sortings[index].column = value;
      this.updateChips();
    }
  }

  selectAndApply(event: Event, item: DataToDisplay) {
    event.stopPropagation();
    this.resetFilters(event);
    this.setSelected(item);
    this.updatePanelFilter(event, 'FavoriteFilter', 'favorite-filter-panel', false);
    const compDataGrid = UniversalFilterUtils.dataToDisplayToCompiereDataGrid(item, this.tabId);
    this.handleUpdateFilter(compDataGrid);
  }

  addFilter(event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    const emptyFilter = {
      column: { id: -1, displayValue: '' },
      operator: { id: -1, displayValue: '' },
      filter: '',
      filterTo: '',
      link: FilterLink.AND
    };
    this.selected.filters.push(emptyFilter);
    setTimeout(() => {
      if (this.filtersContainer) {
        this.filtersContainer.nativeElement.scrollTop =
          this.filtersContainer.nativeElement.scrollHeight - this.filtersContainer.nativeElement.clientHeight;
      }
    }, 50);
  }

  removeFilter(index: number, event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    if (this.gridView && this.gridView.fromForm && this.selected.filters[index]) {
      this.removeFromGridForm(index);
    }
    if (this.selected.filters[index] && this.selected.filters[index].column && this.selected.filters[index].column.id) {
      // keep track of changed values to reset store later if localdatastore is shared with a specific window
      this.fieldToUpdates.push(this.selected.filters[index].column.id);
      this.localDatastore.data[this.selected.filters[index].column.id] = clone(
        this.initialStateDatastore.data[this.selected.filters[index].column.id]
      );
    }
    this.selected.filters.splice(index, 1);
    this.componentRefs.splice(index, 1);
    this.updateChips();
  }
  removeFromGridForm(index: number) {
    let columnName = null;
    let shouldApply = false;
    if (this.selected.filters[index].filter) {
      columnName = this.selected.filters[index].column.id;
    }
    if (this.gridView.container && this.gridView.container.dataStore) {
      this.gridView.container.setDatacontainerValue(columnName, null);
      this.gridView.container.setDatacontainerValue(columnName + '_To', null);
      const dataContainerFound = this.gridView.container.getDatacontainer(columnName);
      shouldApply = !dataContainerFound || !dataContainerFound.data || !dataContainerFound.data.isLaunchSearchGrid;
      if (
        this.gridView.GridTabInfinityScrollUiComponent &&
        this.gridView.GridTabInfinityScrollUiComponent.query &&
        this.gridView.GridTabInfinityScrollUiComponent.query.filters
      ) {
        delete this.gridView.GridTabInfinityScrollUiComponent.query.filters[columnName];
        delete this.gridView.GridTabInfinityScrollUiComponent.filterModel[columnName];
        delete this.gridView.GridTabInfinityScrollUiComponent.query.filters[columnName + '_To'];
        delete this.gridView.GridTabInfinityScrollUiComponent.filterModel[columnName + '_To'];
      }
    }
    return shouldApply;
  }
  addGroup(event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    const emptyGroup = { id: -1, displayValue: '' };
    this.selected.groups.push(emptyGroup);
    setTimeout(() => {
      if (this.groupsContainer) {
        this.groupsContainer.nativeElement.scrollTop =
          this.groupsContainer.nativeElement.scrollHeight - this.groupsContainer.nativeElement.clientHeight;
      }
    }, 50);
  }

  removeGroup(index: number, event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    this.selected.groups.splice(index, 1);
    this.updateChips();
  }

  addSorting(event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    const emptySorting = {
      column: { id: -1, displayValue: '' },
      sortingType: CompiereDataGridSortModelType.ASC
    };
    this.selected.sortings.push(emptySorting);

    setTimeout(() => {
      if (this.sortsContainer) {
        this.sortsContainer.nativeElement.scrollTop =
          this.sortsContainer.nativeElement.scrollHeight - this.sortsContainer.nativeElement.clientHeight;
      }
    }, 50);
  }

  removeSorting(index: number, event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    this.selected.sortings.splice(index, 1);
    this.updateChips();
  }

  saveFilter(event: Event, name: string) {
    event.stopPropagation();
    this.selected = UniversalFilterUtils.cleanDataToDisplay(this.selected);
    if (this.selected.filters.findIndex((filter) => filter.column.id === -1 || filter.operator.id === -1) < 0) {
      this.selected.favorite = name;
      const filterToSave = UniversalFilterUtils.dataToDisplayToCompiereDataGrid(this.selected, this.tabId);
      let tempFilter: { index: number; filter: DataToDisplay };
      if (this.dataToDisplay.findIndex((d) => d.favorite === this.selected.favorite) < 0) {
        this.dataToDisplay.push(cloneDeep(this.selected));
      } else {
        const index = this.dataToDisplay.findIndex((d) => d.favorite === this.selected.favorite);
        tempFilter = {
          index: index,
          filter: cloneDeep(this.dataToDisplay[index])
        };
        this.dataToDisplay[index] = cloneDeep(this.selected);
      }
      const sub = this.universalFilterService.saveFilter(filterToSave).subscribe(
        () => {
          this.messageManager.newMessage(
            new IupicsMessage(
              this.translator.instant('universalFilter.saveTitle'),
              this.translator.instant('universalFilter.saveSuccess'),
              'success'
            )
          );
          sub.unsubscribe();
        },
        (err) => {
          this.messageManager.newMessage(
            new IupicsMessage(
              this.translator.instant('universalFilter.saveTitle'),
              this.translator.instant('universalFilter.saveFailed'),
              'error',
              err
            )
          );
          if (tempFilter !== undefined) {
            this.dataToDisplay[tempFilter.index] = cloneDeep(tempFilter.filter);
          } else {
            this.dataToDisplay.pop();
          }
          sub.unsubscribe();
        }
      );
    }
  }

  deleteFilter(event: Event, favorite: DataToDisplay) {
    event.stopPropagation();
    const filterToDelete = UniversalFilterUtils.dataToDisplayToCompiereDataGrid(favorite, this.tabId);
    const tempFilter = {
      index: this.dataToDisplay.findIndex((d) => d.favorite === favorite.favorite),
      filter: this.dataToDisplay.splice(
        this.dataToDisplay.findIndex((d) => d.favorite === favorite.favorite),
        1
      )[0]
    };
    const sub = this.universalFilterService.deleteFilter(filterToDelete).subscribe(
      () => {
        this.messageManager.newMessage(
          new IupicsMessage(
            this.translator.instant('universalFilter.deleteTitle'),
            this.translator.instant('universalFilter.deleteSuccess'),
            'success'
          )
        );
        sub.unsubscribe();
      },
      (err) => {
        this.messageManager.newMessage(
          new IupicsMessage(
            this.translator.instant('universalFilter.deleteTitle'),
            this.translator.instant('universalFilter.deleteFailed'),
            'error',
            err
          )
        );
        if (tempFilter) {
          this.dataToDisplay.splice(tempFilter.index, 0, tempFilter.filter);
        }
        sub.unsubscribe();
      }
    );
    if (this.selected.favorite === favorite.favorite) {
      this.initData();
    }
  }

  selectFilter(event: Event, item: DataToDisplay) {
    event.stopPropagation();
    this.setSelected(item);
    this.updatePanelFilter(event, 'FavoriteFilter', 'favorite-filter-panel', false);
    const compDataGrid = UniversalFilterUtils.dataToDisplayToCompiereDataGrid(item, this.tabId);
    this.handleUpdateFilter(compDataGrid, null, false, () => {
      this.copylocalDatastoreData = cloneDeep(this.localDatastore.data);
      this.updatePanelFilter(event, 'GroupFilter', 'group-filter-panel');
    });
  }

  addFilterByChips(event: Event, value: string) {
    event.stopPropagation();
    if (value.startsWith('groupBy:')) {
      this.subscriptions.push(
        this.columnNames$.subscribe((cfas) => {
          const columnName = value.replace('groupBy:', '').trim();
          const col = cfas.items.find((cfa) => cfa.id === columnName);
          this.selected.groups.push(col);
        })
      );
    } else if (value.startsWith('orderBy')) {
      this.subscriptions.push(
        this.columnNames$.subscribe((cfas) => {
          const columnName = value.replace('orderBy:', '').trim();
          const col = cfas.items.find((cfa) => cfa.id === columnName);
          this.selected.sortings.push({
            column: col,
            sortingType: CompiereDataGridSortModelType.ASC
          });
        })
      );
    } else {
      const containFilter = {
        column: this.defaultColumn,
        operator: this.defaultOperator,
        filter: value,
        filterTo: ''
      };
      if (!this.localDatastore) {
        this.initLocalDatastore();
      }
      if (this.localDatastore) {
        const dataModified = {};
        dataModified[containFilter.column.id] = value;
        if (this.gridView && this.gridView.fromForm && this.gridView.container && this.gridView.container.dataStore) {
          this.gridView.container.setDatacontainerValue(containFilter.column.id, dataModified[containFilter.column.id]);
        }
        this.store.syncDataChanges(this.localDatastore, dataModified, true);
      }
      if (
        this.selected.filters.findIndex(
          (filter) => filter.column.id === -1 || filter.operator.id === -1 || filter.filter === undefined || filter.filter === ''
        ) < 0
      ) {
        this.selected.filters.push(containFilter);
      } else {
        this.selected.filters[0] = containFilter;
      }
    }
    this.apply();
  }

  removeFilterByChips(event: Event, value: FilterChip) {
    event.stopPropagation();
    let shouldApply = true;
    if (this.gridView && this.gridView.fromForm && value.type === 'filters' && this.selected.filters[value.index]) {
      if (this.selected.filters[value.index]) {
        shouldApply = this.removeFromGridForm(value.index);
      }
    }
    if (
      this.selected.filters[value.index] &&
      this.selected.filters[value.index].column &&
      this.selected.filters[value.index].column.id
    ) {
      // keep track of changed values to reset store later if localdatastore is shared with a specific window
      this.fieldToUpdates.push(this.selected.filters[value.index].column.id);
      this.localDatastore.data[this.selected.filters[value.index].column.id] = null;
    }
    this.selected[value.type].splice(value.index, 1);
    // Cette ligne sert à reconstruire les chips pour update les index enregistré dans ceux-ci
    this.updateChips();
    if (shouldApply) {
      this.apply();
    }
  }

  applyFilter(event: Event) {
    event.stopPropagation();
    this.updateChips();
    this.apply();
    this.updatePanelFilter(event, 'GroupFilter', 'group-filter-panel', false);
  }

  /**
   *
   * @param event
   * @param type 'filters' | 'groups' | 'sortings'
   */
  moveItem(event: CdkDragDrop<any[]>, type: string) {
    if (this.selected[type]) {
      moveItemInArray(this.selected[type], event.previousIndex, event.currentIndex);
    }
    if (type === 'filters') {
      moveItemInArray(this.componentRefs, event.previousIndex, event.currentIndex);
    }
  }

  onAddError() {
    if (this.defaultColumn === undefined || this.defaultColumn === null) {
      this.messageManager.newMessage(
        new IupicsMessage(
          this.translator.instant('generic.warning'),
          this.translator.instant('universalFilter.noDefaultColumn'),
          'warning'
        )
      );
    }
  }

  /*
   * *****************************************************************************
   * *****************************************************************************
   * *****************************************************************************
   * ***************************** PRIVATE METHODS *******************************
   * *****************************************************************************
   * *****************************************************************************
   * *****************************************************************************
   */

  private getDataToDisplay() {
    const sub = zip(this.columnNames$, this.operators$, this.data$).subscribe(([columnFilters, operatorFilters, filters]) => {
      this.dataToDisplay = UniversalFilterUtils.compiereDataGridToDataToDisplay(
        columnFilters,
        operatorFilters,
        filters
      ).sort((a, b) =>
        a.favorite.toLowerCase() > b.favorite.toLowerCase() ? 1 : b.favorite.toLowerCase() > a.favorite.toLowerCase() ? -1 : 0
      );
      sub.unsubscribe();
    });
  }

  private getSource() {
    this.source$ = zip(this.columnNames$, this.operators$).pipe(
      map(([columnFilters, operatorFilters]) => {
        return {
          columnFilters: columnFilters,
          operatorFilters: operatorFilters
        };
      }),
      catchError((err) => {
        this.messageManager.newMessage(new IupicsMessage(this.translator.instant('generic.error'), err, 'error'));
        return [];
      })
    );
  }

  private getColumnFilters() {
    this.columnNames$ =
      this.tabId === -1
        ? of(this.columns).pipe(
            map((columns) => {
              return {
                items: columns.map((column) => {
                  return {
                    id: column.field.ColumnName,
                    displayValue: column.field.name,
                    columnInfo: {
                      fieldEntity: column,
                      filterType: UICreatorUtils.getFilterTypeFromReference(column.field.AD_Reference_ID)
                    }
                  };
                })
              };
            }),
            tap((columnInfos) =>
              columnInfos.items.sort((a, b) =>
                a.displayValue.toLowerCase() > b.displayValue.toLowerCase()
                  ? 1
                  : b.displayValue.toLowerCase() > a.displayValue.toLowerCase()
                  ? -1
                  : 0
              )
            ),
            tap(
              (cfas) =>
                (this.selectionColumns = cfas.items
                  .filter(
                    (cfa) =>
                      cfa.columnInfo.fieldEntity.field.AD_Reference_ID === 10 ||
                      cfa.columnInfo.fieldEntity.field.AD_Reference_ID === 14 ||
                      cfa.columnInfo.fieldEntity.field.AD_Reference_ID === 40 ||
                      cfa.columnInfo.fieldEntity.field.AD_Reference_ID === 38 ||
                      cfa.columnInfo.fieldEntity.field.AD_Reference_ID === 39
                  )
                  .filter((cfa) =>
                    cfa.columnInfo.fieldEntity.field.isQueryCriteria !== undefined &&
                    cfa.columnInfo.fieldEntity.field.isQueryCriteria
                      ? cfa.columnInfo.fieldEntity.field.isQueryCriteria
                      : cfa.columnInfo.fieldEntity.field.IsSelectionColumn
                  )
                  .sort((a, b) =>
                    a.columnInfo.fieldEntity.field.SelectionSeqNo > b.columnInfo.fieldEntity.field.SelectionSeqNo
                      ? 1
                      : a.columnInfo.fieldEntity.field.SelectionSeqNo < b.columnInfo.fieldEntity.field.SelectionSeqNo
                      ? -1
                      : 0
                  ))
            ),
            tap((_) => (this.defaultColumn = this.selectionColumns[0])),
            tap((cfas) => (this._columnNames = cfas)),
            catchError((err) => {
              this.messageManager.newMessage(new IupicsMessage('Erreur', err, 'error'));
              return [];
            })
          )
        : this.uiCreator.getColumnInfos(this.tabId).pipe(
            map((columnInfos) => ({
              items: columnInfos
                .filter(
                  (ci) =>
                    ((ci.fieldEntity.field.Name !== null &&
                      ci.fieldEntity.field.Name !== undefined &&
                      ci.fieldEntity.field.Name.trim() !== '') ||
                      (ci.fieldEntity.field.name !== null &&
                        ci.fieldEntity.field.name !== undefined &&
                        ci.fieldEntity.field.name.trim() !== '')) &&
                    (ci.fieldEntity.field.AD_Field_ID === -2 || ci.fieldEntity.field.IsDisplayed || ci.fieldEntity.field.IsKey)
                )
                .map((ci) => {
                  return {
                    id: ci.fieldEntity.field.ColumnName,
                    displayValue:
                      (ci.fieldEntity.field.AD_Field_ID === -2
                        ? this.translator.instant('mandatoryColumns.' + ci.fieldEntity.field.ColumnName)
                        : ci.fieldEntity.field.Name
                        ? ci.fieldEntity.field.Name
                        : ci.fieldEntity.field.name) + // 130422
                      (ci.fieldEntity.field.IsKey ? ' (ID)' : ''),
                    columnInfo: ci
                  };
                })
            })),
            tap((cfas) =>
              cfas.items.sort((a, b) =>
                a.displayValue.toLowerCase() > b.displayValue.toLowerCase()
                  ? 1
                  : b.displayValue.toLowerCase() > a.displayValue.toLowerCase()
                  ? -1
                  : 0
              )
            ),
            tap(
              (cfas) =>
                (this.selectionColumns = cfas.items
                  .filter((cfa) => cfa.columnInfo.fieldEntity.field.IsSelectionColumn === true)
                  .sort((a, b) =>
                    a.columnInfo.fieldEntity.AD_FormDetail_ID > 0
                      ? a.columnInfo.fieldEntity.SeqNo - b.columnInfo.fieldEntity.SeqNo
                      : a.columnInfo.fieldEntity.field.SelectionSeqNo - b.columnInfo.fieldEntity.field.SelectionSeqNo
                  ))
            ),
            tap((_) => (this.defaultColumn = this.selectionColumns[0])),
            tap((cfas) => (this._columnNames = cfas)),
            catchError((err) => {
              this.messageManager.newMessage(new IupicsMessage(this.translator.instant('generic.error'), err, 'error'));
              return [];
            })
          );
  }

  private getOperatorFilters() {
    this.operators$ = of(filterOperators).pipe(
      map((operators) => operators.map((op) => ({ ...op, ...{ label: this.translator.instant(op.label) } }))),
      map((operators) => ({
        items: operators.map((operator: FilterOperator) => ({
          id: operator.id,
          displayValue: operator.label,
          operator: operator
        }))
      })),
      tap(
        (ofas) =>
          (this.defaultOperator = ofas.items.find(
            (ofa) =>
              ofa.operator.filterType === CompiereDataGridFilterType.TEXT && ofa.operator.type === OperatorFilterType.CONTAINS
          ))
      ),
      tap((ofas) => (this._operators = ofas)),
      catchError((err) => {
        this.messageManager.newMessage(new IupicsMessage(this.translator.instant('generic.error'), err, 'error'));
        return [];
      })
    );
  }

  private getData() {
    this.data$ = this.universalFilterService.getFilters(this.tabId);
  }

  private initData() {
    this.selected = {
      favorite: '',
      groups: [],
      filters: [],
      sortings: []
    };
  }

  private apply(isNotFromUF = false) {
    // update copy of chips and store
    this.copylocalDatastoreData = cloneDeep(this.localDatastore.data);
    this.copySelected = cloneDeep(this.selected);
    this.fieldToUpdates = [];
    const filterToApply = UniversalFilterUtils.dataToDisplayToCompiereDataGrid(this.selected, this.tabId);
    if (this.gridView) {
      this.gridView.applyFilter(filterToApply);
      if (!(this.gridView.DOMParentComponent instanceof EditTabUiComponent)) {
        (<BladeUiComponent>this.gridView.DOMParentComponent).notifyUrlChange();
      }
    }
    if (this.gridTab) {
      this.gridTab.applyFilter(
        filterToApply.sortModel,
        filterToApply.filterModel,
        filterToApply.rowGroupCols,
        this.gridTab.valueCols
      );
    }

    if (this.isStandalone) {
      this.filterChange.emit({ filterToApply, isNotFromUF });
    }
  }

  private setSelected(item: DataToDisplay) {
    this.selected = cloneDeep(item);
    this.copySelected = cloneDeep(item);
    this.selectedLabel = this.selected.favorite;
  }

  private _buildChipsOnSave(value: string): FilterChip {
    let filterChip: FilterChip;
    if (value.startsWith('orderBy:')) {
      const columnName = value.replace('orderBy:', '').trim();
      const column = this._columnNames.items.find((c) => c.id === columnName);
      if (column) {
        filterChip = {
          displayValue: `${column.displayValue}`,
          icon: 'icon-ico-tri-az',
          type: 'sortings',
          index: this.selected.sortings.length
        };
      }
    } else if (value.startsWith('groupBy:')) {
      const columnName = value.replace('groupBy:', '').trim();
      const column = this._columnNames.items.find((c) => c.id === columnName);
      if (column) {
        filterChip = {
          displayValue: `${columnName}`,
          icon: 'icon-ico-group-check',
          type: 'groups',
          index: this.selected.groups.length
        };
      }
    } else {
      filterChip = {
        displayValue: `${this.defaultColumn.displayValue} ${this.defaultOperator.displayValue} '${value}'`,
        icon: 'icon-ico-filter',
        type: 'filters',
        index: this.selected.filters.length
      };
    }
    return filterChip;
  }

  private _checkToAdd() {
    return this.defaultColumn !== undefined && this.defaultColumn !== null;
  }

  private checkVCR() {
    this.vcrFilters.forEach((_, index) => {
      this.setVCR(index);
    });
  }

  private setVCR(index: number) {
    // init store because grid is completly rendered after universalfilter
    if (!this.localDatastore) {
      this.initLocalDatastore();
    }
    let compoRef: ComponentRef<any>;
    if (this.selected.filters[index].column && this.selected.filters[index].column.id !== -1) {
      let componentName = UICreatorUtils.getComponentNameFromReference(
        this.selected.filters[index].column.columnInfo.fieldEntity.field.AD_Reference_ID
      );
      if (componentName === 'InputTextareaUiComponent') {
        componentName = 'InputTextUiComponent';
      } else if (componentName === 'ButtonUiComponent') {
        componentName = 'InputSwitchUiComponent';
      }
      const vcr = this.vcrFilters.find((_, i) => index === i);
      vcr.clear();
      const factory = this.resolver.resolveComponentFactory(Global.iupics_components.get(componentName));
      compoRef = vcr.createComponent(factory);
      compoRef.instance.isStandalone = true;
      compoRef.instance.isInUniversalFilter = true;
      compoRef.instance.isSetDefaultValue = true;
      if (componentName === 'AutocompleteUiComponent') {
        (<AutocompleteUiComponent>compoRef.instance).isInsideOverflow = true;
      }
      /* Set le fieldType utilisé pour le ws /DataField */
      let fieldType = CompiereDataFieldType.FIELD;
      const container = this.gridTab ? this.gridTab.container : this.gridView ? this.gridView.container : null;
      if (container?.windowType || this.isStandalone) {
        const isSearch = this.gridTab ? this.gridTab.isSearch : false;
        if (!isSearch) {
          const windowType = this.isStandalone ? this.windowType : container.windowType;
          switch (windowType) {
            case IupicsMenuType.FORM:
              fieldType = this.gridView?.infoWindowId ? CompiereDataFieldType.COLUMN_INFO : CompiereDataFieldType.FORM_ITEM;
              break;
            case IupicsMenuType.PROCESS:
              fieldType = CompiereDataFieldType.PROCESS_PARA;
              break;
            case IupicsMenuType.WINDOW:
              fieldType = CompiereDataFieldType.FIELD;
              break;
            default:
              break;
          }
        }

        (<AbstractDynamicComponent>compoRef.instance).container = container;
        (<AbstractDynamicComponent>compoRef.instance).fieldType = fieldType;
      } else {
        if (componentName === 'AutocompleteUiComponent') {
          compoRef.instance.isForInfoWindow = true;
        }
      }
      this.subscriptions.push(
        compoRef.instance.fieldValueModified.subscribe((value: any) => {
          const i = this.componentRefs.findIndex((c) => c === compoRef);
          this.setFilter(value, i);
        })
      );
      // ? by default enterKey is Faster than fieldValueModified due to the originalEvent used in both cases
      if (compoRef.instance.hasOwnProperty('enterKey')) {
        this.subscriptions.push(
          compoRef.instance.enterKey.subscribe((e: Event) => {
            e.stopPropagation();
            const i = this.componentRefs.findIndex((c) => c === compoRef);
            this.setFilter(compoRef.instance.inputRef.nativeElement.value, i);
            if (this.componentRefs[i].instance.multiple === false && componentName === 'InputNumberUiComponent') {
              return;
            }
            this.apply();
            this.updatePanelFilter(e, 'GroupFilter', 'group-filter-panel', false);
          })
        );
      }
      if (
        componentName === 'InputSwitchUiComponent' &&
        isNil(compoRef.instance.fieldValue) &&
        isNil(this.localDatastore.data[this.selected.filters[index].column.id])
      ) {
        compoRef.instance.fieldValue = 'N';
        this.selected.filters[index].filter = 'N';
      }
      if (componentName === 'InputNumberUiComponent') {
        compoRef.instance.multiple = this.selected.filters[index].operator.operator
          ? this.selected.filters[index].operator.operator.type === OperatorFilterType.EQUALS ||
            this.selected.filters[index].operator.operator.type === OperatorFilterType.NOT_EQUALS
          : true;
        compoRef.instance.cssClass = 'UniversalFilterInputNumber';
      }
      if (componentName === 'AutocompleteUiComponent') {
        // create internal datastore and use datamodified for that
        // it will be used as a local ctx for the universal filter
        compoRef.instance.multiple = true;
        const fieldEntity = this.selected.filters[index].column.columnInfo.fieldEntity;
        const field = fieldEntity ? fieldEntity.field : null;
        compoRef.instance.data = <IupicsData>{
          fieldId: field.AD_Field_ID,
          columnId: field.AD_Column_ID,
          formId: field.AD_Form_ID,
          fieldType: field.IsEncryptedColumn || field.IsEncryptedField ? 'password' : 'text',
          isCopy: field.IsCopy,
          items: fieldEntity.data,
          description: field.Description,
          columnName: field.ColumnName,
          details: fieldEntity.details,
          urlList: fieldEntity.urlList,
          urlSearch: fieldEntity.urlSearch,
          tableName: field.tableName,
          tabId: field.AD_Tab_ID,
          isSearch: field.AD_Reference_ID === 30 || (fieldEntity.urlSearch !== null && fieldEntity.urlSearch !== undefined),
          isAccount: field.AD_Reference_ID === 25,
          needTime: field.AD_Reference_ID === 16 || field.AD_Reference_ID === 24,
          defaultValue: field.DefaultValue,
          defaultValue2: field.DefaultValue2,
          searchColumns: fieldEntity.details ? fieldEntity.details.searchColumns : null,
          numberType:
            field.AD_Reference_ID === 12 || field.AD_Reference_ID === 37
              ? NumberType.MONTANT
              : field.AD_Reference_ID === 11
              ? NumberType.INTEGER
              : field.AD_Reference_ID === 22 || field.AD_Reference_ID === 29
              ? NumberType.FLOAT
              : null,
          validationCode: field.ValidationCode ? field.ValidationCode.replace(/\n/g, ' ') : null,
          componentName: componentName
        };
      } else {
        compoRef.instance.data = { columnName: this.selected.filters[index].column.id };
      }
      if (this.localDatastore && this.localDatastore.data) {
        const getCurrentContext = () => {
          this.parentContext = this.gridTab
            ? this.gridTab.getCurrentContext()
            : this.gridView
            ? this.gridView.getCurrentContext()
            : {};
          const data = {};
          [...Object.keys(this.parentContext), ...Object.keys(this.localDatastore.data), ...Object.keys(this.ctxArea)].forEach(
            (key) => {
              data[key] = this.localDatastore.data[key]
                ? this.localDatastore.data[key]
                : this.parentContext[key]
                ? this.parentContext[key]
                : this.ctxArea && this.ctxArea[key]
                ? this.ctxArea[key]
                : this.parentContext['#' + key];
            }
          );
          return data;
        };
        compoRef.instance.getCurrentContext = getCurrentContext.bind(this);
        if (compoRef.instance.setNewData) {
          compoRef.instance.setNewData(this.localDatastore);
        }
      }
      this.componentRefs.splice(index, 0, compoRef);
    }
  }

  private handleUpdateFilter(
    newFilter: CompiereDataGridRequestJSON,
    firstChipsName = null,
    shouldApply = true,
    callback = () => {}
  ) {
    // init store because grid is completly rendered after universalfilter
    if (!this.localDatastore) {
      this.initLocalDatastore();
    }
    this.adaptFilterModelToUniversalFilter(newFilter.filterModel).then((newFilterModel) => {
      newFilter.filterModel = newFilterModel;
      const newDisplayFilters = UniversalFilterUtils.filterModelToFilterToDisplay(this._columnNames, this._operators, newFilter);
      const newDisplaySorts = UniversalFilterUtils.sortModelToFilterToDisplay(this._columnNames, newFilter);
      const newDisplayGroups = UniversalFilterUtils.groupModelToFilterToDisplay(this._columnNames, newFilter);
      if (newDisplayFilters) {
        if (firstChipsName) {
          const indexFound = newDisplayFilters.findIndex((f) => f.column.id === firstChipsName);
          if (indexFound !== -1) {
            newDisplayFilters.splice(0, 0, newDisplayFilters.splice(indexFound, 1)[0]);
          } else {
            const indexColumnDate = newDisplayFilters.findIndex(
              (f) => f.column.columnInfo.filterType === CompiereDataGridFilterType.DATE
            );
            newDisplayFilters.splice(0, 0, newDisplayFilters.splice(indexColumnDate, 1)[0]);
          }
        }
        this.selected.filters = newDisplayFilters;
      }
      if (newDisplaySorts) {
        this.selected.sortings = newDisplaySorts;
      }
      if (newDisplayGroups) {
        this.selected.groups = newDisplayGroups;
      }
      this.updateChips();

      if (this.localDatastore) {
        const dataModified = {};
        newDisplayFilters.forEach((f) => {
          if (f.filterTo) {
            dataModified[f.column.id + '_To'] = f.filterTo;
          }
          dataModified[f.column.id] = f.filter;
        });
        // adapt localStore to empty filter
        if (
          this.selected &&
          this.selected.filters &&
          this.selected.filters.length === 0 &&
          this.copySelected &&
          this.copySelected.filters &&
          this.copySelected.filters.length > 0
        ) {
          this.copySelected.filters.forEach((f) => {
            if (f.column && f.column.id) {
              dataModified[f.column.id] = null;
            }
          });
        }
        this.store.syncDataChanges(this.localDatastore, dataModified, true);
      }
      if (shouldApply) {
        this.apply(true);
      }
      callback();
    });
  }

  private handleViewChange() {
    if (this.gridView) {
      this.selected = UniversalFilterUtils.cleanDataToDisplay(this.selected);
      if (
        (this.gridView.viewType === ViewType.KANBAN || this.gridView.viewType === ViewType.CHART) &&
        this.selected.groups.length === 0
      ) {
        this.addGroup(document.createEvent('Event'));
      } else if (this.selected.filters.length === 0) {
        this.addFilter(document.createEvent('Event'));
      }
    } else if (this.gridTab) {
      this.selected.filters.filter((filter) => filter.column.id !== -1);
      this.selected.groups.filter((group) => group.id !== -1);
      this.selected.sortings.filter((sorting) => sorting.column.id !== -1);
      if (this.selected.filters.length === 0) {
        this.addFilter(document.createEvent('Event'));
      }
    } else if (this.isStandalone) {
      this.selected = UniversalFilterUtils.cleanDataToDisplay(this.selected);
      if (this.canFilter && this.selected.filters.length === 0) {
        this.addFilter(document.createEvent('Event'));
      }
    }
  }
  /**
   * reformate le fitermodel pour pouvoir être utilisé par l'universalfilter
   * @param filterModel
   */
  public adaptFilterModelToUniversalFilter(
    filterModel
  ): Promise<{
    [columnName: string]: CompiereDataGridFilterModel;
  }> {
    return new Promise((resolve, reject) => {
      if (filterModel) {
        const results$ = [];
        const resultsColumn = new Map<number, { columnKey: string; indexValue: number }>();
        Object.keys(filterModel).forEach((columnKey) => {
          const column = this._columnNames.items.find(
            (columnFilter) => columnFilter.id === columnKey || columnFilter.columnInfo.fieldEntity.ColumnName === columnKey
          );
          const columnType = this.isStandalone ? this.windowType : this.gridView.fieldType;
          const columnEntity =
            columnType === CompiereDataFieldType.FIELD
              ? column.columnInfo.fieldEntity.field['AD_Field_ID']
              : column.columnInfo.fieldEntity.field['AD_Column_ID'];
          let fieldType: CompiereDataFieldType;
          const container = this.gridTab ? this.gridTab.container : this.gridView ? this.gridView.container : null;
          if (container?.windowType || this.isStandalone) {
            const windowType = this.isStandalone ? this.windowType : container.windowType;
            switch (windowType) {
              case IupicsMenuType.FORM:
                fieldType = this.gridView?.infoWindowId ? CompiereDataFieldType.COLUMN_INFO : CompiereDataFieldType.FORM_ITEM;
                break;
              case IupicsMenuType.PROCESS:
                fieldType = CompiereDataFieldType.PROCESS_PARA;
                break;
              case IupicsMenuType.WINDOW:
                fieldType = CompiereDataFieldType.FIELD;
                break;
              default:
                break;
            }
          }
          if (filterModel[columnKey].filterType === CompiereDataGridFilterType.SET && filterModel[columnKey].values) {
            filterModel[columnKey].values.forEach((value, indexValue) => {
              if (value instanceof Array) {
                value.forEach((f) => {
                  if (!(f instanceof Object)) {
                    results$.push(this.store.getAutocompleteDataById(fieldType, columnEntity, f));
                  } else {
                    results$.push(of(f));
                  }
                  resultsColumn.set(results$.length - 1, { columnKey: columnKey, indexValue: indexValue });
                });
              } else {
                if (!(value instanceof Object)) {
                  results$.push(this.store.getAutocompleteDataById(fieldType, columnEntity, value));
                } else {
                  results$.push(of(value));
                }
                resultsColumn.set(results$.length - 1, { columnKey: columnKey, indexValue: indexValue });
              }
              filterModel[columnKey].values = [];
            });
          }
        });
        if (results$.length > 0) {
          zip(...results$).subscribe((response) => {
            response.forEach((value, index) => {
              const columnKey = resultsColumn.get(index).columnKey;
              const indexValue = resultsColumn.get(index).indexValue;
              if (value && (!(value instanceof Array) || value[0])) {
                if (!filterModel[columnKey].values[indexValue]) {
                  filterModel[columnKey].values[indexValue] = [value instanceof Array ? value[0] : value];
                } else {
                  filterModel[columnKey].values[indexValue].push(value instanceof Array ? value[0] : value);
                }
              }
            });
            resultsColumn.forEach((value, key) => {
              const columnKey = value.columnKey;
              const indexValue = value.indexValue;
              if (filterModel[columnKey]) {
                if (
                  filterModel[columnKey].values[indexValue] === undefined ||
                  (filterModel[columnKey].values[indexValue] instanceof Array &&
                    filterModel[columnKey].values[indexValue].length <= 0)
                ) {
                  delete filterModel[columnKey];
                }
              }
            });
            resolve(filterModel);
          });
        } else {
          resolve(filterModel);
        }
      }
    });
  }

  resetFilters(event: Event) {
    event.stopPropagation();
    if (this.selected.filters) {
      this.selected.filters.forEach((f) => {
        if (f.column) {
          if (this.fieldToUpdates) {
            this.fieldToUpdates.push(f.column.id);
          }
          if (this.localDatastore && this.localDatastore.data) {
            this.localDatastore.data[f.column.id] = null;
          }
        }
      });
    }
    this.initData();
    this.filterChips = [];
    this.usedFilterColumnNames = [];
    if (this.selectionColumns.length > 1) {
      this.selectionColumns.forEach((column) => {
        this.addFilter(document.createEvent('Event'));
        this.setColumnFilter(column, this.selected.filters.length - 1, false);
      });
    } else {
      this.addFilter(document.createEvent('Event'));
    }
    setTimeout(() => {
      this.componentRefs = [];
      this.checkVCR();
    }, 50);
  }
  updateChips() {
    this.filterChips = UniversalFilterUtils.dataToDisplayToChips(this.selected);
    this.usedFilterColumnNames = [];
    this.selected.filters.forEach((f) => this.usedFilterColumnNames.push(f.column.id));
  }
}
