//Prime 10.0.3
import { Component, forwardRef, Inject, ViewEncapsulation } from '@angular/core';
import { ContextMenu, ContextMenuSub } from 'primeng/contextmenu';
import { ConnectedOverlayScrollHandler, DomHandler } from 'primeng/dom';

@Component({
  selector: 'iu-prime-contextMenuSub',
  templateUrl: './prime-contextmenusub.component.html',
  styleUrls: ['./prime-contextmenusub.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrimeContextMenuSubComponent extends ContextMenuSub {
  constructor(@Inject(forwardRef(() => PrimeContextMenuComponent)) contextMenu) {
    super(contextMenu);
  }
}

@Component({
  selector: 'iu-prime-contextMenu',
  templateUrl: './prime-contextmenu.component.html',
  styleUrls: ['./prime-contextmenu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrimeContextMenuComponent extends ContextMenu {
  scrollHandler: any;
  srcEvent: any;
  position(event?: any) {
    // console.log(event);
    if (event) {
      if (event.srcEvent) {
        event = event.srcEvent;
      }
      let left = event.pageX + 1;
      let top = event.pageY + 1;
      const height = this.containerViewChild.nativeElement.clientHeight;
      const width = this.containerViewChild.nativeElement.clientWidth;
      const viewport = DomHandler.getViewport();

      // console.log(height, width, viewport);

      // flip
      // console.log(left + width, viewport.width);
      if (left + width > viewport.width) {
        left -= width;
      }

      // flip
      // console.log(top + height, viewport.height);
      if (top + height > viewport.height) {
        top -= height;
      }

      // fit
      // console.log(left, document.body.scrollLeft);
      if (left < document.body.scrollLeft) {
        left = document.body.scrollLeft;
      }

      // fit
      // console.log(top, document.body.scrollTop);
      if (top < document.body.scrollTop) {
        top = document.body.scrollTop;
      }

      this.containerViewChild.nativeElement.style.left = left + 'px';
      this.containerViewChild.nativeElement.style.top = top + 'px';
    }
  }
  show(event) {
    this.position(event);
    this.moveOnTop();
    this.containerViewChild.nativeElement.style.display = 'block';
    this.parentActive = true;
    DomHandler.fadeIn(this.containerViewChild.nativeElement, 250);
    this.srcEvent = event;
    this.bindGlobalListeners();
    if (event) {
      event.preventDefault();
    }
    this.onShow.emit();
  }
  //scrollListener
  bindScrollListener() {
    if (!this.scrollHandler && this.srcEvent.target) {
      this.scrollHandler = new ConnectedOverlayScrollHandler(this.srcEvent.target, () => {
        if (this.containerViewChild.nativeElement.style.display !== 'none') {
          this.hide();
        }
      });
    }

    this.scrollHandler.bindScrollListener();
  }
  unbindScrollListener() {
    if (this.scrollHandler) {
      this.scrollHandler.unbindScrollListener();
    }
  }
  bindGlobalListeners() {
    this.bindScrollListener();
    if (!this.documentClickListener) {
      const documentTarget: any = this.el ? this.el.nativeElement.ownerDocument : 'document';

      this.documentClickListener = this.renderer.listen(documentTarget, 'click', (event) => {
        if (this.containerViewChild.nativeElement.offsetParent && this.isOutsideClicked(event) && event.button !== 2) {
          this.hide();
        }
      });
    }

    this.zone.runOutsideAngular(() => {
      if (!this.windowResizeListener) {
        this.windowResizeListener = this.onWindowResize.bind(this);
        window.addEventListener('resize', this.windowResizeListener);
      }
    });
  }
  //Fin scrollListener
  unbindGlobalListeners() {
    this.unbindScrollListener();
    if (this.documentClickListener) {
      this.documentClickListener();
      this.documentClickListener = null;
    }

    if (this.windowResizeListener) {
      window.removeEventListener('resize', this.windowResizeListener);
      this.windowResizeListener = null;
    }
  }
}
