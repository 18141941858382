import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

export enum CallInProgressState {
  NOTFOUND = 'NF',
  DONE = 'D',
  IN_PROGRESS = 'IP'
}

@Injectable({
  providedIn: 'root'
})
export class CallInProgressService implements OnDestroy {
  private subscriptions: Map<string, Map<string, Subscription>> = new Map<string, Map<string, Subscription>>();
  constructor() {}
  setSubscription(containerIdentifier: string, callIdentifier: string, subscription: Subscription) {
    if (subscription instanceof Subscription) {
      if (this.subscriptions.get(containerIdentifier)) {
        this.subscriptions.get(containerIdentifier).set(callIdentifier, subscription);
      } else {
        this.subscriptions.set(
          containerIdentifier,
          new Map<string, Subscription>([[callIdentifier, subscription]])
        );
      }
    } else {
      console.log('Service only support Subscription type.');
    }
  }
  isSubscriptionDone(containerIdentifier: string, callIdentifier: string) {
    return this.getSubscriptionState(containerIdentifier, callIdentifier) !== CallInProgressState.IN_PROGRESS;
  }
  getSubscriptionState(containerIdentifier: string, callIdentifier: string) {
    if (this.subscriptions.get(containerIdentifier) && this.subscriptions.get(containerIdentifier).get(callIdentifier)) {
      return this.subscriptions.get(containerIdentifier).get(callIdentifier).closed
        ? CallInProgressState.DONE
        : CallInProgressState.IN_PROGRESS;
    } else {
      return CallInProgressState.NOTFOUND;
    }
  }
  deleteSubscription(containerIdentifier: string, callIdentifier: string) {
    if (this.subscriptions.get(containerIdentifier) && this.subscriptions.get(containerIdentifier).get(callIdentifier)) {
      this.subscriptions.get(containerIdentifier).delete(callIdentifier);
    }
  }
  completeSubscription(containerIdentifier: string, callIdentifier: string) {
    if (this.subscriptions.get(containerIdentifier) && this.subscriptions.get(containerIdentifier).get(callIdentifier)) {
      this.subscriptions
        .get(containerIdentifier)
        .get(callIdentifier)
        .unsubscribe();
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((value, key) => {
      if (value && value.size > 0) {
        value.forEach((value2, key2) => {
          if (value2) {
            value2.unsubscribe();
          }
        });
      }
    });
  }
}
