<span
  [style.display]="displayCss"
  [pTooltip]="tooltip"
  life="3"
  tooltipPosition="top"
  showDelay="500"
  [style.margin-top.px]="data && data.marginTop > 0 ? data.marginTop : null"
  tooltipZIndex="2100"
  #input
  (contextmenu)="onContextMenu($event)"
  (press)="onContextMenu($event)"
  [style.backgroundColor]="backgroundColorCss"
>
  <iu-prime-calendar
    #calendar
    [label]="label"
    [isLabelDisplay]="this.isLabelDisplay"
    [iupicsLocale]="locale"
    [hourFormat]="hourFormat"
    [showTime]="true"
    [showIcon]="true"
    (fieldChange)="fieldChange($event)"
    [timeOnly]="true"
    [readonlyInput]="isReadOnly"
    [columnName]="data.columnName"
  ></iu-prime-calendar>
  <iu-value-preference-panel
    *ngIf="showValuePrefPanel"
    [fieldValue]="fieldValue"
    [dataStored]="this.dataStored"
    [sourceComponent]="this"
    [data]="this.data"
    (closeEmitter)="showValuePrefPanel = !showValuePrefPanel"
  ></iu-value-preference-panel>
</span>
