<div #specificContainer class="iu-specific-window iu-specific-window-container" [attr.data-cy-specific-window-name]="name">
  <p-progressBar
    *ngIf="!isModal && isLoading"
    [mode]="'indeterminate'"
    [value]="0"
    [showValue]="false"
    [styleClass]="'loading-progressbar'"
  ></p-progressBar>
  <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
    <div class="apiz-p-grid">
      <div class="p-col-12 apiz-p-grid">
        <h1 class="p-col-11 import-file-loader-window-title">{{ title }}</h1>
        <button
          class="p-button p-button-error p-col-1 import-file-loader-reset-button"
          [title]="'generic.reset' | translate"
          (click)="reset($event)"
        >
          <i class="fas fa-trash-restore"></i>
        </button>
      </div>
      <div class="p-col-4 p-grid import-file-loader-parameters">
        <ng-template #vcrParameters></ng-template>
        <!-- <iu-input-file-ui
          #fileContainer
          [isStandalone]="true"
          [mustGet]="false"
          [canDelete]="true"
          [canPreview]="false"
          [canUpload]="true"
          [canDownload]="false"
          [columnName]="'FilePath'"
          [multiple]="true"
          (uploadEnd)="onUploadEnd($event)"
          (delete)="onDelete($event)"
          class="p-col-12"
        ></iu-input-file-ui> -->
      </div>
      <div class="p-col-8" style="height: 315px">
        <iu-grid-tab-rowdata #grid></iu-grid-tab-rowdata>
      </div>
      <div class="p-col-12 p-grid">
        <ng-template #vcrProcess></ng-template>
      </div>
    </div>
  </p-scrollPanel>
</div>
<ng-container *ngIf="displaySearch">
  <iu-modal-ui
    data-cy="search-panel"
    [attr.data-cy-columnName]="this.searchLinkedComponent.autoComplete.data.columnName"
    [title]="this.searchLinkedComponent.autoComplete.label"
    [hasCloseBtn]="true"
    (closeModalEmitter)="displaySearch = false"
  >
    <div class="autocomplete-universal-filter">
      <iu-universal-filter-ui
        [tabId]="-1"
        [columns]="searchLinkedComponent.autoComplete.data.searchColumns"
        [gridTab]="gridTab"
      ></iu-universal-filter-ui>
    </div>
    <iu-grid-tab-infinity-scroll-ui
      #gridTab
      (searchEmitter)="this.searchLinkedComponent.setSearchSelectItem($event); displaySearch = false"
      [data]="this.searchLinkedComponent.autoComplete.data"
      [container]="this"
      [isSearch]="true"
      [dataStored]="this.searchLinkedComponent.autoComplete.dataStored"
    ></iu-grid-tab-infinity-scroll-ui>
  </iu-modal-ui>
</ng-container>
<div [ngClass]="[!displayFormUI ? 'hidden' : '', 'specificModal']">
  <iu-modal-ui
    [title]="specificWindowTitle"
    [isModalDisplay]="displayFormUI"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false })"
  >
    <ng-template #vcrSpecific></ng-template>
  </iu-modal-ui>
</div>
