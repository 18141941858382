export interface CompiereNotification {
  additionalProperties?: any;
  title: string;
  summary: string;
  description: string;
  created: string;
  processChannelId: string;
  request_id: number;
  closed: boolean;
  isError?: boolean;
  priority: CompiereNotificationPriority;
  target: string;
  type?: string;
  sourceType: string;
  sourceTypeId: number;
  sourceRecordId: string;
  sourceTableNameTargeted: string;
  processParams?: any;
  zoomInfo?: ZoomInfo;
  fileLinks: CompiereNotificationFileInfo[];
}
export interface ZoomInfo {
  zoomType?: string;
  zoomTypeId?: number;
  zoomUrl?: string;
  recordId?: string;
  validation?: string;
  tableNameTargeted?: string;
  colValues?: any;
}

export interface CompiereNotificationFileInfo {
  path: string;
  fileName: string;
  extension: string;
}

export enum CompiereNotificationStatus {
  TREATED = 'Traitée',
  TERMINATED = 'Terminée',
  UNTREATED = 'Non traitée'
}

export enum CompiereNotificationPriority {
  IMPORTANT = 'IMPORTANT',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW'
}
