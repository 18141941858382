import { AfterViewInit, Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { AutocompleteUiComponent } from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';
import { ButtonUiComponent } from '@iupics-components/standard/fields/button-ui/button-ui.component';
import { WindowFactoryUtils } from '@iupics-manager/managers/ui-creator/window-factory/window-factory-utils';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { IupicsEvent } from '@iupics-manager/models/iupics-event';

@Component({
  selector: 'iu-row-ui',
  templateUrl: './row-ui.component.html',
  styleUrls: ['./row-ui.component.css']
})
export class RowUiComponent extends AbstractDynamicComponent implements OnInit, AfterViewInit {
  constructor(private resolver: ComponentFactoryResolver) {
    super();
  }

  ngOnInit() {}
  onChildUpdate(event: IupicsEvent) {}
  onSiblingUpdate(event: IupicsEvent) {}
  onRemoveComponent(event: IupicsEvent) {}
  ngAfterViewInit() {
    this.children.forEach(child => {
      const componentRef = WindowFactoryUtils.addContainerComponent(
        this,
        child,
        this.resolver,
        child.container ? child.isCssOnComponent : false
      );

      if (componentRef.instance instanceof ButtonUiComponent) {
        (<ButtonUiComponent>componentRef.instance).itemData = child;
      }
      if (componentRef.instance instanceof AutocompleteUiComponent) {
        (<AutocompleteUiComponent>componentRef.instance).itemData = child;
      }
    });
  }
}
