import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IupicsCookieService, LocalStorageIupics } from '@iupics-manager/managers/security-manager/cookies/iupics-cookie.service';
import { UserAccount } from '@login-page/models/user-account.';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'iu-account-selector',
  templateUrl: './account-selector.component.html',
  styleUrls: ['./account-selector.component.scss']
})
export class AccountSelectorComponent implements OnInit {
  @ViewChild('dropDownContent', { static: true })
  dropDownContent: ElementRef;
  @Input()
  accounts: UserAccount[];
  defaultAccount: UserAccount;
  isDisplayContent = false;

  @Output()
  clickOnAccount: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  clickAnotherAccount: EventEmitter<any> = new EventEmitter<any>();

  anotherAccountObj;

  constructor(private translateService: TranslateService, private cookieService: IupicsCookieService) {}

  ngOnInit() {
    if (this.cookieService.checkLS(LocalStorageIupics.last_connected_user)) {
      const userAccount = <UserAccount>(
        JSON.parse(this.cookieService.getDecryptedLocalStorage(LocalStorageIupics.last_connected_user))
      );
      this.defaultAccount = userAccount;
      this.accounts = this.accounts.filter(account => account.id !== userAccount.id);
    } else {
      this.defaultAccount = this.accounts[0];
      this.accounts = this.accounts.slice(1);
    }
    this.defaultAccount.showCarret = true;
  }

  displayDropdown(event) {
    this.isDisplayContent = true;
    this.anotherAccountObj = {
      id: -1,
      login: this.translateService.instant('homepage.anotheraccount'),
      isAnotherAccount: true
    };
    event.stopPropagation();
  }

  hideDropdown() {
    this.isDisplayContent = false;
  }

  changeAccount(event, account: UserAccount) {
    this.hideDropdown();
    for (let i = 0; i < this.accounts.length; i++) {
      if (this.accounts[i].id === account.id) {
        this.accounts[i] = this.defaultAccount;
        break;
      }
    }
    this.defaultAccount = account;
    this.clickOnAccount.emit(account);
    event.stopPropagation();
  }

  anotherAccount() {
    this.hideDropdown();
    this.clickAnotherAccount.emit();
    event.stopPropagation();
  }

  @HostListener('document:click', ['$event'])
  clickonLoginPage() {
    if (this.isDisplayContent) {
      this.hideDropdown();
    }
  }
}
