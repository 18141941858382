import { CompiereMenu } from '@compiere-ws/models/compiere-menu-json';
import { WidgetFavoriteCompiereJson } from '@compiere-ws/models/widget-center-json';
import { IupicsMenuType, MenuCategoryUI, MenuFavoritesCategoryUI, MenuItemUI } from '@web-desktop/models/menu-item-ui';
import { cloneDeep } from 'lodash';

export const cssClasses = {
  Window: 'icon-ico-window-2',
  Process: 'icon-ico-process-cogs-3',
  Report: 'icon-ico-graph-3',
  Form: 'icon-ico-super-power-2',
  Workflow: 'icon-ico-code-branch-3'
};

export class MenuCreatorUtils {
  public static transformMenus(
    iupicsMenuFavoritesCategories: MenuFavoritesCategoryUI[],
    menus: CompiereMenu[],
    isMenuFavorite = false
  ): MenuItemUI[] {
    if (menus) {
      const menusTransformed: MenuItemUI[] = [];
      const menuCategories: Map<String, MenuCategoryUI> = new Map();
      let filteredMenus: CompiereMenu[];
      if (!isMenuFavorite) {
        filteredMenus = menus.filter((menu) => menu.level > 0 && !menu.isSummary);
      } else {
        filteredMenus = menus.filter((menu) => menu.level > 0);
      }
      filteredMenus.forEach((menu) => {
        if (isMenuFavorite && menu.isSummary) {
          // pour les favoris on prépare la liste des catégories
          iupicsMenuFavoritesCategories.push({ id: menu.menuId, name: menu.name, seqNo: menu.seqNo });
        } else if (!menu.isSummary) {
          if (!menu.icon) {
            menu.icon = cssClasses[menu.menuAction.type];
          }

          let menuCategory: MenuCategoryUI;
          if (menu.category) {
            menuCategory = menuCategories.get(menu.category);
            if (!menuCategory) {
              const cat = menus.find((m) => m.name === menu.category);
              const newCategory: MenuCategoryUI = {
                id: 0,
                name: menu.category,
                isSelected: false,
                icon: cat.icon,
                isDisplay: true
              };
              menuCategories.set(menu.category, newCategory);
              menuCategory = newCategory;
            }
          }

          const newMenu: MenuItemUI = {
            menu_id: menu.menuId,
            menu_category: menuCategory,
            menu_type: menu.menuAction.type as IupicsMenuType,
            action_id: menu.menuAction.actionID,
            iconClass: menu.icon,
            name: menu.name,
            tags: menu.tags,
            type: 'shortcut',
            color: menu.cssColor,
            isSummary: menu.isSummary,
            parentId: menu.parentId,
            seqNo: menu.seqNo,
            angularClass: menu.angularClass
          };
          menusTransformed.push(newMenu);
        }
      });

      if (!isMenuFavorite) {
        return menusTransformed.sort((m1, m2) => (m1.name < m2.name ? -1 : m1.name > m2.name ? 1 : 0));
      } else {
        return menusTransformed;
      }
    } else {
      return [];
    }
  }

  public static transformWidgets(
    iupicsWidgetFavoritesCategories: MenuFavoritesCategoryUI[],
    widgets: WidgetFavoriteCompiereJson[],
    isWidgetFavorite = false
  ): WidgetFavoriteCompiereJson[] {
    if (widgets) {
      const widgetsTransformed: WidgetFavoriteCompiereJson[] = [];
      const menuCategories: Map<String, MenuCategoryUI> = new Map();
      let filteredMenus: WidgetFavoriteCompiereJson[];
      if (!isWidgetFavorite) {
        filteredMenus = widgets.filter((widget) => widget.menu.level > 0 && !widget.menu.isSummary);
      } else {
        filteredMenus = widgets.filter((menu) => menu.menu.level > 0);
      }
      filteredMenus.forEach((widget) => {
        if (isWidgetFavorite && widget.menu.isSummary) {
          iupicsWidgetFavoritesCategories.push({ id: widget.menu.menuId, name: widget.menu.name, seqNo: widget.menu.seqNo });
        } else if (!widget.menu.isSummary) {
          if (!widget.menu.icon) {
            widget.menu.icon = cssClasses[widget.menu.menuAction.type];
          }

          let menuCategory: MenuCategoryUI;
          if (widget.menu.category) {
            menuCategory = menuCategories.get(widget.menu.category);
            if (!menuCategory) {
              const cat = widgets.find((m) => m.menu.name === widget.menu.category);
              const newCategory: MenuCategoryUI = {
                id: 0,
                name: widget.menu.category,
                isSelected: false,
                icon: cat.menu.icon,
                isDisplay: true
              };
              menuCategories.set(widget.menu.category, newCategory);
              menuCategory = newCategory;
            }
          }

          const newMenu: WidgetFavoriteCompiereJson = cloneDeep(widget);
          widgetsTransformed.push(newMenu);
        }
      });

      if (!isWidgetFavorite) {
        return widgetsTransformed.sort((m1, m2) => (m1.menu.name < m2.menu.name ? -1 : m1.menu.name > m2.menu.name ? 1 : 0));
      } else {
        return widgetsTransformed;
      }
    } else {
      return [];
    }
  }
}
