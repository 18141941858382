<span
  [pTooltip]="tooltip"
  life="3"
  tooltipPosition="top"
  showDelay="500"
  tooltipZIndex="2100"
  style="margin-top: 0.7em"
  [style.display]="displayCss"
  [style.margin-top.px]="data && data.marginTop > 0 ? data.marginTop : null"
>
  <button
    #btn
    pButton
    class="{{ isStandalone ? 'p-button ' + type : 'button-field p-button-secondary' }}"
    icon="{{ icon || '' }}"
    type="button"
    [label]="label"
    [disabled]="isReadOnly"
    data-cy="input-button"
    [attr.data-cy-columnName]="data ? data.columnName : columnName"
    [attr.data-cy-channelId]="channelId"
  ></button>
</span>
