import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';
import { WebDesktopService } from '@web-desktop/controllers/web-desktop.service';
import { IupicsComponentType, IupicsContextMenuComponent } from '@web-desktop/models/iupics-context-menu';
import { DomHandler } from 'primeng/dom';

@Component({
  selector: 'wd-task-bar-ui',
  templateUrl: './task-bar-ui.component.html',
  styleUrls: ['./task-bar-ui.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TaskBarComponent implements OnInit, IupicsContextMenuComponent {
  @ViewChild('toolbarLeft', { static: true })
  toolbarLeft: ElementRef;

  @Input()
  items: any[] = [];
  @Output()
  windowDisplayUpdate: EventEmitter<any> = new EventEmitter();

  scrollableStyle = {
    height: '35%'
  };

  savedIndex: number;

  componentType: IupicsComponentType = IupicsComponentType.PIN_DIALOG_UI;

  constructor(public cmService: ContextMenuService, private webDesktopService: WebDesktopService) {}

  ngOnInit() {}

  onContextMenu(event: Event) {
    event.preventDefault();
  }

  displayWindow(event: Event, index: number) {
    this.windowDisplayUpdate.emit({
      originalEvent: event,
      index: index
    });
  }

  onWheel(event, element): void {
    event.preventDefault();
    const height = DomHandler.getInnerHeight(element);
    element.scrollTop += event.deltaY >= 0 ? height : -height;
  }

  showPinOptions(event, index) {
    this.cmService.showContextMenu(this, this.handleEvent.bind(this), event);
    this.savedIndex = index;
  }

  handleEvent(event: any) {
    if (event.item.id === 'pin-close-window') {
      this.webDesktopService.triggerEvents({ index: this.savedIndex }, IupicsTypeEvent.INIT_CLOSE_WINDOW);
    }
  }
}
