import { Injectable } from '@angular/core';
import { DataStoreKey, DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { RecentItem } from '@compiere-ws/models/compiere-recent-item';
import { CompiereRecentItemService } from '@compiere-ws/services/compiere-recent-item/compiere-recent-item.service';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DataStoreService } from '../data-store/data-store.service';

@Injectable()
export class RecentItemsManagerService {
  constructor(private recentItemService: CompiereRecentItemService, private datastoreService: DataStoreService) {}

  getRecentItems(): Observable<RecentItem[]> {
    return this.recentItemService.getRecentItems();
  }

  addRecentItem(request: DataStoreRequest, label: string): Observable<boolean> {
    return this.datastoreService.getWindowSingleData(request).pipe(
      switchMap((ds) => {
        const item = new RecentItem(`${label}: ${this.getInfoLabel(ds.data)}`, ds.key.tabId, ds.key.windowId, ds.data.Data_UUID);
        return this.recentItemService.addRecentItem(item);
      })
    );
  }

  deleteRecentItems(dsKey: DataStoreKey, recordIds: any[]) {
    return this.recentItemService.deleteRecentItems(dsKey.windowId, dsKey.tabId, recordIds);
  }

  private getInfoLabel(data: any): string {
    if (data.Name !== undefined) {
      return data.Name;
    }

    if (data.DocumentNo !== undefined) {
      return data.DocumentNo;
    }

    return data.Data_UUID;
  }
}
