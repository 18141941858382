<ng-container *ngIf="recentItems$ | async as recentItems; riLoading">
  <cdk-virtual-scroll-viewport itemSize="40" class="recent-items">
    <ng-container *cdkVirtualFor="let item of recentItems; templateCacheSize: 100; let first = first; let last = last">
      <div
        [ngClass]="[first ? 'recent-item-first' : '', last ? 'recent-item-last' : '', 'recent-item']"
        (click)="openTab($event, item)"
      >
        {{ item.name }}
      </div>
    </ng-container>
  </cdk-virtual-scroll-viewport>
</ng-container>

<ng-template #riLoading>
  <cdk-virtual-scroll-viewport itemSize="40" class="recent-items">
    <ng-container *cdkVirtualFor="let item of fakeRI; let first = first; let last = last">
      <div [ngClass]="[first ? 'recent-item-first' : '', last ? 'recent-item-last' : '', 'recent-item']">{{ item.name }}</div>
    </ng-container>
  </cdk-virtual-scroll-viewport>
</ng-template>
