<div
  [ngClass]="[!isModal ? 'iu-specific-window-container' : '', 'iu-specific-window fast']"
  [attr.data-cy-specific-window-name]="name"
>
  <p-progressBar
    *ngIf="!isModal && (isQrInfoLoading || isLoading)"
    [mode]="'indeterminate'"
    [value]="0"
    [showValue]="false"
    [styleClass]="'loading-progressbar'"
  ></p-progressBar>
  <div class="menu-container">
    <h1>{{ 'specificWindow.quickInvoiceVendor.title' | translate }} ( {{ docStatus }} )</h1>
    <a
      *ngIf="dataStore && dataStore.data && dataStore.data['C_Invoice_ID']"
      class="settings attachFile"
      (mouseup)="updateModalDisplay({ key: 'displayJoinFilesPanel', value: true })"
      data-cy="button-attachFile"
    >
      <i class="icon-ico-attach3x">
        <span *ngIf="nbUploadedFiles !== undefined && nbUploadedFiles > 0" class="iupics-badge iupics-badge-count">{{
          nbUploadedFiles > 9 ? '9+' : nbUploadedFiles
        }}</span></i
      >
    </a>
    <a class="settings" (click)="isSidebarOpen = !isSidebarOpen"><i class="fas fa-cog"></i></a>
  </div>

  <div class="quickInvoiceMainContainer">
    <div class="quickInvoiceContainer panelLeft">
      <textarea
        #qrInfoArea
        class="quickInvoiceQRInfo"
        (focus)="clearQRArea(); setPlaceHolder(true)"
        (blur)="setQRInfo(); setPlaceHolder()"
        autocomplete="no"
        autoResize="autoResize"
        placeholder="{{ placeHolder }}"
        [(ngModel)]="qrInfoString"
        [ngClass]="{
          quickInvoiceQRInfoEmpty: !qrInfoString || qrInfoString.length === 0,
          hasError: (qrInfoStringLast || qrInfoString) && qrInfo && qrInfo.logicalErrors > 0
        }"
      ></textarea>
      <label class="quickInvoiceLogs-header">
        {{ 'specificWindow.quickInvoiceVendor.invoiceLogTitle' | translate
        }}<span *ngIf="qrInfo && qrInfo.logicalWarnings > 0" class="quickInvoiceLogs-header-warning-cpt"
          >({{ qrInfo.logicalWarnings }})</span
        ></label
      >
      <div
        #logsContainer
        class="quickInvoiceLogs"
        [ngClass]="{
          hasError: (qrInfoStringLast || qrInfoString) && qrInfo && qrInfo.logicalErrors > 0,
          isSuccess:
            dataStore &&
            dataStore.data &&
            dataStore.data['C_BPartner_ID'] &&
            !isQrInfoLoading &&
            qrInfoStringLast &&
            qrInfo &&
            qrInfo.logicalErrors === 0 &&
            qrInfo.logicalWarnings === 0
        }"
      >
        <ng-container *ngFor="let log of logs">
          <div
            class="quickInvoiceLogs-item"
            [ngClass]="{ 'quickInvoiceLogs-error': !log.isSuccess, 'quickInvoiceLogs-title': log.type === 'title' }"
          >
            {{ log.value }}
          </div>
        </ng-container>
      </div>
    </div>
    <p-scrollPanel class="quickInvoiceProcessPanel" [style]="{ width: '100%', height: '100%' }">
      <div id="page-wrap">
        <div id="customer">
          <div class="row">
            <table class="metaleft p-col-12 p-md-12 p-lg-4" id="meta">
              <tr>
                <td class="meta-head">{{ 'specificWindow.quickInvoiceVendor.vendor' | translate }}</td>
                <td class="p-grid"><ng-template #vcrBPartner></ng-template></td>
              </tr>
              <tr>
                <td class="meta-head">{{ 'specificWindow.quickInvoiceVendor.vendorLocation' | translate }}</td>
                <td class="p-grid">
                  <ng-template #vcrBPartnerLocation></ng-template>
                </td>
              </tr>
              <tr>
                <td class="meta-head">{{ 'specificWindow.quickInvoiceVendor.amountToPay' | translate }}</td>
                <td class="p-grid">
                  <iu-input-number-ui
                    [isStandalone]="true"
                    [fieldValue]="this.amountToPay"
                    label=""
                    [data]="grandTotalData"
                    [currency]="currency"
                    [cssClass]="'p-col-12'"
                  ></iu-input-number-ui>
                </td>
              </tr>
              <tr>
                <td class="meta-head">{{ 'specificWindow.quickInvoiceVendor.vendorIBAN' | translate }}</td>
                <td class="p-grid">
                  <ng-template #vcrBPBankAccount></ng-template>
                </td>
              </tr>
            </table>
            <table class="metaright p-col-12 p-md-12 p-lg-8" id="meta">
              <tr>
                <td class="meta-head">{{ 'specificWindow.quickInvoiceVendor.order' | translate }}</td>
                <td class="p-grid"><ng-template #vcrOrder></ng-template></td>
                <td class="meta-head">{{ 'specificWindow.quickInvoiceVendor.dateInvoiced' | translate }}</td>
                <td class="p-grid"><ng-template #vcrDateInvoiced></ng-template></td>
              </tr>
              <tr>
                <td class="meta-head">{{ 'specificWindow.quickInvoiceVendor.poReference' | translate }}</td>
                <td colspan="3"><ng-template #vcrDescriptionHeader></ng-template></td>
              </tr>
              <tr>
                <td class="meta-head">{{ 'specificWindow.quickInvoiceVendor.BVRNumber' | translate }}</td>
                <td class="p-grid"><ng-template #vcrBVR></ng-template></td>
                <td class="meta-head">{{ 'specificWindow.quickInvoiceVendor.validator' | translate }}</td>
                <td class="p-grid"><ng-template #vcrValidator></ng-template></td>
              </tr>
            </table>
          </div>
        </div>
        <div class="total-header p-col-12 p-md-12 p-lg-12" id="meta">
          <div class="meta-block p-col-12 p-md-6 p-lg-4">
            <div class="meta-head p-col-12 p-md-4 p-lg-4">
              {{ 'specificWindow.quickInvoiceVendor.grandNetTotal' | translate }}
            </div>
            <div class="meta-body p-col-12 p-md-8 p-lg-8">
              <iu-input-number-ui
                [isStandalone]="true"
                [isReadOnly]="true"
                [fieldValue]="this.grandNetTotal"
                label=""
                [data]="grandTotalData"
                [currency]="currency"
                [cssClass]="'p-col-12'"
              ></iu-input-number-ui>
            </div>
          </div>
          <div class="meta-block p-col-12 p-md-6 p-lg-4">
            <div class="meta-head p-col-12 p-md-4 p-lg-4">
              {{ 'specificWindow.quickInvoiceVendor.grandVatTotal' | translate }}
            </div>
            <div class="meta-body p-col-12 p-md-8 p-lg-8">
              <iu-input-number-ui
                [isStandalone]="true"
                [isReadOnly]="true"
                [fieldValue]="this.grandVatTotal"
                label=""
                [data]="grandTotalData"
                [currency]="currency"
                [cssClass]="'p-col-12'"
              ></iu-input-number-ui>
            </div>
          </div>
          <div class="meta-block p-col-12 p-md-6 p-lg-4">
            <div class="meta-head p-col-12 p-md-4 p-lg-4">{{ 'specificWindow.quickInvoiceVendor.grandTotal' | translate }}</div>
            <div class="meta-body p-col-12 p-md-8 p-lg-8">
              <iu-input-number-ui
                [isStandalone]="true"
                [isReadOnly]="true"
                [fieldValue]="this.grandTotal"
                label=""
                [data]="grandTotalData"
                [currency]="currency"
                [cssClass]="'p-col-12'"
              ></iu-input-number-ui>
            </div>
          </div>
          <div class="meta-block p-col-12 p-md-6 p-lg-12">
            <div class="meta-body button p-col-12 p-md-12 p-lg-12">
              <ng-template #vcrCreateFrom></ng-template>
              <button
                *ngIf="!isProcessed"
                [ngClass]="['button p-button-error cancelButton']"
                (click)="$event.preventDefault(); $event.stopPropagation(); cancelDoc()"
                pButton
                label=" {{ 'specificWindow.quickInvoiceVendor.cancel' | translate }}"
                icon="fas fa-ban"
              ></button>
              <button
                [ngClass]="[
                  isProcessed ||
                  (dataStore &&
                    dataStore.data &&
                    (!dataStore.data['C_Invoice_ID'] ||
                      (dataStore.data['DocStatus'] &&
                        (dataStore.data['DocStatus'] === 'CO' || dataStore.data['DocStatus'].id === 'CO'))))
                    ? 'disabledButton'
                    : '',
                  'button p-button-success saveButton'
                ]"
                (click)="$event.preventDefault(); $event.stopPropagation(); saveInvoice()"
                pButton
                label="{{ 'specificWindow.quickInvoiceVendor.save' | translate }}"
                icon="{{
                  dataStore &&
                  dataStore.data['DocStatus'] &&
                  (dataStore.data['DocStatus'] === 'CO' || dataStore.data['DocStatus'].id === 'CO')
                    ? 'fa fa-ban'
                    : 'fa fa-check'
                }}"
              ></button>
              <button
                *ngIf="isProcessed"
                pButton
                class="button p-button-warning"
                (click)="$event.preventDefault(); $event.stopPropagation(); resetInvoice()"
                label="{{ 'specificWindow.quickInvoiceVendor.newInvoice' | translate }}"
                icon="fa fa-plus"
              ></button>
            </div>
          </div>
        </div>
        <div class="items-container">
          <table id="items">
            <thead>
              <tr>
                <th>{{ translations.product }}</th>
                <th>{{ translations.charge }}</th>
                <th>{{ translations.quantity }}</th>
                <th>{{ translations.priceEntered }}</th>
                <th>{{ translations.priceList }}</th>
                <th>{{ translations.taxAmt }}</th>
                <th>{{ translations.tax }}</th>
                <th>{{ translations.totalLine }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let item of dataLines.length + 1 | fakeArray; let i = index">
                <tr class="item-row">
                  <td [attr.data-label]="translations.product">
                    <ng-template #vcrProduct></ng-template>
                  </td>
                  <td [attr.data-label]="translations.charge">
                    <ng-template #vcrCharge></ng-template>
                  </td>
                  <td [attr.data-label]="translations.quantity">
                    <ng-template #vcrQty></ng-template>
                  </td>
                  <td [attr.data-label]="translations.priceEntered">
                    <ng-template #vcrPriceEntered></ng-template>
                  </td>
                  <td [attr.data-label]="translations.priceList">
                    <ng-template #vcrPriceList></ng-template>
                  </td>
                  <td [attr.data-label]="translations.taxAmt">
                    <ng-template #vcrTaxAmt></ng-template>
                  </td>
                  <td [attr.data-label]="translations.tax">
                    <ng-template #vcrTax></ng-template>
                  </td>
                  <td [attr.data-label]="translations.totalLine">
                    <ng-template #vcrLineNetAmt></ng-template>
                  </td>
                  <div style="display: none"><ng-template #vcrOthers></ng-template></div>
                  <td class="deleteButton" data-label="">
                    {{ item }}
                    <button
                      *ngIf="i < $any(dataLines).length"
                      [ngClass]="[
                        dataStore &&
                        dataStore.data['DocStatus'] &&
                        (dataStore.data['DocStatus'] === 'CO' || dataStore.data['DocStatus'].id === 'CO')
                          ? 'disabledButton'
                          : ''
                      ]"
                      tooltipPosition="top"
                      showDelay="250"
                      life="3"
                      pTooltip="{{ 'generic.delete' | translate }}"
                      pButton
                      icon="icon-ico-delete"
                      (click)="removeLine(dataLines[i], i)"
                    ></button>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
        <div id="hiderow">
          <button
            pButton
            id="addrow"
            [ngClass]="[
              dataStore &&
              (!dataStore.data['C_Invoice_ID'] ||
                (dataStore.data['DocStatus'] &&
                  (dataStore.data['DocStatus'] === 'CO' || dataStore.data['DocStatus'].id === 'CO')))
                ? 'disabledButton'
                : '',
              'button p-button-secondary'
            ]"
            (click)="newLine()"
            (focus)="newLine()"
            label="{{ 'specificWindow.quickInvoiceVendor.addrow' | translate }}"
            icon="icon-ico-add"
          ></button>
        </div>
      </div>
      <div style="height: 70px"></div>
    </p-scrollPanel>
  </div>

  <p-sidebar
    [(visible)]="isSidebarOpen"
    [baseZIndex]="6000"
    position="right"
    styleClass="p-sidebar-md fast-create-invoice-vendor"
  >
    <h1 class="iu-notification-center-title">
      {{ 'specificWindow.quickInvoiceVendor.settings' | translate }}
    </h1>
    <ng-template #vcrSettings></ng-template>
  </p-sidebar>
</div>
<ng-container *ngIf="displaySearch">
  <iu-modal-ui
    data-cy="search-panel"
    [attr.data-cy-columnName]="this.searchLinkedComponent.autoComplete.data.columnName"
    [title]="this.searchLinkedComponent.autoComplete.label"
    [hasCloseBtn]="true"
    (closeModalEmitter)="displaySearch = false"
  >
    <div class="autocomplete-universal-filter">
      <iu-universal-filter-ui
        [tabId]="-1"
        [columns]="searchLinkedComponent.autoComplete.data.searchColumns"
        [gridTab]="gridTab"
      ></iu-universal-filter-ui>
    </div>
    <iu-grid-tab-infinity-scroll-ui
      #gridTab
      (searchEmitter)="this.searchLinkedComponent.setSearchSelectItem($event); displaySearch = false"
      [data]="this.searchLinkedComponent.autoComplete.data"
      [container]="this"
      [isSearch]="true"
      [dataStored]="this.searchLinkedComponent.autoComplete.dataStored"
    ></iu-grid-tab-infinity-scroll-ui>
  </iu-modal-ui>
</ng-container>
<div [ngClass]="[!displayFormUI ? 'hidden' : '', 'specificModal']">
  <iu-modal-ui
    [title]="specificWindowTitle"
    [isModalDisplay]="displayFormUI"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false })"
  >
    <ng-template #vcrSpecific></ng-template>
  </iu-modal-ui>
</div>
<ng-container *ngIf="displayJoinFilesPanel">
  <iu-modal-ui
    [title]="'joinFiles.title'"
    [hasCloseBtn]="true"
    [contentType]="'joinFiles'"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayJoinFilesPanel', value: false })"
  >
    <iu-join-file-ui [dsKey]="dsKey" [adTable_ID]="tableID" [(nbUploadedFiles)]="nbUploadedFiles" [data]="data"></iu-join-file-ui>
  </iu-modal-ui>
</ng-container>
