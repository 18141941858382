<div class="gantt-container">
  <div class="gantt-header" #ganttHeader>
    <button *ngIf="showCollapseButton" class="gantt-header-btn" (click)="updateDisplayStatus(false)">
      <i class="fas fa-minus-square"></i>
    </button>
    <button *ngIf="showExpandButton" class="gantt-header-btn" (click)="updateDisplayStatus(true)">
      <i class="fas fa-plus-square"></i>
    </button>
    <button *ngIf="showUndoButton" class="gantt-header-btn" (click)="gantt.undo()">
      <i class="fas fa-undo"></i>
    </button>
    <button *ngIf="showRedoButton" class="gantt-header-btn" (click)="gantt.redo()">
      <i class="fas fa-redo"></i>
    </button>
    <button *ngIf="showZoomInButton" class="gantt-header-btn" (click)="gantt.ext.zoom.zoomIn()">
      <i class="fas fa-search-plus"></i>
    </button>
    <button *ngIf="showZoomOutButton" class="gantt-header-btn" (click)="gantt.ext.zoom.zoomOut()">
      <i class="fas fa-search-minus"></i>
    </button>
    <button
      [disabled]="!paginationEnable || paginationInProgress"
      class="gantt-header-btn"
      (click)="handlePageBtn($event, 'prev')"
    >
      <i class="fas fa-step-backward"></i>
    </button>
    <div class="gantt-header-item">
      {{
        maximumPage === -1
          ? ('gantt.more-page' | translate: { actualPage: actualPage })
          : ('gantt.fixed-page' | translate: { actualPage: actualPage, maximumPage: maximumPage })
      }}
    </div>
    <button
      [disabled]="!paginationEnable || paginationInProgress"
      class="gantt-header-btn"
      (click)="handlePageBtn($event, 'next')"
    >
      <i class="fas fa-step-forward"></i>
    </button>
    <!-- <button class="gantt-header-btn" (click)="printToConsole()">
      <i class="fas fa-laptop-code"></i>
    </button> -->
    <div class="gantt-universal-filter" *ngIf="hasUniversalFilter">
      <iu-universal-filter-ui
        [isStandalone]="uf_isStandalone"
        [tabId]="uf_formId"
        [formId]="uf_formId"
        [windowType]="'Form'"
        [setFilterEmitter]="setFilterEmitter"
        (filterChange)="onFilterChange($event)"
        [canFilter]="true"
        [canGroup]="false"
        [canSort]="true"
      ></iu-universal-filter-ui>
    </div>
  </div>
  <div #ganttDiv [ngStyle]="{ height: ganttHeight - ganttHeader.clientHeight + 'px' }" class="gantt-chart"></div>
</div>
