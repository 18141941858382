import { Injectable } from '@angular/core';
import { CompiereLanguage } from '@compiere-ws/models/compiere-language-json';
import { CompiereLanguageService } from '@compiere-ws/services/compiere-language/compiere-language.service';
import { AppConfig } from '@iupics-config/app.config';
import { IupicsCookieService, LocalStorageIupics } from '@iupics-manager/managers/security-manager/cookies/iupics-cookie.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { Global } from '@iupics-manager/models/global-var';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageSelectionService {
  languages_isoCode: string[] = [];

  constructor(
    private cookieService: IupicsCookieService,
    private connectorService: SecurityManagerService,
    private languageService: CompiereLanguageService,
    private translate: TranslateService,
    private config: AppConfig
  ) {}

  getLanguages(): Observable<CompiereLanguage[]> {
    return this.languageService.getSystemLanguage();
  }

  initLanguages(): CompiereLanguage {
    this.translate.addLangs(this.languages_isoCode);

    // 1. Initilisation de la langue par défaut par celle du browser si 'fr' ou 'en'
    let defaultLanguage: CompiereLanguage = this.getDefaultLanguage();

    // 2. Si un cookie avec la langue existe, on récupère celle-ci
    if (this.cookieService.checkLS(LocalStorageIupics.default_language)) {
      defaultLanguage = <CompiereLanguage>(
        JSON.parse(this.cookieService.getDecryptedLocalStorage(LocalStorageIupics.default_language))
      );
    }
    this.translate.setDefaultLang(defaultLanguage.iso_code);
    this.changeLanguage(defaultLanguage);
    this.connectorService.setIupicsDefaultLanguage(defaultLanguage);
    this.cookieService.setAndEncryptLocalStorage(LocalStorageIupics.default_language, JSON.stringify(defaultLanguage));
    return defaultLanguage;
  }

  getDefaultLanguage(): CompiereLanguage {
    // 1. Initilisation de la langue par défaut par en_GB
    let defaultLanguage: CompiereLanguage = {
      id: 'en_GB',
      displayValue: 'en_GB',
      iso_code: 'en_GB'
    };

    //2. Si la langue par défaut de browser est à 'fr_XX' ou 'fr', on utilise fr_FR
    const browserLang = navigator.language.substring(0,2);
    if(browserLang == 'fr') {
      defaultLanguage = {
        id: 'fr_FR',
        displayValue: 'fr_FR',
        iso_code: 'fr_FR'
      };
    }
    return defaultLanguage;
  }

  changeLanguage(language: CompiereLanguage): boolean {
    this.translate.use(language.iso_code);
    this.connectorService.setIupicsDefaultLanguage(language);
    this.cookieService.setAndEncryptLocalStorage(LocalStorageIupics.default_language, JSON.stringify(language));
    document.documentElement.lang = language.iso_code.split('_').shift();
    Global.resetCachedData();
    return true;
  }

  getCurrentLanguage(): CompiereLanguage {
    if (this.cookieService.checkLS(LocalStorageIupics.default_language)) {
      return JSON.parse(this.cookieService.getDecryptedLocalStorage(LocalStorageIupics.default_language));
    } else if (this.connectorService.getIupicsDefaultLanguage()) {
      return cloneDeep(this.connectorService.getIupicsDefaultLanguage());
    } else if (this.connectorService.getIupicsUserAccount().default_language) {
      return cloneDeep(this.connectorService.getIupicsUserAccount().default_language);
    }
    return undefined;
  }
}
