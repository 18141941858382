<div #specificContainer class="iu-specific-window iu-specific-window-container" [attr.data-cy-specific-window-name]="name">
  <p-progressBar
    *ngIf="!isModal && isLoading"
    [mode]="'indeterminate'"
    [value]="0"
    [showValue]="false"
    [styleClass]="'loading-progressbar'"
  ></p-progressBar>
  <h1>{{ name }}</h1>
  <p-scrollPanel [style]="{ width: '100%', height: 'calc(100% - 30px)' }">
    <iu-process-in-progress-ui [activeTab]="activeTab"></iu-process-in-progress-ui>
  </p-scrollPanel>
</div>
