import { AfterViewInit, Component, ComponentFactoryResolver, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { EditViewUiComponent } from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateService } from '@ngx-translate/core';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';
import { AutocompleteUiComponent } from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';

@Component({
  selector: 'iu-product-view-mfg',
  templateUrl: './product-view-mfg.component.html',
  styleUrls: ['./product-view-mfg.component.scss'],
})
export class ProductViewMFGComponent extends SpecificWindowUiComponent implements OnInit {
  @ViewChild('vcrHidden', { read: ViewContainerRef, static: true })
  vcrHidden: ViewContainerRef;
  constructor(
    protected windowFactory: WindowFactoryService,
    protected resolver: ComponentFactoryResolver,
    protected uiCreator: UICreatorService,
    protected store: DataStoreService,
    protected processService: CompiereProcessService,
    protected socketService: SocketService,
    protected connectorService: SecurityManagerService,
    protected progressService: ProcessInProgressService,
    protected translateService: TranslateService,
    protected messageManager: MessageManagerService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
  }
 // @Input()
  //isReadOnly = false;
  ngOnInit() {
    // @start_custo_code
    if (this.parentComponent instanceof EditViewUiComponent) {
      if (!this.dataStore) {
        this.dataStore = this.store.newSpecificWindowData(
          this.formId,
          (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored
        );
      }
    }
    // @end_custo_code
    this.subscriptions.push(
      this.uiCreator.getSpecificWindow(this.formId).subscribe((specificWindow) => {
        specificWindow.items.forEach((item) => {
          switch (item.data.columnName) {
            case 'Price_History_Button':
              this.customDesignArray.push({
                vcr: 'vcrHidden',
                type: CustomDesignItemType.FIELD,
                columnName: item.data.columnName,
                cssClass: 'p-lg-3 p-md-3 p-col-12',
              });
              break;
            case 'Product Info MFG Table':
              this.customDesignArray.push({
                vcr: 'vcr',
                type: CustomDesignItemType.GRID,
                tableName: item.name,
                cssClass: item.cssClass,
              });
              break;
            default:
              if (item.component === 'GridViewUiComponent') {
                this.customDesignArray.push({
                  vcr: 'vcr',
                  type: CustomDesignItemType.GRID,
                  tableName: item.name,
                  cssClass: item.cssClass,
                });
              } else if (item.component === 'ButtonUiComponent') {
                this.customDesignArray.push({
                  vcr: 'vcr',
                  type: CustomDesignItemType.FIELD,
                  columnName: item.data.columnName,
                  cssClass: 'p-col-12 p-md-4 p-lg-2',
                });
              } else {
                let vcr = 'vcr';
                if (item.data.IsOptional) {
                  vcr = 'vcrOptional';
                }
                this.customDesignArray.push({
                  vcr: vcr,
                  type: CustomDesignItemType.FIELD,
                  columnName: item.data.columnName,
                  cssClass: 'p-col-12 p-md-4 p-lg-2',
                });
              }
              break;
          }
        });
        this.showSpecificWindow();
        if (this.gridViews) {
          const gridFound = this.gridViews.find((grid) => grid.data.columnName === 'Product Info MFG Table');
          let validationCode = null;
          validationCode = gridFound.data.validationCode;
          if (gridFound.data.validationCode) {
            gridFound.data.validationCode = validationCode.replaceAll('M_Product','Product_Info_MFG');
          }
        }
      })
    );
  }
  showPriceHistoryForm() {
    const selections = this.getGridSelection('Product Info MFG Table');
    const button = this.getDatacontainer('Price_History_Button');
    const gridFound = this.gridViews.find((grid) => grid.data.columnName === 'Product Info MFG Table');
    if (gridFound && selections && selections.length > 0) {
     if(selections[0]['MFG_Product_ID'] > 0 && 
        (!selections[0]['M_Product_ID'] || (selections[0]['M_Product_ID'] && 
        (!selections[0]['M_Product_ID'].id || selections[0]['M_Product_ID'].id < 0))))
      {
      this.messageManager.newMessage(
        new IupicsMessage(this.translateService.instant('specificWindow.productViewMFG.priceHistoryERR'), 'error')
      );
      } else {
        this.updateModalDisplay(
          {
            key: 'displayFormUI',
            value: true,
            sourceComponent: gridFound.GridTabInfinityScrollUiComponent,
          },
          { key: 'formId', value: button.data['formId'] }
        );
      }
    } else {
      this.messageManager.newMessage(
        new IupicsMessage(
          this.translateService.instant('infodialog.dialogs.noLineSelected.title'),
          this.translateService.instant('infodialog.dialogs.noLineSelected.message'),
          'warning'
        )
      );
    }
  }

  setSelectedRowOnAutocomplete() {
    let selections = [];
    const gridFound = this.gridViews.find((grid) => grid.data.columnName === 'Product Info MFG Table');
    if (gridFound) {
      selections = gridFound.GridTabInfinityScrollUiComponent.agGrid.api.getSelectedRows();
    }
    if (selections && selections.length > 0 && this.parentComponent instanceof EditViewUiComponent && (<EditViewUiComponent>this.parentComponent).editTabs && (<EditViewUiComponent>this.parentComponent).editTabs[0] && (<EditViewUiComponent>this.parentComponent).editTabs[0].dataContainers) {
        
      const mfgProduct = (<EditViewUiComponent>this.parentComponent).editTabs[0].dataContainers.find((datacontainer) => datacontainer.DOMParentComponent.DOMChildrenComponent[0].data.columnName === 'MFG_Product_ID');
       
        if(selections[0]['MFG_Product_ID'] > 0 && (!selections[0]['M_Product_ID'] || (selections[0]['M_Product_ID'] && (
           !selections[0]['M_Product_ID'].id || selections[0]['M_Product_ID'].id < 0)))){
          const event = { data: { Data_UUID: 'MFG_Product_ID' + ',' + selections[0]['MFG_Product_ID']  } };
          (<AutocompleteUiComponent>this.sourceComponent).dataChange(null);
          (<AutocompleteUiComponent>mfgProduct).setSearchSelectItem(event);
          
        } else {
          this.addContextDataToParentStore(
            selections[0],
            this.connectorService.getConfig().getConstant('ContextPrefixSearchWindow')
          );
          const event = { data: { Data_UUID: 'M_Product_ID' + ',' + selections[0]['M_Product_ID'] } };
          (<AutocompleteUiComponent>mfgProduct).dataChange(null);
          (<AutocompleteUiComponent>this.sourceComponent).setSearchSelectItem(event);
        }
    }
  }
  zoomOnLineSelected(){
    let selections = [];
    const gridFound = this.gridViews.find((grid) => grid.data.columnName === 'Product Info MFG Table');
    if (gridFound) {
      selections = gridFound.GridTabInfinityScrollUiComponent.agGrid.api.getSelectedRows();
    }
    if(selections && selections.length > 0 && selections[0]['MFG_Product_ID'] > 0 && 
       (!selections[0]['M_Product_ID'] || (selections[0]['M_Product_ID'] && 
       (!selections[0]['M_Product_ID'].id || selections[0]['M_Product_ID'].id < 0))))
    {
      this.messageManager.newMessage(
        new IupicsMessage(this.translateService.instant('specificWindow.productViewMFG.zoomERR'), 'error')
      );
    } else {
      super.zoomOnLineSelected();
    }

  }
}
