import { Component, Input, OnInit } from '@angular/core';
import { IupicsWidget } from '@web-desktop/models/iupics-widget';

@Component({
  selector: 'iu-list-widget',
  templateUrl: './list-widget.component.html',
  styleUrls: ['./list-widget.component.scss']
})
export class ListWidgetComponent implements OnInit {
  @Input()
  widget: IupicsWidget;
  constructor() {}
  ngOnInit() {}
}
