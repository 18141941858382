import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import * as mime from 'mime';
@Pipe({
  name: 'docServerUrl'
})
export class DocServerUrlPipe implements PipeTransform {
  constructor(private http: HttpClient, private connectorService: SecurityManagerService, private sanitized: DomSanitizer) {}

  async transform(src: string, callbackOnError?: Function): Promise<any> {
    const token = this.connectorService.getOAuth2AccessToken();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token.access_token}`,
      Accept:
        'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9'
    });
    try {
      if (src && src.includes && src.includes('doc-server')) {
        const response = await this.http.get(src, { headers, observe: 'response', responseType: 'blob' }).toPromise();
        const text = await response.body.text();
        if (text.includes('Could not find document:')) {
          throw new Error(text);
        }
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
          reader.onloadend = (evt) => {
            let base64 = evt.target.result as string;
            const contentDisposition = response.headers.get('content-disposition');
            let fileName;
            let ext = 'png';
            let mimeType = 'image/png';
            try {
              fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
              ext = fileName.replaceAll('"', '').split('.').pop();
            } catch {
              fileName = null;
            }
            if (ext) {
              ext = ext.toLocaleLowerCase();
              mimeType = mime.getType(ext);
            }
            if (fileName) {
              let mimeTypeToReplace = base64.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
              base64 = base64.replace(mimeTypeToReplace, mimeType);
              let url = this.sanitized.bypassSecurityTrustUrl(base64);
              if (!mimeType.includes('image')) {
                url = this.sanitized.bypassSecurityTrustResourceUrl(base64);
              }
              resolve(url);
            } else {
              if (!mimeType.includes('image')) {
                resolve(null);
              } else {
                resolve(this.sanitized.bypassSecurityTrustUrl('assets/imageError.webp'));
              }
            }
          };
          reader.readAsDataURL(response.body as Blob);
        });
      } else {
        return src;
      }
    } catch (err) {
      if (((err && err.includes && err.includes('Could not find document:')) || src) && callbackOnError) {
        callbackOnError();
      }
      return null;
    }
  }
}
