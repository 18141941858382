import { Injectable } from '@angular/core';
import { WidgetCompiereJson } from '@compiere-ws/models/widget-center-json';
import { AppConfig } from '@iupics-config/app.config';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable()
export class WidgetCenterService {
  private widgetsListURL: string;

  constructor(private http: ApiService, private config: AppConfig) {}

  getWidgetList(): Observable<WidgetCompiereJson[]> {
    if (this.widgetsListURL === undefined) {
      this.widgetsListURL = this.config.getBackendResource('widgetsList');
    }
    return this.http.get<WidgetCompiereJson[]>(`${this.widgetsListURL}`);
  }
}
