// Angular

import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule } from '@angular/core';
import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HttpInterceptorService } from '@compiere-ws/http-interceptor.service';
import { IuErrorHandler } from '@compiere-ws/iu-error-handler.service';
import { AppConfig } from '@iupics-config/app.config';
import { KeybindConfigService } from '@iupics-config/keybind.config.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
// Project Components
import { AppComponent } from '@iupics/app.component';
import { LoginPageModule } from '@login-page/login-page.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SplitManagerService } from '@web-desktop/controllers/split-manager/split-manager.service';
import { DesktopModule } from '@web-desktop/web-desktop.module';
import { environment } from 'environments/environment';
import * as Hammer from 'hammerjs';
import { AppRoutingModule } from './app-routing.module';
// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, 'assets/i18n/', '.json');
}

export function loadEnvVariable(config: AppConfig, connectorService: SecurityManagerService) {
  return () => config.loadEnvVariable().then(() => connectorService.loadConfig());
}

export function initializeKeybinding(keybindConfig: KeybindConfigService) {
  return () => keybindConfig.load();
}

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    // override hammerjs default configuration
    swipe: { direction: Hammer.DIRECTION_HORIZONTAL },
    pinch: {
      enable: false
    },
    rotate: {
      enable: false
    },
    press: {
      time: 500
    }
  };
  options = <any>{
    behavior: {
      userSelect: 'auto'
    }
  };
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    // Angular
    BrowserModule,
    BrowserAnimationsModule,
    HammerModule,
    // Projects Modules
    DesktopModule,
    LoginPageModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    environment.production ? ServiceWorkerModule.register('ngsw-worker.js') : [],
    AppRoutingModule
  ],
  providers: [
    AppConfig,
    KeybindConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadEnvVariable,
      deps: [AppConfig, SecurityManagerService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeybinding,
      deps: [KeybindConfigService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: IuErrorHandler
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    },
    SplitManagerService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
