<div *ngIf="displayFileUploaded" class="file_list">
  <div *ngIf="uploadedFiles.length === 0; else uploadedFilesList" [style.font-size]="overridedCSS?.inputFontSize">
    <p-progressSpinner *ngIf="isLoaderActive; else noFileMessage"></p-progressSpinner>
    <ng-template #noFileMessage> {{ uploadedFileList_noFiles | translate }} </ng-template>
  </div>
  <ng-template #uploadedFilesList>
    <table class="uploadedFiles">
      <thead>
        <tr class="file_list_header">
          <th colspan="2">
            <span [style.font-size]="overridedCSS?.labelFontSize">{{ uploadedFileList_title | translate }}</span>
          </th>
          <th>{{ fileList_createdDate | translate }}</th>
          <th *ngFor="let tag of displayedTags | slice: 0:4">
            {{ tag.colLabel }}
          </th>
          <th>
            <input
              #filterInput
              type="text"
              class="file_upload_input_search"
              (keyup)="updateFilterOptions($event, filterInput.value, 'files')"
              placeholder="{{ uploadedFileList_search | translate }}"
              value=""
              width="10vw"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let file of uploadedFiles | filterList: filterListOptions; let i = index">
          <td>
            <img
              class="fileUpload-logo"
              [src]="getLogoFileSrc(file) | docServerUrl | async"
              [width]="previewWidth"
              (click)="onPreview($event, file, i)"
              data-cy="previewFile"
            />
          </td>
          <td class="fileUpload-name" [style.font-size]="overridedCSS?.inputFontSize" (click)="onPreview($event, file, i)">
            {{ file.name }}
          </td>
          <td>
            <div *ngIf="file.createdDate" class="taggedValue">
              <span>{{ file.createdDate }}</span>
            </div>
          </td>
          <td *ngFor="let tag of displayedTags | slice: 0:4" class="taggedValue">
            <div *ngIf="file && file.tags && file.tags['META|' + tag.columnName.toUpperCase()]" class="taggedValue">
              <span>
                {{
                  (file.tags['META|' + tag.columnName.toUpperCase() + '$']
                    ? file.tags['META|' + tag.columnName.toUpperCase() + '$']
                    : file.tags['META|' + tag.columnName.toUpperCase()]
                  ) | docServerFormatValue: tag.ad_reference_id
                }}</span
              >
            </div>
          </td>
          <td>
            <p-button
              *ngIf="displayedTags && displayedTags.length > 4"
              icon="fas fa-info"
              [title]="'fileupload.moreTags' | translate"
              (onClick)="selectedFile = file; op.toggle($event)"
            ></p-button>
            <p-button
              *ngIf="canDownload"
              icon="fa fa-download"
              [title]="'generic.download' | translate"
              (onClick)="downloadFile($event, file, i)"
            ></p-button>
            <p-button
              class="fileUpload-copyLink"
              icon="fa fa-link"
              [title]="'generic.copyLink' | translate"
              (onClick)="copyLinkFile($event, file, i)"
              data-cy="copyLinkFile"
            ></p-button>
            <p-button
              *ngIf="canDelete && (file.isDeletable === undefined || file.isDeletable)"
              icon="fa fa-trash"
              (onClick)="isReadOnly ? null : deleteFile($event, file, i)"
              [title]="'generic.delete' | translate"
              data-cy="deleteFile"
            ></p-button>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-template>
</div>
<div *ngIf="displayFileUploaded && linkedFiles.length > 0" class="file_list linked_file" data-cy="fileupload-linkedfile-list">
  <div *ngIf="linkedFiles.length === 0; else linkedFilesList" [style.font-size]="overridedCSS?.inputFontSize">
    <p-progressSpinner *ngIf="isLoaderActive; else noFileMessage"></p-progressSpinner>
    <ng-template #noFileMessage> {{ uploadedFileList_noLinkedFiles | translate }} </ng-template>
  </div>
  <ng-template #linkedFilesList>
    <table class="linkedFiles">
      <thead>
        <tr class="file_list_header">
          <th colspan="2">
            <span [style.font-size]="overridedCSS?.labelFontSize">{{ linkedFileList_title | translate }}</span>
          </th>
          <th>{{ fileList_createdDate | translate }}</th>
          <th *ngFor="let tag of displayedTags | slice: 0:4">
            {{ tag.colLabel }}
          </th>
          <th>
            <input
              #filterLinkedInput
              type="text"
              class="file_upload_input_search"
              (keyup)="updateFilterOptions($event, filterLinkedInput.value, 'linkedFiles')"
              value=""
              placeholder="{{ uploadedFileList_search | translate }}"
              width="10vw"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let file of linkedFiles | filterList: filterLinkedListOptions; let i = index"
          [attr.data-cy-index]="i"
          data-cy="fileupload-linkedfile"
        >
          <td>
            <img
              class="fileUpload-logo"
              [src]="getLogoFileSrc(file) | docServerUrl | async"
              [width]="previewWidth"
              (click)="onPreview($event, file, i)"
              data-cy="previewFile"
            />
          </td>
          <td class="fileUpload-name" [style.font-size]="overridedCSS?.inputFontSize" (click)="onPreview($event, file, i)">
            {{ file.name }}
          </td>
          <td>
            <div *ngIf="file.createdDate" class="taggedValue">
              <span>{{ file.createdDate }}</span>
            </div>
          </td>
          <td *ngFor="let tag of displayedTags | slice: 0:4" class="taggedValue">
            <div *ngIf="file && file.tags && file.tags['META|' + tag.columnName.toUpperCase()]" class="taggedValue">
              <span>
                {{
                  (file.tags['META|' + tag.columnName.toUpperCase() + '$']
                    ? file.tags['META|' + tag.columnName.toUpperCase() + '$']
                    : file.tags['META|' + tag.columnName.toUpperCase()]
                  ) | docServerFormatValue: tag.ad_reference_id
                }}</span
              >
            </div>
          </td>

          <td>
            <p-button
              *ngIf="displayedTags && displayedTags.length > 4"
              icon="fas fa-info"
              [title]="'fileupload.moreTags' | translate"
              (onClick)="selectedFile = file; op.toggle($event)"
            ></p-button>
            <p-button
              *ngIf="canDownload"
              icon="fa fa-download"
              (onClick)="downloadFile($event, file, i)"
              data-cy="downloadFile"
            ></p-button>
            <p-button
              class="fileUpload-copyLink"
              icon="fa fa-link"
              (onClick)="copyLinkFile($event, file, i)"
              data-cy="copyLinkFile"
            ></p-button>
            <p-button
              *ngIf="canDelete && (file.isDeletable === undefined || file.isDeletable)"
              icon="fa fa-trash"
              (onClick)="isReadOnly ? null : deleteFile($event, file, i)"
              data-cy="deleteFile"
            ></p-button>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-template>
</div>
<p-overlayPanel
  #op
  appendTo="body"
  baseZIndex="2000"
  styleClass="fileUpload-overlayPanel"
  [showCloseIcon]="true"
  [style]="{ width: '450px' }"
>
  <ng-template pTemplate>
    <p-table [value]="displayedTags | slice: 4" [paginator]="true" [rows]="5">
      <ng-template pTemplate="header">
        <tr>
          <th>{{ 'fileupload.tagName' | translate }}</th>
          <th>{{ 'fileupload.tagValue' | translate }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-displayedTag>
        <tr [pSelectableRow]="rowData">
          <td>{{ displayedTag.colLabel }}</td>
          <td>
            {{
              (selectedFile.tags['META|' + displayedTag.columnName.toUpperCase() + '$']
                ? selectedFile.tags['META|' + displayedTag.columnName.toUpperCase() + '$']
                : selectedFile.tags['META|' + displayedTag.columnName.toUpperCase()]
              ) | docServerFormatValue: displayedTag.ad_reference_id
            }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-template>
</p-overlayPanel>
<div *ngIf="isReadOnly || canUpload">
  <h3 style="margin-top: 0px">{{ uploadingFileList_title | translate }}</h3>
  <div [ngClass]="['ui-fileupload', styleClass || '']" [ngStyle]="style">
    <div
      #content
      [ngClass]="['ui-fileupload-content ui-corner-bottom']"
      (dragenter)="onDragEnter($event)"
      (dragleave)="onDragLeave($event)"
      (drop)="onDrop($event)"
      (click)="addFile($event)"
      data-cy="fileupload-click-or-drop-zone"
    >
      <p-messages [value]="msgs" [enableService]="false"></p-messages>
      <input
        #advancedfileinput
        type="file"
        style="display: none"
        (change)="onFileSelect($event)"
        [multiple]="multiple"
        [readonly]="isReadOnly"
        (focus)="!isReadOnly ? onFocus($event) : null"
        [tabindex]="isReadOnly ? '-1' : null"
        (blur)="onBlur()"
        data-cy="fileupload-input-file"
      />
      <div *ngIf="!hasFiles(); else notEmpty" class="ui-fileupload-label">
        <i class="fas fa-cloud-upload-alt fa-4x" aria-hidden="true"></i>
        <p>{{ uploadingFileList_drag | translate }}</p>
      </div>
      <ng-template #notEmpty>
        <div class="ui-fileupload-files">
          <table class="ui-fileupload-table">
            <tr *ngFor="let file of files; let i = index">
              <td *ngIf="uploadingFiles.indexOf(file) !== -1">
                <p-progressSpinner></p-progressSpinner>
              </td>
              <td><img [src]="getLogoFileSrc(file) | docServerUrl | async" [width]="previewWidth" /></td>
              <td>
                <div
                  [ngStyle]="{
                    width: content | getPercentInPxCss: { of: 'clientWidth', many: 40 }
                  }"
                  [style.font-size]="overridedCSS?.inputFontSize"
                  class="prime-fileupload-filename"
                >
                  {{ file.name }}
                </div>
              </td>
              <td>
                <button
                  type="button"
                  icon="fa fa-trash"
                  pButton
                  (click)="remove($event, i)"
                  *ngIf="uploadingFiles.indexOf(file) === -1"
                ></button>
              </td>
            </tr>
          </table>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="ui-fileupload-button-container" style="margin-top: 10px" *ngIf="!auto">
    <p-button
      *ngIf="displayButtons"
      (click)="onClickUploadFiles()"
      [disabled]="uploadingFiles.length > 0"
      label="{{ uploadBtn_confirm | translate }}"
      [styleClass]="'p-button-secondary'"
      data-cy="fileupload-btn-confirm"
    ></p-button>
    <p-button
      *ngIf="displayButtons"
      (click)="clear()"
      [disabled]="uploadingFiles.length > 0"
      label="{{ uploadBtn_cancel | translate }}"
      [styleClass]="'p-button-secondary'"
      data-cy="fileupload-btn-cancel"
    ></p-button>
  </div>
</div>
<iu-preview-doc [fromDocServer]="fromDocServer"></iu-preview-doc>
