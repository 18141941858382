import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccordionModule } from 'primeng/accordion';
import { MessageService, TreeDragDropService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BlockUIModule } from 'primeng/blockui';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { InplaceModule } from 'primeng/inplace';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import { SpinnerModule } from 'primeng/spinner';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TerminalModule } from 'primeng/terminal';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
@NgModule({
  exports: [
    BrowserAnimationsModule,
    ScrollPanelModule,
    PaginatorModule,
    CardModule,
    TooltipModule,
    MultiSelectModule,
    ButtonModule,
    PanelModule,
    InputTextModule,
    BreadcrumbModule,
    MenubarModule,
    DialogModule,
    ToolbarModule,
    SplitButtonModule,
    DropdownModule,
    CalendarModule,
    ToggleButtonModule,
    FieldsetModule,
    InputSwitchModule,
    SpinnerModule,
    AccordionModule,
    StepsModule,
    AutoCompleteModule,
    ChartModule,
    ContextMenuModule,
    SidebarModule,
    ColorPickerModule,
    CheckboxModule,
    OverlayPanelModule,
    BlockUIModule,
    InputMaskModule,
    FileUploadModule,
    TerminalModule,
    MenuModule,
    ToastModule,
    TreeModule,
    MessageModule,
    FullCalendarModule,
    ListboxModule,
    PickListModule,
    InplaceModule,
    EditorModule,
    ChipsModule,
    TabViewModule,
    ProgressBarModule,
    TableModule,
    ProgressSpinnerModule
  ],
  providers: [MessageService, TreeDragDropService]
})
export class PrimengModule {}
