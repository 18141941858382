import { Injectable } from '@angular/core';
import { AppConfig } from '@iupics-config/app.config';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  active: {};
  availableThemes: { items: {}[] };

  constructor(private config: AppConfig, protected connectorService: SecurityManagerService) {
    const themes = this.config.getThemeList();
    this.availableThemes = { items: [] };
    let themeId = 1;
    Object.keys(themes).forEach(themeName => {
      this.availableThemes.items.push({ id: themeId++, displayValue: themeName, data: themes[themeName] });
    });
  }

  getAvailableThemes() {
    return this.availableThemes;
  }

  getActiveTheme() {
    return this.active;
  }

  getThemeProperty(propertyName: string) {
    return this.active['data'][propertyName];
  }

  setActiveTheme(themeName?: string): void {
    if (!themeName) {
      themeName = this.connectorService.getIupicsUserContext()['#UITheme'];
    }
    this.active = this.availableThemes.items.find(theme => theme['displayValue'] === themeName);
    Object.keys(this.active['data']).forEach(property => {
      document.documentElement.style.setProperty('--' + property, this.active['data'][property]);
    });
  }
}
