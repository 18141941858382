import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { DataStore, DataStoreKey } from '@compiere-ws/models/compiere-data-json';
import { CompiereNotification, CompiereNotificationPriority } from '@compiere-ws/models/compiere-notification-json';
import { CompiereDataService } from '@compiere-ws/services/compiere-data/compiere-data.service';
import { CompiereNotificationService } from '@compiere-ws/services/compiere-notification/compiere-notification.service';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { PoService } from '@compiere-ws/services/po/po.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItem, CustomDesignItemType } from '@iupics-components/models/custom-design';
import { ButtonUiComponent } from '@iupics-components/standard/fields/button-ui/button-ui.component';
import { EditViewUtils } from '@iupics-components/standard/layouts/edit-view-ui/utils/edit-view.utils';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { NumberType } from '@iupics-manager/models/iupics-data';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateService } from '@ngx-translate/core';
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';
import { environment } from 'environments/environment';
import { cloneDeep } from 'lodash';
import { Observable, of, zip } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';
import {
  getBankRequest,
  getChargeDescRequest,
  getCountryDataRequest,
  getCurrencyRequest,
  getHeaderCustomDesignItems,
  getInvoiceLineRequest,
  getInvoiceRequest,
  getInvoiceTaxRequest,
  getLineCustomDesignItems,
  getLogMessageRequest,
  getNullValueInData,
  getOrderRequest,
  getPartnerLocationRequest,
  getPartnerRequest,
  getProductDescRequest,
  getTabDataRequest,
  getUserRoleRequest,
  QRInfo
} from './fast-create-invoice-vendor-utils';
@Component({
  selector: 'iu-fast-create-invoice-vendor',
  templateUrl: './fast-create-invoice-vendor.component.html',
  styleUrls: ['./fast-create-invoice-vendor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
//todo flex du deuxieme table
export class FastCreateInvoiceVendorComponent extends SpecificWindowUiComponent implements OnInit, AfterViewInit {
  @ViewChild('vcrBVR', { read: ViewContainerRef, static: true })
  vcrBVR: ViewContainerRef;
  @ViewChild('vcrBPartner', { read: ViewContainerRef, static: true })
  vcrBPartner: ViewContainerRef;
  @ViewChild('vcrBPBankAccount', { read: ViewContainerRef, static: true })
  vcrBPBankAccount: ViewContainerRef;
  @ViewChild('vcrProcessInvoice', { read: ViewContainerRef, static: true })
  vcrProcessInvoice: ViewContainerRef;
  @ViewChild('vcrDeliveryRule', { read: ViewContainerRef, static: true })
  vcrDeliveryRule: ViewContainerRef;
  @ViewChild('vcrDeliveryViaRule', { read: ViewContainerRef, static: true })
  vcrDeliveryViaRule: ViewContainerRef;
  @ViewChild('vcrValidator', { read: ViewContainerRef, static: true })
  vcrValidator: ViewContainerRef;
  @ViewChild('vcrCreateFrom', { read: ViewContainerRef, static: true })
  vcrCreateFrom: ViewContainerRef;

  @ViewChild('vcrBPartnerLocation', { read: ViewContainerRef, static: true })
  vcrBPartnerLocation: ViewContainerRef;

  @ViewChild('vcrOrder', { read: ViewContainerRef, static: true })
  vcrOrder: ViewContainerRef;

  @ViewChild('vcrDescriptionHeader', { read: ViewContainerRef, static: true })
  vcrDescription: ViewContainerRef;
  @ViewChild('vcrDateInvoiced', { read: ViewContainerRef, static: true })
  vcrDateInvoiced: ViewContainerRef;

  @ViewChildren('vcrCharge', { read: ViewContainerRef })
  vcrsCharge: QueryList<ViewContainerRef>;
  @ViewChildren('vcrProduct', { read: ViewContainerRef })
  vcrsProduct: QueryList<ViewContainerRef>;

  @ViewChildren('vcrQty', { read: ViewContainerRef })
  vcrsQty: QueryList<ViewContainerRef>;

  @ViewChildren('vcrDescription', { read: ViewContainerRef })
  vcrsDescription: QueryList<ViewContainerRef>;

  @ViewChildren('vcrPriceEntered', { read: ViewContainerRef })
  vcrsPriceEntered: QueryList<ViewContainerRef>;

  @ViewChildren('vcrPriceActual', { read: ViewContainerRef })
  vcrsPriceActual: QueryList<ViewContainerRef>;
  @ViewChildren('vcrPriceList', { read: ViewContainerRef })
  vcrsPriceList: QueryList<ViewContainerRef>;

  @ViewChildren('vcrLineTotalAmt', { read: ViewContainerRef })
  vcrsLineTotalAmt: QueryList<ViewContainerRef>;
  @ViewChildren('vcrLineNetAmt', { read: ViewContainerRef })
  vcrsLineNetAmt: QueryList<ViewContainerRef>;
  @ViewChildren('vcrOthers', { read: ViewContainerRef })
  vcrsOthers: QueryList<ViewContainerRef>;

  @ViewChildren('vcrTax', { read: ViewContainerRef })
  vcrsTax: QueryList<ViewContainerRef>;
  @ViewChildren('vcrTaxAmt', { read: ViewContainerRef })
  vcrsTaxAmt: QueryList<ViewContainerRef>;

  @ViewChild('vcrGrandTotal', { read: ViewContainerRef, static: true })
  vcrGrandTotal: ViewContainerRef;

  @ViewChild('vcrSettings', { read: ViewContainerRef, static: true })
  vcrSettings: ViewContainerRef;
  @ViewChild('logsContainer', { read: ElementRef, static: true })
  logsContainer: ElementRef;
  @ViewChild('qrInfoArea', { read: ElementRef, static: true })
  qrInfoArea: ElementRef;
  logs: QuickInvoiceLog[] = [];
  isSidebarOpen = false;
  subscription;
  docStatus = '*';
  qrInfoString = '';
  qrInfoStringLast = '';
  qrInfo: QRInfo = new QRInfo([]);
  amountToPay = 0;
  //AD
  messages: ADMessage[];
  tableID;
  invoiceTabID;
  invoiceLineTabID;
  invoiceTaxTabID;
  windowID;
  marginError = 0.05;
  displayJoinFilesPanel = false;
  nbUploadedFiles = 0;
  dsKey: DataStoreKey;
  placeHolder = '';
  currency = '';
  lineMetaData: CustomDesignItem[] = [];

  dataLines: DataStore[] = [];
  dataContainersLine: AbstractDataContainer[][] = [];
  tabindex = 1;
  grandTotal = 0;
  grandVatTotal = 0;
  grandNetTotal = 0;
  grandTotalData = { numberType: NumberType.MONTANT };
  ibanValue = '';
  ad_language = 'fr_FR';
  isProcessed = false;
  MANDATORY_HEADER_COLUMNS = [
    'C_BPartner_ID',
    'C_BPartner_Location_ID',
    'DateInvoiced',
    'AD_Org_ID',
    'SalesRep_ID',
    'DateAcct',
    'XX_BVR',
    'M_PriceList_ID',
    'C_DocTypeTarget_ID',
    'Description',
    'AD_Client_ID',
    'C_PaymentTerm_ID',
    'PaymentRule',
    'C_Currency_ID',
    'IsReturnTrx',
    'C_BP_BankAccount_ID'
  ];
  isQrInfoLoading = false;
  translations: any;
  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    protected translateService: TranslateService,
    socketService: SocketService,
    private poService: PoService,
    private messageManager: MessageManagerService,
    connectorService: SecurityManagerService,
    progressService: ProcessInProgressService,
    private dataService: CompiereDataService,
    private notificationService: CompiereNotificationService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
    this.docStatus = this.translateService.instant('specificWindow.quickInvoiceVendor.new');
    this.isModal = false;
    this.customDesignArray.push(...getHeaderCustomDesignItems());
  }
  ngOnInit() {
    super.ngOnInit();
    this.initTranslations();
    this.setPlaceHolder();
    this.ad_language = this.connectorService.getIupicsDefaultLanguage().iso_code;
    this.lineMetaData = getLineCustomDesignItems();
    //INIT
    this.initAD_Data();
    const item: DynamicComponent = {
      container: this,
      DOMParentComponent: this,
      component: 'SpecificWindowUiComponent',
      cssClass: 'p-col-12',
      isCssOnComponent: false,
      tabId: this.formId,
      gridPaginator: false
    };
    this.windowFactory.newEventHandler({
      type: IupicsTypeEvent.showSpecificWindow,
      item: item
    });
  }

  ngAfterViewInit() {
    this.dataStore.dataChange.subscribe((dataChanged) => {
      Object.keys(dataChanged.dataModified).forEach((columnName) => {
        this.notifyFromDataChange({ data: { columnName: columnName } });
      });
    });
    //setFocused for scan
    this.qrInfoArea.nativeElement.focus();
    const ctx = this.getCurrentContext();
    if (ctx['C_Currency_ID']) {
      this.setCurrencySymbol(ctx['C_Currency_ID']);
    } else if (ctx['$C_Currency_ID']) {
      this.setCurrencySymbol(ctx['$C_Currency_ID']);
    }
  }
  updateDisplayLogicOnButton(columnName: string, isDisplayed = true) {
    const buttonComponent = <ButtonUiComponent>(
      this.dataContainers.find((datacontainer) => datacontainer.data && datacontainer.data.columnName === columnName)
    );
    if (buttonComponent) {
      buttonComponent.data.DisplayLogic = isDisplayed ? '1=1' : '1=2';
    }
  }
  initTranslations() {
    this.translations = {
      product: this.translateService.instant('specificWindow.quickInvoiceVendor.product'),
      charge: this.translateService.instant('specificWindow.quickInvoiceVendor.charge'),
      quantity: this.translateService.instant('specificWindow.quickInvoiceVendor.quantity'),
      priceEntered: this.translateService.instant('specificWindow.quickInvoiceVendor.priceEntered'),
      priceList: this.translateService.instant('specificWindow.quickInvoiceVendor.priceList'),
      taxAmt: this.translateService.instant('specificWindow.quickInvoiceVendor.taxAmt'),
      tax: this.translateService.instant('specificWindow.quickInvoiceVendor.tax'),
      totalLine: this.translateService.instant('specificWindow.quickInvoiceVendor.totalLine')
    };
  }
  setPlaceHolder(isEmpty = false) {
    this.placeHolder = isEmpty ? '' : this.translateService.instant('specificWindow.quickInvoiceVendor.qrPlaceHolder');
  }

  updateHeader() {
    this.grandTotal = 0;
    this.grandNetTotal = 0;
    this.grandVatTotal = 0;
    this.dataLines.forEach((line) => {
      this.grandTotal = this.grandTotal + line.data['LineTotalAmt'];
      this.grandNetTotal = this.grandNetTotal + line.data['LineNetAmt'];
      this.grandVatTotal = this.grandVatTotal + line.data['TaxAmt'];
    });
  }
  resetlines() {
    //Reset lines
    this.dataLines.forEach((dataLine, index) => {
      this.lineMetaData.forEach((metaData) => {
        this[metaData.vcr].toArray()[index].clear();
      });
    });
    this.dataLines = [];
    this.dataContainersLine = [];
  }
  resetInvoice() {
    this.isProcessed = false;
    this.updateDisplayLogicOnButton('CreateFrom', true);
    this.resetlines();
    this.dataStore.dataChange.unsubscribe();
    this.dataStore = this.store.newSpecificWindowData(this.formId);
    this.dataStore.dataChange.subscribe((dataChanged) => {
      Object.keys(dataChanged.dataModified).forEach((columnName) => {
        this.notifyFromDataChange({ data: { columnName: columnName } });
      });
    });
    this.dataContainers.forEach((dataContainer) => {
      dataContainer.setNewData(this.dataStore);
    });
    this.grandTotal = 0;
    this.grandVatTotal = 0;
    this.grandNetTotal = 0;
    this.ibanValue = '';
    this.clearLogs();
    this.resetQRInfo();
    this.docStatus = this.translateService.instant('specificWindow.quickOrder.new');
  }
  //#region notification
  getUserRoleObs(ad_user_id: number) {
    const ad_client_id =
      this.dataStore.data['AD_Client_ID'] instanceof Object
        ? this.dataStore.data['AD_Client_ID'].id
        : this.dataStore.data['AD_Client_ID'];
    return this.store.getDataGrid(getUserRoleRequest(ad_user_id, ad_client_id, this.ad_language));
  }
  sendNotification() {
    this.updateLoading(true);
    const ctx = this.getCurrentContext();
    const validatorId = ctx['XX_Validator_ID'] instanceof Object ? ctx['XX_Validator_ID'].id : ctx['XX_Validator_ID'];
    const invoiceId = ctx['C_Invoice_ID'] instanceof Object ? ctx['C_Invoice_ID'].id : ctx['C_Invoice_ID'];
    const notifTemplate: CompiereNotification = {
      created: null,
      target: null,
      title: this.getMessage('SZ_VendorInvoiceMsg24'),
      summary: this.getMessage('SZ_VendorInvoiceMsg23'),
      description: '',
      processChannelId: null,
      request_id: null,
      closed: false,
      isError: false,
      priority: CompiereNotificationPriority.IMPORTANT,
      type: 'user',
      additionalProperties: { AD_Role_ID: 0, AD_User_ID: validatorId, AD_Org_ID: 0 },
      sourceType: IupicsMenuType.WINDOW,
      sourceTypeId: this.windowID,
      sourceRecordId: invoiceId,
      sourceTableNameTargeted: 'C_Invoice',
      processParams: null,
      zoomInfo: { zoomUrl: `${environment.domain}/workspace/window/${this.windowID}/C_Invoice_ID,${invoiceId}` },
      fileLinks: null
    };
    this.getUserRoleObs(validatorId)
      .pipe(
        switchMap((userRoleResponse) => {
          const obss = [];
          if (userRoleResponse && userRoleResponse.data) {
            userRoleResponse.data.forEach((roleData) => {
              const roleId = roleData['AD_Role_ID'] instanceof Object ? roleData['AD_Role_ID'].id : roleData['AD_Role_ID'];
              const notifCopied = cloneDeep(notifTemplate);
              notifCopied.additionalProperties.AD_Role_ID = roleId;
              obss.push(this.notificationService.sendNotification(notifCopied));
            });
            if (obss.length === 0) {
              return of([]);
            } else {
              return zip(...obss);
            }
          }
        }),
        switchMap((responses) => {
          return of(responses.length > 0);
        }),
        catchError(() => {
          return of(false);
        })
      )
      .subscribe((success) => {
        //reset loading
        this.updateLoading(false);
        if (success) {
          this.isProcessed = true;
          this.updateDisplayLogicOnButton('CreateFrom', false);
          this.messageManager.newMessage(
            new IupicsMessage(
              this.translateService.instant('generic.success'),
              this.getMessage('SZ_VendorInvoiceMsg25'),
              'success'
            )
          );
        } else {
          this.messageManager.newMessage(
            new IupicsMessage(this.translateService.instant('generic.error'), this.getMessage('SZ_VendorInvoiceMsg18'), 'error')
          );
        }
      });
  }
  //#endregion notification
  //#region override
  public updateModalDisplay(
    display: { key: string; value: boolean; sourceComponent?: any },
    id?: { key: string; value: number }
  ) {
    this.customFormModalBodyCss =
      display.key && display.key === 'displayFormUI'
        ? { 'iu-modal-body': { overflow: 'auto', height: 'calc(100% - 25px)' } }
        : undefined;
    this[display.key] = display.value;
    if (id && display.value === true) {
      this[id.key] = id.value;
      if (id.key !== 'processId') {
        this.createSpecificWindow(id.value, display.sourceComponent);
      }
    } else {
      if (display.key === 'displayFormUI' && display.value === false) {
        this.refreshInvoice();
      }
      this.customFormModalBodyCss = undefined;
    }
  }
  processCreateFrom(item: any) {
    //to override button process behaviour
    this.checkMissingField();
    this.dataStore.data['Record_ID'] = this.dataStore.data['C_Invoice_ID'];
  }
  /**
   * Overrided to avoid std behaviour of datacontainer in specific window
   */
  setDataContainersValueWithChangedStore(dataStore?: DataStore) {}

  notifyFromDataChange(item: any, vcrIndex?: number) {
    let lineDataStore = null;
    if (vcrIndex !== undefined) {
      lineDataStore = this.dataLines[vcrIndex];
    }

    if (
      ['C_DocTypeTarget_ID', 'C_BPartner_ID'].includes(item.data['columnName']) &&
      this.dataStore &&
      this.dataStore.data[item.data['columnName']]
    ) {
      this.calloutColumn(item.data['columnName']);
    } else if (item.data['columnName'] === 'M_Product_ID' && lineDataStore && lineDataStore.data['M_Product_ID']) {
      this.calloutProduct(lineDataStore);
    } else if (item.data['columnName'] === 'C_Charge_ID' && lineDataStore && lineDataStore.data['C_Charge_ID']) {
      this.calloutCharge(lineDataStore);
    } else if (item.data['columnName'] === 'QtyEntered' && lineDataStore) {
      this.calloutColumnLine('QtyEntered', lineDataStore);
    } else if (item.data['columnName'] === 'C_Tax_ID' && lineDataStore && lineDataStore.data['C_Tax_ID']) {
      this.calloutColumnLine('C_Tax_ID', lineDataStore);
    } else if (item.data['columnName'] === 'PriceActual' && lineDataStore && lineDataStore.data['PriceActual']) {
      this.calloutColumnLine('PriceActual', lineDataStore);
    } else if (item.data['columnName'] === 'PriceList' && lineDataStore && lineDataStore.data['PriceList']) {
      this.calloutColumnLine('PriceList', lineDataStore);
    } else if (item.data['columnName'] === 'PriceEntered' && lineDataStore && lineDataStore.data['PriceEntered']) {
      this.calloutColumnLine('PriceEntered', lineDataStore);
    }
    if (item.data['columnName'] === 'C_Invoice_ID' && this.dataStore.data['C_Invoice_ID'] !== null) {
      this.docStatus = this.translateService.instant('specificWindow.quickOrder.draft');
      // this.newLine(true);
    }
    //autosave header
    if (this.MANDATORY_HEADER_COLUMNS.includes(item.data['columnName'])) {
      this.saveInvoice(true);
    }
  }
  //#endregion override
  //#region CALLOUT
  calloutColumn(columnName: string) {
    this.store.calloutData(
      '/Field/Callout/C_Invoice/' + columnName,
      {
        columnName: columnName,
        newValue: this.dataStore.data[columnName].id ? this.dataStore.data[columnName].id : this.dataStore.data[columnName],
        windowCtx: this.getCurrentContext()
      },
      this.dataStore,
      null
    );
  }
  calloutColumnLine(columnName: string, lineDataStore: DataStore) {
    const value =
      lineDataStore.data[columnName] && lineDataStore.data[columnName].id
        ? lineDataStore.data[columnName].id
        : lineDataStore.data[columnName];
    this.store.calloutData(
      '/Field/Callout/C_InvoiceLine/' + columnName,
      {
        columnName: columnName,
        newValue: value,
        windowCtx: this.getCurrentContext(lineDataStore, this.dataStore)
      },
      lineDataStore,
      null,
      () => {
        this.saveInvoiceLine(lineDataStore);
      }
    );
  }

  calloutCharge(lineDataStore: DataStore) {
    lineDataStore.data['M_Product_ID'] = null;
    this.store.syncDataChanges(lineDataStore, { M_Product_ID: null });
    this.store.calloutData(
      '/Field/Callout/C_InvoiceLine/C_Charge_ID',
      {
        columnName: 'C_Charge_ID',
        newValue:
          lineDataStore.data['C_Charge_ID'] instanceof Object
            ? lineDataStore.data['C_Charge_ID'].id
            : lineDataStore.data['C_Charge_ID'],
        windowCtx: this.getCurrentContext(lineDataStore, this.dataStore)
      },
      lineDataStore,
      null,
      () => {
        if (this.subscription) {
          this.subscription.unsubscribe();
          this.subscription = null;
        }
        const chargeId =
          lineDataStore.data['C_Charge_ID'] && lineDataStore.data['C_Charge_ID'].id
            ? lineDataStore.data['C_Charge_ID'].id
            : lineDataStore.data['C_Charge_ID'];
        this.subscription = this.store.getDataGrid(getChargeDescRequest(chargeId), true).subscribe(({ data }) => {
          this.store.syncDataChanges(lineDataStore, { Description: data[0]['DESCRIPTION'] });
          this.saveInvoiceLine(lineDataStore);
          this.subscription.unsubscribe();
        });
      }
    );
  }
  calloutProduct(lineDataStore: DataStore) {
    lineDataStore.data['C_Charge_ID'] = null;
    this.store.syncDataChanges(lineDataStore, { C_Charge_ID: null });
    const productId =
      lineDataStore.data['M_Product_ID'] && lineDataStore.data['M_Product_ID'].id
        ? lineDataStore.data['M_Product_ID'].id
        : lineDataStore.data['M_Product_ID'];
    this.store.calloutData(
      '/Field/Callout/C_InvoiceLine/M_Product_ID',
      {
        columnName: 'M_Product_ID',
        newValue: productId,
        windowCtx: EditViewUtils.mergeCurrentDataDeepCopy(cloneDeep(lineDataStore.data), this.dataStore.data)
      },
      lineDataStore,
      null,
      () => {
        if (this.subscription) {
          this.subscription.unsubscribe();
          this.subscription = null;
        }
        this.subscription = this.store.getDataGrid(getProductDescRequest(productId), true).subscribe(({ data }) => {
          this.store.syncDataChanges(lineDataStore, { Description: data[0]['DESCRIPTION'] });
          this.saveInvoiceLine(lineDataStore);
          this.subscription.unsubscribe();
        });
      }
    );
  }
  //#endregion CALLOUT
  //#region CRUD
  //#region DocManagement
  cancelDoc() {
    if (this.dataStore && this.dataStore.data && this.dataStore.data['C_Invoice_ID']) {
      this.updateLoading(true);
      const sub$ = this.deleteDoc().subscribe((deleted) => {
        this.updateLoading(false);
        if (deleted) {
          this.messageManager.newMessage(
            new IupicsMessage(
              this.translateService.instant('generic.success'),
              this.getMessage('SZ_VendorInvoiceMsg19'),
              'success'
            )
          );
          this.resetInvoice();
        } else {
          this.messageManager.newMessage(
            new IupicsMessage(this.translateService.instant('generic.error'), this.getMessage('SZ_VendorInvoiceMsg20'), 'error')
          );
        }
        sub$.unsubscribe();
      });
    } else {
      this.resetInvoice();
    }
  }
  saveDoc() {
    if (this.amountToPay + this.marginError > this.grandTotal && this.amountToPay - this.marginError < this.grandTotal) {
      this.messageManager.newMessage(
        new IupicsMessage(this.translateService.instant('generic.warning'), this.getMessage('SZ_VendorInvoiceMsg21'), 'warning')
      );
    } else if (!this.getFormStoreData('XX_Validator_ID')) {
      this.messageManager.newMessage(
        new IupicsMessage(this.translateService.instant('generic.warning'), this.getMessage('SZ_VendorInvoiceMsg22'), 'warning')
      );
    } else {
      this.sendNotification();
      return true;
    }
    return false;
  }
  deleteDoc() {
    this.updateLoading(true);
    const invoiceId =
      this.dataStore.data['C_Invoice_ID'] instanceof Object
        ? this.dataStore.data['C_Invoice_ID'].id
        : this.dataStore.data['C_Invoice_ID'];
    const data_UUID_list = [];
    //DELETE INVOICELINE
    this.dataLines.forEach((lineDataStore) => {
      const invoiceLineId =
        lineDataStore.data['C_InvoiceLine_ID'] instanceof Object
          ? lineDataStore.data['C_InvoiceLine_ID'].id
          : lineDataStore.data['C_InvoiceLine_ID'];
      data_UUID_list.push({ C_InvoiceLine_ID: invoiceLineId });
    });
    let obs = of([]);
    if (data_UUID_list.length > 0) {
      obs = this.dataService.deleteData(this.invoiceLineTabID, data_UUID_list);
    }
    return obs.pipe(
      switchMap((resultLines) => {
        //CHECK ERROR DURING INVOICELINE DELETE
        let hasInvoiceLineError = false;
        if (resultLines) {
          hasInvoiceLineError =
            resultLines.find((resultLine) => resultLine.messages.find((msg) => msg.type !== 'SUCCESS')) !== undefined;
        } else hasInvoiceLineError = true;
        if (resultLines) {
          //CHECK REMOVE DELETED LINES ON UI
          resultLines.forEach((line) => {
            if (line.messages.find((msg) => msg.type === 'SUCCESS')) {
              const index = this.dataLines.findIndex((dataLine) => dataLine.data['C_InvoiceLine_ID'] == line['C_InvoiceLine_ID']);
              this.lineMetaData.forEach((metaData) => {
                this[metaData.vcr].toArray()[index].clear();
              });
              for (let i = index + 1; i < this.dataLines.length; i++) {
                this.lineMetaData.forEach((metaData) => {
                  const element = (<ViewContainerRef>this[metaData.vcr].toArray()[i]).detach();
                  (<ViewContainerRef>this[metaData.vcr].toArray()[i - 1]).insert(element);
                });
              }
              this.dataLines.splice(index, 1);
              this.dataContainersLine.splice(index, 1);
            }
          });
        }
        this.updateHeader();
        if (hasInvoiceLineError) {
          return of(null);
        }
        //GET INVOICETAX
        return this.store.getDataGrid(getInvoiceTaxRequest(invoiceId, this.ad_language)).pipe(
          catchError(() => {
            return of(null);
          })
        );
      }),
      switchMap((results) => {
        //DELETE INVOICETAX
        const data_UUID_list = [];
        results.data.forEach((taxData) => {
          const taxId = taxData['C_Tax_ID'] instanceof Object ? taxData['C_Tax_ID'].id : taxData['C_Tax_ID'];
          data_UUID_list.push({ C_Invoice_ID: invoiceId, C_Tax_ID: taxId });
        });
        if (data_UUID_list.length > 0) {
          return this.dataService.deleteData(this.invoiceTaxTabID, data_UUID_list).pipe(
            catchError(() => {
              return of(null);
            })
          );
        } else {
          return of([]);
        }
      }),
      switchMap((results) => {
        //CHECK ERROR DURING INVOICETAX DELETE
        let hasInvoiceTaxError = false;
        if (results) {
          if (results.length > 0) {
            hasInvoiceTaxError = results.find((result) => result.messages.find((msg) => msg.type === 'ERROR')) !== undefined;
          }
        } else {
          hasInvoiceTaxError = true;
        }
        return of(!hasInvoiceTaxError);
      }),
      switchMap((results) => {
        //CHECK C_ORDER EXIST INVOICE
        let obs = of(true);
        if (this.dataStore.data['C_Order_ID']) {
          this.setDatacontainerValue('C_Order_ID', '');
          obs = this.saveInvoiceInStore(this.dataStore, invoiceId).pipe(
            switchMap((saved) => {
              return of(saved);
            })
          );
        }
        return obs;
      }),
      switchMap((isSuccess) => {
        if (isSuccess) {
          //DELETE INVOICE
          return this.dataService.deleteData(this.invoiceTabID, [{ C_Invoice_ID: invoiceId }]).pipe(
            map((results) => {
              let hasInvoiceError = false;
              if (results) {
                if (results.length > 0) {
                  hasInvoiceError = results.find((result) => result.messages.find((msg) => msg.type !== 'SUCCESS')) !== undefined;
                }
              } else {
                hasInvoiceError = true;
              }
              return of(!hasInvoiceError);
            }),
            catchError(() => {
              return of(false);
            })
          );
        } else {
          return of(false);
        }
      }),
      catchError(() => {
        return of(false);
      })
    );
  }
  //#endregion DocManagement
  saveInvoice(isAutoSave = false) {
    if (!this.isQrInfoLoading) {
      const nullValue = getNullValueInData(this.dataStore.data, this.MANDATORY_HEADER_COLUMNS);
      if (nullValue == null) {
        if (this.dataStore.data['C_DocType_ID'] !== this.dataStore.data['C_DocTypeTarget_ID']) {
          this.dataStore.data['C_DocType_ID'] = this.dataStore.data['C_DocTypeTarget_ID'];
        }
        const invoiceId =
          this.dataStore.data['C_Invoice_ID'] instanceof Object
            ? this.dataStore.data['C_Invoice_ID'].id
            : this.dataStore.data['C_Invoice_ID'];
        if (this.subscription) {
          this.subscription.unsubscribe();
          this.subscription = undefined;
        }
        this.subscription = this.saveInvoiceInStore(this.dataStore, invoiceId).subscribe(() => {
          if (!isAutoSave) {
            if (this.saveDoc()) {
              this.messageManager.newMessage(
                new IupicsMessage(
                  this.translateService.instant('generic.success'),
                  this.translateService.instant('specificWindow.quickInvoiceVendor.saveSuccess'),
                  'success'
                )
              );
            }
          }
        });
      } else {
        this.checkMissingField(isAutoSave);
      }
    }
  }
  checkMissingField(isAutoSave = false) {
    const nullValue = getNullValueInData(this.dataStore.data, this.MANDATORY_HEADER_COLUMNS);
    if (
      [
        'AD_Org_ID',
        'DateInvoiced',
        'XX_Validator_ID',
        'M_PriceList_ID',
        'C_DocTypeTarget_ID',
        'C_PaymentTerm_ID',
        'PaymentRule',
        'C_Currency_ID',
        'IsReturnTrx'
      ].includes(nullValue)
    ) {
      this.isSidebarOpen = !this.isSidebarOpen;
    }
    if (!isAutoSave) {
      this.messageManager.newMessage(
        new IupicsMessage(
          this.translateService.instant('generic.warning'),
          this.translateService.instant('specificWindow.quickInvoiceVendor.missingParams'),
          'warning'
        )
      );
    }
  }
  saveInvoiceInStore(datastore: DataStore, id?: number, sync = true) {
    const dataReformat = Object.assign({}, datastore.data);
    Object.keys(dataReformat).forEach((key) => {
      if (dataReformat[key] instanceof Object) {
        dataReformat[key] = dataReformat[key].id;
      }
    });
    return this.poService.save('C_Invoice', dataReformat, id).pipe(
      switchMap((result) => {
        //joinfile need dskey to get back oldcurrentstore info during taggedcolumns generation
        const invoiceId = result['C_Invoice_ID'] instanceof Object ? result['C_Invoice_ID'].id : result['C_Invoice_ID'];
        this.dsKey = {
          parentId: 'quickCreateInvoice',
          recordId: `C_Invoice_ID,${invoiceId}`,
          tabId: this.invoiceTabID,
          windowId: this.windowID
        };
        datastore.key = this.dsKey;
        //Set data_uuid needed for joinfile
        datastore.data['Data_UUID'] = `C_Invoice_ID,${invoiceId}`;
        result['Data_UUID'] = `C_Invoice_ID,${invoiceId}`;
        this.store.copyWindowDataToOldStore(datastore);
        datastore.key = null;
        datastore.data = EditViewUtils.mergeCurrentDataDeepCopy(datastore.data, result);
        if (sync) {
          this.store.syncDataChanges(datastore, result, false, true);
        }
        return of(true);
      }),
      catchError(() => {
        return of(false);
      })
    );
  }
  getCreatedInvoiceLines() {
    const invoiceId =
      this.dataStore.data['C_Invoice_ID'] instanceof Object
        ? this.dataStore.data['C_Invoice_ID'].id
        : this.dataStore.data['C_Invoice_ID'];
    return this.store.getDataGrid(getInvoiceLineRequest(invoiceId, this.ad_language), true).pipe(
      switchMap((response) => {
        if (response && response.data) {
          this.resetlines();
          response.data.forEach((lineData) => {
            const lineDataStore = this.store.newSpecificWindowData(this.formId, this.dataStore);
            lineDataStore.data = lineData;
            this.dataLines.push(lineDataStore);
            this.lineMetaData.forEach((metaData) => {
              const compRef = this.createCustomDesignItem(metaData, true, this.dataLines.length - 1, lineDataStore);
              //make autocomplete overlay visible outside overflow
              compRef.instance['isInsideOverflow'] = true;
              if (compRef) {
                if (metaData.columnName !== 'Description') {
                  compRef.location.nativeElement.setAttribute('tabindex', this.tabindex++);
                } else {
                  compRef.location.nativeElement.setAttribute('tabindex', -1);
                }
                this.dataContainers.pop();
                (<AbstractDataContainer>compRef.instance).data['fieldValueModifiedSub'].unsubscribe();
                (<AbstractDataContainer>compRef.instance).fieldValueModified.subscribe((dataStored) => {
                  const item = this.specificData.items.find((specificItem) => {
                    return metaData.type === CustomDesignItemType.FIELD
                      ? metaData.columnName === specificItem.data.columnName
                      : metaData.tableName === specificItem.name;
                  });
                  this.notifyFromDataChange(item, this.dataLines.indexOf(dataStored));
                });
                if (!this.dataContainersLine[this.dataLines.length - 1]) {
                  this.dataContainersLine[this.dataLines.length - 1] = [];
                }
                this.dataContainersLine[this.dataLines.length - 1].push(<AbstractDataContainer>compRef.instance);

                (<AbstractDataContainer>compRef.instance).isSetDefaultValue = true;
                (<AbstractDataContainer>compRef.instance).setNewData(lineDataStore);
              }
            });
            this.store.syncDataChanges(lineDataStore, lineData);
          });
          return of(true);
        } else {
          return of(false);
        }
      }),
      catchError((err) => {
        return of(false);
      })
    );
  }
  refreshInvoice(onlyHeader = false) {
    this.updateLoading(true);
    const invoiceId =
      this.dataStore.data['C_Invoice_ID'] instanceof Object
        ? this.dataStore.data['C_Invoice_ID'].id
        : this.dataStore.data['C_Invoice_ID'];
    let obs = of(true);
    if (!onlyHeader) {
      obs = this.getCreatedInvoiceLines();
    }
    obs
      .pipe(
        switchMap((getLineSuccess) => {
          if (getLineSuccess) {
            return this.store.getDataGrid(getInvoiceRequest(invoiceId, this.ad_language));
          } else {
            this.messageManager.newMessage(
              new IupicsMessage(
                this.translateService.instant('generic.error'),
                this.translateService.instant('specificWindow.quickInvoiceVendor.log.invoiceLineNotFound'),
                'error'
              )
            );
            return of(null);
          }
        }),
        catchError(() => {
          return of(null);
        })
      )
      .subscribe((invoiceData) => {
        this.updateLoading(false);
        if (invoiceData) {
          this.dataStore.data = invoiceData.data[0];
          this.store.syncDataChanges(this.dataStore, invoiceData.data[0], false, true);
          this.updateHeader();
        } else {
          this.messageManager.newMessage(
            new IupicsMessage(
              this.translateService.instant('generic.error'),
              this.translateService.instant('specificWindow.quickInvoiceVendor.log.invoiceNotFound'),
              'error'
            )
          );
        }
      });
  }
  saveInvoiceLineInStore(datastore: DataStore, id?: number) {
    const tmpStore = new DataStore();
    tmpStore.data = this.getCurrentContext();
    const dataReformat = Object.assign({}, this.getCurrentContext(datastore, tmpStore));
    Object.keys(dataReformat).forEach((key) => {
      if (dataReformat[key] instanceof Object) {
        dataReformat[key] = dataReformat[key].id;
      }
    });
    return this.poService.save('C_InvoiceLine', dataReformat, id);
  }
  saveInvoiceLine(lineDataStore: DataStore) {
    const isNew = lineDataStore.data['C_InvoiceLine_ID'] == null;
    if (
      (lineDataStore.data['M_Product_ID'] instanceof Object || lineDataStore.data['C_Charge_ID'] instanceof Object) &&
      lineDataStore.data['QtyEntered'] != null &&
      (isNew || (lineDataStore.data['PriceEntered'] != null && lineDataStore.data['C_Tax_ID'] != null))
    ) {
      const id =
        lineDataStore.data['C_InvoiceLine_ID'] instanceof Object
          ? lineDataStore.data['C_InvoiceLine_ID'].id
          : lineDataStore.data['C_InvoiceLine_ID'];
      const sub$ = this.saveInvoiceLineInStore(lineDataStore, id).subscribe((result) => {
        //if new we should use callout again because record_id is needed
        lineDataStore.data['C_InvoiceLine_ID'] = result['C_InvoiceLine_ID'];
        if (isNew) {
          if (lineDataStore.data['M_Product_ID'] instanceof Object) {
            this.calloutProduct(lineDataStore);
          } else {
            this.calloutCharge(lineDataStore);
          }
          this.messageManager.newMessage(
            new IupicsMessage(
              this.translateService.instant('generic.success'),
              this.translateService.instant('specificWindow.quickInvoiceVendor.saveLineSuccess'),
              'success'
            )
          );
        } else {
          this.store.syncDataChanges(lineDataStore, result, false, true);
        }
        this.updateHeader();
        sub$.unsubscribe();
      });
    }
  }
  /**
   * Permet d'ajouter visuellement une nouvelle ligne de commande + création d'un nouveau datastore
   */
  newLine() {
    if (
      this.dataStore &&
      this.dataStore.data &&
      this.dataStore.data['C_Invoice_ID'] &&
      this.dataStore.data['DocStatus'] &&
      (this.dataStore.data['DocStatus'] !== 'CO' || this.dataStore.data['DocStatus'].id !== 'CO')
    ) {
      this.dataLines.push(this.store.newSpecificWindowData(this.formId));
      this.dataLines[this.dataLines.length - 1].data['Line'] = this.dataLines.length * 10;

      this.lineMetaData.forEach((metaData) => {
        const compRef = this.createCustomDesignItem(metaData, true, this.dataLines.length - 1);
        //make autocomplete overlay visible outside overflow
        compRef.instance['isInsideOverflow'] = true;
        if (compRef) {
          if (metaData.columnName !== 'Description') {
            compRef.location.nativeElement.setAttribute('tabindex', this.tabindex++);
          } else {
            compRef.location.nativeElement.setAttribute('tabindex', -1);
          }
          this.dataContainers.pop();
          (<AbstractDataContainer>compRef.instance).data['fieldValueModifiedSub'].unsubscribe();
          (<AbstractDataContainer>compRef.instance).fieldValueModified.subscribe((dataStored) => {
            const item = this.specificData.items.find((specificItem) => {
              return metaData.type === CustomDesignItemType.FIELD
                ? metaData.columnName === specificItem.data.columnName
                : metaData.tableName === specificItem.name;
            });
            this.notifyFromDataChange(item, this.dataLines.indexOf(dataStored));
          });
          if (!this.dataContainersLine[this.dataLines.length - 1]) {
            this.dataContainersLine[this.dataLines.length - 1] = [];
          }
          this.dataContainersLine[this.dataLines.length - 1].push(<AbstractDataContainer>compRef.instance);

          (<AbstractDataContainer>compRef.instance).setNewData(this.dataLines[this.dataLines.length - 1]);
        }
      });
    } else {
      this.checkMissingField();
    }
  }
  removeLine(lineDataStore: DataStore, index: number) {
    if (
      this.dataStore &&
      this.dataStore.data['DocStatus'] &&
      (this.dataStore.data['DocStatus'] !== 'CO' || this.dataStore.data['DocStatus'].id !== 'CO')
    ) {
      const id =
        lineDataStore.data['C_InvoiceLine_ID'] instanceof Object
          ? lineDataStore.data['C_InvoiceLine_ID'].id
          : lineDataStore.data['C_InvoiceLine_ID'];
      let obs = of(null);
      if (id) {
        obs = this.store.deleteWindowSpecificData('C_InvoiceLine', lineDataStore, id);
      }
      this.subscriptions.push(
        obs.subscribe((result) => {
          this.lineMetaData.forEach((metaData) => {
            this[metaData.vcr].toArray()[index].clear();
          });
          for (let i = index + 1; i < this.dataLines.length; i++) {
            this.lineMetaData.forEach((metaData) => {
              const element = (<ViewContainerRef>this[metaData.vcr].toArray()[i]).detach();
              (<ViewContainerRef>this[metaData.vcr].toArray()[i - 1]).insert(element);
            });
          }
          this.dataLines.splice(index, 1);
          this.dataContainersLine.splice(index, 1);
          if (result) {
            this.updateHeader();
          }
        })
      );
    }
  }

  createPartnerLocation(locationData: any): Observable<boolean> {
    if (!locationData) {
      this.pushQuickInvoiceLog(this.translateService.instant('specificWindow.quickInvoiceVendor.log.createAddressError'), false);
      return of(false);
    }
    const partnerLocationReformat = {
      AD_Client_ID: this.dataStore.data['AD_Client_ID'],
      AD_Org_ID: this.dataStore.data['AD_Org_ID'],
      C_BPartner_ID: this.dataStore.data['C_BPartner_ID'],
      Z_TransportMode: null,
      Z_INCOTERMS_ID: null,
      IsRemitTo: 'Y',
      C_SalesRegion_ID: null,
      C_Location_ID: locationData.C_Location_ID,
      IsActive: 'Y',
      IsPayFrom: 'Y',
      IsShipTo: 'Y',
      Name: this.qrInfo.getCompleteAddress(),
      IsBillTo: 'Y',
      Phone2: null,
      Phone: null,
      ISDN: null,
      Fax: null,
      Z_UnloadingPlace: null
    };
    Object.keys(partnerLocationReformat).forEach((key) => {
      if (partnerLocationReformat[key] instanceof Object) {
        partnerLocationReformat[key] = partnerLocationReformat[key].id;
      }
    });
    return this.poService.save('C_BPartner_Location', partnerLocationReformat).pipe(
      tap(
        (result) => {
          const component = this.getDatacontainer('C_BPartner_Location_ID');
          this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg02');
          component.dataChange(result['C_BPartner_Location_ID']);
          return of(true);
        },
        () => {
          this.pushQuickInvoiceLog(
            this.translateService.instant('specificWindow.quickInvoiceVendor.log.createAddressError'),
            false
          );
          return of(false);
        }
      )
    );
  }
  createAddress(): Observable<boolean> {
    return this.getCountryInfoObs().pipe(
      switchMap((result) => {
        if (result) {
          if (result.NAME) {
            this.qrInfo.countryName = result.NAME;
          }
          const locationReformat = {
            IsActive: 'Y',
            Address2: this.qrInfo.address2,
            C_Country_ID: result.C_COUNTRY_ID,
            Address1: this.qrInfo.address1,
            City: this.qrInfo.city,
            Postal_Add: null,
            C_City_ID: null,
            RegionName: null,
            C_Region_ID: null,
            AD_Client_ID: this.dataStore.data['AD_Client_ID'],
            AD_Org_ID: this.dataStore.data['AD_Org_ID'],
            Postal: this.qrInfo.postalCode
          };
          Object.keys(locationReformat).forEach((key) => {
            if (locationReformat[key] instanceof Object) {
              locationReformat[key] = locationReformat[key].id;
            }
          });
          return this.poService.save('C_Location', locationReformat).pipe(
            switchMap((result) => {
              return this.createPartnerLocation(result);
            }),
            catchError(() => {
              this.pushQuickInvoiceLog(
                this.translateService.instant('specificWindow.quickInvoiceVendor.log.createAddressError'),
                false
              );
              return of(false);
            })
          );
        } else {
          this.pushQuickInvoiceLog(
            this.translateService.instant('specificWindow.quickInvoiceVendor.log.createAddressError'),
            false
          );
          return of(false);
        }
      })
    );
  }
  createBankAccount(partnerId): Observable<boolean> {
    const dataReformat = {
      AD_Client_ID: this.dataStore.data['AD_Client_ID'],
      AD_Org_ID: this.dataStore.data['AD_Org_ID'],
      C_BPartner_ID: partnerId,
      IsActive: 'Y',
      IsACH: 'Y',
      BPBankAcctUse: 'B',
      IBAN: this.ibanValue,
      A_Name: this.qrInfo.vendorName,
      A_Zip: this.qrInfo.postalCode,
      A_CountryCode: this.qrInfo.countryCode,
      A_Country: this.qrInfo.countryCode,
      A_City: this.dataStore.data['AD_Client_ID'],
      ISO_Code: this.dataStore.data['C_Currency_ID']
        ? this.dataStore.data['C_Currency_ID'].displayValue
          ? this.dataStore.data['C_Currency_ID'].displayValue
          : this.dataStore.data['C_Currency_ID']
        : this.currency,
      Z_OEurope: 'N'
    };
    Object.keys(dataReformat).forEach((key) => {
      if (dataReformat[key] instanceof Object) {
        dataReformat[key] = dataReformat[key].id;
      }
    });
    return this.poService.save('C_BP_BankAccount', dataReformat).pipe(
      tap(
        (result) => {
          this.setDatacontainerValue('C_BP_BankAccount_ID', result['C_BP_BankAccount_ID']);
          this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg06');
          return of(true);
        },
        () => {
          this.pushQuickInvoiceLog(this.translateService.instant('specificWindow.quickInvoiceVendor.log.createBankError'), false);
          return of(false);
        }
      )
    );
  }

  //#endregion CRUD
  //#region LOGS

  private gotoBottomOfLogs() {
    setTimeout(() => {
      this.logsContainer.nativeElement.scrollTop =
        this.logsContainer.nativeElement.scrollHeight - this.logsContainer.nativeElement.clientHeight;
    }, 100);
  }
  clearLogs() {
    this.logs = [];
  }

  getMessage(value: string) {
    const msgFound = this.messages.find((msg) => msg.Value === value);
    let msg = value;
    if (msgFound) {
      msg = msgFound.MsgText;
    }
    return msg;
  }
  pushQuickInvoiceLog(value: string, isSuccess = true, type: 'msg' | 'title' = 'msg') {
    if (this.qrInfo && type === 'msg' && !isSuccess) {
      this.qrInfo.logicalWarnings++;
    }
    const msgFound = this.getMessage(value);
    this.logs.push(new QuickInvoiceLog(msgFound, type, isSuccess));
    this.gotoBottomOfLogs();
  }
  //#endregion LOGS
  //#region QRInfo
  resetQRInfo() {
    this.qrInfoString = '';
    this.qrInfoStringLast = '';
    this.qrInfo = new QRInfo([]);
  }
  checkQRInfo(): boolean {
    if (this.qrInfo.errors.length > 0) {
      this.pushQuickInvoiceLog(
        this.translateService.instant('specificWindow.quickInvoiceVendor.log.missingMandatoryParams'),
        false,
        'title'
      );

      let errorMessage = '';
      this.qrInfo.errors.forEach(
        (err) => (errorMessage += this.translateService.instant('specificWindow.quickInvoiceVendor.qr.buildError.' + err) + ', ')
      );
      this.pushQuickInvoiceLog(errorMessage.substring(0, errorMessage.length - 2), false, 'msg');
      return false;
    }
    return true;
  }
  setQRInfo() {
    if (this.qrInfoString != null && this.qrInfoString.trim().length > 0) {
      const qrInfoStringBackup = this.qrInfoString;
      this.resetInvoice();
      this.qrInfoString = qrInfoStringBackup;
      this.qrInfo = new QRInfo(this.qrInfoString.split('\n'));
      if (this.checkQRInfo()) {
        this.setInvoiceInfoFromQRInfo();
        this.qrInfoStringLast = this.qrInfoString;
      } else {
        this.qrInfo.logicalErrors++;
      }
    } else {
      this.qrInfoString = this.qrInfoStringLast;
    }
  }
  clearQRArea() {
    this.clearLogs();
    this.resetQRInfo();
  }
  setInvoiceInfoFromQRInfo() {
    //remove validation tmp
    const component = this.getDatacontainer('C_BP_BankAccount_ID');
    const validation = component.data.validationCode;
    component.data.validationCode = null;
    this.isQrInfoLoading = true;
    this.subscriptions.push(
      //SEARCH IBAN AND VENDOR
      this.setPartnerObs()
        .pipe(
          switchMap((canContinue: boolean) => {
            if (canContinue) {
              //SEARCH ADDRESS
              return this.searchAddressObs();
            } else {
              this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg03', false);
              return of(false);
            }
          }),
          switchMap((addressFound: boolean) => {
            if (!addressFound) {
              this.setDatacontainerValue('C_BPartner_Location_ID', null);
            }
            //SEARCH CURRENCY
            return this.setCurrencyObs();
          })
        )
        .subscribe((canContinue) => {
          component.data.validationCode = validation;
          if (canContinue) {
            let obs = of(null);
            //SEARCH AMOUNTOPAY
            if (this.setAmountToPay()) {
              //SEARCH ORDER
              obs = this.setOrderObs();
            }
            this.subscriptions.push(
              obs.subscribe(() => {
                //SEARCH BVR
                this.setBVR();
                //SEARCH REF
                this.setRef();
                this.isQrInfoLoading = false;
                this.saveInvoice();
              })
            );
          } else {
            this.isQrInfoLoading = false;
            this.saveInvoice();
          }
        })
    );
  }
  //#endregion QRInfo
  //#region QR INFO CALL
  getCountryInfoObs() {
    return this.store.getDataGrid(getCountryDataRequest(this.qrInfo, this.ad_language), true).pipe(
      switchMap((response: any) => {
        //SEARCH COUNTRY USING ISO
        if (response && response.data && response.data[0]) {
          return of(response.data[0]);
        } else {
          this.pushQuickInvoiceLog(this.translateService.instant('specificWindow.quickInvoiceVendor.log.countryNotExist'), false);
          return of(null);
        }
      }),
      catchError((err) => {
        this.pushQuickInvoiceLog(this.translateService.instant('specificWindow.quickInvoiceVendor.log.countryNotExist'), false);
        return of(null);
      })
    );
  }
  setPartnerObs(): Observable<boolean> {
    const component = this.getDatacontainer('C_BPartner_ID');
    let obs;
    //SEARCH IBAN
    this.pushQuickInvoiceLog(this.translateService.instant('specificWindow.quickInvoiceVendor.qr.searchIban'), true, 'title');
    this.pushQuickInvoiceLog(this.qrInfo.IBAN);
    if (this.qrInfo.IBAN && this.qrInfo.IBAN.length > 0) {
      this.ibanValue = this.qrInfo.IBAN;
      //SEARCH VENDOR USING IBAN
      obs = this.store.getDataGrid(getBankRequest(this.qrInfo, this.ad_language), true);
    } else {
      this.pushQuickInvoiceLog(this.translateService.instant('specificWindow.quickInvoiceVendor.log.ibanNotFound'), false, 'msg');
      return of(false);
    }

    return obs.pipe(
      switchMap((response: any) => {
        //SEARCH VENDOR USING IBAN
        this.pushQuickInvoiceLog(
          this.translateService.instant('specificWindow.quickInvoiceVendor.qr.searchVendor'),
          true,
          'title'
        );
        this.pushQuickInvoiceLog(this.qrInfo.vendorName);
        if (response && response.data && response.data[0] && response.data[0]['C_BPartner_ID']) {
          if (component) {
            this.ibanValue = response.data[0]['IBAN'];
            this.setDatacontainerValue('C_BP_BankAccount_ID', response.data[0]['C_BP_BankAccount_ID']);
            this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg04');
            component.dataChange(response.data[0]['C_BPartner_ID']);
            this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg05');
          }
          return of(true);
        } else {
          return this.store.getDataGrid(getPartnerRequest(this.qrInfo, this.ad_language), true).pipe(
            switchMap((response2: any) => {
              //SEARCH VENDOR USING HIS NAME
              if (response2 && response2.data && response2.data[0] && response2.data[0]['C_BPartner_ID']) {
                component.dataChange(response2.data[0]['C_BPartner_ID']);
                this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg05');
                //CREATE BANKACCOUNT
                return this.createBankAccount(response2.data[0]['C_BPartner_ID']);
              } else {
                this.pushQuickInvoiceLog(
                  this.translateService.instant('specificWindow.quickInvoiceVendor.log.vendorNotFound'),
                  false
                );
                return of(false);
              }
            }),
            catchError((err) => {
              this.pushQuickInvoiceLog(
                this.translateService.instant('specificWindow.quickInvoiceVendor.log.vendorNotFound'),
                false
              );
              return of(false);
            })
          );
        }
      })
    );
  }
  searchAddressObs(): Observable<boolean> {
    //check whether or not callout from partner is done
    this.pushQuickInvoiceLog(this.translateService.instant('specificWindow.quickInvoiceVendor.qr.searchAddress'), true, 'title');
    this.pushQuickInvoiceLog(this.qrInfo.getCompleteAddress());
    if (this.dataStore.calloutStates.size > 0) {
      return this.dataStore.calloutStackEmptied.pipe(
        switchMap(() => {
          return this.setAddressObs();
        })
      );
    } else {
      return this.setAddressObs();
    }
  }
  setAddressObs(): Observable<any> {
    return this.store.getDataGrid(getPartnerLocationRequest(this.qrInfo), true).pipe(
      switchMap((response: any) => {
        if (response && response.data && response.data[0] && response.data[0]['C_BPARTNER_LOCATION_ID']) {
          this.setDatacontainerValue('C_BPartner_Location_ID', response.data[0]['C_BPARTNER_LOCATION_ID']);
          this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg01');
        } else {
          return this.createAddress();
        }
        return of(true);
      })
    );
  }
  setCurrencyObs(): Observable<any> {
    this.pushQuickInvoiceLog(this.translateService.instant('specificWindow.quickInvoiceVendor.qr.searchCurrency'), true, 'title');
    if (this.qrInfo.currency && this.qrInfo.currency.length > 0) {
      this.pushQuickInvoiceLog(this.qrInfo.currency);
      return this.store.getDataGrid(getCurrencyRequest(this.qrInfo, this.ad_language), true).pipe(
        switchMap((response: any) => {
          if (response && response.data && response.data[0] && response.data[0]['C_Currency_ID']) {
            this.setDatacontainerValue('C_Currency_ID', response.data[0]['C_Currency_ID']);
            this.setCurrencySymbol(response.data[0]['C_Currency_ID']);
            this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg07');
            return of(true);
          } else {
            this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg08', false);
            return of(false);
          }
        })
      );
    } else {
      this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg08', false);
      return of(false);
    }
  }
  setOrderObs(): Observable<any> {
    this.pushQuickInvoiceLog(this.translateService.instant('specificWindow.quickInvoiceVendor.qr.searchOrder'), true, 'title');
    return this.store
      .getDataGrid(getOrderRequest(this.qrInfo, this.dataStore.data, this.ad_language, this.marginError), true)
      .pipe(
        switchMap((response: any) => {
          if (response && response.data && response.data[0] && response.data[0]['C_Order_ID']) {
            const component = this.getDatacontainer('C_Order_ID');
            if (component) {
              component.dataChange(response.data[0]['C_Order_ID']);
              if (response.data[0]['DocumentNo']) {
                this.pushQuickInvoiceLog(response.data[0]['DocumentNo']);
              }
              this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg11');
            }
            return of(true);
          } else {
            this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg12', false);
            return of(false);
          }
        })
      );
  }
  setCurrencySymbol(currencyId: number) {
    const component = this.getDatacontainer('C_Currency_ID');
    if (component) {
      if (component.cache && component.cache.currency && component.cache.currency.hasOwnProperty(currencyId)) {
        this.currency = component.cache.currency[currencyId]['CurSymbol'];
      } else {
        Global.getCurrencySymbol(currencyId, this.store, this.connectorService.getIupicsDefaultLanguage().iso_code).subscribe(
          (res: any) => {
            if (res) {
              component.cache.currency[res.C_Currency_ID] = res;
              this.currency = component.cache.currency[currencyId]['CurSymbol'];
            }
          }
        );
      }
    }
  }
  setAmountToPay(): boolean {
    this.pushQuickInvoiceLog(
      this.translateService.instant('specificWindow.quickInvoiceVendor.qr.searchAmountToPay'),
      true,
      'title'
    );
    if (this.qrInfo.amountToPay != null && this.qrInfo.amountToPay !== undefined) {
      this.amountToPay = this.qrInfo.amountToPay;
      this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg09');
      this.pushQuickInvoiceLog(this.qrInfo.amountToPay + '');
      return true;
    } else {
      this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg10', false);
      return false;
    }
  }
  setBVR(): boolean {
    this.pushQuickInvoiceLog(this.translateService.instant('specificWindow.quickInvoiceVendor.qr.searchBVR'), true, 'title');
    if (this.qrInfo.bvr && this.qrInfo.bvr.length > 0) {
      this.pushQuickInvoiceLog(this.qrInfo.bvr);
      this.setDatacontainerValue('XX_BVR', this.qrInfo.bvr);
      this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg13');
      return true;
    } else {
      this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg14', false);
      return false;
    }
  }
  setRef(): boolean {
    this.pushQuickInvoiceLog(
      this.translateService.instant('specificWindow.quickInvoiceVendor.qr.searchVendorRef'),
      true,
      'title'
    );
    if (this.qrInfo.vendorRef && this.qrInfo.vendorRef.length > 0) {
      this.pushQuickInvoiceLog(this.qrInfo.vendorRef);
      this.setDatacontainerValue('Description', this.qrInfo.vendorRef);
      this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg15');
      return true;
    } else {
      this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg16', false);
      return false;
    }
  }
  //#endregion SETTING QR INFO CALL
  //#region AD DATA
  initAD_Data() {
    this.tableID = 318;
    this.invoiceTabID = 290;
    this.invoiceLineTabID = 291;
    this.invoiceTaxTabID = 292;
    this.windowID = 183;
    this.subscriptions.push(
      this.store.getDataGrid(getLogMessageRequest(this.ad_language), true).subscribe((results) => {
        this.messages = results.data as any[];
        const msg = this.getMessage('SZ_VendorInvoiceSetting01');
        this.marginError = msg ? (msg.replace ? parseFloat(msg.replace(',', '.')) / 100 : 0.05) : 0.05;
      })
    );
    this.subscriptions.push(
      this.store.getDataGrid(getTabDataRequest(), true).subscribe((response) => {
        if (!this.data) {
          this.data = {};
        }
        if (response && response.data) {
          const invoiceLineTab = response.data.find((tab) => tab['TABLENAME'] === 'C_InvoiceLine');
          const invoiceTab = response.data.find((tab) => tab['TABLENAME'] === 'C_Invoice');
          const invoiceTaxTab = response.data.find((tab) => tab['TABLENAME'] === 'C_InvoiceTax');
          if (invoiceLineTab) {
            this.invoiceLineTabID = invoiceLineTab['AD_TAB_ID'];
          }
          if (invoiceTaxTab) {
            this.invoiceTaxTabID = invoiceTaxTab['AD_TAB_ID'];
          }
          if (invoiceTab) {
            this.data.DocFilters = invoiceTab['DOCFILTERS'];
            this.data.TaggedColumns = invoiceTab['TAGGEDCOLUMNS'];
            this.tableID = invoiceTab['AD_TABLE_ID'];
            this.invoiceTabID = invoiceTab['AD_TAB_ID'];
            this.windowID = invoiceTab['AD_WINDOW_ID'];
          }
        }
      })
    );
  }
  //#endregion AD DATA
}
//#region Model
class QuickInvoiceLog {
  value: string;
  type: 'title' | 'msg';
  isSuccess: boolean;
  constructor(value: string, type: 'title' | 'msg' = 'msg', isSuccess = true) {
    this.value = value;
    this.type = type;
    this.isSuccess = isSuccess;
  }
}
class ADMessage {
  Value: string;
  MsgText: string;
  constructor(value: string, msgText: string) {
    this.Value = value;
    this.MsgText = msgText;
  }
  //#endregion Model
}
