<div class="account" (click)="clickCarret.emit($event)">
  <div class="avatar">
    <div [ngClass]="[account?.isAnotherAccount ? 'avatar-circle-another' : 'avatar-circle']">
      <span *ngIf="!account?.isAnotherAccount" class="avatar-circle-content">{{ account?.avatarLetters | uppercase }}</span>
      <span *ngIf="account?.isAnotherAccount" class="avatar-circle-content-another"> <i class="icon-ico-tiers"></i> </span>
    </div>
  </div>
  <span [ngClass]="[account?.isAnotherAccount ? 'anotheraccount-name' : 'account-name']"
    >{{ account?.login }}
    <i *ngIf="displayCarret" class="icon-ico-open" aria-hidden="true"></i>
  </span>
</div>
