import { AgGridAngular } from '@ag-grid-community/angular';
import { AllModules, Module } from '@ag-grid-enterprise/all-modules';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { IupicsTableDataHeader } from '@iupics-manager/models/iupics-data';
import { IupicsEvent } from '@iupics-manager/models/iupics-event';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'iu-grid-tab-rowdata',
  templateUrl: './grid-tab-rowdata.component.html',
  styleUrls: ['./grid-tab-rowdata.component.scss'],
})
export class GridTabRowdataComponent extends AbstractDynamicComponent implements OnInit {
  @Input()
  columnsTableHeader: IupicsTableDataHeader[] = [];
  @Input()
  rowsData: any[] = [];
  localeText: any;

  @ViewChild('agGrid')
  agGrid: AgGridAngular;
  public modules: Module[] = AllModules;

  constructor(private translate: TranslateService) {
    super();
    this.gridToolsInternationalization();
  }

  gridReady() {
    this.agGrid.api.hideOverlay();
    this.agGrid.api.closeToolPanel();
  }

  ngOnInit() {}

  onChildUpdate(event): void {}

  onSiblingUpdate(event: IupicsEvent) {}

  onRemoveComponent(event: IupicsEvent) {}

  gridToolsInternationalization(): any {
    this.localeText = {
      // for filter panel
      page: this.translate.instant('gridTools.page'),
      more: this.translate.instant('gridTools.more'),
      to: this.translate.instant('gridTools.to'),
      of: this.translate.instant('gridTools.of'),
      next: this.translate.instant('gridTools.next'),
      last: this.translate.instant('gridTools.last'),
      first: this.translate.instant('gridTools.first'),
      previous: this.translate.instant('gridTools.previous'),
      loadingOoo: this.translate.instant('gridTools.loadingOoo'),

      // for set filter
      selectAll: this.translate.instant('gridTools.selectAll'),
      searchOoo: this.translate.instant('gridTools.searchOoo'),
      blanks: this.translate.instant('gridTools.blanks'),

      // for number filter and text filter
      filterOoo: this.translate.instant('gridTools.filterOoo'),
      applyFilter: this.translate.instant('gridTools.applyFilter'),

      // for number filter
      equals: this.translate.instant('gridTools.equals'),
      lessThan: this.translate.instant('gridTools.lessThan'),
      greaterThan: this.translate.instant('gridTools.greaterThan'),

      // for text filter
      contains: this.translate.instant('gridTools.contains'),
      notContains: this.translate.instant('gridTools.notContains'),
      clearFilter: this.translate.instant('gridTools.clearFilter'),
      startsWith: this.translate.instant('gridTools.startsWith'),
      endsWith: this.translate.instant('gridTools.endsWith'),

      // the header of the default group column
      group: this.translate.instant('gridTools.group'),

      // tool panel
      columns: this.translate.instant('gridTools.columns'),
      rowGroupColumns: this.translate.instant('gridTools.rowGroupColumns'),
      rowGroupColumnsEmptyMessage: this.translate.instant('gridTools.rowGroupColumnsEmptyMessage'),
      valueColumns: this.translate.instant('gridTools.valueColumns'),
      pivotMode: this.translate.instant('gridTools.pivotMode'),
      groups: this.translate.instant('gridTools.groups'),
      values: this.translate.instant('gridTools.values'),
      pivots: this.translate.instant('gridTools.pivots'),
      valueColumnsEmptyMessage: this.translate.instant('gridTools.valueColumnsEmptyMessage'),
      pivotColumnsEmptyMessage: this.translate.instant('gridTools.pivotColumnsEmptyMessage'),
      toolPanelButton: this.translate.instant('gridTools.toolPanelButton'),

      // other
      noRowsToShow: this.translate.instant('gridTools.noRowsToShow'),

      // enterprise menu
      pinColumn: this.translate.instant('gridTools.pinColumn'),
      valueAggregation: this.translate.instant('gridTools.valueAggregation'),
      autosizeThiscolumn: this.translate.instant('gridTools.autosizeThiscolumn'),
      autosizeAllColumns: this.translate.instant('gridTools.autosizeAllColumns'),
      groupBy: this.translate.instant('gridTools.groupBy'),
      ungroupBy: this.translate.instant('gridTools.ungroupBy'),
      resetColumns: this.translate.instant('gridTools.resetColumns'),
      expandAll: this.translate.instant('gridTools.expandAll'),
      collapseAll: this.translate.instant('gridTools.collapseAll'),
      toolPanel: this.translate.instant('gridTools.toolPanel'),
      export: this.translate.instant('gridTools.export'),
      csvExport: this.translate.instant('gridTools.csvExport'),
      excelExport: this.translate.instant('gridTools.excelExport'),

      // enterprise menu pinning
      pinLeft: this.translate.instant('gridTools.pinLeft'),
      pinRight: this.translate.instant('gridTools.pinRight'),
      noPin: this.translate.instant('gridTools.noPin'),

      // enterprise menu aggregation and status panel
      sum: this.translate.instant('gridTools.sum'),
      min: this.translate.instant('gridTools.min'),
      max: this.translate.instant('gridTools.max'),
      none: this.translate.instant('gridTools.none'),
      count: this.translate.instant('gridTools.count'),
      average: this.translate.instant('gridTools.average'),

      // standard menu
      copy: this.translate.instant('gridTools.copy'),
      copyWithHeaders: this.translate.instant('gridTools.copyWithHeaders'),
      ctrlC: this.translate.instant('gridTools.ctrlC'),
      paste: this.translate.instant('gridTools.paste'),
      ctrlV: this.translate.instant('gridTools.ctrlV'),
    };
  }
}
