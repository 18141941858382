<div class="app" (keydown)="handleKeyPressed($event)">
  <div *ngIf="globalEnv.isActiveRequest()" class="loader-spinner">
    <img alt="loading" class="spinner" src="assets/themes/iupics/img/ico-loader.gif" />
  </div>
  <router-outlet></router-outlet>
  <div *ngIf="successMessage" class="clearMessageBanner">
    <i class="icon-ico-close" style="cursor: pointer;" (click)="closeSuccessMessage($event)"></i>
    <div class="iu-banner-message">
      <i class="iu-banner-icon fa-lg fas fa-check"></i>
      <span class="iu-banner-message-text"> {{ successMessage.message }} </span>
    </div>
  </div>
</div>
