<div class="wd-notification-center">
  <h1 class="iu-notification-center-title">
    {{ 'notificationCenterUi.notificationCenter' | translate }}
  </h1>
  <div [ngClass]="[isInfoDialogShow ? 'error' : '', 'buttonContainer']">
    <button
      pButton
      tooltipPosition="top"
      showDelay="250"
      life="3"
      pTooltip="{{ 'notificationCenterUi.deleteAll' | translate }}"
      type="button"
      icon="icon-ico-delete"
      (click)="showDeleteDialog($event)"
      data-cy="notification-center-deleteAll-btn"
    ></button>
    <!-- keybind à voir -->
    <button
      pButton
      tooltipPosition="top"
      showDelay="250"
      life="3"
      pTooltip="{{ 'notificationCenterUi.viewAll' | translate }}"
      icon="far fa-eye"
      type="button"
      (click)="viewAllNotifications($event)"
      data-cy="notification-center-viewAll-btn"
    ></button>
    <button
      pButton
      tooltipPosition="top"
      showDelay="250"
      life="3"
      pTooltip="{{ 'notificationCenterUi.refresh' | translate }}"
      icon="icon-ico-refresh3x"
      type="button"
      (click)="refresh($event)"
    ></button>
    <div [ngClass]="[!isInfoDialogShow ? 'hidden' : '', 'messageContainer']">
      <i class="icon-ico-delete"></i>
      <p>{{ 'notificationCenterUi.confirmDelete' | translate }}</p>
      <button
        pButton
        icon="fa fa-check"
        type="button"
        (click)="deleteAllNotifications($event)"
        data-cy="notification-center-deleteAll-btn-confirm"
      ></button>
      <button pButton icon="fa fa-ban" type="button" (click)="cancelInfoDialog($event)"></button>
    </div>
    <!-- <div [ngClass]="[!isInfoDialogShow ? '' : 'hidden', 'iu-notification-center-header']">
      <input #filterInput type="text" (keyup)="updateFilterOptions($event, filterInput.value)" value="" width="10vw" />
    </div> -->
  </div>
  <!-- keybind à voir -->
  <p-scrollPanel [style]="{ height: 'calc(100% - 74px)', border: 'none' }" styleClass="notification-center-scroll-panel">
    <div
      *ngFor="let item of datas$ | async | filterList: filterListOptions"
      class="wd-notification-center-item"
      [ngClass]="[item.isError ? 'error' : 'success']"
      (click)="handleNotification($event, item)"
    >
      <iu-notification
        [item]="item"
        [areButtonsDisabled]="areButtonsDisabled"
        (notificationEmitter)="deleteNotification($event)"
        (dlFileEmitter)="handleDlFile($event, item)"
      ></iu-notification>
    </div>
  </p-scrollPanel>
</div>
